import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

export const MyLectureDetailPageContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-bottom: 150px;
  padding-top: 60px;

  @media screen and (max-width: 1700px) {
    padding-top: 60px;
    padding-bottom: 150px;
  }
`;

export const MyLectureDetailTitle = styled.div`
  ${FONT_STYLES.P_B};
  font-size: 36px;
  text-align: center;
`;

export const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_3};
  margin: 43px auto;
`;

export const LectureDetailBox = styled.div`
  padding: 53px 91px;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;
`;

export const LectureInfo = styled.div`
  display: flex;
  margin-bottom: 66px;

  & > div {
    :nth-child(1) {
      padding-right: 87px;
      & > img {
        width: 296px;
        height: 296px;
        border-radius: 18px;
      }
    }

    :nth-child(2) {
      & > div {
        display: flex;
        :nth-child(1) {
          ${FONT_STYLES.P_L}
          font-size: 15px;
          padding-bottom: 5px;
        }
        :nth-child(2) {
          ${FONT_STYLES.P_SB}
          font-size: 24px;
          padding-bottom: 13.5px;
        }
        :nth-child(3) {
          padding: 13.5px 0;
          & > p {
            :nth-child(1) {
              ${FONT_STYLES.P_T}
              font-size: 18px;
              padding-right: 13px;
            }
          }
        }

        :nth-child(4),
        :nth-child(5),
        :nth-child(6) {
          padding: 13.5px 0;
          & > p {
            :nth-child(1) {
              ${FONT_STYLES.P_T}
              font-size: 18px;
              width: 81px;
            }
            :nth-child(2) {
              ${FONT_STYLES.P_R}
            }
          }
        }

        :nth-child(7) {
          display: flex;
          justify-content: space-between;
          padding-bottom: 12px;
          margin-top: 37px;
        }
      }
    }
  }
`;

export const LectureProgressBox = styled.div``;

export const LectureItems = styled.div`
  & > div {
    ${FONT_STYLES.P_R}
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    border-top: 1px solid ${COLOR.GRAY_9};
    padding: 37px 0;

    & > div {
      :nth-child(1) {
        text-align: center;
        width: 5%;
      }
      :nth-child(2) {
        width: 40%;

        & > div {
          :nth-child(1) {
            display: flex;
            justify-content: space-between;
            text-align: center;
            padding-bottom: 16px;
            align-items: center;

            & > p {
              :nth-child(1) {
                ${FONT_STYLES.P_M}
                font-size: 20px;
                text-align: left;
                line-height: 1.2;
              }
              :nth-child(2) {
                font-size: 18px;
              }
            }
          }
        }
      }
      :nth-child(3) {
        width: 10%;
      }
      :nth-child(4) {
        width: 10%;
      }
      :nth-child(5) {
        width: 10%;
        cursor: pointer;
      }
      :nth-child(6) {
        width: 10%;
      }
    }
  }
`;

export const LectureProgressHeader = styled.div`
  ${FONT_STYLES.P_R}
  font-size: 18px;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  text-align: center;

  & > p {
    :nth-child(1) {
      width: 5%;
    }
    :nth-child(2) {
      width: 40%;
    }
    :nth-child(3) {
      width: 10%;
    }
    :nth-child(4) {
      width: 10%;
    }
    :nth-child(5) {
      width: 10%;
    }
    :nth-child(6) {
      width: 10%;
    }
  }
`;
