import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { BookStoreNav } from '../../components/molecules/SubNavBar';
import Home from '../../components/organisms/BookStorePageElements/Home';
import EntireBook from '../../components/organisms/BookStorePageElements/EntireBook';
import SeriesBook from '../../components/organisms/BookStorePageElements/SeriesBook';
import SubjectBook from '../../components/organisms/BookStorePageElements/SubjectBook';
import { BookStorePageContainer } from './style';
import Banner from '../../components/organisms/BookStorePageElements/MainBanner';

import PageNotFound from '../../hooks/pageNotFound';

import { useQuery } from 'react-query';
import { getNewBook, getBestSellerBook, getRecommendedBook } from '../../axiosManage/BookStore';
import useHandleError from '../../hooks/useHandleError';

function BookStorePage() {
  const { handleError } = useHandleError();
  const { tab } = useParams();
  const tabs = ['entire', 'series', 'subject', undefined];

  if (!tabs.includes(tab)) {
    return <PageNotFound />;
  }

  /** 신규 교재 데이터 */
  const [newBook, setNewBook] = useState([]);
  const { status: newBookState } = useQuery('getNewBook', getNewBook, {
    retry: 0,
    onSuccess: (data) => {
      setNewBook(data.data.newBooks);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  /** 베스트셀러 교재 데이터 */
  const [bestSellerBook, setBestSellerBook] = useState([]);
  const { status: bestBookStatus } = useQuery('getBestSellerBook', getBestSellerBook, {
    retry: 0,

    onSuccess: (data) => {
      setBestSellerBook([
        data.data.bestLanguageLogicBook[0],
        data.data.bestDataInterpretationBook[0],
        data.data.bestSituationalJudgmentBook[0],
      ]);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  /** 추천 교재 데이터 */
  const [recommendedBook, setRecommendedBook] = useState([]);
  const [currentCategory, setCurrentCategory] = useState('');
  const { status: recommendedBookState } = useQuery('getRecommendedBook', getRecommendedBook, {
    retry: 0,
    onSuccess: (data) => {
      if (data.data.bookList) {
        setRecommendedBook(data.data.bookList);
        setCurrentCategory(data.data.bookList[0].title);
      }
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const renderPage = () => {
    switch (tab) {
      case 'entire':
        return <EntireBook />;
      case 'series':
        return <SeriesBook />;
      case 'subject':
        return <SubjectBook />;
      default:
        return (
          <Home
            newBook={newBook}
            bestSellerBook={bestSellerBook}
            bestBookStatus={bestBookStatus}
            recommendedBook={recommendedBook}
            currentCategory={currentCategory}
            setCurrentCategory={setCurrentCategory}
            recommendedBookState={recommendedBookState}
          />
        );
    }
  };

  const renderBanner = () => {
    return <Banner />;
  };

  return (
    <BookStorePageContainer>
      {renderBanner()}
      <BookStoreNav tab={tab} />
      {renderPage()}
    </BookStorePageContainer>
  );
}

export default BookStorePage;
