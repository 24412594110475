import React from 'react';
import SearchBar from '../../../molecules/SearchBar';
// 배너 스타일
import { MainBannerContainer } from './style';
// 배너 이미지 에셋
import BookStoreMainBanner from '../../../../assets/images/image-bookstore-main-banner.png';

function MainBanner() {
  return <MainBannerContainer backgroundImg={BookStoreMainBanner}></MainBannerContainer>;
}

export default MainBanner;
