import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

const NavContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  width: 100%;
  height: 165px;
  display: flex;
  align-items: center;
  color: ${COLOR.BLACK_1};
  background-color: ${COLOR.GRAY_0};

  & > ul {
    ${FONT_STYLES.P_R}
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    z-index: 1;
  }

  @media screen and (max-width: 1700px) and (min-width: 1400px) {
    padding: 0 100px;
  }
`;

const ActiveTab = styled.div`
  width: 185px;
  height: 180px;

  display: flex;
  align-items: center;
  justify-content: center;

  p:nth-child(1) {
    font-size: 13px;
    padding-bottom: 5px;
    margin: 0 auto 0 0;
  }

  div > div {
    width: 35px;
    margin: 15px auto 0 auto;
    border: 1px solid ${COLOR.GRAY_0};
  }

  &.active {
    margin-bottom: 15px;
    text-align: center;
    color: ${COLOR.GRAY_0};
    background-color: ${COLOR.BLUE_4};
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 5px;

    & > div {
      p:nth-child(2) {
        ${FONT_STYLES.P_B}
        font-size: 20px;
      }
    }
  }
`;

const CurrentLocation = styled.div`
  ${FONT_STYLES.P_L}
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  margin-left: auto;
  min-width: 200px;

  p {
    margin: 0 10px;
  }
`;

const BorderBottom = styled.div`
  border-bottom: 1px solid #c3c3c3;
`;

export { NavContainer, CurrentLocation, ActiveTab, BorderBottom };
