import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

const ProgressContainer = styled.div`
  text-align: center;
`;

const ProgressDiv = styled.div`
  width: 100%;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;
  padding: 20px 30px;
`;

const ProgressStepBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    //width: 370px;
    width: 30%;
    height: 100px;
    border-bottom: 2px solid ${COLOR.GRAY_3};

    & > div {
      text-align: left;
      margin-left: 30px;

      & > p {
        ${FONT_STYLES.P_R}
        :nth-child(1) {
          padding-bottom: 10px;
        }
        :nth-child(2) {
          font-size: 20px;
        }
      }
    }

    &.active {
      border-bottom: 2px solid ${COLOR.BLUE_4};
    }
  }
`;

export { ProgressContainer, ProgressDiv, ProgressStepBox };
