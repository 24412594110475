import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const BookStoreHomeContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  margin: 0 auto;
  padding-top: 45px;
  text-align: center;

  @media screen and (max-width: 1700px) {
    padding-top: 45px;
  }
`;

const NewBookContainer = styled.div`
  margin-top: 60px;

  & > div {
    :nth-child(3) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 35px;
    }
  }
`;

const NewBookLeft = styled.div`
  width: 100%;
  //height: 492px;
  background: transparent linear-gradient(116deg, #f0f0fa 0%, #d8d8f0 100%);
  box-shadow: 0px 3px 15px #00000029;
  border-radius: 22px;

  @media screen and (max-width: 1600px) {
    margin-left: 20px;
  }

  & > div {
    :nth-child(1) {
      padding-bottom: 10px;
      p {
        width: 22px;
        height: 22px;
        background: ${COLOR.GRAY_0};
        box-shadow: inset 0px 3px 6px #00000029;
        border-radius: 50%;
        margin: 20px 20px 0 auto;
      }
    }
    :nth-child(2) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      & > div:nth-child(1) {
        padding: 0 30px 50px 30px;

        @media screen and (max-width: 1600px) {
          padding: 0 50px 50px 30px;
        }

        & > img {
          height: 400px;
          //padding: 0 60px 50px 60px;
        }
      }

      div:nth-child(2) {
        width: 100%;
        text-align: left;
        padding-right: 50px;

        div {
          :nth-child(1) {
            ${FONT_STYLES.P_R}
            font-size: 24px;
            color: ${COLOR.BLUE_3};
            display: flex;
            margin-bottom: 10px;

            & > p {
              :nth-child(2) {
                margin-left: auto;
                margin-right: 10px;

                svg {
                  cursor: pointer;

                  :nth-child(1) {
                    transform: rotate(180deg);
                    margin-right: 15px;
                  }
                }
              }
            }
          }
          :nth-child(2) {
            ${FONT_STYLES.P_B}
            font-size: 24px;
            margin-bottom: 20px;
          }
          :nth-child(3) {
            ${FONT_STYLES.P_M}
            font-size: 20px;
            margin-bottom: 30px;
          }
          :nth-child(4) {
            ${FONT_STYLES.P_R}
            font-size: 15px;
            color: #716b6b;
            margin-bottom: 2px;
            width: 633px;
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 1.3;
          }
          :nth-child(5) {
            ${FONT_STYLES.P_R}
            font-size: 15px;
            color: #716b6b;
            margin: 30px 0 50px 0;
          }
          :nth-child(6) {
            width: 100%;
            ${FONT_STYLES.P_SB}
            font-size: 18px;
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            & > p {
              :nth-child(1) {
                font-size: 15px;
                opacity: 0.5;
                padding-right: 10px;
              }
              :nth-child(3) {
                margin: auto 0;
                width: 300px;
                height: 1px;
                border-bottom: 1px solid ${COLOR.BLACK_0};
                margin: 0 35px;
              }
            }

            & > button {
              border-radius: 19px;
            }
          }
        }
      }
    }
  }
`;

const NewBookRight = styled.div`
  min-width: 300px;
  height: 492px;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-left: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1600px) {
    min-width: 260px;
  }

  & > div {
    & > p {
      padding-left: 30px;
      text-align: left;

      :nth-child(1) {
        ${FONT_STYLES.P_R}
        font-size: 15px;
        color: ${COLOR.GRAY_0};
        margin-bottom: 20px;
      }
      :nth-child(2) {
        ${FONT_STYLES.P_L}
        font-size: 20px;
        color: ${COLOR.GRAY_0};
        margin-bottom: 5px;
      }
      :nth-child(3) {
        ${FONT_STYLES.P_EB}
        font-size: 22px;
        color: ${COLOR.GRAY_0};
        margin-bottom: 30px;
      }
      :nth-child(5) {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        cursor: pointer;

        ${FONT_STYLES.P_R}
        img {
          margin-left: 10px;
        }
      }
    }
  }
`;

const BestSellerContainer = styled.div`
  width: 100%;
  margin-top: 60px;
`;

const BestSellerBox = styled.div`
  position: relative;
  top: -65px;
  z-index: 1;
  width: 100%;
  height: 650px;
  background-color: ${COLOR.GRAY_0};
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;
`;

const BestSellerList = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
`;

const BestSeller = styled.div`
  margin: 0 20px;

  & > div {
    :nth-child(1) {
      position: relative;
      text-align: left;

      p:nth-child(1) {
        ${FONT_STYLES.P_B};
        color: ${COLOR.BLUE_3};
      }

      p:nth-child(2) {
        ${FONT_STYLES.P_B};
        font-size: 28px;
        color: ${COLOR.BLUE_3};
      }
    }

    :nth-child(2) {
      position: relative;
      top: -50px;
      height: 400px;

      & > p {
        position: relative;
        z-index: 3;

        & > img {
          width: 180px;
          height: 320px;
          object-fit: scale-down;
        }
      }

      & > div {
        position: relative;
        top: -240px;
        z-index: 2;
        background-color: #f7f7f7;
        width: 350px;
        height: 350px;
        border-radius: 24px;

        p:nth-child(1) {
          padding-top: 230px;
          ${FONT_STYLES.P_B};
          font-size: 20px;
          margin-bottom: 20px;
        }

        p:nth-child(2) {
          ${FONT_STYLES.P_R};
          font-size: 14px;
          color: #716b6b;
          margin-bottom: 5px;
        }

        p:nth-child(3) {
          ${FONT_STYLES.P_R};
          font-size: 14px;
          color: #716b6b;
        }
      }
    }
  }
`;

const RecommendedBookContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
`;

const RecommendedBookBox = styled.div`
  background: transparent linear-gradient(289deg, #736d76 0%, #bab5bc 100%);
  margin-top: 25px;
  color: ${COLOR.GRAY_0};

  & > div {
    :nth-child(1) {
      ${FONT_STYLES.P_B};
      font-size: 24px;
      padding: 35px 0;
    }

    :nth-child(2) {
      ${FONT_STYLES.P_M}
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        list-style: none;
        margin: 0 60px;
        cursor: pointer;

        & > div {
          border: transparent;
          margin: 10px auto 0 auto;
        }

        &.active {
          & > div {
            border: 2px solid ${COLOR.GRAY_0};
          }
        }
      }
    }

    :nth-child(3) {
      & > p {
        :nth-child(1) {
          margin-top: 30px;
          & > img {
            width: 647px;
            height: 478px;
            object-fit: scale-down;
          }
        }
        :nth-child(2) {
          ${FONT_STYLES.P_B};
          font-size: 36px;
          margin: 25px 0 15px 0;
        }
        :nth-child(3) {
          ${FONT_STYLES.P_R};
          font-size: 20px;
          margin-bottom: 30px;
        }
      }
      & > button {
        border-radius: 19px;
        margin-bottom: 40px;
      }
    }
  }
`;

const BookStoreHomeFooter = styled.div`
  width: 100%;
  margin: 40px 0;
  margin-bottom: 100px;

  & > div {
    :nth-child(1) {
      display: flex;
      justify-content: center;
    }
    :nth-child(2) {
      width: 70%;
      ${FONT_STYLES.P_L}
      font-size: 14px;
      color: ${COLOR.GRAY_4};
      margin: 60px auto 120px auto;
      padding: 10px 40px;
      box-shadow: 0px 3px 15px #00000029;
      border-radius: 61px;
    }
  }
`;

const FooterBox = styled.div`
  width: 50%;
  height: 286px;
  padding: 30px;
  background-color: ${COLOR.GRAY_0};
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;
  text-align: left;
  overflow-y: scroll;

  & > div {
    :nth-child(1) {
      display: flex;
      justify-content: space-between;

      & > div {
        cursor: pointer;
        :nth-child(1) {
          ${FONT_STYLES.P_B};
          font-size: 24px;
          margin-bottom: 30px;
        }
        :nth-child(2) {
          & > p {
            ${FONT_STYLES.P_R};
            margin-bottom: 10px;
          }
        }
      }
    }

    :nth-child(2) {
      position: relative;
      & > div {
        display: flex;
        cursor: pointer;
        margin-bottom: 25px;

        & > p {
          line-height: 1.3;
          :nth-child(1) {
            padding-right: 5px;
          }
        }
      }
    }

    :nth-child(3) {
      position: relative;
      float: right;
      top: -50px;
      cursor: pointer;

      & > p {
        ${FONT_STYLES.P_R};
        margin-bottom: 10px;
      }
    }
  }
`;

const SubTitle = styled.div`
  ${FONT_STYLES.P_B}
  font-size: 36px;
  color: ${COLOR.BLUE_4};

  & > p {
    ${FONT_STYLES.P_M}
    font-size: 20px;
    color: ${COLOR.BLACK_1};
    margin-top: 30px;
  }
`;

const BestSellerSubTitle = styled.div`
  position: relative;
  z-index: 2;
  ${FONT_STYLES.P_B}
  font-size: 36px;
  color: ${COLOR.BLUE_4};

  & > p {
    ${FONT_STYLES.P_M}
    font-size: 20px;
    color: ${COLOR.BLACK_1};
    margin-top: 30px;
  }
`;

const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_4};
  margin: 0 auto 40px auto;
`;

export {
  BookStoreHomeContainer,
  NewBookContainer,
  NewBookLeft,
  NewBookRight,
  BestSellerContainer,
  BestSellerBox,
  BestSellerList,
  BestSeller,
  RecommendedBookContainer,
  RecommendedBookBox,
  BookStoreHomeFooter,
  FooterBox,
  SubTitle,
  BestSellerSubTitle,
  HorizonLine,
};
