import React, { useState, useEffect } from 'react';
import { PageContainer, PageList, PageNum } from './style';

// 페이지가 한 화면에 1-10, 11-20, 21-30.. 이런식으로 보이게 하기 위한 함수
const setPageLimit = (totalPage) => {
  const totalPageArray = Array(totalPage)
    .fill()
    .map((_, i) => i);
  return Array(Math.ceil(totalPage / 10))
    .fill()
    .map(() => totalPageArray.splice(0, 10));
};

/* Main */
const Pagination = ({ currentPage, postsPerPage, totalPosts, paginate }) => {
  const [currentPageArray, setCurrentPageArray] = useState([]);
  const [totalPageArray, setTotalPageArray] = useState([]);

  useEffect(() => {
    if (currentPage % 10 === 1) {
      setCurrentPageArray(totalPageArray[Math.floor(currentPage / 10)]);
    } else if (currentPage % 10 === 0) {
      setCurrentPageArray(totalPageArray[Math.floor(currentPage / 10) - 1]);
    }
  }, [totalPageArray, currentPage]);

  useEffect(() => {
    if (totalPosts !== undefined) {
      const slicedPageArray = setPageLimit(Math.ceil(totalPosts / postsPerPage));
      setTotalPageArray(slicedPageArray);
      if (~~(currentPage / 10) === 0) {
        setCurrentPageArray(slicedPageArray[0]);
      } else {
        setCurrentPageArray(slicedPageArray[~~(currentPage / 10)]);
      }
    }
  }, [currentPage, Math.ceil(totalPosts / postsPerPage)]);

  if (currentPageArray !== undefined) {
    return (
      <PageContainer>
        {currentPageArray.map((number) => (
          <PageList key={number + 1}>
            <PageNum
              className={`${currentPage === number + 1 ? 'active' : ''}`}
              onClick={() => paginate(number + 1)}
            >
              {number + 1}
            </PageNum>
          </PageList>
        ))}
      </PageContainer>
    );
  } else {
    return null;
  }
};

export default Pagination;
