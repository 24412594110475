import React, { useState, useRef, useEffect } from 'react';
import {
  PassReviewModifyContainer,
  PassReviewModifyTitle,
  FormContainer,
  ButtonContainer,
} from './style';

import Input from '../../components/atoms/Input';
import { Button, CancelButton } from '../../components/atoms/Button/index';
import ToastEditor from '../../components/atoms/ToastTextEditor';
import Select from '../../components/atoms/Select';
import COLOR from '../../styles/common/color';

import { useMutation } from 'react-query';
import { modifyPassReview } from '../../axiosManage/ExamInfo';

import useHandleError from '../../hooks/useHandleError';

import { useRecoilValue, useRecoilState } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';
import { myInfoAtom, myContactAtom, myPassReviewId } from '../../recoil/MyPageStore/MyPageRecoil';

import { useNavigate, Navigate } from 'react-router-dom';

function PassReviewModifyPage() {
  const { handleError } = useHandleError();
  const editorRef = useRef();
  const navigate = useNavigate();
  const myInfo = useRecoilValue(myInfoAtom);
  const isLogin = useRecoilValue(isLoginAtom);

  if (isLogin === false) {
    alert('로그인을 해주세요.');
    return <Navigate to="/login" />;
  }

  const [passReviewContent, setPassReviewContent] = useRecoilState(myContactAtom);
  const [passReviewId, setPassReviewContentId] = useRecoilState(myPassReviewId);

  const SELECT_OPTIONS = [
    { value: passReviewContent.category, optionName: passReviewContent.category },
    { value: '5급공채', optionName: '5급공채' },
    { value: '국립외교원', optionName: '국립외교원' },
    { value: '지역인재', optionName: '지역인재' },
    { value: '7급공채', optionName: '7급공채' },
  ];

  // 유저가 입력한 서버로 전송할 데이터(제목, 내용, 카테고리)
  const [title, setTitle] = useState(passReviewContent.title);
  const [category, setCategory] = useState(passReviewContent.category);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  /** 수강후기 글 전송 로직 */
  const postModifyPassReview = useMutation(
    (data) => {
      modifyPassReview(passReviewId, data);
    },
    {
      onSuccess: () => {
        alert('수정이 완료되었습니다!');
        navigate(-1);
      },
      onError: (error) => {
        alert('수정에 실패했습니다.');
        handleError(error);
      },
    },
  );

  const modifySubmit = () => {
    const data = {
      title: title,
      content: editorRef.current?.getInstance().getHTML(),
      category: category,
    };

    if (
      data.title !== '' &&
      !data.content.includes('위에 구분을 먼저 선택 후 글을 작성해주시기 바랍니다!') &&
      data.category !== '구분'
    ) {
      postModifyPassReview.mutate(data);
    } else {
      alert('제목, 내용, 카테고리를 모두 입력해주세요!');
    }
  };

  const modifyCancel = () => {
    let isYes = confirm('수정을 취소하시겠습니까?');

    if (isYes) {
      alert('수정을 취소하셨습니다.');
      navigate(-1);
    }
  };

  return (
    <PassReviewModifyContainer>
      <PassReviewModifyTitle>
        합격후기
        <p>합격후기 수정하기</p>
      </PassReviewModifyTitle>
      <FormContainer>
        <div>
          <Select
            width={313}
            height={58}
            selectOptions={SELECT_OPTIONS}
            onChangeFunc={handleCategoryChange}
          />
          <Input
            width={645}
            height={58}
            placeholder="제목을 입력하세요."
            defaultValue={title}
            onChangeFunc={handleTitleChange}
          />
          {isLogin ? (
            <div>이름: {myInfo.name}</div>
          ) : (
            <Input width={313} height={58} placeholder="이름을 입력하세요." />
          )}
        </div>
        <ToastEditor editorRef={editorRef} content={passReviewContent.content} />
        <div>
          <ButtonContainer>
            <div>
              <CancelButton
                width={124}
                height={48}
                bgColor={COLOR.BLUE_4}
                onClickFunc={() => modifyCancel()}
              >
                취소
              </CancelButton>
              <Button width={124} height={48} bgColor={COLOR.BLUE_4} onClickFunc={modifySubmit}>
                수정하기
              </Button>
            </div>
          </ButtonContainer>
        </div>
      </FormContainer>
    </PassReviewModifyContainer>
  );
}

export default PassReviewModifyPage;
