import { atom } from 'recoil';

/** 온라인 서점 - 전체 교재 데이터 정보 */
export const searchedEntireBookListAtom = atom({
  key: 'searchedEntireBookListAtom',
  default: [],
});

/** 온라인 서점 - 과목별 교재 데이터 정보 */
export const subjectBookListAtom = atom({
  key: 'subjectBookListAtom',
  default: [],
});
