import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';

export const SlidesContainer = styled.div`
  width: 424px;
  height: 369px;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 21px;
`;

export const LectureReviewSlide = styled.div`
  width: 100%;
  display: flex;
`;

export const LectureReviewControllerBox = styled.div`
  position: relative;
  display: flex;
  width: 64px;
  bottom: 30px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`;

export const LectureReviewDot = styled.div`
  width: ${({ className }) => (className === 'active' ? '24px' : '12px')};
  height: 12px;
  border: ${({ className }) =>
    className === 'active' ? `1px solid ${COLOR.BLUE_3}` : `1px solid ${COLOR.GRAY_3}`};
  border-radius: ${({ className }) => (className === 'active' ? '7px' : '50%')};
  background-color: ${({ className }) => (className === 'active' ? COLOR.BLUE_3 : COLOR.GRAY_3)};

  :hover {
    cursor: pointer;
  }
`;
