import styled, { css } from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const ExamInfoContentBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  svg {
    cursor: pointer;
  }
`;

const ExamInfoGraphContainer = styled.div`
  
  margin: 0 10px;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 22px;
  background: linear-gradient(#cad7fa 160px, #ffffff 25.5%);
`;

const Table = styled.div`
  width: 1313px;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  ${FONT_STYLES.P_SB}
  font-size: 24px;
  padding: 10px 10px 30px 10px;

  & > div {
    :nth-child(1) {
      width: 80px;
      ${FONT_STYLES.P_R}
      font-size: 18px;
    }
    :nth-child(2) {
      width: 100%;
      display: flex;
      justify-content: space-around;
      & > div {
        width: 300px;
        border-right: 1px solid ${COLOR.GRAY_3};
        :nth-last-child(1) {
          border-right: none;
        }
        ${({ isThree }) =>
          isThree
            ? css`
                width: 400px;
              `
            : null}
        ${({ isTwo }) =>
          isTwo
            ? css`
                width: 610px;
              `
            : null}
      }
    }
  }
`;

const TableSubHeaderContainer = styled.div`
  display: flex;
  padding: 10px 10px 30px 10px;

  & > div {
    :nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      ${FONT_STYLES.P_R}
      font-size: 18px;
    }
    :nth-child(2) {
      width: 100%;
      align-items: center;
    }
  }
`;

const TableSubHeader = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  & > div {
    width: 65px;
    ${FONT_STYLES.P_R}
    font-size: 18px;
    padding: 5px 0;
  }
`;

const TableData = styled.td`
  ${FONT_STYLES.P_R}
  font-size: 18px;
  padding: 10px 0;
`;

const TableDataContainer = styled.div`
  padding: 0 10px;
  & > div {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    & > div {
      :nth-child(1) {
        width: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

const Data = styled.div`
  & > div {
    display: flex;
    width: 300px;
    padding: 3.5px 0;

    & > p {
      width: 100px;
      justify-content: space-around;
    }
  }

  ${({ isThree }) =>
    isThree
      ? css`
          & > div {
            display: flex;
            width: 400px;
            padding: 3.5px 0;

            & > p {
              width: 100%;
              justify-content: space-around;
            }
          }
        `
      : null}

  ${({ isTwo }) =>
    isTwo
      ? css`
          & > div {
            display: flex;
            width: 610px;
            padding: 3.5px 0;

            & > p {
              width: 100%;
              justify-content: space-around;
            }
          }
        `
      : null}

      ${({ isOne }) =>
    isOne
      ? css`
          & > div {
            display: flex;
            width: 1217px;
            padding: 3.5px 0;

            & > p {
              width: 100%;
              justify-content: space-around;
            }
          }
        `
      : null}
`;

const TableDataSlice = styled.div`
  height: 20px;
`;

const PageNumber = styled.div`
  margin: 25px 0 20px 0;
`;



export {
  ExamInfoContentBox,
  ExamInfoGraphContainer,
  Table,
  TableHeader,
  TableData,
  TableDataSlice,
  PageNumber,
  TableSubHeader,
  TableSubHeaderContainer,
  TableDataContainer,
  Data,
};
