import React from 'react';
// 배너 스타일
import { BannerContainer, BannerTextContainer } from './style';
// 배너 이미지 에셋
import ExamInfoPageBanner from '../../../../assets/images/ExamInfoPage-Banner.png';

function Banner() {
  return (
    <BannerContainer backgroundImg={ExamInfoPageBanner}>
      <BannerTextContainer>
        <p>수험정보 & 팁</p>
        <p>유용한 정보 한 눈에 모아 보기</p>
      </BannerTextContainer>
    </BannerContainer>
  );
}

export default Banner;
