import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import {
  RegisterAssignmentPageContainer,
  RegisterPageTitle,
  HorizonLine,
  RegisterContent,
} from './style';
import COLOR from '../../styles/common/color';
import { Button } from '../../components/atoms/Button';
import { CircleCheckBox } from '../../components/atoms/CheckBox';

import ProgressBox from '../../components/molecules/ProgressBox';

import StepIcon1Selected from '../../assets/icons/icon-assignment-selected.png';
import StepIcon2 from '../../assets/icons/icon-join.png';
import StepIcon3 from '../../assets/icons/icon-complete.png';

import { getAgreeText } from '../../axiosManage/Assignment';

import { useRecoilState } from 'recoil';
import {
  registerAssignmentAtom,
  registerAssignmentIsAllCheckAtom,
} from '../../recoil/RegisterStore/RegisterRecoil';

import { useRecoilValue } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';

import AssignmentModal from '../../components/molecules/Modal';
import useModal from '../../hooks/useModal';
import AssignmentContent from '../../components/organisms/AssignmentContent';

function RegisterAssignmentPage() {
  const navigate = useNavigate();
  const isLogin = useRecoilValue(isLoginAtom);

  if (isLogin === true) {
    return <Navigate to="/" />;
  }

  const [personalInformation, setPersonalInformation] = useState('');
  const [labstandard, setLabstandard] = useState('');
  useEffect(() => {
    getAgreeText().then((res) => {
      setPersonalInformation(JSON.parse(res.data.data).personalInformation);
      setLabstandard(JSON.parse(res.data.data).labstandard);
    });
  }, []);

  const [modalOption, showModal] = useModal();

  // 알림 페이지 모달 오픈
  const openPersonalInformationModal = useCallback(
    (content) => {
      showModal(true, null, null, <AssignmentContent content={content} />);
      document.body.style.overflow = 'hidden';
    },
    [showModal],
  );

  const openLabstandardModal = useCallback(
    (content) => {
      showModal(true, null, null, <AssignmentContent content={content} />);
      document.body.style.overflow = 'hidden';
    },
    [showModal],
  );

  // 체크한 항목을 전역 상태로 관리
  const [registerAssignmentState, setRegisterAssignmentState] =
    useRecoilState(registerAssignmentAtom);
  const [isAllChecked, setIsAllChecked] = useRecoilState(registerAssignmentIsAllCheckAtom);

  // 전체 동의 선택, 해제 할 때마다 검사
  useEffect(() => {
    if (isAllChecked === true) {
      const newArr = Array(6).fill(true);
      setRegisterAssignmentState(newArr);
    } else if (isAllChecked === false) {
      if ([...registerAssignmentState][0] === false) {
        const newArr = [...registerAssignmentState];
        setRegisterAssignmentState(newArr);
      } else {
        const newArr = Array(6).fill(false);
        setRegisterAssignmentState(newArr);
      }
    }
  }, [isAllChecked]);

  const onClickCheck = (id) => {
    // 전체 동의하는 경우
    if (id === 0) {
      setIsAllChecked((isAllChecked) => !isAllChecked);
    } else if (id === 3) {
      const newArr = [...registerAssignmentState];
      newArr[3] = !registerAssignmentState[3];
      if (newArr[3] === false) {
        newArr[4] = false;
        newArr[5] = false;
      } else {
        newArr[4] = true;
        newArr[5] = true;
      }

      setRegisterAssignmentState(newArr);
    }
    // 부분 동의하는 경우
    else {
      const newArr = [...registerAssignmentState];
      // 전체 동의 눌렀다가 다른 동의 취소하면 전체 동의 체크박스 해제
      if (isAllChecked === true) {
        newArr[0] = false;
        setIsAllChecked(false);
      }
      newArr[id] = !registerAssignmentState[id];
      setRegisterAssignmentState(newArr);
    }
  };

  // 다음 단계로 넘어가기 버튼 로직
  const goToJoinPage = () => {
    navigate('/register/join');
  };

  return (
    <RegisterAssignmentPageContainer>
      <RegisterPageTitle>랩스탠다드 회원가입</RegisterPageTitle>
      <HorizonLine />
      <AssignmentModal modalOption={modalOption} />
      <AssignmentModal modalOption={modalOption} />
      <ProgressBox
        stepIcon1={StepIcon1Selected}
        stepIcon2={StepIcon2}
        stepIcon3={StepIcon3}
        stepText1={`이용약관 동의`}
        stepText2={`회원정보 입력`}
        stepText3={`회원가입 완료`}
      >
        <RegisterContent>
          <div>
            <CircleCheckBox
              type="checkbox"
              width={30}
              height={30}
              isChecked={registerAssignmentState[1]}
              onChangeFunc={() => onClickCheck(1)}
              onClickFunc={() => onClickCheck(1)}
            />
            <p onClick={() => onClickCheck(1)}>랩스탠다드 이용약관(필수)</p>
            <p onClick={() => openLabstandardModal(labstandard)}>내용보기</p>
          </div>
          <div>
            <CircleCheckBox
              type="checkbox"
              width={30}
              height={30}
              isChecked={registerAssignmentState[2]}
              onChangeFunc={() => onClickCheck(2)}
              onClickFunc={() => onClickCheck(2)}
            />
            <p onClick={() => onClickCheck(2)}>개인정보 수집 및 이용에 관한 동의(필수)</p>
            <p onClick={() => openPersonalInformationModal(personalInformation)}>내용보기</p>
          </div>
          <div>
            <CircleCheckBox
              type="checkbox"
              width={30}
              height={30}
              isChecked={registerAssignmentState[3]}
              onChangeFunc={() => onClickCheck(3)}
              onClickFunc={() => onClickCheck(3)}
            />
            <p
              onClick={() => {
                onClickCheck(3);
              }}
            >
              상품 정보 및 이벤트 혜택 수신 동의(선택)
            </p>
            <div>
              <CircleCheckBox
                type="checkbox"
                width={30}
                height={30}
                isChecked={registerAssignmentState[4]}
                onChangeFunc={() => onClickCheck(4)}
                onClickFunc={() => onClickCheck(4)}
              />
              <p onClick={() => onClickCheck(4)}>SMS</p>
            </div>
            <div>
              <CircleCheckBox
                type="checkbox"
                width={30}
                height={30}
                isChecked={registerAssignmentState[5]}
                onChangeFunc={() => onClickCheck(5)}
                onClickFunc={() => onClickCheck(5)}
              />
              <p onClick={() => onClickCheck(5)}>E-MAIL</p>
            </div>
          </div>
          <div>
            <span>
              * (주)랩스탠다드에서 제공하는 무료강의, 포인트, 이벤트, 상품안내 등에 대하여 SMS,
              이메일을 통해 서비스를 제공받으실 수 있습니다.
            </span>
            <span>
              동의하지 않을 경우 (주)랩스탠다드에서 제공하는 이벤트 및 혜택 등의 정보를 제공받으실
              수 없으며,
            </span>
            <span>수신 동의는 언제든지 마이페이지에서 철회하시거나 변경하실 수 있습니다.</span>
          </div>
          <div>
            <CircleCheckBox
              type="checkbox"
              width={30}
              height={30}
              isChecked={registerAssignmentState[0]}
              onChangeFunc={() => onClickCheck(0)}
              onClickFunc={() => onClickCheck(0)}
            />
            <span onClick={() => onClickCheck(0)}>전체 동의</span>
          </div>
        </RegisterContent>
      </ProgressBox>
      <Button
        width={202}
        height={62}
        bgColor={COLOR.BLUE_4}
        fontSize={20}
        onClickFunc={goToJoinPage}
      >
        다음 단계로
      </Button>
    </RegisterAssignmentPageContainer>
  );
}

export default RegisterAssignmentPage;
