import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  LectureHomeAdBanner,
  LectureHomeBanner,
  LectureHomeContents,
  LectureHomePageContainer,
  ProfessorCardBox,
  ProfessorContents,
} from './style';
import backgroundImg from '../../assets/images/image-lectureHomeBanner.png';
import ProfessorNav from '../../components/molecules/ProfessorNav';
import { LectureHomeNav } from '../../components/molecules/LectureNav/index';
import ProfessorCard from '../../components/molecules/ProfessorCard';

import useHandleError from '../../hooks/useHandleError';
import { getLectureBanner, getProfessors } from '../../axiosManage/Professor';

const LectureHomePage = () => {
  const { handleError } = useHandleError();
  const navigate = useNavigate();
  

  const [subBanner, setSubBanner] = useState([]);

  useEffect(() => {
    getLectureBanner()
      .then((res) => {
        if(res.data){
          
          setSubBanner(JSON.parse(res.data.data));
        }
        
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  const [JLJProfessor, setJLJProfessor] = useState([]);
  const [KSHProfessor, setKSHProfessor] = useState([]);
  const [HNSProfessor, setHNSProfessor] = useState([]);
  const [ProfessorList, setProfessorList] = useState([]);

  useEffect(() => {
    getProfessors()
      .then((res) => {
        let JLJ = res.data.professors.find((item) => item.id === 1);
        let KSH = res.data.professors.find((item) => item.id === 2);
        let HNS = res.data.professors.find((item) => item.id === 3);
        setJLJProfessor(JLJ);
        setKSHProfessor(KSH);
        setHNSProfessor(HNS);
        setProfessorList(res.data.professors);
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  const goToSubBannerLink = () => {
    if (subBanner.url) {
      navigate("/" + subBanner.url.split("/")[3]);
    }
  };

  return (
    <LectureHomePageContainer>
      <LectureHomeBanner backgroundImg={backgroundImg}>
        <div>
          <p>5급 강의</p>
          <p>수많은 합격 사례로 검증된 전문 교수진의 강의</p>
        </div>
      </LectureHomeBanner>
      <LectureHomeNav />
      <LectureHomeContents>
        <LectureHomeAdBanner backgroundImg={subBanner.img} onClick={() => goToSubBannerLink()} />
        <ProfessorContents>
          <ProfessorNav professorList={ProfessorList} />
          <ProfessorCardBox>
            {
              ProfessorList.map(professor => {
                return (
                  <ProfessorCard
                    key={professor.id}
                    backgroundImg={professor.professorProfileImg}
                    professorName={professor.professorName}
                    title={professor.slogan}
                    onClickFunc={() => (navigate(`/lecture/${professor.id}`))}
                  />)
              })
            }
          </ProfessorCardBox>
        </ProfessorContents>
      </LectureHomeContents>
    </LectureHomePageContainer>
  );
};

export default LectureHomePage;
