import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import COLOR from '../../../../styles/common/color';
import {
  MyLecturePageContainer,
  MyLectureTitle,
  HorizonLine,
  LectureBox,
  LectureBoxHeader,
  LectureItems,
  Lecture,
  GuideBox,
  PassBox,
  PassItem,
  RegularPassItem,
  RegularPassBox,
} from './style';
import { paymentBilling } from '../../../../axiosManage/OrderBasket/index';
import { useAxios } from '../../../../hooks/useAxios';
import { ProgressCircle } from '../../../atoms/ProgressCircle';
import MyLectureDetailPage from '../../../../pages/MyPageLectureDetail/MyPageLectureDetailPage';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from 'react-query';
import { getMyLecture, getLectureStart, getMyPass } from '../../../../axiosManage/MyPage';
import useHandleError from '../../../../hooks/useHandleError';
import { SpinLoader, typeList } from '../../../atoms/SpinLoader';
import { CancelButton } from "../../../atoms/Button/index";
import { useRecoilState, useRecoilValue } from 'recoil';
import { myInfoAtom } from '../../../../recoil/MyPageStore/MyPageRecoil';

import {
  myPageMyLectureAtom,
  myPageMyLectureStatusAtom,
} from '../../../../recoil/MyPageStore/MyPageRecoil';
import { Button } from '../../../atoms/Button';

function MyLecture() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const myInfo = useRecoilValue(myInfoAtom);
  const [lectureId, setLectureId] = useState(null);
  const { refetch: myLectureStartRefetch } = useQuery(
    ['getLectureStart', lectureId],
    () => getLectureStart(lectureId),
    {
      retry: 0,
      enabled: !!lectureId,
      onSuccess: () => {
        alert('수강 신청 되었습니다.');
        myLectureRefetch();
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const lectureStart = (id) => {
    setLectureId(id);
    if (lectureId) {
      myLectureStartRefetch();
    }
  };

  const [myLecture, setMyLecture] = useRecoilState(myPageMyLectureAtom);
  const { status: myLectureStatus, refetch: myLectureRefetch } = useQuery(
    ['getMyLecture', lectureId],
    getMyLecture,
    {
      retry: 0,
      onSuccess: (data) => {
        setMyLecture(data.data.myLectures);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const [myPassList, setMyPassList] = useState([]);
  const [myRegularPass, setMyRegularPass] = useState([]);
  const [isAlreadyBilling, setIsAlreadyBilling] = useState(false);
  useEffect(() => {
    getMyPass().then((res) => {
      const regularPass = res.data.myPassList.find((item) => item.passId === 1);
      setMyRegularPass(regularPass);
      setMyPassList(res.data.myPassList);
      setIsAlreadyBilling(res.data.isAlreadyBilling);
    });
  }, []);

  const [signUpLectures, setSignUpLectures] = useState([]);
  const [etcLectures, setEtcLectures] = useState([]);

  useEffect(() => {
    let signUpLecture = [];
    let etcLecture = [];

    myLecture.forEach((lecture) => {
      if (lecture.status === '수강 중') {
        signUpLecture.push(lecture);
      } else if (lecture.status === '수강 대기' || lecture.status === '수강 종료') {
        etcLecture.push(lecture);
        setEtcLectures(etcLecture);
      }
    });
    setSignUpLectures(signUpLecture);
  }, [myLecture, myLectureStatus]);

  /* 카테고리 선택 */
  const [currentCategory, setCurrentCategory] = useState(1);
  const clickCategory = (id) => {
    setCurrentCategory(id);
  };

  // 수강 퍼센테이지 계산 로직(수강 중인 강의)
  const [signUpProgressRate, setSignUpProgressRate] = useState([]);
  useEffect(() => {
    const newArr = [...signUpProgressRate];
    for (let i = 0; i < signUpLectures.length; i++) {
      let totalLearningTime = 0;
      let currLearningTime = 0;

      if (signUpLectures[i].lectureInfo && signUpLectures[i].lectureInfo.lectures) {
        signUpLectures[i].lectureInfo.lectures.forEach((lecture) => {
          totalLearningTime += lecture.time * 60;
        });
      }

      if (signUpLectures[i].learningStatusList) {
        signUpLectures[i].learningStatusList.forEach((lecture) => {
          const lectureTime =
            signUpLectures[i].lectureInfo.lectures.find(
              (item) => item.id === lecture.lectureVideoId,
            ).time * 60;
          const currentLecture = signUpLectures[i].lectureInfo.lectures.find(
            (item) => item.id === lecture.lectureVideoId,
          );
          if (lecture.learningTime > lectureTime) {
            currLearningTime += currentLecture.time * 60;
          } else {
            currLearningTime += lecture.learningTime;
          }
        });
      }
      newArr.push([signUpLectures[i].id, ~~((currLearningTime / totalLearningTime) * 100)]);
    }
    setSignUpProgressRate(newArr);
  }, [signUpLectures]);

  // 수강 퍼센테이지 계산 로직(수강 대기/종료인 강의)
  const [etcProgressRate, setEtcUpProgressRate] = useState([]);
  useEffect(() => {
    const newArr = [...etcProgressRate];
    for (let i = 0; i < etcLectures.length; i++) {
      let totalLearningTime = 0;
      let currLearningTime = 0;

      if (etcLectures[i].lectureInfo && etcLectures[i].lectureInfo.lectures) {
        etcLectures[i].lectureInfo.lectures.forEach((lecture) => {
          totalLearningTime += lecture.time * 60;
        });
      }

      if (etcLectures[i].learningStatusList) {
        etcLectures[i].learningStatusList.forEach((lecture) => {
          const lectureTime =
            etcLectures[i].lectureInfo.lectures.find((item) => item.id === lecture.lectureVideoId)
              .time * 60;
          const currentLecture = etcLectures[i].lectureInfo.lectures.find(
            (item) => item.id === lecture.lectureVideoId,
          );
          if (lecture.learningTime > lectureTime) {
            currLearningTime += currentLecture.time * 60;
          } else {
            currLearningTime += lecture.learningTime;
          }
        });
      }
      newArr.push([etcLectures[i].id, ~~((currLearningTime / totalLearningTime) * 100)]);
    }
    setEtcUpProgressRate(newArr);
  }, [etcLectures]);

  const [myPassBanner, setMyPassBanner] = useState("");
  useEffect(() => {
    async function fetch() {
      const res = await useAxios.get("/mypass-banner");
      setMyPassBanner(res.data.data);
    }
    fetch();
  }, [])


  const goToLectureDetailPage = (status, id) => {
    if (status === '수강 대기') {
      // 수강 대기면 아무것도 안함
    } else {
      navigate(`/mypage/mylecture/${id})`);
    }
  };

  const unSubscribePass = async () => {
    try {
      const res = await useAxios.get("/payment/cancel-billing");
      if (res.status == 200) {
        getMyPass().then((res) => {
          const regularPass = res.data.myPassList.find((item) => item.passId === 1);
          setMyRegularPass(regularPass);
          setMyPassList(res.data.myPassList);
          setIsAlreadyBilling(false);
        });
        alert("구독종료가 완료되었습니다. 다음 달 부터는 자동결제가 진행되지 않습니다.");
      }
      if (res.status == 400) {
        alert("이미 구독종료 상태입니다.");
      }
    }
    catch (err) {
      alert("오류가 발생하였습니다.");
    }
  }

  const callback = (response) => {
    const {
      success,
      imp_uid,
      merchant_uid,
      error_msg,
      emb_pg_provider,
      pay_method,
      paid_at,
      paid_amount,
      pg_provider,
      apply_num,
      customer_uid,
    } = response;

    const data = {
      customerUid: customer_uid,
    };

    if (success) {
      paymentBilling(data).then((res) => {
        setIsAlreadyBilling(true);
        alert("구독 종료가 철회되었습니다.")
      });
    } else {
      //alert('결제에 실패하였습니다. 에러 내용: ' + error_msg);
      alert('결제에 실패하였습니다.');
    }
  };

  const resumeSubscribe = () => {
    let customer_uid = uuidv4();
    const { IMP } = window;
    IMP.init(process.env.REACT_APP_IMPORT_IMP);
    /* 2. 결제 데이터 정의하기 */
    const data = {
      pg: 'kakaopay.CAJBIUO02K',
      pay_method: 'card',
      name: '랩스탠다드 정기 패스',
      amount: 0,
      customer_uid,
      buyer_email: myInfo.email,
      buyer_name: myInfo.name,
      buyer_tel: myInfo.phoneNumber,
      buyer_addr: JSON.parse(myInfo.address).address + JSON.parse(myInfo.address).detailAddress,
      buyer_postcode: JSON.parse(myInfo.address).code,
      custom_data: { parcelDetail: '' },
      m_redirect_url: `https://labstandard.kr/mobileorder/complete?resume=true&customer_uid=${customer_uid}`
    };

    /* 4. 결제 창 호출하기 */
    IMP.request_pay(data, callback);
  }

  function createMarkup(data) {
    return { __html: data };
  }

  const RenderEtc = ({ data }) => {
    return (
      <div dangerouslySetInnerHTML={createMarkup(data)} />
    );
  };

  return (
    <>
      {id ? (
        <MyLectureDetailPage
          signUpProgressRate={signUpProgressRate}
          etcProgressRate={etcProgressRate}
        />
      ) : (
        <MyLecturePageContainer>
          <MyLectureTitle>수강 강좌</MyLectureTitle>
          <HorizonLine />
          <LectureBox>
            <LectureBoxHeader>
              <div onClick={() => clickCategory(1)}>
                <li className={currentCategory === 1 ? 'active' : ''}>
                  <span>수강 중</span>
                  <span>{signUpLectures.length}</span>
                </li>
              </div>
              <div onClick={() => clickCategory(2)}>
                <li className={currentCategory === 2 ? 'active' : ''}>
                  <span>수강 종료 / 대기</span>
                  <span>{etcLectures.length}</span>
                </li>
              </div>
            </LectureBoxHeader>
            {currentCategory === 1 ? (
              <LectureItems>
                {signUpLectures !== undefined ? (
                  <>
                    {signUpLectures.map((lecture, i) => (
                      <Lecture
                        key={lecture.id}
                        onClick={(e) => navigate(`/mypage/mylecture/${lecture.id}`)}
                      >
                        <div>{i + 1}</div>
                        <div>
                          <img src={lecture.lectureInfo.lectureImg} />
                        </div>
                        <div>
                          {lecture.lectureInfo.lectureCategory === 'language-logic' ? (
                            <p>언어논리</p>
                          ) : lecture.lectureInfo.lectureCategory === 'data-interpretation' ? (
                            <p>자료해석</p>
                          ) : lecture.lectureInfo.lectureCategory === 'situational-judgment' ? (
                            <p>상황판단</p>
                          ) : (
                            ''
                          )}
                          {lecture.lectureInfo.professor !== undefined ? (
                            <p>{lecture.lectureInfo.professor.professorName}</p>
                          ) : (
                            ''
                          )}
                        </div>
                        <div>
                          <p>{lecture.status}</p>
                          <p>{lecture.lectureInfo.lectureName}</p>
                          <p>
                            <span>교재</span>
                            {lecture.lectureInfo.books[0] !== undefined ? (
                              <>
                                {lecture.lectureInfo.books && lecture.lectureInfo.books.length > 1
                                  ? `${lecture.lectureInfo.books[0] &&
                                  lecture.lectureInfo.books[0].bookName
                                  } 외${' '}
                                ${lecture.lectureInfo.books.length - 1}권`
                                  : `${lecture.lectureInfo.books[0] &&
                                  lecture.lectureInfo.books[0].bookName
                                  }`}
                              </>
                            ) : (
                              '교재 없음'
                            )}
                          </p>
                        </div>
                        <div>
                          <p>학습기간</p>
                          <p>
                            <span>{lecture.startedAt?.slice(0, 10).replace(/-/g, '.')}</span>~
                            <span>{lecture.endAt?.slice(0, 10).replace(/-/g, '.')}</span>
                          </p>
                        </div>
                        <div>
                          <ProgressCircle
                            percent={signUpProgressRate[i] && signUpProgressRate[i][1]}
                          >
                            <p>진도율</p>
                            <p>{signUpProgressRate[i] && signUpProgressRate[i][1]}%</p>
                          </ProgressCircle>
                        </div>
                      </Lecture>
                    ))}
                  </>
                ) : (
                  <SpinLoader type={typeList[2].prop} color={'#000'} width={690} height={550} />
                )}
              </LectureItems>
            ) : currentCategory === 2 ? (
              <LectureItems>
                {etcLectures !== undefined ? (
                  <>
                    {etcLectures.map((lecture, i) => (
                      <Lecture
                        key={lecture.id}
                        onClick={(e) => goToLectureDetailPage(lecture.status, lecture.id)}
                      >
                        <div>{i + 1}</div>
                        <div>
                          <img src={lecture.lectureInfo.lectureImg} />
                        </div>
                        <div>
                          {lecture.lectureInfo.lectureCategory === 'language-logic' ? (
                            <p>언어논리</p>
                          ) : lecture.lectureInfo.lectureCategory === 'data-interpretation' ? (
                            <p>자료해석</p>
                          ) : lecture.lectureInfo.lectureCategory === 'situational-judgment' ? (
                            <p>상황판단</p>
                          ) : (
                            ''
                          )}
                          {lecture.lectureInfo.professor !== undefined ? (
                            <p>{lecture.lectureInfo.professor.professorName}</p>
                          ) : (
                            <SpinLoader
                              type={typeList[2].prop}
                              color={'#000'}
                              width={690}
                              height={550}
                            />
                          )}
                        </div>
                        <div>
                          <p>{lecture.status}</p>
                          <p>{lecture.lectureInfo.lectureName}</p>
                          <p>
                            <span>교재</span>
                            {lecture.lectureInfo.books[0] !== undefined ? (
                              <>
                                {lecture.lectureInfo.books && lecture.lectureInfo.books.length > 1
                                  ? `${lecture.lectureInfo.books[0] &&
                                  lecture.lectureInfo.books[0].bookName
                                  } 외${' '}
                                ${lecture.lectureInfo.books.length - 1}권`
                                  : `${lecture.lectureInfo.books[0] &&
                                  lecture.lectureInfo.books[0].bookName
                                  }`}
                              </>
                            ) : (
                              '교재 없음'
                            )}
                          </p>
                        </div>
                        <div>
                          <p>학습기간</p>
                          <p>
                            <span>{lecture.startedAt?.slice(0, 10).replace(/-/g, '.')}</span>~
                            <span>{lecture.endAt?.slice(0, 10).replace(/-/g, '.')}</span>
                          </p>
                        </div>
                        <div>
                          {lecture.status === '수강 대기' ? (
                            <Button
                              width={124}
                              height={48}
                              bgColor={COLOR.BLUE_4}
                              onClickFunc={() => lectureStart(lecture.id)}
                            >
                              수강 시작
                            </Button>
                          ) : (
                            <ProgressCircle percent={etcProgressRate[i] && etcProgressRate[i][1]}>
                              <p>진도율</p>
                              <p>{etcProgressRate[i] && etcProgressRate[i][1]}%</p>
                            </ProgressCircle>
                            // <ProgressCircle percent={100}>
                            //   <p>진도율</p>
                            //   <p>{100}%</p>
                            // </ProgressCircle>
                          )}
                        </div>
                      </Lecture>
                    ))}
                  </>
                ) : (
                  ''
                )}
              </LectureItems>
            ) : (
              ''
            )}
          </LectureBox>
          <MyLectureTitle style={{ marginTop: '50px' }}>일반 패스</MyLectureTitle>
          <HorizonLine />
          <PassBox>
            {myPassList &&
              myPassList.map((pass) => {
                if (pass.passId != 1) {
                  return (
                    <>
                      <PassItem key={pass.passId}>
                        <div>{pass.passName}</div>
                        <div>
                          <span>기간</span>
                          <span>{pass.startedAt?.slice(0, 10).replace(/-/g, '.')}</span>
                          <span>~</span>
                          <span>{pass.endAt?.slice(0, 10).replace(/-/g, '.')}</span>
                        </div>
                      </PassItem>
                    </>
                  )
                }
              })}
          </PassBox>
          <MyLectureTitle style={{ marginTop: '50px' }}>정기패스</MyLectureTitle>
          <HorizonLine />
          <PassBox>
            {myRegularPass && (
              <>
                <PassItem>
                  <div>{myRegularPass.passName}</div>
                  {isAlreadyBilling ? "" : <div style={{ marginRight: '300px', fontSize: '18px' }}>구독 종료 예약됨</div>}
                  <div>
                    {
                      isAlreadyBilling ? <Button
                        width={120}
                        height={48}
                        bgColor={COLOR.BLUE_4}
                        marginRight={50}
                        onClickFunc={async () => {
                          if (confirm("정기패스 구독을 종료하시겠습니까?")) {
                            await unSubscribePass();
                          }
                        }}
                      >
                        구독 종료 예약
                      </Button> : <Button
                        width={120}
                        height={48}
                        bgColor={COLOR.BLUE_4}
                        marginRight={50}
                        onClickFunc={() => {
                          resumeSubscribe();
                        }}
                      >
                        구독 계속하기
                      </Button>
                    }
                    <span style={{ fontSize: '20px', paddingRight: '10px', fontFamily: 'Pretendard Variable', fontWeight: '600' }}>기간</span>
                    <span>{myRegularPass.startedAt?.slice(0, 10).replace(/-/g, '.')}</span>
                    <span>~</span>
                    <span>{myRegularPass.endAt?.slice(0, 10).replace(/-/g, '.')}</span>
                  </div>
                </PassItem>
              </>
            )}
          </PassBox>
          <GuideBox>
            <div>이용 안내</div>
            <RenderEtc data={myPassBanner} />
          </GuideBox>
        </MyLecturePageContainer>
      )}
    </>
  );
}

export default MyLecture;
