import React, { useState, useEffect } from 'react';
import {
  LoginPageContainer,
  LoginContainer,
  LoginTitle,
  InputBox,
  LoginOptionBox,
  LoginButton,
  OauthLoginBox,
} from './style';

import { SquareInput } from '../../components/atoms/Input';

import NaverLoginBtn from '../../assets/images/naver-login-btn.png';
import KakaoLoginBtn from '../../assets/images/kakao_login_original.png';
import LabstandardRegisterBtn from '../../assets/images/labstandard-register-btn.png';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { useRecoilState, useRecoilValue } from 'recoil';
import { isLoginAtom, isSaveIdChecked } from '../../recoil/AuthStore/AuthRecoil';

import { labstandardLogin } from '../../axiosManage/Auth';
import { getMyInfo } from '../../axiosManage/MyPage';
import { myInfoAtom } from '../../recoil/MyPageStore/MyPageRecoil';
import useHandleError from '../../hooks/useHandleError';

const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_API_KEY}&&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}`;

function LoginPage() {
  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);

  if (isLogin === true) {
    return <Navigate to="/" />;
  }

  // 카카오 로그인
  const handleKakaoLogin = () => {
    window.location.href = KAKAO_AUTH_URL;
  };

  // 네이버 로그인
  const handleNaverLogin = () => {
    window.location.href = NAVER_AUTH_URL;
  };

  // 로그인 성공한 경우 내 정보 가져오기 위한 api 요청
  const [myInfo, setMyInfo] = useRecoilState(myInfoAtom);

  // 랩스 로그인
  const [userId, setUserId] = useState(localStorage.getItem('userId') || '');
  const handleUserId = (e) => {
    setUserId(e.target.value);
  };
  const [userPassword, setUserPassword] = useState('');
  const handleUserPassword = (e) => {
    setUserPassword(e.target.value);
  };
  const loginRequest = () => {
    if (userId === '' || userPassword === '') {
      alert('아이디 혹은 비밀번호를 입력해주세요.');
    } else {
      labstandardLogin({
        userId: userId,
        password: userPassword,
      })
        .then((res) => {
          if (res.data.success === true) {
            localStorage.setItem('accessToken', res.data.accessToken);
            localStorage.setItem('refreshToken', res.data.refreshToken);
            setIsLogin(true);
            navigate('/');
            getMyInfo().then((res) => {
              setMyInfo(res.data.myInfo);
            });
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  // 아이디 저장 체크 여부
  const [isIdSaveChecked, setIsIdSaveChecked] = useRecoilState(isSaveIdChecked);
  const idSaveClick = () => {
    setIsIdSaveChecked((isIdSaveChecked) => !isIdSaveChecked);
    if (userId !== '') {
      localStorage.setItem('userId', userId);
    }
  };

  useEffect(() => {
    if (isIdSaveChecked === false) {
      localStorage.removeItem('userId');
    } else if (isIdSaveChecked === true) {
      setIsIdSaveChecked(true);
    }
  }, [isIdSaveChecked]);

  return (
    <LoginPageContainer>
      <LoginContainer>
        <LoginTitle>랩스탠다드 로그인</LoginTitle>
        <SquareInput
          width={448}
          height={62}
          defaultValue={userId}
          placeholder="아이디"
          onChangeFunc={handleUserId}
        />
        <SquareInput
          type="password"
          width={448}
          height={62}
          placeholder="비밀번호"
          onChangeFunc={handleUserPassword}
        />
        <LoginOptionBox>
          <p>
            <input type="checkbox" onClick={idSaveClick} checked={isIdSaveChecked} />
            <span>아이디 저장</span>
          </p>
          <Link to="/findaccount">아이디 / 비밀번호 찾기</Link>
        </LoginOptionBox>
        <LoginButton onClick={loginRequest}>로그인</LoginButton>
        <OauthLoginBox>
          <div onClick={handleNaverLogin}>
            <img src={NaverLoginBtn} />
            <p>네이버 로그인</p>
          </div>
          <div onClick={handleKakaoLogin}>
            <img src={KakaoLoginBtn} />
            <p>카카오 로그인</p>
          </div>
          <Link to="/register">
            <img src={LabstandardRegisterBtn} />
            <p>랩스탠다드 회원가입</p>
          </Link>
        </OauthLoginBox>
      </LoginContainer>
    </LoginPageContainer>
  );
}

export default LoginPage;
