import React, { useState, useEffect } from 'react';
import {
  ButtonContainer,
  ColumnTitle,
  Container,
  LectureInfoBox,
  ProfessorInfoBox,
  SelectBox,
  Table,
  TableContainer,
  TableItem,
  VideoInfoBox,
  LoadMoreBtn,
} from './style';
import { Button } from '../../../atoms/Button';
import COLOR from '../../../../styles/common/color';
import { useNavigate } from 'react-router-dom';

import { useQuery } from 'react-query';
import { getLectureListByProfessor, getPassList } from '../../../../axiosManage/Professor';
import useHandleError from '../../../../hooks/useHandleError';
import { SpinLoader, typeList } from '../../../atoms/SpinLoader';

import { useRecoilState, useRecoilValue } from 'recoil';
import {
  myOrderBasketListAtom,
  myOrderAtom,
} from '../../../../recoil/OrderBasketStore/OrderBasketRecoil';
import { isLoginAtom } from '../../../../recoil/AuthStore/AuthRecoil';

const LecturesTable = ({ professorId }) => {
  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const isLogin = useRecoilValue(isLoginAtom);

  // 단과 강좌 or 패키지 강좌 탭 조절
  const [CurrentTab, setCurrentTab] = useState(0);
  const selectTab = (index) => {
    setCurrentTab(index);
  };

  const [ProfessorId, setProfessorId] = useState(1);
  useEffect(() => {
    setProfessorId(professorId);
  }, [professorId]);

  const [lecture, setLecture] = useState([]);
  const [slicedLecture, setSlicedLecture] = useState([]);
  const { status: lectureStatus } = useQuery(
    ['getLectureListByProfessor', ProfessorId],
    () => getLectureListByProfessor(ProfessorId),
    {
      retry: 0,
      onSuccess: (data) => {
        setLecture(data.data.lectureList);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const [passList, setPassList] = useState([]);
  const { status: packageLectureStatus } = useQuery(
    ['getPassList', ProfessorId],
    () => getPassList(ProfessorId),
    {
      retry: 0,
      onSuccess: (data) => {
        setPassList(data.data.passList);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const LECTURES_DATA = [
    {
      tabName: '단과 강좌',
      content: lecture,
    },
    {
      tabName: '패키지 강좌',
      content: passList,
    },
  ];

  /** 더보기 버튼 기능 */
  const [sliceStartIndex, setSliceStartIndex] = useState(0);
  const loadMore = () => {
    setSliceStartIndex((prev) => prev + 6);
  };

  useEffect(() => {
    if (lectureStatus === 'success' && lecture !== undefined) {
      setSlicedLecture([...slicedLecture, ...lecture.slice(sliceStartIndex, sliceStartIndex + 6)]);

      if (sliceStartIndex > lecture.length) {
        alert('강의가 없습니다!');
      }
    }
  }, [lecture, sliceStartIndex]);

  // 장바구니 버튼 클릭 했을 때
  const [selectedProductList, setSelectedProductList] = useRecoilState(myOrderBasketListAtom);
  const clickOrderBasketButton = (pass) => {
    if (isLogin === false) {
      alert('로그인을 해주세요.');
    } else {
      let checkProduct = [];
      selectedProductList.forEach((product) => {
        if ('passName' in product) {
          checkProduct.push(product.passName);
        }
      });

      if (checkProduct.includes(pass.passName)) {
        alert('이미 장바구니에 동일한 상품이 있습니다.');
      } else {
        setSelectedProductList([...selectedProductList, pass]);
        alert('장바구니에 상품이 담겼습니다.');
      }
    }
  };

  // 구매하기 버튼 클릭 했을 때
  const [selectedProduct, setSelectedProduct] = useRecoilState(myOrderAtom);
  const clickOrderButton = (pass) => {
    if (isLogin === false) {
      alert('로그인을 해주세요.');
    } else {
      setSelectedProduct(pass);
      navigate(`/order`);
    }
  };

  return (
    <>
      {professorId ? (
        <Container>
          <TableContainer>
            <Table>
              {LECTURES_DATA.length > 0 ? (
                LECTURES_DATA.map((data, index) => (
                  <ColumnTitle
                    key={index}
                    onClick={() => selectTab(index)}
                    className={index === CurrentTab ? 'active' : ''}
                  >
                    <p>{data.tabName}</p>
                  </ColumnTitle>
                ))
              ) : (
                <></>
              )}
              {lectureStatus === 'loading' ? (
                <SpinLoader type={typeList[2].prop} color={'#000'} width={979} height={452} />
              ) : CurrentTab === 0 && slicedLecture !== undefined ? ( // 단과 강좌
                slicedLecture.map((data) => (
                  <TableItem key={data.id}>
                    <img
                      src={data.lectureImg}
                      onClick={() => navigate(`/lecture/${professorId}/lectures/${data.id}`)}
                    />
                    <ProfessorInfoBox
                      onClick={() => navigate(`/lecture/${professorId}/lectures/${data.id}`)}
                    >
                      {data.lectureCategory === 'language-logic' ? (
                        <p>언어논리</p>
                      ) : data.lectureCategory === 'data-interpretation' ? (
                        <p>자료해석</p>
                      ) : data.lectureCategory === 'situational-judgment' ? (
                        <p>상황판단</p>
                      ) : (
                        ''
                      )}
                      <p>{data.professorId}</p>
                    </ProfessorInfoBox>
                    <LectureInfoBox
                      onClick={() => navigate(`/lecture/${professorId}/lectures/${data.id}`)}
                    >
                      <p>{data.status}</p>
                      <p>{data.lectureName}</p>
                      <p>
                        <span>교재</span>
                        {data.books.length > 1 ? (
                          <span>{data.books[0].bookName} 외 1권</span>
                        ) : data.books[0] !== undefined ? (
                          <span>{data.books[0].bookName}</span>
                        ) : (
                          ''
                        )}
                      </p>
                    </LectureInfoBox>
                  </TableItem>
                ))
              ) : CurrentTab === 1 ? ( // 패키지 강좌
                LECTURES_DATA[CurrentTab].content.map((data, i) => (
                  <TableItem key={data.id}>
                    <LectureInfoBox
                      onClick={
                        () => navigate(`/lecture/${professorId}/passes/${data.id}`)
                        //navigate(`/pass/${data.id}`)
                      }
                    >
                      <p>{data.status}</p>
                      <p>{data.passName}</p>
                    </LectureInfoBox>
                    {data.sale === 0 ? (
                      <>
                        <span></span>
                        <span>
                          {data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원
                        </span>
                      </>
                    ) : (
                      <>
                        <span style={{ textDecoration: 'line-through' }}>
                          {data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원
                        </span>
                        <span>
                          {(data.price - data.sale)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                          원
                        </span>
                      </>
                    )}
                    {/* <ButtonContainer>
                      <Button
                        width={124}
                        height={48}
                        onClickFunc={() => clickOrderBasketButton(data)}
                      >
                        장바구니
                      </Button>
                      <Button
                        width={124}
                        height={48}
                        bgColor={COLOR.BLUE_4}
                        onClickFunc={() => clickOrderButton(data)}
                      >
                        구매하기
                      </Button>
                    </ButtonContainer> */}
                  </TableItem>
                ))
              ) : (
                ''
              )}
            </Table>
          </TableContainer>
          <div>
            <LoadMoreBtn onClick={loadMore}>더보기 +</LoadMoreBtn>
          </div>

          {/* <ButtonContainer>
            <Button width={124} height={48}>
              장바구니
            </Button>
            <Button width={124} height={48} bgColor={COLOR.BLUE_4}>
              구매하기
            </Button>
          </ButtonContainer> */}
        </Container>
      ) : null}
    </>
  );
};

export default LecturesTable;
