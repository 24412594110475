import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const BannerContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  width: 100%;
  height: 637px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-size: cover;
`;

const BannerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ textColor }) => textColor};

  // 교수님 설명
  div {
    text-align: right;
    p:nth-child(1) {
      ${FONT_STYLES.P_SB}
      font-size: 16px;
      line-height: 19px;
      padding-bottom: 7px;
    }
    p:nth-child(2) {
      ${FONT_STYLES.P_B}
      font-size: 40px;
      line-height: 44px;
      padding-bottom: 47px;
    }
    p:nth-child(3) {
      ${FONT_STYLES.P_L}
      font-size: 28px;
      line-height: 38px;
    }
    p:nth-child(4) {
      ${FONT_STYLES.P_M}
      display: inline-block;
      font-size: 28px;
      line-height: 38px;
      background: linear-gradient(to top, ${({ bgColor }) => bgColor} 60%, transparent 38%);
    }
  }

  // 교수님 한 마디 문구
  div:nth-child(2) {
    ${FONT_STYLES.P_R}
    font-size: 15px;
    line-height: 18px;
  }
`;

const BannerNewsContainer = styled.div``;

const RecentNews = styled.div`
  width: 424px;
  color: ${COLOR.GRAY_0};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 26px;
  padding: 30px 34px 33px 34px;
  margin-bottom: 16px;
  box-shadow: rgba(50, 50, 93, 0.2) 0px 13px 27px -5px, rgba(0, 0, 0, 0.1) 0px 8px 16px -8px;

  hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  & > p {
    ${FONT_STYLES.P_SB}
    font-size: 24px;
  }

  ul {
    ${FONT_STYLES.P_M}
    font-size: 18px;
    list-style: disc;
    list-style-position: inside;

    li {
      cursor: pointer;
    }

    li:not(:last-child) {
      padding-bottom: 20px;
    }
  }
`;

const RecentRewards = styled.div`
  width: 424px;
  display: flex;
  justify-content: space-between;
  color: ${COLOR.GRAY_0};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 26px;
  padding: 41px 36px 27px 36px;
  box-shadow: rgba(50, 50, 93, 0.2) 0px 13px 27px -5px, rgba(0, 0, 0, 0.1) 0px 8px 16px -8px;
`;

const RewardBox = styled.div`
  width: 158px;
  height: 137px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-repeat: no-repeat;
  p {
    ${FONT_STYLES.P_EB}
    font-size: 18px;
    line-height: 24px;
    color: ${COLOR.YELLOW_0};
    span {
      font-size: 14px;
    }
  }
`;

// 리뷰배너
const ReviewMainBannerContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  width: 100%;
  height: 637px;
  display: flex;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const ReviewMainBannerTextContainer = styled.div`
  //position: absolute;
  text-align: right;
  padding-top: 99px;
  right: 300px;
  color: ${({ textColor }) => textColor};

  p:nth-child(1) {
    ${FONT_STYLES.P_SB}
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 7px;
  }

  p:nth-child(2) {
    ${FONT_STYLES.P_B}
    font-size: 40px;
    line-height: 47px;
    padding-bottom: 47px;
  }

  p:nth-child(3) {
    ${FONT_STYLES.P_L}
    font-size: 28px;
    line-height: 38px;
  }
  p:nth-child(4) {
    ${FONT_STYLES.P_M}
    display: inline-block;
    font-size: 28px;
    line-height: 38px;
    background: linear-gradient(to top, ${({ bgColor }) => bgColor} 60%, transparent 38%);
  }
`;

const ReviewBubbleContainer = styled.div`
  width: 605px;
  height: 360px;
  margin: 186px 0px 0px 361px;
  position: relative;
`;

const LargeBubble = styled.div`
  width: 460px;
  height: 262px;
  position: absolute;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-repeat: no-repeat;
  background-position: 0px;
  padding: 36px 48px 31px 36px;
  z-index: 5;
  right: 0;
  bottom: 0;
  p:nth-child(1) {
    ${FONT_STYLES.P_SB}
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 25px;
  }
  p:nth-child(2) {
    ${FONT_STYLES.N_R}
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 26px;
  }
  p:nth-child(3) {
    ${FONT_STYLES.N_R}
    text-align: right;
    font-size: 16px;
    line-height: 18px;
  }
`;

const MediumBubble = styled.div`
  width: 301px;
  height: 148px;
  position: absolute;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-repeat: no-repeat;
  background-position: 0px;
  padding: 18px 0px 20px 21px;
  z-index: 1;
  left: 170px;

  p:nth-child(1) {
    ${FONT_STYLES.P_SB}
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 12px;
  }
  p:nth-child(2) {
    ${FONT_STYLES.N_R}
    font-size: 15px;
    line-height: 26px;
  }
`;

const SmallBubble = styled.div`
  width: 208px;
  height: 131px;
  position: absolute;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-repeat: no-repeat;
  background-position: 0px;
  z-index: 2;
  padding: 18px 0px 20px 22px;
  top: 80px;

  p:nth-child(1) {
    ${FONT_STYLES.P_SB}
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 12px;
  }
  p:nth-child(2) {
    ${FONT_STYLES.N_R}
    font-size: 15px;
    line-height: 26px;
  }
`;

export {
  BannerContainer,
  BannerTextContainer,
  RecentNews,
  BannerNewsContainer,
  RecentRewards,
  RewardBox,
  ReviewMainBannerContainer,
  ReviewMainBannerTextContainer,
  ReviewBubbleContainer,
  LargeBubble,
  MediumBubble,
  SmallBubble,
};
