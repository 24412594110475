import React from 'react';
import { CardContainer, ContentBox, Info, Title } from './style';
import { ReactComponent as ArrowBtn } from '../../../../assets/icons/icon-ArrowBtn.svg';

const LectureCard = ({ backgroundImg, backgroundColor, title, info, onClickFunc }) => {
  return (
    <CardContainer
      backgroundImg={backgroundImg}
      backgroundColor={backgroundColor}
      onClick={onClickFunc}
    >
      {/* <ContentBox>
        <Title>{title}</Title>
        <Info>{info}</Info>
        <ArrowBtn />
      </ContentBox> */}
    </CardContainer>
  );
};

export default LectureCard;
