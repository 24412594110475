import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import {
  RegisterJoinPageContainer,
  RegisterPageTitle,
  HorizonLine,
  RegisterContent,
  Border,
} from './style';
import COLOR from '../../styles/common/color';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';
import { DatePickerBirthInput } from '../../components/atoms/Input';
import moment from 'moment';

import SearchAddress from '../../components/organisms/SearchAddress';
import ProgressBox from '../../components/molecules/ProgressBox';
import StepIcon1 from '../../assets/icons/icon-assignment.png';
import StepIcon2Selected from '../../assets/icons/icon-join-selected.png';
import StepIcon3 from '../../assets/icons/icon-complete.png';

import { RegisterButton } from '../../components/atoms/Button';
import { SquareInput } from '../../components/atoms/Input';
import { CircleCheckBox } from '../../components/atoms/CheckBox';
import {
  requestRegisterSocialAccountConfirm,
  confirmRegister,
  registerAccount,
} from '../../axiosManage/Auth';

import { useRecoilState, useRecoilValue } from 'recoil';
import { isLoginAtom, registerSocialAccountTokenAtom } from '../../recoil/AuthStore/AuthRecoil';
import { getMyInfo } from '../../axiosManage/MyPage';
import { myInfoAtom } from '../../recoil/MyPageStore/MyPageRecoil';
import { registerAssignmentAtom } from '../../recoil/RegisterStore/RegisterRecoil';

function RegisterJoinPage() {
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const navigate = useNavigate();
  const [myInfo, setMyInfo] = useRecoilState(myInfoAtom);
  const registerAssignmentState = useRecoilValue(registerAssignmentAtom);

  useEffect(() => {
    if (isLogin === true) {
      alert('잘못된 접근입니다.');
      return <Navigate to="/" />;
    }
  }, []);

  // 이름 입력
  const [name, setName] = useState(''); // 휴대폰 번호
  const handleName = (e) => {
    setName(e.target.value);
  };

  // 성별 선택
  const [gender, setGender] = useState('남자');
  const genderSelect = (gen) => {
    if (gen === '남자') {
      setGender('남자');
    } else if (gen === '여자') {
      setGender('여자');
    }
  };

  // 생년월일 입력
  const [birth, setBirth] = useState(new Date());

  // 이메일 입력
  const [firstEmail, setFirstEmail] = useState('');
  const handleFirstEmail = (e) => {
    setFirstEmail(e.target.value);
  };
  const [secondEmail, setSecondEmail] = useState('');
  const handleSecondEmail = (e) => {
    setSecondEmail(e.target.value);
  };

  // 전화 번호 입력
  const [firstPhoneNumber, setFirstPhoneNumber] = useState(''); // 휴대폰 번호
  const handleFirstPhoneNumber = (e) => {
    setFirstPhoneNumber(e.target.value);
  };
  const [secondPhoneNumber, setSecondPhoneNumber] = useState(''); // 휴대폰 번호
  const handleSecondPhoneNumber = (e) => {
    setSecondPhoneNumber(e.target.value);
  };
  const [lastPhoneNumber, setLastPhoneNumber] = useState(''); // 휴대폰 번호
  const handleLastPhoneNumber = (e) => {
    setLastPhoneNumber(e.target.value);
  };

  // 인증번호 입력
  const [certificationNum, setCertificationNum] = useState(''); // 휴대폰 번호
  const handleCertificationNum = (e) => {
    setCertificationNum(e.target.value);
  };

  // 배송지 주소 입력
  const [addressDetail, setAddressDetail] = useState(''); // 상세 주소
  const handleAddressDetail = (e) => {
    setAddressDetail(e.target.value);
  };

  // 우편번호 팝업창
  const [postAddress, setPostAddress] = useState({
    address: '',
    zoneCode: '',
  });
  const [popup, setPopup] = useState(false);
  const handleSearchAddress = (e) => {
    setPostAddress({
      ...postAddress,
      [e.target.name]: e.target.value,
    });
  };

  const openSearchAddressPopUp = (data) => {
    setPopup(true);
  };

  // SMS, EMAIL 동의
  const [receiveAssignment, setReceiveAssignment] = useState(Array(2).fill(false));
  const receiveCheck = (id) => {
    let checkList = [...receiveAssignment];

    checkList[id] = !checkList[id];
    setReceiveAssignment(checkList);
  };

  /** 인증 문자 받기 */
  const registerSocialAccountToken = useRecoilValue(registerSocialAccountTokenAtom);
  const messageRequest = () => {
    if (
      firstPhoneNumber === '' ||
      secondPhoneNumber === '' ||
      lastPhoneNumber === '' ||
      Number.isInteger(firstPhoneNumber) ||
      Number.isInteger(secondPhoneNumber) ||
      Number.isInteger(lastPhoneNumber)
    ) {
      alert('휴대폰 번호를 정확하게 입력해주세요.');
    } else {
      requestRegisterSocialAccountConfirm({
        registerSocialAccountToken: registerSocialAccountToken,
        phoneNumber: firstPhoneNumber + secondPhoneNumber + lastPhoneNumber,
      })
        .then((res) => {
          if (res.data.success === true) alert('문자가 발송되었습니다');
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  /** 입력한 인증번호 검사 */
  const [certificationNumSuccess, setCertificationNumSuccess] = useState(false);
  const certificationNumCheck = () => {
    if (certificationNum === '') {
      alert('인증번호를 입력해주세요.');
    } else {
      confirmRegister({
        registerAccountToken: registerSocialAccountToken,
        randomNumber: certificationNum,
      })
        .then((res) => {
          if (res.data.success === true) {
            alert('인증이 완료되었습니다.');
            setCertificationNumSuccess(true);
          } else if (res.data.success === false) {
            alert('다시 인증을 받아주세요.');
          }
        })
        .catch((error) => {
          //alert(error.response.data.message);
        });
    }
  };

  const emailCheck = (email) => {
    const emailRegex =
      /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;

    return emailRegex.test(email);
  };

  /** 회원가입 데이터 전송 */
  const clickRegisterButton = () => {
    if (
      name === '' ||
      emailCheck(firstEmail + '@' + secondEmail) === false ||
      certificationNumSuccess === false ||
      postAddress.address === '' ||
      addressDetail === ''
    ) {
      alert('모든 칸을 입력해주시거나 인증번호 확인을 진행해주세요.');
    } else {
      registerAccount({
        registerAccountToken: registerSocialAccountToken,
        name: name,
        gender: gender,
        birthDay: birth,
        address: JSON.stringify({
          code: postAddress.zoneCode,
          address: postAddress.address,
          detailAddress: addressDetail,
        }),
        email: firstEmail + '@' + secondEmail,
        // agreeEmail: receiveAssignment[1],
        // agreeSMS: receiveAssignment[0],
        agreeEmail: registerAssignmentState[5],
        agreeSMS: registerAssignmentState[4],
      }).then((res) => {
        if (res.data.success === true) {
          localStorage.setItem('accessToken', res.data.accessToken);
          localStorage.setItem('refreshToken', res.data.refreshToken);
          setIsLogin(true);
          getMyInfo().then((res) => {
            setMyInfo(res.data.myInfo);
            navigate('/register/complete');
          });
        } else if (res.data.success === false) {
          alert('회원가입에 실패했습니다.');
        }
      });
    }
  };

  return (
    <RegisterJoinPageContainer>
      <RegisterPageTitle>랩스탠다드 회원가입</RegisterPageTitle>
      <HorizonLine />
      {popup && (
        <SearchAddress
          postAddress={postAddress}
          setPostAddress={setPostAddress}
          setPopup={setPopup}
        />
      )}
      <ProgressBox
        stepIcon1={StepIcon1}
        stepIcon2={StepIcon2Selected}
        stepIcon3={StepIcon3}
        stepText1={`이용약관 동의`}
        stepText2={`회원정보 입력`}
        stepText3={`회원가입 완료`}
      >
        <RegisterContent>
          <div>
            <p>이름</p>
            <SquareInput width={233} height={62} onChangeFunc={handleName} />
            <span>성별</span>
            <p>
              <RegisterButton
                width={76}
                height={62}
                bgColor={gender === '남자' ? COLOR.BLUE_4 : COLOR.GRAY_0}
                fontWeight={300}
                onClickFunc={() => genderSelect('남자')}
              >
                남자
              </RegisterButton>
            </p>
            <p>
              <RegisterButton
                width={76}
                height={62}
                bgColor={gender === '여자' ? COLOR.BLUE_4 : COLOR.GRAY_0}
                fontWeight={300}
                onClickFunc={() => genderSelect('여자')}
              >
                여자
              </RegisterButton>
            </p>
          </div>
          <div>
            <p>생년월일</p>
            <p>
              <DatePicker
                selected={birth}
                onChange={(date) => setBirth(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormat={'yyyy/MM/dd'}
                dateFormatCalendar={'yyyy년 MM월'}
                customInput={<DatePickerBirthInput />}
                locale={ko}
              />
            </p>
          </div>
          <div>
            <p>이메일</p>
            <SquareInput
              width={231}
              height={62}
              placeholder="labstandard77"
              textAlign={'center'}
              onChangeFunc={handleFirstEmail}
            />
            <span>@</span>
            <SquareInput
              width={187}
              height={62}
              placeholder="gmail.com"
              textAlign={'center'}
              onChangeFunc={handleSecondEmail}
            />
          </div>
          <div>
            <p>휴대폰</p>
            <SquareInput width={135} height={62} onChangeFunc={handleFirstPhoneNumber} />
            <span>-</span>
            <SquareInput width={135} height={62} onChangeFunc={handleSecondPhoneNumber} />
            <span>-</span>
            <SquareInput width={135} height={62} onChangeFunc={handleLastPhoneNumber} />
            <RegisterButton
              width={130}
              height={62}
              bgColor={COLOR.BLUE_4}
              fontSize={14}
              fontWeight={300}
              onClickFunc={messageRequest}
            >
              인증번호 받기
            </RegisterButton>
          </div>
          <div>
            <p>인증번호 입력</p>
            <SquareInput width={295} height={62} onChangeFunc={handleCertificationNum} />
            <RegisterButton
              width={130}
              height={62}
              bgColor={COLOR.BLUE_4}
              fontSize={14}
              fontWeight={300}
              onClickFunc={certificationNumCheck}
            >
              인증번호 확인
            </RegisterButton>
          </div>
          <Border />
          <div>
            <p>배송지 주소 입력</p>
            <SquareInput
              width={313}
              height={62}
              onChangeFunc={handleSearchAddress}
              value={postAddress.address}
            />
            <RegisterButton
              width={130}
              height={62}
              bgColor={COLOR.BLUE_4}
              fontSize={14}
              fontWeight={300}
              onClickFunc={openSearchAddressPopUp}
            >
              우편번호 검색
            </RegisterButton>
          </div>
          <div>
            <p></p>
            <SquareInput width={602} height={62} onChangeFunc={handleAddressDetail} />
          </div>
          {/* <Border />
          <div>
            <CircleCheckBox
              type="checkbox"
              width={30}
              height={30}
              isChecked={receiveAssignment[0]}
              onClickFunc={() => receiveCheck(0)}
            />
            <p onClick={() => receiveCheck(0)}>SMS 수신 동의</p>
          </div>
          <div>
            <CircleCheckBox
              type="checkbox"
              width={30}
              height={30}
              isChecked={receiveAssignment[1]}
              onClickFunc={() => receiveCheck(1)}
            />
            <p onClick={() => receiveCheck(1)}>E-Mail 수신 동의</p>
          </div> */}
        </RegisterContent>
      </ProgressBox>
      <RegisterButton
        width={202}
        height={62}
        bgColor={COLOR.BLUE_4}
        fontSize={20}
        onClickFunc={clickRegisterButton}
      >
        가입하기
      </RegisterButton>
    </RegisterJoinPageContainer>
  );
}

export default RegisterJoinPage;
