import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

export const FindIdCompleteContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding};
  padding-top: 60px;
  padding-bottom: 120px;
  text-align: center;

  @media screen and (max-width: 1700px) {
    padding-top: 60px;
    padding-bottom: 120px;
  }
`;

export const FindAccountPageTitle = styled.div`
  font-size: 36px;
  margin-top: 60px;
  text-align: center;

  & > span {
    ${FONT_STYLES.P_M};
  }
`;

export const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_4};
  margin: 50px auto;
`;

export const Content = styled.div`
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;
  padding: 40px 90px;
  margin-bottom: 40px;
  text-align: left;

  & > div {
    display: flex;
    align-items: center;
    margin: 20px 0;

    :nth-child(1) {
      ${FONT_STYLES.P_SB};
      font-size: 24px;
      margin-bottom: 30px;
    }

    :nth-child(2),
    :nth-child(3) {
      & > p {
        :nth-child(1) {
          width: 100px;
        }
        :nth-child(2) {
          width: 402px;
          height: 62px;
          border: none;
          padding: 20px 30px;
          border-radius: 7px;
          background-color: #f5f7fd;
        }
      }
    }
  }
`;
