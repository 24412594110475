import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  StudyTipDetailContainer,
  StudyTipDetailBox,
  DetailTitle,
  Info,
  Attachments,
  HorizonLine,
  ButtonContainer,
  TabTitle,
} from './style';
import COLOR from '../../../../styles/common/color';
import { Button } from '../../../atoms/Button';
import ToastViewer from '../../../atoms/ToastTextViewer';

import { SpinLoader, typeList } from '../../../atoms/SpinLoader';
import { useChangeMonthNumToEn } from '../../../../hooks/useChangeMonthNumToEn';
import { useQuery } from 'react-query';
import { getTip, downloadTipFile } from '../../../../axiosManage/ExamInfo';
import useHandleError from '../../../../hooks/useHandleError';

function StudyTipDetailPage() {
  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const { id } = useParams();

  const [StudyTipDetail, setStudyTipDetail] = useState([]);
  const { status: StudyTipDetailStatus } = useQuery(['getTip', id], () => getTip(id), {
    retry: 0,
    enabled: !!id,
    onSuccess: (data) => {
      console.log(data.data.tip);
      if (data.data.tip !== undefined) {
        setStudyTipDetail(data.data.tip);
      }
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const clickDownloadQuestion = (filename) => {
    downloadTipFile(id, filename);
  };

  return (
    <StudyTipDetailContainer>
      <TabTitle>학습 TIP</TabTitle>
      <HorizonLine />
      <StudyTipDetailBox>
        {StudyTipDetail !== undefined ? (
          <>
            <DetailTitle>{StudyTipDetail.title}</DetailTitle>
            <Info>
              <div>
                <p>{StudyTipDetail.createdAt?.slice(8, 10)}</p>
                <p>{useChangeMonthNumToEn(StudyTipDetail.createdAt?.slice(5, 7))}</p>
                <p>{StudyTipDetail.createdAt?.slice(0, 4)}</p>
              </div>
              <div>
                <p>작성자</p>
                <p>LAB STANDARD</p>
              </div>
            </Info>
            <ToastViewer content={StudyTipDetail.content}></ToastViewer>
            {StudyTipDetail.isExistFile ? (
              <Attachments>
                <span>첨부 파일</span>
                <a onClick={() => clickDownloadQuestion(StudyTipDetail.fileName)}>
                  <span>{StudyTipDetail.fileName}</span>
                </a>
              </Attachments>
            ) : null}
          </>
        ) : (
          <SpinLoader type={typeList[2].prop} color={'#000'} width={1131} height={459} />
        )}
      </StudyTipDetailBox>
      <ButtonContainer>
        <Button width={124} height={48} bgColor={COLOR.BLUE_4} onClickFunc={() => navigate(-1)}>
          목록으로
        </Button>
      </ButtonContainer>
    </StudyTipDetailContainer>
  );
}

export default StudyTipDetailPage;
