import React from 'react';
import {
  ActiveLine,
  LectureCurrentLocation,
  LectureHomeCurrentLocation,
  LectureHomeNavContainer,
  LectureNavContainer,
  Line,
} from './style';
import { ReactComponent as ArrowRight } from '../../../assets/icons/icon-ArrowRight.svg';
import { ReactComponent as ArrowRightB } from '../../../assets/icons/icon-ArrowRight-black.svg';
import { Link } from 'react-router-dom';

const NAV_LIST = [
  {
    name: '홈',
  },
  {
    name: '교수 소개',
    url: 'professor',
  },
  {
    name: '커리큘럼',
    url: 'curriculum',
  },
  {
    name: '수강 후기',
    url: 'review',
  },
  {
    name: '개설강좌',
    url: 'lectures',
  },
  {
    name: '공지사항',
    url: 'notice',
  },
  {
    name: '학습질문',
    url: 'question',
  },
  {
    name: '자료실',
    url: 'library',
  },
];

const LectureNav = ({ tab ,professorId}) => {
  return (
    <LectureNavContainer professorId={professorId}>
      <ul>
        {NAV_LIST.map((menu) => (
          <Link to={menu.url || ''} key={menu.name}>
            <li>
              <p className={tab === menu.url ? 'active' : ''}>{menu.name}</p>
              <ActiveLine className={tab === menu.url ? 'active' : ''} />
            </li>
          </Link>
        ))}
      </ul>
      <LectureCurrentLocation>
        <p>HOME</p>
        <ArrowRight stroke="#FFFFFF" />
        <p>5급 강의</p>
      </LectureCurrentLocation>
    </LectureNavContainer>
  );
};

const LectureHomeNav = () => {
  return (
    <LectureHomeNavContainer>
      <ul>
        <div>
          <p>01</p>
          <li>과목별 교수님</li>
          <Line />
        </div>
      </ul>
      <LectureHomeCurrentLocation>
        <p>HOME</p>
        <ArrowRight stroke="#716B6B" />
        <p>5급 강의</p>
      </LectureHomeCurrentLocation>
    </LectureHomeNavContainer>
  );
};

export { LectureNav, LectureHomeNav };
