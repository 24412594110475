import React from 'react';
import { checkPaymentSuccess } from '../../../axiosManage/OrderBasket';
import { useNavigate } from 'react-router-dom';

function PaymentRedirect() {
  const navigate = useNavigate();
  let imp_uid = new URL(window.location.href).searchParams.get('imp_uid');
  let merchant_uid = new URL(window.location.href).searchParams.get('merchant_uid');
  let imp_success = new URL(window.location.href).searchParams.get('imp_success');

  if (imp_success === 'false') {
    checkPaymentSuccess(imp_uid, merchant_uid)
      .then((res) => {
        alert('결제에 실패했습니다.');
        navigate('/basket');
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  } else {
    navigate('/mypage/myorder');
  }
}

export default PaymentRedirect;
