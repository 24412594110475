import styled, { css } from 'styled-components';
import COLOR from '../../../styles/common/color';

export const StyledProgressBar = styled.div`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : `${100}%`)};
  height: ${({ height }) => `${height}px`};
  background: ${COLOR.BLUE_5};
  overflow: hidden;

  ${({ percent }) =>
    percent
      ? css`
          background: linear-gradient(90deg, ${COLOR.BLUE_4} ${percent}%, ${COLOR.BLUE_5} 0);
        `
      : null}
`;
