import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PassNoteDetailContainer,
  PassNoteDetailBox,
  DetailTitle,
  Info,
  DetailContent,
  Attachments,
  HorizonLine,
  ButtonContainer,
  TabTitle,
  CommentBox,
  DataNotFound,
} from './style';
import COLOR from '../../../../styles/common/color';
import { Button } from '../../../atoms/Button';
import ToastViewer from '../../../atoms/ToastTextViewer';

import { SpinLoader, typeList } from '../../../atoms/SpinLoader';
import { useChangeMonthNumToEn } from '../../../../hooks/useChangeMonthNumToEn';
import { useQuery } from 'react-query';
import { getPassView, deletePassReview } from '../../../../axiosManage/ExamInfo';
import useHandleError from '../../../../hooks/useHandleError';

import { useRecoilState } from 'recoil';
import { myContactAtom, myPassReviewId } from '../../../../recoil/MyPageStore/MyPageRecoil';

function PassNoteDetailPage() {
  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const { id } = useParams();

  const [passReviewId, setPassReviewId] = useRecoilState(myPassReviewId);
  const [passNoteDetail, setPassNoteDetail] = useState([]);
  const [isWriter, setIsWriter] = useState(false);
  const { status: passNoteDetailStatus } = useQuery(['getPassView', id], () => getPassView(id), {
    retry: 0,
    enabled: !!id,
    onSuccess: (data) => {
      setPassReviewId(id);
      if (data.data.isWriter === true) {
        setIsWriter(true);
      }
      if (data.data.passReview !== undefined) {
        setPassNoteDetail(data.data.passReview);
      }
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [passReviewContent, setPassReviewContent] = useRecoilState(myContactAtom);
  const clickModify = (content) => {
    setPassReviewContent(content);
    navigate('/passreview/modify');
  };

  const clickDelete = (passReviewId) => {
    let isYes = confirm('해당 합격후기를 삭제하시겠습니까?');

    if (isYes) {
      deletePassReview(passReviewId)
        .then((res) => {
          alert('삭제되었습니다.');
          navigate(-1);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  return (
    <PassNoteDetailContainer>
      <TabTitle>합격후기</TabTitle>
      <HorizonLine />
      <PassNoteDetailBox>
        {passNoteDetail !== undefined ? (
          <>
            <DetailTitle>{passNoteDetail.title}</DetailTitle>
            <Info>
              <div>
                <p>{passNoteDetail.createdAt?.slice(8, 10)}</p>
                <p>{useChangeMonthNumToEn(passNoteDetail.createdAt?.slice(5, 7))}</p>
                <p>{passNoteDetail.createdAt?.slice(0, 4)}</p>
              </div>
              <div>
                <p>작성자</p>
                <p>{passNoteDetail.name}</p>
              </div>
              <div>
                <p>조회</p>
                <p>{passNoteDetail.views}</p>
              </div>
            </Info>
            <ToastViewer content={passNoteDetail.content}></ToastViewer>
            {passNoteDetail.existFile ? (
              <Attachments>
                <span>첨부 파일</span>
                <a href="#">
                  <span>{passNoteDetail.fileName}</span>
                </a>
              </Attachments>
            ) : null}
          </>
        ) : (
          <SpinLoader type={typeList[2].prop} color={'#000'} width={1131} height={459} />
        )}
      </PassNoteDetailBox>
      <ButtonContainer>
        {isWriter ? (
          <>
            <Button width={120} height={48} onClickFunc={() => clickModify(passNoteDetail)}>
              수정
            </Button>
            <Button width={120} height={48} onClickFunc={() => clickDelete(Number(id))}>
              삭제
            </Button>
          </>
        ) : (
          ''
        )}
        <Button width={124} height={48} bgColor={COLOR.BLUE_4} onClickFunc={() => navigate(-1)}>
          목록으로
        </Button>
      </ButtonContainer>
    </PassNoteDetailContainer>
  );
}

export default PassNoteDetailPage;
