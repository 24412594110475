import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

export const LectureDetailPageContainer = styled.div`
  ${({ theme }) => theme.container.mainContainer}
  padding-bottom: 123px;
`;

export const ContentContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-top: 80px;

  @media screen and (max-width: 1700px) {
    padding-top: 80px;
  }
`;

export const TabTitle = styled.p`
  ${FONT_STYLES.P_EB}
  text-align: center;
  font-size: 40px;
`;

export const Line = styled.p`
  width: 43px;
  border: 1.5px solid ${COLOR.BLUE_3};
  background-color: ${COLOR.BLUE_3};
  margin: 0 auto;
  margin-top: 39px;
`;

export const LectureDetailContainer = styled.div`
  margin-top: 75px;
  padding: 74px 90px 93px 93px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 22px;
`;

// -------- Section1 ----------
export const LectureSection1 = styled.div`
  display: flex;
  justify-content: space-between;

  & > img {
    width: 297px;
    height: 297px;
    border-radius: 7px;
  }
`;

export const LectureTitleContainer = styled.div`
  width: 760px;
  padding-top: 5px;
  padding-right: 63px;
`;

export const LectureSubTitleBox = styled.div`
  padding-top: 29px;
  span:nth-child(1) {
    ${FONT_STYLES.P_T}
    font-size: 18px;
    padding-right: 14px;
  }

  span:nth-child(2) {
    ${FONT_STYLES.P_R}
    font-size: 20px;
  }
`;

export const LectureIsEnd = styled.p`
  ${FONT_STYLES.P_L}
  font-size: 15px;
  line-height: 18px;
`;

export const LectureTitleBox = styled.div`
  ${FONT_STYLES.P_SB}
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 29px;

  & > p {
    flex: 1 1 30%;
  }
`;

export const TitleLine = styled.div`
  width: 80%;
  height: 1px;
  margin-left: 28px;
  background-color: #707070;
`;

export const LectureSection1TableContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`;

export const LectureSection1Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  column-gap: 110px;
  row-gap: 28px;

  & > div {
    display: flex;
    align-items: center;

    p:nth-child(1) {
      ${FONT_STYLES.P_T}
      width: 60px;
      font-size: 15px;
    }

    p:nth-child(2) {
      ${FONT_STYLES.P_R}
      font-size: 18px;
      padding-left: 28px;
    }
  }
`;

export const LectureSection1ButtonContainer = styled.div`
  button {
    display: block;
  }

  button:nth-child(1) {
    margin-bottom: 17px;
  }
`;

// ---------- Section2 ----------
export const LectureSection2 = styled.div`
  margin-top: 62px;
`;

export const LectureSection2Table = styled.div`
  border-top: 1px solid ${COLOR.GRAY_8};
  border-bottom: 1px solid ${COLOR.GRAY_8};
`;

export const LectureSection2ItemBox1 = styled.div`
  padding: 35px 63px 37px 56px;

  & > div:not(:last-child) {
    margin-bottom: 41px;
  }
`;

export const LectureSection2ItemLecture = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    display: flex;

    & > div {
      ${FONT_STYLES.P_SB}
      font-size: 18px;
      & > span {
        padding-left: 10px;
        padding-right: 63px;
      }
    }
  }
`;

export const LectureSection2ItemBook = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    & > div {
      display: flex;
      padding: 10px 0;
      ${FONT_STYLES.P_SB}
      font-size: 18px;
      & > span {
        padding-left: 10px;
        padding-right: 63px;
      }
    }
  }
`;

export const LecturePrice = styled.p`
  ${FONT_STYLES.P_B}
  font-size: 18px;

  & > div {
    padding: 10px 0;
    text-align: right;

    & > span {
      :nth-child(1) {
        opacity: 0.5;
        padding-right: 10px;
      }
    }
  }

  & > span {
    :nth-child(1) {
      opacity: 0.5;
      padding-right: 10px;
    }
  }
`;

export const LectureSection2ItemBox2 = styled.div`
  border-top: 1px solid ${COLOR.GRAY_8};
  padding: 28px 63px 26px 80px;

  & > div {
    display: flex;
    justify-content: space-between;
    p:nth-child(1) {
      ${FONT_STYLES.P_SB}
      font-size: 18px;
    }
  }
`;

export const LectureSection2ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 22px 63px 0px 0px;

  button:nth-child(1) {
    margin-right: 17px;
  }
`;

export const PassListContainer = styled.div`
  margin-top: 30px;

  & > select {
    margin-right: 20px;
  }
`;

export const PassAndButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
`;

// ------- Section3 -------
export const LectureSection3 = styled.div`
  margin-top: 37px;
  background: linear-gradient(90deg, ${COLOR.BLUE_8} 15%, #ffffff 15%);
  border-radius: 22px;
  box-shadow: 0px 3px 15px #00000029;
`;

export const LectureSectionTitle = styled.p`
  ${FONT_STYLES.P_SB}
  font-size: 24px;
`;

export const LectureSection3Table = styled.div`
  display: grid;
  grid-template-columns: 143px 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  border-radius: 22px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-top: 29px;

  div:nth-child(1) {
    border-radius: 22px 0px 0px 0px / 22px 0px 0px 0px;
  }

  div:nth-child(5) {
    border-radius: 0px 0px 0px 22px / 0px 0px 0px 22px;
  }
`;

export const LectureSection3TableTitle = styled.div`
  ${FONT_STYLES.P_SB}
  font-size: 18px;
  color: ${COLOR.GRAY_0};
  padding-left: 19px;
  background-color: ${COLOR.BLUE_8};

  p {
    padding: 58px 0px 58px 19px;
    border-bottom: 1px solid #e5e5e5;
  }

  .last-title {
    border: 0px;
  }
`;

export const LectureSection3TableContent = styled.div`
  padding: 0 20px;

  & > div {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 30px;
    border-bottom: 1px solid ${COLOR.GRAY_9};

    & > p {
      :nth-child(1) {
        flex-basis: 15%;
        ${FONT_STYLES.P_R};
        font-size: 18px;
        color: ${COLOR.GRAY_0};
      }
    }

    & > div {
      ${FONT_STYLES.P_R};
      line-height: 1.5;

      & > p {
        padding: 5px 0;
      }
    }
  }
`;

// ---------- Section 4 ----------
export const LectureSection4 = styled.div`
  margin-top: 88px;
  line-height: 1.5;

  & > div {
    color: #716b6b;
  }
`;

export const LectureSection4Table = styled.div`
  padding: 39px 25px 50px 19px;
  margin-top: 27px;
  border-radius: 22px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

export const LectureSection4TableTitle = styled.div`
  ${FONT_STYLES.P_SB}
  font-size: 18px;
  & > div {
    display: flex;
    justify-content: space-between;
    padding: 0px 50px 30px 50px;
    border-bottom: 1px solid #e5e5e5;

    p:nth-child(2) {
      width: 600px;
      text-align: left;
    }
  }
`;

export const LectureSection4TableContentContainer = styled.div`
  padding: 43px 50px 0px 50px;

  & > div:not(:last-child) {
    margin-bottom: 56px;
  }
`;

export const LectureSection4TableContent = styled.div`
  ${FONT_STYLES.P_R}
  display: flex;
  justify-content: space-between;
  font-size: 18px;

  p:nth-child(2) {
    width: 600px;
    text-align: left;
  }

  p:nth-child(3) {
    width: 60px;
    text-align: center;
  }
`;
