import React from 'react';
import { Contents, LectureReviewCardContainer, UserNameContainer, Writer } from './style';
import ToastViewer from '../../atoms/ToastTextViewer';

const LectureReviewCard = ({ userName, keyword, content, title, writer }) => {
  return (
    <LectureReviewCardContainer>
      <UserNameContainer>
        <p></p>
        <p>{keyword}</p>
      </UserNameContainer>
      <Contents>
        <ToastViewer content={content} />
      </Contents>
      <Writer>
        <div>수강생</div>
        <div>{writer}</div>
      </Writer>
    </LectureReviewCardContainer>
  );
};

export default LectureReviewCard;
