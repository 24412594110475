import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const StudyTipContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-top: 70px;
  padding-bottom: 120px;
  text-align: center;

  @media screen and (max-width: 1700px) {
    padding-top: 70px;
    padding-bottom: 120px;
  }
`;

const StudyTipTitle = styled.div`
  ${FONT_STYLES.P_B}
  font-size: 40px;
  margin-bottom: 40px;
`;



const StudyTipSubTitle = styled.div`
  ${FONT_STYLES.P_M}
  font-size: 22px;
`;

const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_3};
  margin: 40px auto;
`;

const MenuBox = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px auto;

  & > div {
    margin: 0 5px;

    & > div {
      svg,
      div {
        cursor: pointer;
      }
    }
  }
`;

const StudyTipContentBox = styled.div`
  max-width: 1320px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const StudyTipContent = styled.div`
  flex: 1 1 30%;
  max-width: 420px;

  img {
    width: 400px;
    height: 270px;
    cursor: pointer;
    border-radius: 22px;
    object-fit: scale-down;
  }

  p {
    padding: 4px 0;
    margin-left: 10px;
    text-align: left;
    cursor: pointer;
  }
  p:nth-child(2) {
    font-size: 15px;
  }
  p:nth-child(3) {
    ${FONT_STYLES.P_SB}
    font-size: 20px;
  }
  p:nth-child(4) {
    font-size: 14px;
  }
`;

const LoadMoreBtn = styled.button`
  width: 120px;
  height: 50px;
  margin: 50px 0 70px 0;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 25px;
`;

export {
  StudyTipContainer,
  StudyTipTitle,
  HorizonLine,
  MenuBox,
  StudyTipSubTitle,
  StudyTipContentBox,
  StudyTipContent,
  LoadMoreBtn,
};
