import React from 'react';
import {
  RegisterPageContainer,
  RegisterContainer,
  RegisterTitle,
  RegisterBtn,
  GoToLoginPageBox,
} from './style';

import NaverLoginBtn from '../../assets/images/naver-login-btn.png';
import KakaoLoginBtn from '../../assets/images/kakao_login_original.png';
import LabstandardRegisterBtn from '../../assets/images/labstandard-register-btn.png';
import { Link, Navigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';

const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_API_KEY}&&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}`;

function RegisterPage() {
  const isLogin = useRecoilValue(isLoginAtom);

  if (isLogin === true) {
    return <Navigate to="/" />;
  }

  const handleKakaoLogin = () => {
    window.location.href = KAKAO_AUTH_URL;
  };

  // 네이버 로그인
  const handleNaverLogin = () => {
    window.location.href = NAVER_AUTH_URL;
  };

  return (
    <RegisterPageContainer>
      <RegisterContainer>
        <RegisterTitle>랩스탠다드 회원가입</RegisterTitle>
        <RegisterBtn>
          <Link to="/register/assignment">
            <img src={LabstandardRegisterBtn} />
            <p>랩스탠다드 일반회원 가입</p>
          </Link>
        </RegisterBtn>
        <RegisterBtn>
          <div onClick={handleNaverLogin}>
            <img src={NaverLoginBtn} />
            <p>네이버로 시작하기</p>
          </div>
        </RegisterBtn>
        <RegisterBtn>
          <div onClick={handleKakaoLogin}>
            <img src={KakaoLoginBtn} />
            <p>카카오로 시작하기</p>
          </div>
        </RegisterBtn>
        <GoToLoginPageBox>
          <p>이미 랩스탠다드 회원이신가요?</p>
          <Link to="/login">로그인하기</Link>
        </GoToLoginPageBox>
      </RegisterContainer>
    </RegisterPageContainer>
  );
}

export default RegisterPage;
