import styled from 'styled-components';

const LecturesContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  padding-top: 51px;
  padding-bottom: 35px;
`;

export { LecturesContainer };
