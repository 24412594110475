import { atom } from 'recoil';
import moment from 'moment';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

/** 로그인 한 경우 가져온 내 정보 */
export const myInfoAtom = atom({
  key: 'myInfoAtom',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

/** 마이페이지 - 내 강좌 myLectures*/
export const myPageMyLectureAtom = atom({
  key: 'myPageMyLectureAtom',
  default: [],
});

/** 마이페이지 - 내 강좌 learningStatus */
export const myPageMyLectureStatusAtom = atom({
  key: 'myPageMyLectureStatusAtom',
  default: [],
});

/** 마이페이지 - 내 결제 내역 */
export const myPageMyOrderAtom = atom({
  key: 'myPageMyOrderAtom',
  default: [],
});

/** 마이페이지 - 내 결제 내역 기간 설정 */
export const myPageMyOrderStartDateAtom = atom({
  key: 'myPageMyOrderStartDateAtom',
  default: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
});
export const myPageMyOrderEndDateAtom = atom({
  key: 'myPageMyOrderEndDateAtom',
  default: moment(new Date()).format('YYYY-MM-DD'),
});

/** 마이페이지 - 알람 개수 */
export const myPageMyAlarmCountAtom = atom({
  key: 'myPageMyAlarmCountAtom',
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

/** 마이페이지 - 수정할 1:1문의 내용 */
export const myContactAtom = atom({
  key: 'myContactAtom',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

/** 마이페이지 - 수정할 학습질문 게시글 id */
export const myQuestionId = atom({
  key: 'myQuestionId',
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

/** 마이페이지 - 수정할 수강후기 게시글 id */
export const myReviewId = atom({
  key: 'myReviewId',
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

/** 마이페이지 - 수정할 합격후기 게시글 id */
export const myPassReviewId = atom({
  key: 'myPassReviewId',
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
