import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const QuestionDetailBox = styled.div`
  width: 100%;
  min-height: 500px;
  padding: 20px 60px;
  background-color: ${COLOR.GRAY_0};
  filter: drop-shadow(0px 3px 20px #00000029);
  position: relative;
  border-radius: 20px;

  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 10%;
    width: 0;
    height: 0;
    border: 41px solid transparent;
    border-top-color: ${COLOR.GRAY_0};
    border-bottom: 0;
    border-left: 0;
    margin-left: -20.5px;
    margin-bottom: -41px;
    filter: drop-shadow(0px 3px 20px #00000029);
  }
`;

const BottomPadding = styled.div`
  width: 100%;
  height: 50px;
`;

const SmallQuestionDetailBox = styled.div`
  width: 100%;
  min-height: 350px;
  padding: 10px 15px;
  background-color: ${COLOR.GRAY_0};
  filter: drop-shadow(0px 3px 10px #00000029);
  position: relative;
  border-radius: 10px;
`;

const VerySmallQuestionDetailBox = styled.div`
  width: 100%;
  padding: 10px 15px;
  background-color: ${COLOR.GRAY_0};
  filter: drop-shadow(0px 3px 10px #00000029);
  position: relative;
  border-radius: 10px;
`;

export { QuestionDetailBox, BottomPadding, SmallQuestionDetailBox, VerySmallQuestionDetailBox };
