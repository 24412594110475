import React, { useState, useEffect } from 'react';
import {
  Best5SectionTitle,
  BestBookContainer,
  BestBookTabBox,
  BestBookTabContainer,
} from '../../../pages/Main/style';
import BestBookContent from '../../molecules/BestBookContent';
import { BooksContainer, MainBookContainer, MainBookInfo, RemainBooksContainer } from './style';
import { SpinLoader, typeList } from '../../atoms/SpinLoader';
import { useNavigate } from 'react-router-dom';

const BestBooks = ({
  bestLanguageLogicBooks,
  bestDataInterpretationBooks,
  bestSituationalJudgmentBooks,
  bestBookStatus,
}) => {
  const navigate = useNavigate();

  const dataArr = [
    {
      tabName: '언어논리',
      content: bestLanguageLogicBooks,
    },
    {
      tabName: '자료해석',
      content: bestDataInterpretationBooks,
    },
    {
      tabName: '상황판단',
      content: bestSituationalJudgmentBooks,
    },
  ];

  const [CurrentTab, setCurrentTab] = useState(0);

  const selectTab = (index) => {
    setCurrentTab(index);
  };

  const goToBookDetailPage = (bookId) => {
    navigate(`/bookstore/entire/${bookId}`);
  };

  return (
    <BestBookContainer>
      <BestBookTabContainer>
        <Best5SectionTitle>교재 BEST 5</Best5SectionTitle>
        <BestBookTabBox>
          {dataArr.length > 0 ? (
            dataArr.map((data, index) => (
              <p
                onClick={() => selectTab(index)}
                key={data.id}
                className={index === CurrentTab ? 'active' : ''}
              >
                {data.tabName}
              </p>
            ))
          ) : (
            <></>
          )}
        </BestBookTabBox>
      </BestBookTabContainer>
      {bestBookStatus === 'loading' ? (
        <SpinLoader type={typeList[2].prop} color={'#000'} width={690} height={550} />
      ) : (
        <BooksContainer>
          <MainBookContainer>
            {dataArr[CurrentTab].content[0] !== undefined ? (
              <>
                <img src={dataArr[CurrentTab].content[0].bookImg} width="139px" height="171px" />
                <MainBookInfo onClick={() => goToBookDetailPage(dataArr[CurrentTab].content[0].id)}>
                  <p>Best 1</p>
                  <p></p>
                  <p>{dataArr[CurrentTab].content[0].bookName}</p>
                </MainBookInfo>
              </>
            ) : null}
          </MainBookContainer>
          <RemainBooksContainer>
            {dataArr[CurrentTab].content[1] !== undefined ? (
              <BestBookContent
                rank={2}
                subtitle={dataArr[CurrentTab].content[1].introduction}
                title={dataArr[CurrentTab].content[1].bookName}
                img={dataArr[CurrentTab].content[1].bookImg}
                onClickFunc={() => goToBookDetailPage(dataArr[CurrentTab].content[1].id)}
              />
            ) : null}
            {dataArr[CurrentTab].content[2] !== undefined ? (
              <BestBookContent
                rank={3}
                subtitle={dataArr[CurrentTab].content[2].introduction}
                title={dataArr[CurrentTab].content[2].bookName}
                img={dataArr[CurrentTab].content[2].bookImg}
                onClickFunc={() => goToBookDetailPage(dataArr[CurrentTab].content[2].id)}
              />
            ) : null}
            {dataArr[CurrentTab].content[3] !== undefined ? (
              <BestBookContent
                rank={4}
                subtitle={dataArr[CurrentTab].content[3].introduction}
                title={dataArr[CurrentTab].content[3].bookName}
                img={dataArr[CurrentTab].content[3].bookImg}
                onClickFunc={() => goToBookDetailPage(dataArr[CurrentTab].content[3].id)}
              />
            ) : null}
            {dataArr[CurrentTab].content[4] !== undefined ? (
              <BestBookContent
                rank={5}
                subtitle={dataArr[CurrentTab].content[4].introduction}
                title={dataArr[CurrentTab].content[4].bookName}
                img={dataArr[CurrentTab].content[4].bookImg}
                onClickFunc={() => goToBookDetailPage(dataArr[CurrentTab].content[4].id)}
              />
            ) : null}
          </RemainBooksContainer>
        </BooksContainer>
      )}
    </BestBookContainer>
  );
};

export default BestBooks;
