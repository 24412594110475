import React from 'react';
import { Line, TabTitle } from '../Lectures/style';
import CurriculumImg from './CurriculumImg';
import { CurriculumContainer, CurriculumImgContainer } from './style';

const Curriculum = ({ professorId }) => {
  return (
    <CurriculumContainer>
      <TabTitle>커리큘럼</TabTitle>
      <Line />
      <CurriculumImgContainer>
        <CurriculumImg professorId={professorId} />
      </CurriculumImgContainer>
    </CurriculumContainer>
  );
};

export default Curriculum;
