import React, { useState } from 'react';
import { ExamInfoContainer, ExamInfoTitle, HorizonLine, ExamInfoCategoryBar,ExamInfoComment } from './style';

//import { ReactComponent as MenuBtn } from '../../../../assets/icons/icon-MenuBtn.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/icon-Filter.svg';

import AdministrativeJobWide from '../AdministrativeJobWide';
import AdministrativeJobArea from '../AdministrativeJobArea';
import TechnicalJob from '../TechnicalJob';
import Diplomat from '../Diplomat';
import LegislativeTest from '../LegislativeTest';
import RecruitmentOf7th from '../RecruitmentOf7th';

import { useQuery } from 'react-query';
import useHandleError from '../../../../hooks/useHandleError';
import { getExamInfo } from '../../../../axiosManage/ExamInfo';

import { useRecoilState } from 'recoil';
import { examInfoExamPageInfoAtom } from '../../../../recoil/ExamInfoStore/ExamInfoRecoil';

const NAV_LIST = [
  {
    id: 1,
    name: '5급 행정직(전국)',
  },
  {
    id: 2,
    name: '5급 행정직(지역)',
  },
  {
    id: 3,
    name: '5급 기술직(지역)',
  },
  {
    id: 4,
    name: '외교관 후보자',
  },
  {
    id: 5,
    name: '입법고시',
  },
  {
    id: 6,
    name: '7급 공채',
  },
];

function ExamInfo() {
  const { handleError } = useHandleError();
  const [currentCategory, setCurrentCategory] = useState(1);

  const clickCategory = (id) => {
    setCurrentCategory(id);
  };

  const [examInfo, setExamInfo] = useRecoilState(examInfoExamPageInfoAtom);
  const { status: examInfoStatus } = useQuery('getExamInfo', getExamInfo, {
    retry: 0,
    onSuccess: (data) => {
      setExamInfo(data.data);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  return (
    <ExamInfoContainer>
      <ExamInfoTitle>직렬 정보 찾기</ExamInfoTitle>
      <HorizonLine />
      {/* 카테고리 메뉴 바 */}
      <ExamInfoCategoryBar>
        <li>
          {/* <MenuBtn /> */}
          <FilterIcon />
        </li>
        {NAV_LIST.map((menu) => (
          <div key={menu.id}>
            <li
              className={menu.id === currentCategory ? 'active' : ''}
              onClick={() => clickCategory(menu.id)}
            >
              {menu.name}
            </li>
          </div>
        ))}
      </ExamInfoCategoryBar>
      {/* 표 컨텐츠 */}
      {examInfo !== undefined ? (
        <>
          {currentCategory === 1 ? (
            <AdministrativeJobWide data={examInfo.오급행정직전국} />
          ) : currentCategory === 2 ? (
            <AdministrativeJobArea data={examInfo.오급행정직지역} />
          ) : currentCategory === 3 ? (
            <TechnicalJob data={examInfo.오급기술직지역} />
          ) : currentCategory === 4 ? (
            <Diplomat data={examInfo.외교관후보자} />
          ) : currentCategory === 5 ? (
            <LegislativeTest data={examInfo.입법고시} />
          ) : currentCategory === 6 ? (
            <RecruitmentOf7th data={examInfo.칠급공채} />
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
      <ExamInfoComment>
        <p>※ (1) 합격률 : 응시인원 대비 합격인원을 의미함 </p>
        <p>※ (2) 경쟁률 : 합격인원 대비 출원인원을 의미함</p>
      
      </ExamInfoComment>
    </ExamInfoContainer>
  );
}

export default ExamInfo;
