const COLOR = {
  // 회색 계열
  GRAY_0: '#FFFFFF',
  GRAY_1: '#F4F7FF',
  GRAY_2: '#00000029',
  GRAY_3: '#BCBCBC',
  GRAY_4: '#707070',
  GRAY_5: '#6C6666',
  GRAY_6: '#C0C5D1',
  GRAY_7: '#BBBABD',
  GRAY_8: '#D3D3D3',
  GRAY_9: '#E5E5E5',
  GRAY_10: '#D9D9D9',

  // 푸른색 계열
  BLUE_0: '#8EC5FC',
  BLUE_1: '#9DB3D2',
  BLUE_2: '#6667AA',
  BLUE_3: '#2D4878',
  BLUE_4: '#19325F',
  BLUE_5: '#F4F7FF',
  BLUE_6: '#25D4D8',
  BLUE_7: '#B3B7CB',
  BLUE_8: '#5C6072',

  // 검은색 계열
  BLACK_0: '#1A1311',
  BLACK_1: '#000000',
  BLACK_2: '#3B3B3B',

  // 노란색 계열
  YELLOW_0: '#FECA2F',
  YELLOW_1: '#EBBA92',

  //보라색 계열
  PURPLE_0: '#E0C3FC',

  //초록색 계열
  GREEN_0: '#8DA2A2',
  GREEN_1: '#3A5860',

  // 붉은색 계열
  RED_0: '#FEC5C4',
  RED_1: '#AB6277',

  // 카드에 쓰이는 색상
  CARD_SKYBLUE: '#D6DAE7',
  CARD_PINK: '#E5B9C6',
  CARD_BLUE: '#AFB0EB',
  CARD_GRAY: '#C7C5CB',
  CARD_ORANGE: '#EDBE87',
};

export default COLOR;
