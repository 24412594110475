import React from 'react';
import SearchBar from '../../../molecules/SearchBar';
import { SubBannerContainer, HorizonLine, SubBannerTextContainer } from './style';

import BookStoreSubBanner from '../../../../assets/images/image-bookstore-sub-banner.png';

function SubBanner() {
  return (
    <SubBannerContainer backgroundImg={BookStoreSubBanner}>
      <SubBannerTextContainer>
        <p>랩스탠다드 서점</p>
        <HorizonLine />
        {/* <p>교재를 검색해보세요</p>
        <SearchBar /> */}
      </SubBannerTextContainer>
    </SubBannerContainer>
  );
}

export default SubBanner;
