import React, { useState, useEffect, useRef } from 'react';
import Input from '../../components/atoms/Input';
import { Button, CancelButton } from '../../components/atoms/Button/index';
import ToastEditor from '../../components/atoms/ToastTextEditor';
import {
  ButtonContainer,
  ContentContainer,
  FormContainer,
  PassReviewCreatePageContainer,
  SubTitle,
  Title,
} from './style';
import Select from '../../components/atoms/Select';
import COLOR from '../../styles/common/color';

import { addPassReview } from '../../axiosManage/ExamInfo';
import useHandleError from '../../hooks/useHandleError';
import { useNavigate, Navigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';
import { myInfoAtom } from '../../recoil/MyPageStore/MyPageRecoil';

const SELECT_OPTIONS = [
  { value: '', optionName: '구분' },
  { value: '5급공채', optionName: '5급공채' },
  { value: '국립외교원', optionName: '국립외교원' },
  { value: '지역인재', optionName: '지역인재' },
  { value: '7급공채', optionName: '7급공채' },
];

const PassReviewCreatePage = () => {
  const myInfo = useRecoilValue(myInfoAtom);
  const isLogin = useRecoilValue(isLoginAtom);

  if (isLogin === false) {
    alert('로그인을 해주세요.');
    return <Navigate to="/login" />;
  }

  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const editorRef = useRef();

  // 유저가 입력한 서버로 전송할 데이터(제목, 내용, 카테고리)
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('구분');

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const [lectureId, setLectureId] = useState(1);
  useEffect(() => {
    if (category === '5급공채') {
      setLectureId(1);
    } else if (category === '국립외교원') {
      setLectureId(2);
    } else if (category === '지역인재') {
      setLectureId(3);
    } else if (category === '7급공채') {
      setLectureId(4);
    }
  }, [category]);

  const inquirySubmit = () => {
    if (
      title !== '' &&
      !editorRef.current
        ?.getInstance()
        .getHTML()
        .includes('위에 구분을 먼저 선택 후 글을 작성해주시기 바랍니다!') &&
      category !== '구분'
    ) {
      addPassReview({
        title: title,
        content: editorRef.current?.getInstance().getHTML(),
        category: category,
      })
        .then((res) => {
          if (res.data.success === true) {
            alert('합격후기 등록이 완료되었습니다!');
            navigate(`/examinfo/passnote`);
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else {
      alert('제목, 내용, 카테고리를 모두 입력해주세요!');
    }
  };

  return (
    <PassReviewCreatePageContainer>
      <ContentContainer>
        <Title>합격후기</Title>
        <SubTitle>합격후기 작성하기</SubTitle>
        <FormContainer>
          <div>
            <Select
              width={313}
              height={58}
              selectOptions={SELECT_OPTIONS}
              onChangeFunc={handleCategoryChange}
            />
            <Input
              width={645}
              height={58}
              placeholder="제목을 입력하세요."
              onChangeFunc={handleTitleChange}
            />
            {isLogin ? (
              <div>이름: {myInfo.name}</div>
            ) : (
              <Input width={313} height={58} placeholder="이름을 입력하세요." />
            )}
          </div>
          <ToastEditor editorRef={editorRef} />
          <div>
            {/* <FileUploader /> */}
            <ButtonContainer>
              {/* <CancelButton width={124} height={48}>
                취소
              </CancelButton> */}
              <Button width={124} height={48} bgColor={COLOR.BLUE_4} onClickFunc={inquirySubmit}>
                제출
              </Button>
            </ButtonContainer>
          </div>
        </FormContainer>
      </ContentContainer>
    </PassReviewCreatePageContainer>
  );
};

export default PassReviewCreatePage;
