import React from 'react';
import { StyledButton, StyledCancelButton, StyledRegisterButton } from './style';

export const Button = ({ children, name, width, height, onClickFunc, bgColor, fontSize, marginLeft = 0, marginRight = 0 }) => {
  return (
    <StyledButton
      name={name}
      width={width}
      height={height}
      onClick={onClickFunc}
      bgColor={bgColor}
      fontSize={fontSize}
      marginLeft={marginLeft}
      marginRight={marginRight}
    >
      {children}
    </StyledButton>
  );
};

export const CancelButton = ({ children, name, width, height, onClickFunc }) => {
  return (
    <StyledCancelButton name={name} width={width} height={height} onClick={onClickFunc}>
      {children}
    </StyledCancelButton>
  );
};

export const RegisterButton = ({
  children,
  name,
  width,
  height,
  onClickFunc,
  bgColor,
  fontSize,
  fontWeight,
}) => {
  return (
    <StyledRegisterButton
      name={name}
      width={width}
      height={height}
      onClick={onClickFunc}
      bgColor={bgColor}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      {children}
    </StyledRegisterButton>
  );
};
