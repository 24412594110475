/* eslint-disable react/jsx-key */
import React, { useEffect, useRef, useState } from 'react';
import ReviewCard from '../../molecules/ReviewCard';
import {
  ReviewBox,
  ReviewDot,
  ReviewSlide,
  ReviewSlideControllerBox,
  SliderContainer,
  SlidesContainer,
} from './style';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';

const ReviewSlides = ({ passNote }) => {
  const navigate = useNavigate();
  // 전달받은 데이터 저장하는 배열 slides
  const slides = [passNote.slice(0, 4), passNote.slice(4)];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <SliderContainer>
      <Slider {...settings}>
        {slides.map((item, index) => (
          <ReviewBox index={index}>
            <div>
              {item.map((note) => (
                <ReviewCard
                  key={note.id}
                  keyword={note.category}
                  content={note.content}
                  title={note.title}
                  onClickFunc={() => navigate(`/examinfo/passnote/${note.id}`)}
                />
              ))}
            </div>
          </ReviewBox>
        ))}
      </Slider>
    </SliderContainer>
  );
};

export default ReviewSlides;
