import React from 'react';
import { BookImg, CardContainer, SmallTitle, Title } from './style';

const BookCard = ({ bookImg, smalltitle, title, onClickFunc }) => {
  return (
    <CardContainer onClick={onClickFunc}>
      <BookImg src={bookImg} />
      <SmallTitle>{smalltitle}</SmallTitle>
      <Title>{title}</Title>
    </CardContainer>
  );
};

export default BookCard;
