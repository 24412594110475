import styled from 'styled-components';
import { FONT_STYLES } from '../../../styles/common/font-styles';

const ContentContainer = styled.div`
  width: 303px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  :hover {
    cursor: pointer;
  }
  img {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

const Rank = styled.p`
  ${FONT_STYLES.N_EB}
  font-size: 24px;
`;

const Content = styled.div`
  flex: 1;
  margin-left: 33px;
  p {
    ${FONT_STYLES.P_M}
    width: 190px;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export { ContentContainer, Rank, Content };
