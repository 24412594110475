import React, { useState, useEffect } from 'react';
import introduceImg from '../../assets/images/image-introducePage.png';
import logo from '../../assets/images/image-introLogo.svg';
import {
  CompanyInfo,
  CompanyName,
  ImageContainer,
  IntroPageContainer,
  Line,
  LogoContainer,
  MapBox,
  MapContainer,
  MapContents,
  MapInfoBox,
  MapInfoContainer,
  Title,
} from './style';
import KakaoMap from '../../components/molecules/KakaoMap';

const IntroPage = () => {
  return (
    <IntroPageContainer>
      <ImageContainer>
        <img src={introduceImg} />
      </ImageContainer>
      <MapContainer>
        <Line />
        <MapContents>
          <MapInfoContainer>
            <MapInfoBox>
              <Title>오시는 길</Title>
              <CompanyName>랩스탠다드</CompanyName>
              <CompanyInfo>
                <p>
                  <span>ADDRESS</span>
                  <span>서울특별시 관악구 호암로 26길 25(덕영빌딩) 2층</span>
                </p>
                <p>
                  <span>TEL</span>
                  <span>070-8808-0700</span>
                </p>
                <p>
                  <span>대중교통</span>
                  <span>서울대벤처타운역 2번 출구에서 231m</span>
                </p>
              </CompanyInfo>
            </MapInfoBox>
            <MapBox>
              <KakaoMap />
            </MapBox>
          </MapInfoContainer>
        </MapContents>
      </MapContainer>
      <LogoContainer>
        <img src={logo} />
      </LogoContainer>
    </IntroPageContainer>
  );
};

export default IntroPage;
