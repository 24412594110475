import React, { useState, useEffect } from 'react';
import {
  UserInfoPageContainer,
  UserInfoContent,
  UserInfoTitle,
  Border,
  ButtonContainer,
} from './style';
import COLOR from '../../../../styles/common/color';
import moment from 'moment';

import SearchAddress from '../../../organisms/SearchAddress';
import { Button, RegisterButton } from '../../../atoms/Button';
import { SquareInput } from '../../../atoms/Input';

import { useRecoilState } from 'recoil';
import {
  getMyInfo,
  modifyMyInfo,
  requestModifyPhoneCertification,
  requestModifyPhone,
} from '../../../../axiosManage/MyPage';
import { userWithdrawal } from '../../../../axiosManage/Auth';
import { myInfoAtom } from '../../../../recoil/MyPageStore/MyPageRecoil';
import { isLoginAtom } from '../../../../recoil/AuthStore/AuthRecoil';

function MyInfo() {
  const [myInfo, setMyInfo] = useRecoilState(myInfoAtom);
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);

  useEffect(() => {
    getMyInfo().then((res) => {
      setMyInfo(res.data.myInfo);
    });
  }, []);

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setPwConfirm] = useState('');
  const [name, setName] = useState(myInfo.name);
  const [birth, setBirth] = useState(new Date(myInfo.birthday));
  const [emailFirst, setEmailFirst] = useState(myInfo.email.split('@')[0]);
  const [emailLast, setEmailLast] = useState(myInfo.email.split('@')[1]);
  const [phoneNumber, setPhoneNumber] = useState(myInfo.phoneNumber); // 휴대폰 번호
  const [addressDetail, setAddressDetail] = useState(JSON.parse(myInfo.address).detailAddress);

  // 비밀번호 변경
  const handlePassword = (e) => {
    setNewPassword(e.target.value);
  };
  const [isPasswordSame, setIsPasswordSame] = useState(false);
  const handleUserPasswordConfirm = (e) => {
    setPwConfirm(e.target.value);
  };
  useEffect(() => {
    if (newPassword !== newPasswordConfirm) {
      setIsPasswordSame(false);
    } else if (newPassword === newPasswordConfirm) {
      setIsPasswordSame(true);
    }
  }, [newPasswordConfirm]);

  const onChangeEmailFirst = (e) => {
    setEmailFirst(e.target.value);
  };
  const onChangeEmailLast = (e) => {
    setEmailLast(e.target.value);
  };
  const onChangeAddressDetail = (e) => {
    setAddressDetail(e.target.value);
  };

  // 성별 선택
  const [gender, setGender] = useState(myInfo && myInfo.gender);

  // 우편번호 팝업창
  const [postAddress, setPostAddress] = useState({
    address: JSON.parse(myInfo.address).address,
    zoneCode: JSON.parse(myInfo.address).code,
  });
  const [popup, setPopup] = useState(false);
  const handleSearchAddress = (e) => {
    setPostAddress({
      ...postAddress,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setName(myInfo.name);
    setBirth(new Date(myInfo.birthday));
    setEmailFirst(myInfo.email.split('@')[0]);
    setEmailLast(myInfo.email.split('@')[1]);
    setPhoneNumber(myInfo.phoneNumber);
    setPostAddress({
      address: JSON.parse(myInfo.address).address,
      zoneCode: JSON.parse(myInfo.address).code,
    });
    setAddressDetail(JSON.parse(myInfo.address).detailAddress);
  }, [myInfo]);

  const openSearchAddressPopUp = (data) => {
    setPopup(true);
  };

  // 번호 수정하기 버튼 누르면
  const [isModifyPhoneNumberClicked, setIsModifyPhoneNumberClicked] = useState(false);
  const clickModifyPhoneNumber = () => {
    setIsModifyPhoneNumberClicked(true);
  };

  // 전화 번호 입력
  const [firstPhoneNumber, setFirstPhoneNumber] = useState(''); // 휴대폰 번호
  const handleFirstPhoneNumber = (e) => {
    setFirstPhoneNumber(e.target.value);
  };
  const [secondPhoneNumber, setSecondPhoneNumber] = useState(''); // 휴대폰 번호
  const handleSecondPhoneNumber = (e) => {
    setSecondPhoneNumber(e.target.value);
  };
  const [lastPhoneNumber, setLastPhoneNumber] = useState(''); // 휴대폰 번호
  const handleLastPhoneNumber = (e) => {
    setLastPhoneNumber(e.target.value);
  };

  // 인증번호 입력
  const [certificationNum, setCertificationNum] = useState(''); // 휴대폰 번호
  const handleCertificationNum = (e) => {
    setCertificationNum(e.target.value);
  };

  /** 인증 문자 받기 */
  const [modifyPhoneNumberToken, setModifyPhoneNumberToken] = useState('');
  const messageRequest = () => {
    if (
      firstPhoneNumber === '' ||
      secondPhoneNumber === '' ||
      lastPhoneNumber === '' ||
      Number.isInteger(firstPhoneNumber) ||
      Number.isInteger(secondPhoneNumber) ||
      Number.isInteger(lastPhoneNumber)
    ) {
      alert('휴대폰 번호를 정확하게 입력해주세요.');
    } else {
      requestModifyPhoneCertification({
        phoneNumber: firstPhoneNumber + secondPhoneNumber + lastPhoneNumber,
      })
        .then((res) => {
          if (res.data.success === true) {
            alert('문자가 발송되었습니다');
            setModifyPhoneNumberToken(res.data.token);
            //console.log(res.data.registerAccountToken);
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  /** 입력한 인증번호 검사 */
  const certificationNumCheck = () => {
    if (certificationNum === '') {
      alert('인증번호를 입력해주세요.');
    } else {
      requestModifyPhone({
        randomNumber: certificationNum,
        token: modifyPhoneNumberToken,
      })
        .then((res) => {
          if (res.data.success === true) {
            alert('번호 변경이 완료되었습니다.');
            window.location.reload();
          } else if (res.data.success === false) {
            alert('다시 인증을 받아주세요.');
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  // 이메일 유효성 검사
  const emailCheck = (email) => {
    const emailRegex =
      /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;

    return emailRegex.test(email);
  };

  // 비밀번호 유효성 검사
  const passwordCheck = (password) => {
    // 6-16자에 하나이상의 문자 및 하나의 숫자 및 하나의 특수 문자 포함된 비밀번호인지 체크
    const passwordRegEx = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,16}$/g;
    if (password === '') {
      return true;
    } else {
      return passwordRegEx.test(password);
    }
  };

  /** 회원가입 수정 데이터 전송 */
  const clickModifyButton = () => {
    console.log(newPassword);
    if (
      emailCheck(emailFirst + '@' + emailLast) === false ||
      passwordCheck(newPassword) === false ||
      postAddress.address === '' ||
      addressDetail === ''
    ) {
      alert('모든 칸을 입력해주시거나 아이디 중복 확인 및 인증번호 확인을 진행해주세요.');
    } else {
      modifyMyInfo({
        password: newPassword,
        email: emailFirst + '@' + emailLast,
        address: JSON.stringify({
          code: postAddress.zoneCode,
          address: postAddress.address,
          detailAddress: addressDetail,
        }),
      })
        .then((res) => {
          if (res.data.success === true) {
            alert('회원정보가 수정되었습니다.');
            window.location.reload();
          }
        })
        .catch((error) => {
          alert('회원정보 수정에 실패했습니다.');
        });
    }
  };

  const clickWithdrawalButton = () => {
    let isYes = confirm('정말 탈퇴하시겠습니까?');

    if (isYes) {
      userWithdrawal()
        .then((res) => {
          alert('탈퇴가 완료되었습니다.');
          setIsLogin(false);
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
        })
        .catch((error) => {
          alert('탈퇴에 실패하였습니다.');
        });
    }
  };

  return (
    <UserInfoPageContainer>
      {popup && (
        <SearchAddress
          postAddress={postAddress}
          setPostAddress={setPostAddress}
          setPopup={setPopup}
        />
      )}
      <UserInfoContent isPasswordSame={isPasswordSame}>
        <UserInfoTitle>회원정보 수정</UserInfoTitle>
        <div>
          <p>아이디</p>
          <div>{myInfo.userId}</div>
        </div>
        {myInfo && myInfo.accountType === 'LABSTANDARD' ? (
          <>
            <div>
              <p>비밀번호</p>
              <SquareInput
                width={455}
                height={62}
                type="password"
                placeholder="문자, 숫자, 특수문자 조합 6 - 16자"
                onChangeFunc={handlePassword}
              />
            </div>
            <div>
              <p>비밀번호 확인</p>
              <SquareInput
                width={455}
                height={62}
                type="password"
                placeholder="문자, 숫자, 특수문자 조합 6 - 16자"
                onChangeFunc={handleUserPasswordConfirm}
              />
              <span>비밀번호가 다릅니다.</span>
            </div>
          </>
        ) : (
          <>
            <div>
              <p></p>
              <div></div>
            </div>
            <div>
              <p></p>
              <div></div>
            </div>
          </>
        )}

        <Border />
        <div>
          <p>이름</p>
          <div>{name}</div>
          <span>성별</span>
          <p>
            <div>{gender}</div>
          </p>
        </div>
        <div>
          <p>생년월일</p>
          <div>{moment(birth).format('YYYY/MM/DD')}</div>
        </div>
        <div>
          <p>이메일</p>
          <SquareInput
            width={231}
            height={62}
            placeholder={emailFirst}
            textAlign={'center'}
            onChangeFunc={onChangeEmailFirst}
          />
          <span>@</span>
          <SquareInput
            width={187}
            height={62}
            placeholder={emailLast}
            textAlign={'center'}
            onChangeFunc={onChangeEmailLast}
          />
        </div>
        {isModifyPhoneNumberClicked ? (
          <>
            <div>
              <p>휴대폰</p>
              <SquareInput width={135} height={62} onChangeFunc={handleFirstPhoneNumber} />
              <span>-</span>
              <SquareInput width={135} height={62} onChangeFunc={handleSecondPhoneNumber} />
              <span>-</span>
              <SquareInput width={135} height={62} onChangeFunc={handleLastPhoneNumber} />
              <RegisterButton
                width={130}
                height={62}
                bgColor={COLOR.BLUE_4}
                fontSize={14}
                fontWeight={300}
                onClickFunc={messageRequest}
              >
                인증번호 받기
              </RegisterButton>
            </div>
            <div>
              <p>인증번호 입력</p>
              <SquareInput width={295} height={62} onChangeFunc={handleCertificationNum} />
              <RegisterButton
                width={130}
                height={62}
                bgColor={COLOR.BLUE_4}
                fontSize={14}
                fontWeight={300}
                onClickFunc={certificationNumCheck}
              >
                번호 변경하기
              </RegisterButton>
            </div>
          </>
        ) : (
          <div>
            <p>휴대폰</p>
            <div>{phoneNumber.slice(0, 3)}</div>
            <span>-</span>
            <div>{phoneNumber.slice(3, 7)}</div>
            <span>-</span>
            <div>{phoneNumber.slice(7, 11)}</div>
            <RegisterButton
              width={130}
              height={62}
              bgColor={COLOR.BLUE_4}
              fontSize={14}
              fontWeight={300}
              onClickFunc={clickModifyPhoneNumber}
            >
              번호 수정하기
            </RegisterButton>
          </div>
        )}

        <Border />
        <div>
          <p>배송지 주소 입력</p>
          <SquareInput
            width={400}
            height={62}
            onChangeFunc={handleSearchAddress}
            value={postAddress.address}
          />
          <RegisterButton
            width={130}
            height={62}
            bgColor={COLOR.BLUE_4}
            fontSize={14}
            fontWeight={300}
            onClickFunc={openSearchAddressPopUp}
          >
            우편번호 검색
          </RegisterButton>
        </div>
        <div>
          <p></p>
          <SquareInput
            width={602}
            height={62}
            placeholder={addressDetail}
            onChangeFunc={onChangeAddressDetail}
          />
        </div>
      </UserInfoContent>
      <ButtonContainer>
        <button onClick={clickWithdrawalButton}>회원 탈퇴하기</button>
        <Button width={202} height={62} bgColor={COLOR.BLUE_4} onClickFunc={clickModifyButton}>
          수정하기
        </Button>
      </ButtonContainer>
    </UserInfoPageContainer>
  );
}

export default MyInfo;
