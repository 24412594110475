import React, { useState } from 'react';
import { Button } from '../../../../components/atoms/Button';
import {
  QuestionDetailContainer,
  QuestionDetailBox,
  DetailTitle,
  Info,
  Attachments,
  ButtonContainer,
} from '../common/detailPage';
import { ListTitle, HorizonLine } from '../common/list-style';
import COLOR from '../../../../styles/common/color';
import { useParams, useNavigate } from 'react-router-dom';

import { SpinLoader, typeList } from '../../../atoms/SpinLoader';
import { useQuery } from 'react-query';
import { getNoticeDetail, downloadNotice } from '../../../../axiosManage/SupportCenter';
import useHandleError from '../../../../hooks/useHandleError';
import { useChangeMonthNumToEn } from '../../../../hooks/useChangeMonthNumToEn';
import ToastViewer from '../../../atoms/ToastTextViewer';

function NoticeDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { handleError } = useHandleError();

  const [noticeDetail, setNoticeDetail] = useState([]);
  const { status: noticeDetailState } = useQuery('getNoticeDetail', () => getNoticeDetail(id), {
    retry: 0,
    onSuccess: (data) => {
      setNoticeDetail(data.data.educationHelpCenterNotice);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const clickDownloadNotice = (filename) => {
    downloadNotice(id, filename);
  };

  return (
    <QuestionDetailContainer>
      <ListTitle>공지사항</ListTitle>
      <HorizonLine />
      <QuestionDetailBox>
        {noticeDetail !== undefined ? (
          <>
            <DetailTitle>{noticeDetail.title}</DetailTitle>
            <Info>
              <div>
                <p>{noticeDetail.createdAt?.slice(8, 10)}</p>
                <p>{useChangeMonthNumToEn(noticeDetail.createdAt?.slice(5, 7))}</p>
                <p>{noticeDetail.createdAt?.slice(0, 4)}</p>
              </div>
              <div>
                <p>작성자</p>
                <p>LAB STANDARD</p>
              </div>
              <div>
                <p>조회</p>
                <p>{noticeDetail.views}</p>
              </div>
            </Info>
            <ToastViewer content={noticeDetail.content} />
            {noticeDetail.isExistFile ? (
              <Attachments>
                <span>첨부 파일</span>
                <a onClick={() => clickDownloadNotice(noticeDetail.fileName)}>
                  <span>{noticeDetail.url}</span>
                </a>
              </Attachments>
            ) : null}
          </>
        ) : (
          <SpinLoader type={typeList[2].prop} color={'#000'} width={1131} height={459} />
        )}
      </QuestionDetailBox>
      <ButtonContainer>
        <Button
          width={124}
          height={48}
          bgColor={COLOR.BLUE_4}
          onClickFunc={() => (navigate('/supportcenter/notice'))}
        >
          목록으로
        </Button>
      </ButtonContainer>
    </QuestionDetailContainer>
  );
}

export default NoticeDetail;
