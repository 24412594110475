import React from 'react';
import { SearchBarContainer, SearchBarInput, SearchBtn } from './style';

//import { ReactComponent as SearchIcon } from '../../../assets/icons/icon-searchBtn.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/icon-Search.svg';

function SearchBar() {
  return (
    <SearchBarContainer>
      {/* <SearchIcon /> */}
      <SearchIcon />
      <SearchBarInput placeholder="검색어를 입력하세요" />
      <SearchBtn>검색</SearchBtn>
    </SearchBarContainer>
  );
}

export default SearchBar;
