import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

export const FindAccountContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding};
  padding-top: 60px;
  padding-bottom: 120px;
  text-align: center;

  @media screen and (max-width: 1700px) {
    padding-top: 60px;
    padding-bottom: 120px;
  }
`;

export const FindAccountPageTitle = styled.div`
  font-size: 36px;
  margin-top: 60px;

  & > span {
    ${FONT_STYLES.P_M};
  }
`;

export const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_4};
  margin: 50px auto;
`;

export const SubInfoBox = styled.div`
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;
  margin-bottom: 40px;
`;

export const MenuBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid ${COLOR.GRAY_9};
  padding: 25px 0;
  margin: 0 42px;

  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;

    :nth-child(1) {
      border-right: 1px solid ${COLOR.GRAY_9};
    }
  }

  li {
    ${FONT_STYLES.P_R}
    color: #716b6b;
    list-style: none;
    width: 420px;

    & > p {
      font-size: 18px;
      margin: 0 5px;
      cursor: pointer;
    }

    &.active {
      ${FONT_STYLES.P_SB}
      color: ${COLOR.BLACK_1};
    }
  }
`;

export const DetailInfoBox = styled.div``;

export const Container = styled.div`
  padding: 40px 90px;
`;

export const Content = styled.div`
  & > div {
    ${FONT_STYLES.P_R}
    display: flex;
    align-items: center;
    margin-bottom: 27px;

    :nth-child(1) {
      ${FONT_STYLES.P_SB};
      font-size: 24px;
    }

    :nth-child(2) {
      ${FONT_STYLES.P_M};
      font-size: 18px;
    }

    & > p {
      :nth-child(1) {
        text-align: left;
        width: 130px;
      }
    }

    :nth-child(3) {
      & > span {
        margin: 0 10px;
      }

      & > button {
        margin-left: 20px;
      }
    }

    :nth-child(4) {
      & > button {
        margin-left: 20px;
      }
    }
  }
`;

export const PassWordContent = styled.div`
  padding: 40px 90px 100px 90px;

  & > div {
    ${FONT_STYLES.P_R}
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 30px;

    :nth-child(1) {
      ${FONT_STYLES.P_SB};
      font-size: 24px;
    }
  }
`;
