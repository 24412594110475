import React from 'react';
import { ProgressContainer, ProgressDiv, ProgressStepBox } from './style';
import { useLocation } from 'react-router-dom';

function ProgressBox({
  children,
  stepIcon1,
  stepIcon2,
  stepIcon3,
  stepText1,
  stepText2,
  stepText3,
}) {
  const location = useLocation();

  return (
    <ProgressContainer>
      <ProgressDiv>
        <ProgressStepBox>
          {/* 나중에 장바구니 url도 ||로 추가 */}
          <div
            className={
              location.pathname === '/register/assignment' || location.pathname === '/basket'
                ? 'active'
                : ''
            }
          >
            <img src={stepIcon1} />
            <div>
              <p>01</p>
              <p>{stepText1}</p>
            </div>
          </div>
          <div
            className={
              location.pathname === '/register/join' || location.pathname === '/basket/order'
                ? 'active'
                : ''
            }
          >
            <img src={stepIcon2} />
            <div>
              <p>02</p>
              <p>{stepText2}</p>
            </div>
          </div>
          <div
            className={
              location.pathname === '/register/complete' || location.pathname === '/order/complete'
                ? 'active'
                : ''
            }
          >
            <img src={stepIcon3} />
            <div>
              <p>03</p>
              <p>{stepText3}</p>
            </div>
          </div>
        </ProgressStepBox>
        {children}
      </ProgressDiv>
    </ProgressContainer>
  );
}

export default ProgressBox;
