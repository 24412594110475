import styled from 'styled-components';
import { FONT_STYLES } from '../../../styles/common/font-styles';
import COLOR from '../../../styles/common/color';

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  :hover {
    cursor: pointer;
  }
`;

const Rank = styled.p`
  ${FONT_STYLES.N_EB}
  font-size: 24px;
`;

const Content = styled.div`
  flex: 1;
  margin-left: 53px;
  line-height: 1.3;
  height: 80px;
  & > div {
    :nth-child(1) {
      display: flex;
      align-items: center;
      height: 20px;

      & > p {
        :nth-child(1) {
          ${FONT_STYLES.P_R}
          font-size: 14px;
          color: ${COLOR.GRAY_5};
          padding-right: 8px;
        }
        :nth-child(2) {
          ${FONT_STYLES.P_M}
          font-size: 14px;
        }
      }
    }
  }
  p:nth-child(2) {
    ${FONT_STYLES.P_M}
    font-size: 18px;
    padding: 7px 0;
  }
`;

const Img = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  object-fit: cover;
`;

export { ContentContainer, Content, Img, Rank };
