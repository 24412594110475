import React, { useState } from 'react';
import {
  FindAccountContainer,
  FindAccountPageTitle,
  HorizonLine,
  SubInfoBox,
  MenuBox,
  DetailInfoBox,
  Container,
  Content,
  PassWordContainer,
  PassWordContent,
} from './style';
import COLOR from '../../styles/common/color';
import { SquareInput } from '../../components/atoms/Input';
import { RegisterButton } from '../../components/atoms/Button';
import { useNavigate, Navigate } from 'react-router-dom';

import { useRecoilValue, useRecoilState } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';

import { findIdMessageRequest, findAccount } from '../../axiosManage/Auth';
import {
  findAccountTokenAtom,
  foundAccountIdAtom,
  isSocialAccountAtom,
  userIdAtom,
} from '../../recoil/AuthStore/AuthRecoil';

const NAV_LIST = [
  {
    id: 1,
    name: '아이디 찾기',
  },
  {
    id: 2,
    name: '비밀번호 찾기',
  },
];

function FindAccountPage() {
  const isLogin = useRecoilValue(isLoginAtom);
  const navigate = useNavigate();

  if (isLogin === true) {
    alert('잘못된 접근입니다.');
    return <Navigate to="/" />;
  }

  const [findAccountToken, setFindAccountTokenAtom] = useRecoilState(findAccountTokenAtom);

  // 전화 번호 입력
  const [firstPhoneNumber, setFirstPhoneNumber] = useState(''); // 휴대폰 번호
  const handleFirstPhoneNumber = (e) => {
    setFirstPhoneNumber(e.target.value);
  };
  const [secondPhoneNumber, setSecondPhoneNumber] = useState(''); // 휴대폰 번호
  const handleSecondPhoneNumber = (e) => {
    setSecondPhoneNumber(e.target.value);
  };
  const [lastPhoneNumber, setLastPhoneNumber] = useState(''); // 휴대폰 번호
  const handleLastPhoneNumber = (e) => {
    setLastPhoneNumber(e.target.value);
  };

  // 인증번호 입력
  const [certificationNum, setCertificationNum] = useState(''); // 휴대폰 번호
  const handleCertificationNum = (e) => {
    setCertificationNum(e.target.value);
  };

  const [certificationNumSuccess, setCertificationNumSuccess] = useState(false);
  const [foundedUserId, setFoundedUserId] = useRecoilState(foundAccountIdAtom);
  const [isSocialAccount, setIsSocialAccountAtom] = useRecoilState(isSocialAccountAtom);

  /** 인증 문자 받기 */
  // const [registerAccountToken, setRegisterAccountToken] = useRecoilState(registerAccountTokenAtom);
  const messageRequest = () => {
    if (
      firstPhoneNumber === '' ||
      secondPhoneNumber === '' ||
      lastPhoneNumber === '' ||
      Number.isInteger(firstPhoneNumber) ||
      Number.isInteger(secondPhoneNumber) ||
      Number.isInteger(lastPhoneNumber)
    ) {
      alert('휴대폰 번호를 정확하게 입력해주세요.');
    } else {
      findIdMessageRequest({
        phoneNumber: firstPhoneNumber + secondPhoneNumber + lastPhoneNumber,
      })
        .then((res) => {
          if (res.data.success === true) {
            alert('문자가 발송되었습니다');
            setFindAccountTokenAtom(res.data.findAccountToken);
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  /** 입력한 인증번호 검사 */

  const certificationNumCheck = () => {
    if (certificationNum === '') {
      alert('인증번호를 입력해주세요.');
    } else {
      findAccount({
        findAccountToken: findAccountToken,
        randomNumber: certificationNum,
      })
        .then((res) => {
          if (res.data.success === true) {
            alert('인증이 완료되었습니다.');
            setCertificationNumSuccess(true);
            setFoundedUserId(res.data.account);
            if (res.data.isSocialAccount === true) {
              setIsSocialAccountAtom(true);
            }
          } else if (res.data.success === false) {
            alert('다시 인증을 받아주세요.');
          }
        })
        .catch((error) => {
          //alert(error.response.data.message);
        });
    }
  };

  /* 메뉴 선택 */
  const [currentCategory, setCurrentCategory] = useState(1);
  const clickCategory = (id) => {
    setCurrentCategory(id);
  };

  const goToIdCompletePage = () => {
    if (certificationNumSuccess === false || !foundedUserId) {
      alert('휴대폰 인증을 받아주세요.');
    } else {
      navigate('/findaccount/findIdComplete');
    }
  };

  /** 패스워드 찾기 */
  const [userId, setUserId] = useRecoilState(userIdAtom);
  const handleUserId = (e) => {
    setUserId(e.target.value);
  };

  return (
    <FindAccountContainer>
      <FindAccountPageTitle>
        <span>아이디 / 비밀번호 찾기</span>
      </FindAccountPageTitle>
      <HorizonLine />
      <SubInfoBox>
        <MenuBox>
          {NAV_LIST.map((menu) => (
            <div key={menu.id}>
              <li
                className={menu.id === currentCategory ? 'active' : ''}
                onClick={() => clickCategory(menu.id)}
              >
                <p>{menu.name}</p>
              </li>
            </div>
          ))}
        </MenuBox>
        <DetailInfoBox>
          {currentCategory === 1 ? (
            <Container>
              <Content>
                <div>회원정보에 등록한 휴대전화로 인증</div>
                <div>*회원정보에 등록한 휴대전화 번호가 같아야 인증번호를 받을 수 있습니다.</div>
                {/* <div>
                  <p>이름</p>
                  <SquareInput width={402} height={62} />
                </div> */}
                <div>
                  <p>휴대폰</p>
                  <SquareInput width={135} height={62} onChangeFunc={handleFirstPhoneNumber} />
                  <span>-</span>
                  <SquareInput width={135} height={62} onChangeFunc={handleSecondPhoneNumber} />
                  <span>-</span>
                  <SquareInput width={135} height={62} onChangeFunc={handleLastPhoneNumber} />
                  <RegisterButton
                    width={130}
                    height={62}
                    bgColor={COLOR.BLUE_4}
                    fontSize={14}
                    fontWeight={300}
                    onClickFunc={messageRequest}
                  >
                    인증번호 받기
                  </RegisterButton>
                </div>
                <div>
                  <p>인증번호 입력</p>
                  <SquareInput width={295} height={62} onChangeFunc={handleCertificationNum} />
                  <RegisterButton
                    width={130}
                    height={62}
                    bgColor={COLOR.BLUE_4}
                    fontSize={14}
                    fontWeight={300}
                    onClickFunc={certificationNumCheck}
                  >
                    인증번호 확인
                  </RegisterButton>
                </div>
              </Content>
            </Container>
          ) : currentCategory === 2 ? (
            <PassWordContent>
              <div>패스워드를 찾고자 하는 아이디를 입력해 주세요.</div>
              <SquareInput width={700} height={62} onChangeFunc={handleUserId} />
            </PassWordContent>
          ) : (
            ''
          )}
        </DetailInfoBox>
      </SubInfoBox>
      {currentCategory === 1 ? (
        <RegisterButton
          width={202}
          height={62}
          bgColor={COLOR.BLUE_4}
          fontSize={20}
          fontWeight={300}
          onClickFunc={goToIdCompletePage}
        >
          다음 단계로
        </RegisterButton>
      ) : currentCategory === 2 ? (
        <RegisterButton
          width={202}
          height={62}
          bgColor={COLOR.BLUE_4}
          fontSize={20}
          fontWeight={300}
          onClickFunc={() => navigate('/findaccount/certification')}
        >
          다음 단계로
        </RegisterButton>
      ) : (
        ''
      )}
    </FindAccountContainer>
  );
}

export default FindAccountPage;
