import React, { useState, useEffect, useCallback } from 'react';
import COLOR from '../../../../styles/common/color';
import {
  MyPageHomeContainer,
  MainBox,
  MainLeft,
  MainRight,
  LectureBox,
  ContentHeaderBox,
  LectureItems,
  Lecture,
  CounselBox,
  CounselItems,
  Counsel,
  FooterBox,
  FooterContent,
} from './style';
import { VerySmallQuestionDetail } from '../../SupportCenterPageElements/QuestionsDetail';

import { ProgressCircle } from '../../../atoms/ProgressCircle';
import { useChangeMonthNumToEn } from '../../../../hooks/useChangeMonthNumToEn';

import GoToIcon from '../../../../assets/images/goto.png';
import MyInfoIcon from '../../../../assets/icons/icon-MyInfo.png';
import MyAlarmIcon from '../../../../assets/icons/icon-Alarm.png';
import myLectureIcon from '../../../../assets/icons/icon-MyLecture.png';
import MyOrderIcon from '../../../../assets/icons/icon-Order.png';
import { Link, useNavigate, useParams } from 'react-router-dom';

// 알림 모달창
import AlarmModal from '../../../molecules/Modal';
import InquiryModal from '../../../molecules/Modal';
import useModal from '../../../../hooks/useModal';
import AlarmPage from '../../../../pages/Alarm/AlarmPage';
import InquiryDetailPage from '../../../../pages/InquiryDetail/InquiryDetailPage';

import { useQuery } from 'react-query';
import {
  getMyLecture,
  getMyOneOnOneInquiry,
  getMyProfessorQnaList,
  getLectureStart,
  deleteOneOnOneInquiry,
} from '../../../../axiosManage/MyPage';
import { getMyNotify } from '../../../../axiosManage/Alarm';
import { getFrequentQuestion, getNotice } from '../../../../axiosManage/BookStore';
import useHandleError from '../../../../hooks/useHandleError';
import { SpinLoader, typeList } from '../../../atoms/SpinLoader';

import { useRecoilState, useRecoilValue } from 'recoil';
import { myPageMyAlarmCountAtom } from '../../../../recoil/MyPageStore/MyPageRecoil';
import { Button } from '../../../atoms/Button/index';

import { myInfoAtom, myContactAtom } from '../../../../recoil/MyPageStore/MyPageRecoil';
import { isLoginAtom } from '../../../../recoil/AuthStore/AuthRecoil';

const LECTURE_NAV_LIST = [
  {
    id: 1,
    name: '수강 중',
  },
  {
    id: 2,
    name: '수강 종료 / 대기',
  },
];

const COUNSEL_NAV_LIST = [
  {
    id: 1,
    name: '고객 센터 1:1 문의',
  },
  {
    id: 2,
    name: '교수님 Q&A',
  },
];

function Home() {
  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const myInfo = useRecoilValue(myInfoAtom);
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);

  /** 수강 강좌 박스 */
  const [myLecture, setMyLecture] = useState([]);
  const [learnTimeOfHour, setLearnTimeOfHour] = useState(0);
  const [learnTimeOfMinute, setLearnTimeOfMinute] = useState(0);
  const { status: myLectureStatus, refetch: myLectureRefetch } = useQuery(
    'getMyLecture',
    getMyLecture,
    {
      retry: 0,
      onSuccess: (data) => {
        setMyLecture(data.data.myLectures);
        const learnTime = data.data.learningTimeOfThisMonth;
        const learnHour = ~~(learnTime / 3600);
        const learnMinute = ~~((learnTime - learnHour * 3600) / 60);
        setLearnTimeOfHour(learnHour);
        setLearnTimeOfMinute(learnMinute);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const [signUpLectures, setSignUpLectures] = useState([]);
  const [etcLectures, setEtcLectures] = useState([]);

  useEffect(() => {
    let signUpLecture = [];
    let etcLecture = [];

    myLecture.forEach((lecture) => {
      if (lecture.status === '수강 중') {
        signUpLecture.push(lecture);
      } else if (lecture.status === '수강 대기' || lecture.status === '수강 종료') {
        etcLecture.push(lecture);
        setEtcLectures(etcLecture);
      }
    });
    setSignUpLectures(signUpLecture);
  }, [myLecture, myLectureStatus]);

  // 수강 퍼센테이지 계산 로직(수강 중인 강의)
  const [signUpProgressRate, setSignUpProgressRate] = useState(
    Array(signUpLectures && signUpLectures.length).fill(0),
  );
  useEffect(() => {
    const newArr = [...signUpProgressRate];
    for (let i = 0; i < signUpLectures.length; i++) {
      let totalLearningTime = 0;
      let currLearningTime = 0;

      if (signUpLectures[i].lectureInfo && signUpLectures[i].lectureInfo.lectures) {
        signUpLectures[i].lectureInfo.lectures.forEach((lecture) => {
          totalLearningTime += lecture.time * 60;
        });
      }

      if (signUpLectures[i].learningStatusList) {
        signUpLectures[i].learningStatusList.forEach((lecture) => {
          const lectureTime =
            signUpLectures[i].lectureInfo.lectures.find(
              (item) => item.id === lecture.lectureVideoId,
            ).time * 60;
          const currentLecture = signUpLectures[i].lectureInfo.lectures.find(
            (item) => item.id === lecture.lectureVideoId,
          );
          if (lecture.learningTime > lectureTime) {
            currLearningTime += currentLecture.time * 60;
          } else {
            currLearningTime += lecture.learningTime;
          }
        });
      }

      newArr[i] = ~~((currLearningTime / totalLearningTime) * 100);
    }
    setSignUpProgressRate(newArr);
  }, [signUpLectures]);

  // 수강 퍼센테이지 계산 로직(수강 대기/종료인 강의)
  const [etcProgressRate, setEtcUpProgressRate] = useState([]);
  useEffect(() => {
    const newArr = [...etcProgressRate];
    for (let i = 0; i < etcLectures.length; i++) {
      let totalLearningTime = 0;
      let currLearningTime = 0;

      if (etcLectures[i].lectureInfo && etcLectures[i].lectureInfo.lectures) {
        etcLectures[i].lectureInfo.lectures.forEach((lecture) => {
          totalLearningTime += lecture.time * 60;
        });
      }

      if (etcLectures[i].learningStatusList) {
        etcLectures[i].learningStatusList.forEach((lecture) => {
          const lectureTime =
            etcLectures[i].lectureInfo.lectures.find((item) => item.id === lecture.lectureVideoId)
              .time * 60;
          const currentLecture = etcLectures[i].lectureInfo.lectures.find(
            (item) => item.id === lecture.lectureVideoId,
          );
          if (lecture.learningTime > lectureTime) {
            currLearningTime += currentLecture.time * 60;
          } else {
            currLearningTime += lecture.learningTime;
          }
        });
      }
      newArr.push([etcLectures[i].id, ~~((currLearningTime / totalLearningTime) * 100)]);
    }
    setEtcUpProgressRate(newArr);
  }, [etcLectures]);

  /** 1:1 문의 & 학습 질문 박스 */
  const [myOneOnOneInquiry, setMyOneOnOneInquiry] = useState([]);
  const { status: myOneOnOneInquiryStatus,refetch  } = useQuery(
    'getMyOneOnOneInquiry',
    getMyOneOnOneInquiry,
    {
      retry: 0,
      onSuccess: (data) => {
        setMyOneOnOneInquiry(data.data);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const [answerCompleteInquiry, setAnswerCompleteInquiry] = useState([]);
  useEffect(() => {
    let answerComplete = [];

    myOneOnOneInquiry.forEach((inquiry) => {
      if (inquiry.status === '답변 완료') {
        answerComplete.push(inquiry);
      }
    });
    setAnswerCompleteInquiry(answerComplete);
  }, [myOneOnOneInquiry]);

  const [myProfessorQnaList, setMyProfessorQnaList] = useState([]);
  const { status: myProfessorQnaListStatus } = useQuery(
    'getMyProfessorQnaList',
    getMyProfessorQnaList,
    {
      retry: 0,
      onSuccess: (data) => {
        setMyProfessorQnaList(data.data.qnaList);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const goToQnaDetailPage = (professorName, qnaId) => {
    if (professorName === '주이재') {
      navigate(`/lecture/JLJ/question/${qnaId}`);
    } else if (professorName === '김승환') {
      navigate(`/lecture/KSH/question/${qnaId}`);
    } else if (professorName === '하나산') {
      navigate(`/lecture/HNS/question/${qnaId}`);
    }
  };

  const [answerCompleteQnaList, setAnswerCompleteQnaList] = useState([]);
  useEffect(() => {
    let answerComplete = [];

    myProfessorQnaList.forEach((qna) => {
      if (qna.status === '답변 완료') {
        answerComplete.push(qna);
      }
    });
    setAnswerCompleteQnaList(answerComplete);
  }, [myProfessorQnaList]);

  /** Footer - 자주묻는질문 & 공지사항 박스 */
  const [frequentQuestionList, setFrequentQuestionList] = useState([]);
  const { status: frequentQuestionListState } = useQuery(
    'getFrequentQuestion',
    getFrequentQuestion,
    {
      retry: 0,
      onSuccess: (data) => {
        setFrequentQuestionList(data.data.questions.slice(0, 4));
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  /** 공지사항 박스 */
  const [noticeList, setNoticeList] = useState([]);
  const { status: noticeListStatus } = useQuery('getNotice', () => getNotice('', '', 1), {
    retry: 0,
    onSuccess: (data) => {
      setNoticeList(data.data.educationHelpCenterNoticeList.slice(0, 4));
    },
    onError: (error) => {
      handleError(error);
    },
  });

  // 카테고리 선택
  const [currentLectureCategory, setCurrentLectureCategory] = useState(1);
  const clickLectureCategory = (id) => {
    setCurrentLectureCategory(id);
  };

  const [currentCounselCategory, setCurrentCounselCategory] = useState(1);
  const clickCounselCategory = (id) => {
    setCurrentCounselCategory(id);
  };

  /** 내 알람 데이터 */
  const [alarmDataLength, setAlarmDataLength] = useRecoilState(myPageMyAlarmCountAtom);
  const [alarmDataLength1, setAlarmDataLength1] = useState(0);
  if (isLogin === true) {
    getMyNotify()
      .then((res) => {
        setAlarmDataLength1(res.data.notifies.length);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  const [modalOption, showModal] = useModal();

  // 알림 페이지 모달 오픈
  const openAlarm = useCallback(() => {
    showModal(true, null, null, <AlarmPage />);
    document.body.style.overflow = 'hidden';
  }, [showModal]);

  // 1:1 문의 상세페이지 모달 오픈
  const openInquiry = useCallback(
    (content) => {
      showModal(true, null, null, <InquiryDetailPage content={content} />);
      document.body.style.overflow = 'hidden';
    },
    [showModal],
  );

  const goToLectureDetailPage = (status, id) => {
    if (status === '수강 대기') {
      // 수강 대기면 아무것도 안함
    } else {
      navigate(`/mypage/mylecture/${id}`);
    }
  };

  /** 강의 수강시작 */
  const [lectureId, setLectureId] = useState(null);
  const { refetch: myLectureStartRefetch } = useQuery(
    ['getLectureStart', lectureId],
    () => getLectureStart(lectureId),
    {
      retry: 0,
      enabled: !!lectureId,
      onSuccess: () => {
        alert('수강 신청 되었습니다.');
        myLectureRefetch();
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const lectureStart = (id) => {
    setLectureId(id);
    if (lectureId) {
      myLectureStartRefetch();
    }
  };

  const [contactContent, setContactContent] = useRecoilState(myContactAtom);
  const clickModify = (content) => {
    setContactContent(content);
    navigate('/contact/modify');
  };

  const clickDelete = (contactId) => {
    let isYes = confirm('해당 문의를 삭제하시겠습니까?');

    if (isYes) {
      deleteOneOnOneInquiry(contactId)
        .then((res) => {
          alert('삭제되었습니다.');
          refetch();
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  /** 자주 묻는 질문에 대한 상세 페이지 보여주기 */
  const [openDetailID, setOpenDetailID] = useState(0);
  const [showSelectedDetail, setShowSelectedDetail] = useState(
    Array(frequentQuestionList.length).fill(false),
  );

  const openDetail = (id) => {
    setOpenDetailID(id);
    if (showSelectedDetail[id] === true) {
      const newArr = [...showSelectedDetail];
      newArr[id] = false;
      setShowSelectedDetail(newArr);
    } else {
      const newArr = Array(frequentQuestionList.length).fill(false);
      newArr[id] = true;
      setShowSelectedDetail(newArr);
    }
  };

  return (
    <MyPageHomeContainer>
      <AlarmModal modalOption={modalOption} />
      <InquiryModal modalOption={modalOption} />
      <MainBox>
        <MainLeft>
          <div>
            <span>{myInfo.name}님,</span>안녕하세요
          </div>
          <div>
            <p>이번 달 학습시간</p>
            <p>
              {learnTimeOfHour}시간 {learnTimeOfMinute}분
            </p>
          </div>
          <div>
            <p>1:1 문의</p>
            <p>{myOneOnOneInquiry && myOneOnOneInquiry.length}건</p>
          </div>
          <div>
            <p>교수님 Q&A</p>
            <p>{myProfessorQnaList && myProfessorQnaList.length}건</p>
          </div>
          <div>
            <p>내 강의실</p>
            <p>{(signUpLectures && signUpLectures.length) + (etcLectures && etcLectures.length)}</p>
          </div>
        </MainLeft>
        <MainRight>
          <div>정보 관리</div>
          <div>
            <Link to="/mypage/myinfo">
              <p>
                <img src={MyInfoIcon} />
              </p>
              <p>나의 회원정보</p>
            </Link>
            <Link to="#" onClick={openAlarm}>
              <p>
                <img src={MyAlarmIcon} />
              </p>
              <span>{alarmDataLength}</span>
              <p>알림</p>
            </Link>
          </div>
          <div>
            <Link to="/mypage/mylecture">
              <p>
                <img src={myLectureIcon} />
              </p>
              <p>내 강의실</p>
            </Link>
            <Link to="/mypage/myorder">
              <p>
                <img src={MyOrderIcon} />
              </p>
              <p>주문 / 배송</p>
            </Link>
          </div>
        </MainRight>
      </MainBox>
      <LectureBox>
        <ContentHeaderBox>
          <div>
            <p>수강 강좌</p>
            <div>
              {LECTURE_NAV_LIST.map((menu) => (
                <div key={menu.id}>
                  <li
                    className={menu.id === currentLectureCategory ? 'active' : ''}
                    onClick={() => clickLectureCategory(menu.id)}
                  >
                    {menu.name}
                  </li>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div>
              <p>수강 중</p>
              <p>{signUpLectures.length}</p>
            </div>
            <div>
              <p>수강 종료 / 대기</p>
              <p>{etcLectures.length}</p>
            </div>
          </div>
        </ContentHeaderBox>
        <LectureItems>
          {currentLectureCategory === 1 ? (
            <LectureItems>
              {signUpLectures !== undefined ? (
                <>
                  {signUpLectures.map((lecture, i) => (
                    <Lecture
                      key={lecture.id}
                      onClick={(e) => navigate(`/mypage/mylecture/${lecture.id}`)}
                    >
                      <div>{i + 1}</div>
                      <div>
                        <img src={lecture.lectureInfo.lectureImg} />
                      </div>
                      <div>
                        {lecture.lectureInfo.lectureCategory === 'language-logic' ? (
                          <p>언어논리</p>
                        ) : lecture.lectureInfo.lectureCategory === 'data-interpretation' ? (
                          <p>자료해석</p>
                        ) : lecture.lectureInfo.lectureCategory === 'situational-judgment' ? (
                          <p>상황판단</p>
                        ) : (
                          ''
                        )}
                        {lecture.lectureInfo.professor !== undefined ? (
                          <p>{lecture.lectureInfo.professor.professorName}</p>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        <p>{lecture.status}</p>
                        <p>{lecture.lectureInfo.lectureName}</p>
                        <p>
                          <span>교재</span>
                          {lecture.lectureInfo.books[0] !== undefined ? (
                            <>
                              {lecture.lectureInfo.books[0].bookName} 외{' '}
                              {lecture.lectureInfo.books.length - 1}권
                            </>
                          ) : (
                            '교재 없음'
                          )}
                        </p>
                      </div>
                      <div>
                        <p>학습기간</p>
                        <p>
                          <span>{lecture.startedAt?.slice(0, 10).replace(/-/g, '.')}</span>~
                          <span>{lecture.endAt?.slice(0, 10).replace(/-/g, '.')}</span>
                        </p>
                      </div>
                      <div>
                        <ProgressCircle percent={signUpProgressRate[i]}>
                          <p>진도율</p>
                          <p>{signUpProgressRate[i]}%</p>
                        </ProgressCircle>
                      </div>
                    </Lecture>
                  ))}
                </>
              ) : (
                <SpinLoader type={typeList[2].prop} color={'#000'} width={690} height={550} />
              )}
            </LectureItems>
          ) : currentLectureCategory === 2 ? (
            <LectureItems>
              {etcLectures !== undefined ? (
                <>
                  {etcLectures.map((lecture, i) => (
                    <Lecture
                      key={lecture.id}
                      onClick={(e) => goToLectureDetailPage(lecture.status, lecture.id)}
                    >
                      <div>{i + 1}</div>
                      <div>
                        <img src={lecture.lectureInfo.lectureImg} />
                      </div>
                      <div>
                        {lecture.lectureInfo.lectureCategory === 'language-logic' ? (
                          <p>언어논리</p>
                        ) : lecture.lectureInfo.lectureCategory === 'data-interpretation' ? (
                          <p>자료해석</p>
                        ) : lecture.lectureInfo.lectureCategory === 'situational-judgment' ? (
                          <p>상황판단</p>
                        ) : (
                          ''
                        )}
                        {lecture.lectureInfo.professor !== undefined ? (
                          <p>{lecture.lectureInfo.professor.professorName}</p>
                        ) : (
                          <SpinLoader
                            type={typeList[2].prop}
                            color={'#000'}
                            width={690}
                            height={550}
                          />
                        )}
                      </div>
                      <div>
                        <p>{lecture.status}</p>
                        <p>{lecture.lectureInfo.lectureName}</p>
                        <p>
                          <span>교재</span>
                          {lecture.lectureInfo.books[0] !== undefined ? (
                            <>
                              {lecture.lectureInfo.books[0].bookName} 외{' '}
                              {lecture.lectureInfo.books.length - 1}권
                            </>
                          ) : (
                            '교재 없음'
                          )}
                        </p>
                      </div>
                      <div>
                        <p>학습기간</p>
                        <p>
                          <span>{lecture.startedAt?.slice(0, 10).replace(/-/g, '.')}</span>~
                          <span>{lecture.endAt?.slice(0, 10).replace(/-/g, '.')}</span>
                        </p>
                      </div>
                      <div>
                        {lecture.status === '수강 대기' ? (
                          <Button
                            width={124}
                            height={48}
                            bgColor={COLOR.BLUE_4}
                            onClickFunc={() => lectureStart(lecture.id)}
                          >
                            수강 시작
                          </Button>
                        ) : (
                          // <ProgressCircle percent={etcProgressRate[i]}>
                          //   <p>진도율</p>
                          //   <p>{etcProgressRate[i]}%</p>
                          // </ProgressCircle>
                          <ProgressCircle percent={etcProgressRate[i] && etcProgressRate[i][1]}>
                            <p>진도율</p>
                            <p>{etcProgressRate[i] && etcProgressRate[i][1]}%</p>
                          </ProgressCircle>
                        )}
                      </div>
                    </Lecture>
                  ))}
                </>
              ) : (
                ''
              )}
            </LectureItems>
          ) : (
            ''
          )}
        </LectureItems>
      </LectureBox>
      <CounselBox>
        <ContentHeaderBox>
          <div>
            <p>상담 내역</p>
            <div>
              {COUNSEL_NAV_LIST.map((menu) => (
                <div key={menu.id}>
                  <li
                    className={menu.id === currentCounselCategory ? 'active' : ''}
                    onClick={() => clickCounselCategory(menu.id)}
                  >
                    {menu.name}
                  </li>
                </div>
              ))}
            </div>
          </div>
          {currentCounselCategory === 1 ? (
            <div>
              <div>
                <p>답변 완료</p>
                <p>{answerCompleteInquiry.length}</p>
              </div>
              <div>
                <p>답변 대기</p>
                <p>{myOneOnOneInquiry.length - answerCompleteInquiry.length}</p>
              </div>
            </div>
          ) : currentCounselCategory === 2 ? (
            <div>
              <div>
                <p>답변 완료</p>
                <p>{answerCompleteQnaList.length}</p>
              </div>
              <div>
                <p>답변 대기</p>
                <p>{myProfessorQnaList.length - answerCompleteQnaList.length}</p>
              </div>
            </div>
          ) : (
            ''
          )}
        </ContentHeaderBox>
        <CounselItems>
          {currentCounselCategory === 1 ? (
            <>
              {myOneOnOneInquiry !== undefined ? (
                <>
                  {myOneOnOneInquiry.map((inquiry, i) => (
                    <Counsel key={inquiry.id}>
                      <div>{i + 1}</div>
                      <div onClick={() => openInquiry(inquiry)}>
                        <p>{useChangeMonthNumToEn(inquiry.createdAt?.slice(5, 7))}</p>
                        <p>{inquiry.createdAt?.slice(8, 10)}</p>
                        <p>{inquiry.createdAt?.slice(0, 4)}</p>
                      </div>
                      <div onClick={() => openInquiry(inquiry)}>
                        {inquiry.category === 'PARCEL' ? (
                          <p>배송</p>
                        ) : inquiry.category === 'PAYMENT' ? (
                          <p>결제/환불</p>
                        ) : inquiry.category === 'VIDEO' ? (
                          <p>동영상</p>
                        ) : inquiry.category === 'WEBPAGE' ? (
                          <p>사이트 이용</p>
                        ) : inquiry.category === 'MOBILE' ? (
                          <p>모바일</p>
                        ) : inquiry.category === 'BOOK' ? (
                          <p>교재</p>
                        ) :(
                          ''
                        )}
                        <p onClick={() => openInquiry(inquiry)}>{inquiry.title}</p>
                      </div>
                      <div
                        className={inquiry.status === '답변 완료' ? 'complete' : ''}
                        onClick={() => openInquiry(inquiry)}
                      >
                        <p>{inquiry.status}</p>
                      </div>
                      <div>
                        <Button
                          width={60}
                          height={30}
                          bgColor={COLOR.BLUE_4}
                          onClickFunc={() => clickModify(inquiry)}
                        >
                          수정
                        </Button>
                        <Button width={60} height={30} onClickFunc={() => clickDelete(inquiry.id)}>
                          삭제
                        </Button>
                      </div>
                    </Counsel>
                  ))}
                </>
              ) : (
                ''
              )}
            </>
          ) : currentCounselCategory === 2 ? (
            <>
              {myProfessorQnaList !== undefined ? (
                <>
                  {myProfessorQnaList.map((qna, i) => (
                    <Counsel
                      key={qna.id}
                      onClick={() => goToQnaDetailPage(qna.professorName, qna.id)}
                    >
                      <div>{i + 1}</div>
                      <div>
                        <p>{useChangeMonthNumToEn(qna.createdAt?.slice(5, 7))}</p>
                        <p>{qna.createdAt?.slice(8, 10)}</p>
                        <p>{qna.createdAt?.slice(0, 4)}</p>
                      </div>
                      <div>
                        {qna.category === 'LECTURE' ? (
                          <p>강의</p>
                        ) : qna.category === 'CURRICULUM' ? (
                          <p>커리큘럼</p>
                        ) : qna.category === 'BOOK' ? (
                          <p>교재</p>
                        ) : (
                          ''
                        )}
                        <p>{qna.title}</p>
                      </div>
                      <div className={qna.status === '답변 완료' ? 'complete' : ''}>
                        <p>{qna.status}</p>
                      </div>
                    </Counsel>
                  ))}
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </CounselItems>
        <div>문의사항이 있으면 언제든지 문의하세요!</div>
      </CounselBox>
      <FooterBox>
        <div>
          <FooterContent>
            <div>
              <div>FAQ</div>
              <div onClick={() => navigate('/supportcenter/questions')}>
                <p>전체보기</p>
                <img src={GoToIcon} />
              </div>
            </div>
            {frequentQuestionList !== undefined ? (
              <>
                <div>
                  {frequentQuestionList.map((data, i) => (
                    <>
                      <div key={data.id} onClick={() => openDetail(i)}>
                        <p>•</p>
                        <p>{data.title}</p>
                      </div>
                      <div style={{ marginBottom: '0px' }}>
                        {showSelectedDetail[i] ? (
                          <VerySmallQuestionDetail
                            openDetailContent={frequentQuestionList[openDetailID].content}
                          />
                        ) : null}
                      </div>
                    </>
                  ))}
                </div>
              </>
            ) : (
              <SpinLoader type={typeList[2].prop} color={'#000'} />
            )}

            {/* {frequentQuestionList !== undefined ? (
              <>
                <div>
                  {frequentQuestionList &&
                    frequentQuestionList.map((data, i) => (
                      <>
                        <div key={data.id} onClick={() => openDetail(i)}>
                          <p>•</p>
                          <p>{data.title}</p>
                        </div>
                        <div>
                          {showSelectedDetail[i] ? (
                            <VerySmallQuestionDetail
                              openDetailContent={frequentQuestionList[openDetailID].content}
                            />
                          ) : null}
                        </div>
                      </>
                    ))}
                </div>
              </>
            ) : (
              <SpinLoader type={typeList[2].prop} color={'#000'} />
            )} */}
          </FooterContent>
          <div className="border-block" />
          <FooterContent>
            <div>
              <div>공지사항</div>
              <div onClick={() => navigate('/supportcenter/notice')}>
                <p>전체보기</p>
                <img src={GoToIcon} />
              </div>
            </div>
            {noticeList !== undefined ? (
              <>
                <div>
                  {noticeList.map((data) => (
                    <div key={data.id} onClick={() => navigate(`/supportcenter/notice/${data.id}`)}>
                      <p>•</p>
                      <p>
                        [{data.category}] {data.title}
                      </p>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <SpinLoader type={typeList[2].prop} color={'#000'} />
            )}
          </FooterContent>
        </div>
      </FooterBox>
    </MyPageHomeContainer>
  );
}

export default Home;
