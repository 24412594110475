import axios from 'axios';


export const useAxios = axios.create({
  baseURL: process.env.REACT_APP_AXIOS,
  headers: {
    'Access-Control-Allow-Credentials': true,
  },
});

export const useDownloadAxios = axios.create({
  baseURL: process.env.REACT_APP_AXIOS,
  headers: {
    'Access-Control-Allow-Credentials': true,
  },
  responseType: 'blob',
});


