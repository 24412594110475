import { atom, selector } from 'recoil';

/** 학습지원센터 - 공지사항에서 유저가 선택한 필터링 카테고리 저장 */
export const selectedNoticeFilterMenuNumAtom = atom({
  key: 'selectedNoticeFilterMenuNumAtom',
  default: Array(3).fill(false),
});

export const selectedNoticeFilterMenuAtom = atom({
  key: 'selectedNoticeFilterMenuAtom',
  default: [],
});

/** 학습지원센터 - 정오표에서 유저가 선택한 필터링 카테고리 저장 */
export const selectedErrataFilterMenuNumAtom = atom({
  key: 'selectedErrataFilterMenuNumAtom',
  default: Array(3).fill(false),
});

export const selectedErrataFilterMenuAtom = atom({
  key: 'selectedErrataFilterMenuAtom',
  default: Array(3).fill(false),
});

/** 5급 강의 - 학습질문에서 유저가 선택한 필터링 카테고리 저장 */
export const selectedProfessorQuestionFilterMenuNumAtom = atom({
  key: 'selectedProfessorQuestionFilterMenuNumAtom',
  default: Array(3).fill(false),
});

export const selectedProfessorQuestionFilterMenuAtom = atom({
  key: 'selectedProfessorQuestionFilterMenuAtom',
  default: Array(3).fill(false),
});

/** 5급 강의 - 수강후기에서 유저가 선택한 필터링 카테고리 저장 */
export const selectedProfessorReviewFilterMenuNumAtom = atom({
  key: 'selectedProfessorReviewFilterMenuNumAtom',
  default: Array(3).fill(false),
});

export const selectedProfessorReviewFilterMenuAtom = atom({
  key: 'selectedProfessorReviewFilterMenuAtom',
  default: [],
});

/** 5급 강의 - 자료실에서 유저가 선택한 필터링 카테고리 저장 */
export const selectedProfessorReferenceFilterMenuNumAtom = atom({
  key: 'selectedProfessorReferenceFilterMenuNumAtom',
  default: Array(3).fill(false),
});

export const selectedProfessorReferenceFilterMenuAtom = atom({
  key: 'selectedProfessorReferenceFilterMenuAtom',
  default: [],
});

/** 5급 강의 - 공지사항에서 유저가 선택한 필터링 카테고리 저장 */
export const selectedProfessorNoticeFilterMenuNumAtom = atom({
  key: 'selectedProfessorNoticeFilterMenuNumAtom',
  default: Array(3).fill(false),
});

export const selectedProfessorNoticeFilterMenuAtom = atom({
  key: 'selectedProfessorNoticeFilterMenuAtom',
  default: [],
});

/** 수험정보&팁 - 학습 팁에서 유저가 선택한 필터링 카테고리 저장 */
export const selectedStudyTipFilterMenuNumAtom = atom({
  key: 'selectedStudyTipFilterMenuNumAtom',
  default: Array(4).fill(false),
});

export const selectedStudyTipFilterMenuAtom = atom({
  key: 'selectedStudyTipFilterMenuAtom',
  default: [],
});

/** 수험정보&팁 - 합격 후기에서 유저가 선택한 필터링 카테고리 저장 */
export const selectedPassNoteFilterMenuNumAtom = atom({
  key: 'selectedPassNoteFilterMenuNumAtom',
  default: Array(4).fill(false),
});

export const selectedPassNoteFilterMenuAtom = atom({
  key: 'selectedPassNoteFilterMenuAtom',
  default: [],
});

/** 온라인 서점 - 과목별 교재에서 유저가 선택한 카테고리 저장 (사용 여부 미정)*/
export const selectedSubjectBookCategoryAtom = atom({
  key: 'selectedSubjectBookCategoryAtom',
  default: 1,
});
