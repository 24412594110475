import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

const handleColor = (professorId) => {


  switch(professorId){
    
    default:
      return '#4C4C4C';
  }
  
};

const LectureNavContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  width: 100%;
  height: 73px;
  display: flex;
  justify-content: space-between;
  color: ${COLOR.GRAY_0};
  background-color: ${({ professorId }) => handleColor(professorId)};



  & > ul {
    ${FONT_STYLES.P_R}
    width: 1005px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 18px;

    li {
      width: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      :hover {
        cursor: pointer;
      }

      p {
        display: flex;
        align-items: center;
        height: 70px;

        &.active {
          ${FONT_STYLES.P_EB}
        }
      }
    }
  }
`;

const LectureCurrentLocation = styled.div`
  ${FONT_STYLES.P_L}
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
`;

const ActiveLine = styled.div`
  width: 60px;
  display: none;
  background-color: ${COLOR.GRAY_0};
  border: 2px solid ${COLOR.GRAY_0};

  &.active {
    display: block;
  }
`;

const LectureHomeNavContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  width: 100%;
  height: 165px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c3c3c3;

  ul > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${COLOR.GRAY_0};
    width: 185px;
    height: 180px;
    margin-bottom: 15px;
    background-color: ${COLOR.BLUE_4};
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.6) 0px 2px 8px 0px;
    p {
      ${FONT_STYLES.P_R}
      font-size: 14px;
      padding-bottom: 9px;
    }
  }

  li {
    ${FONT_STYLES.P_B}
    font-size: 20px;
    padding-bottom: 23px;
  }
`;

const LectureHomeCurrentLocation = styled.div`
  ${FONT_STYLES.P_M}
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  font-size: 18px;
  p:nth-child(1) {
    color: #716b6b;
  }
  p:nth-child(2) {
    color: ${COLOR.BLUE_4};
  }
`;

const Line = styled.div`
  width: 35px;
  border: 1px solid white;
`;

export {
  LectureNavContainer,
  LectureCurrentLocation,
  ActiveLine,
  LectureHomeNavContainer,
  LectureHomeCurrentLocation,
  Line,
};
