import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

export const BookStoreDetailContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-top: 70px;
  padding-bottom: 140px;
  text-align: center;

  @media screen and (max-width: 1700px) {
    padding-top: 70px;
    padding-bottom: 140px;
  }
`;

export const MainInfoBox = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 100px;
`;

export const BookImg = styled.div`
  width: 573px;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const MainInfoRightBox = styled.div`
  width: 60%;
  text-align: left;
  margin-left: 95px;
`;

export const TitleBox = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid ${COLOR.GRAY_9};

  & > div {
    :nth-child(1) {
      ${FONT_STYLES.P_R};
      font-size: 15px;
      margin-bottom: 13px;
    }
    :nth-child(2) {
      ${FONT_STYLES.P_B}
      font-size: 36px;
      margin-bottom: 24px;
    }
    :nth-child(3) {
      ${FONT_STYLES.P_R};
      font-size: 15px;
      display: flex;
      margin-bottom: 25px;

      & > p {
        padding-right: 32px;
      }
    }
  }
`;

export const ShortInfoBox = styled.div`
  padding: 25px 0;
  border-bottom: 1px solid ${COLOR.GRAY_9};
  font-size: 15px;

  & > div {
    display: flex;
    flex-wrap: wrap;
    padding: 25px 0;

    :nth-child(3) {
      & > p {
        :nth-child(1) {
          width: 80px;
          ${FONT_STYLES.P_T};
        }
        :nth-child(2) {
          padding-right: 5px;
          cursor: pointer;
        }
        :nth-child(3) {
          ${FONT_STYLES.P_R}
          cursor: pointer;
        }
      }
    }

    & > div {
      flex: 1 1 50%;
      display: flex;

      & > p {
        :nth-child(1) {
          ${FONT_STYLES.P_T};
          width: 80px;
        }
        :nth-child(2) {
          ${FONT_STYLES.P_R}
        }
      }
    }
  }
`;

export const PriceBox = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 0;
  border-bottom: 1px solid ${COLOR.GRAY_9};

  & > p {
    :nth-child(1) {
      ${FONT_STYLES.P_T};
      font-size: 15px;
      width: 80px;
    }
    :nth-child(2) {
      ${FONT_STYLES.P_B};
      font-size: 16px;
      opacity: 0.5;
      padding-right: 10px;
    }
    :nth-child(3) {
      ${FONT_STYLES.P_B};
      font-size: 18px;
    }
    :nth-child(4) {
      ${FONT_STYLES.P_R};
      font-size: 12px;
      margin-left: 10px;
      opacity: 0.6;
    }
  }
`;

export const BuyInfoBox = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 0 45px 0;

  & > div {
    :nth-child(1) {
      display: flex;
      flex-grow: 2;
      flex-basis: 50%;
      align-items: center;
      width: 80px;
      
      & > p {
        :nth-child(1) {
          ${FONT_STYLES.P_R};
          font-size: 15px;
          width: 80px;
        }
    }
  }
    
    :nth-child(2) {
      display: flex;
      align-items: center;

      & > p {
        :nth-child(1) {
          ${FONT_STYLES.P_R};
          font-size: 15px;
          width: 80px;
        }
        :nth-child(2) {
          ${FONT_STYLES.P_B};
          font-size: 32px;
        }
    }
  }
`;

export const CountButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 154px;
  height: 44px;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 7px;

  & > div {
    padding: 0 20px;
    display: flex;
    align-items: center;

    :nth-child(1),
    :nth-child(3) {
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
  }
`;

export const ButtonBox = styled.div`
  display: flex;

  & > button {
    width: 50%;
    :nth-child(1) {
      margin-right: 20px;
    }
  }
`;

export const SubInfoBox = styled.div`
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;
`;

export const MenuBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${COLOR.GRAY_9};
  padding: 25px 0;
  margin: 0 42px;

  & > div {
    padding: 10px 0;
    margin: 0 auto;

    :nth-child(1) {
      border-right: 1px solid ${COLOR.GRAY_9};
    }
    :nth-child(2) {
      border-right: 1px solid ${COLOR.GRAY_9};
    }
  }

  li {
    ${FONT_STYLES.P_R}
    color: #716b6b;
    list-style: none;
    width: 420px;

    & > p {
      font-size: 18px;
      margin: 0 5px;
      cursor: pointer;
    }

    &.active {
      ${FONT_STYLES.P_SB}
      color: ${COLOR.BLACK_1};
    }
  }
`;

export const DetailInfoBox = styled.div``;

export const DetailIntroduce = styled.div`
  ${FONT_STYLES.P_R};
  text-align: left;
  padding: 50px 90px 0 90px;
  line-height: normal;

  & > div {
    white-space: pre-line;

    :nth-child(1) {
      border-bottom: 1px solid ${COLOR.GRAY_9};
      padding-bottom: 50px;
    }
    :nth-child(2) {
      padding: 12px 0;
    }

    & > div {
      display: flex;
      margin: 38px 0;

      & > p {
        width: 110px;
      }
    }
  }
`;

export const DetailIndexBox = styled.div`
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  background: linear-gradient(90deg, ${COLOR.BLUE_8} 25%, #ffffff 25%);
`;

export const DetailIndexContent = styled.div`
  padding: 0 42px;

  & > div {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 30px;
    border-bottom: 1px solid ${COLOR.GRAY_9};
    white-space: pre=line;

    & > p {
      :nth-child(1) {
        flex-basis: 26%;
        ${FONT_STYLES.P_R};
        font-size: 18px;
        color: ${COLOR.GRAY_0};
        padding-left: 4%;
      }
    }

    & > div {
      ${FONT_STYLES.P_R};

      & > p {
        padding: 5px 0;
      }
    }
  }
`;

export const DetailEtcBox = styled.div`
  text-align: left;
  padding: 0 90px;

  & > div {
    line-height: 1.5;
    padding: 40px 0;
    border-bottom: 1px solid ${COLOR.GRAY_9};
    white-space: pre-line;

    & > p {
      :nth-child(1) {
        ${FONT_STYLES.P_SB}
        font-size: 18px;
        width: 100px;
        padding-top: 5px;
      }
    }

    & > div {
      ${FONT_STYLES.P_R}
      font-size: 15px;
      padding-left: 30px;

      & > p {
        padding: 7.5px 0;
      }
    }
  }
`;
