import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const defaultHandler = () => {
  alert('알 수 없는 에러가 발생했습니다.');
};

const handler400 = () => {
  // Bad Request
  alert('잘못된 요청입니다.');
};

const handler401 = () => {
  // unauthorized
  alert('권한이 없습니다.');
};

const handler403 = () => {
  // Forbidden
  alert('잘못된 요청입니다.');
};

const handler404 = () => {
  alert('존재하지 않는 요청입니다.');
};

const handler409 = () => {
  // Conflict
  alert('잘못된 요청입니다.');
};

const handle500 = () => {
  alert('Error 500');
};

/** 특정 HTTP Status일 때 전역적으로 적용하기로 사전 정의한 핸들러 */
const Handlers = {
  defaultHandler: defaultHandler,

  400: {
    errorHandler: handler400,
  },
  401: {
    errorHandler: handler401,
  },
  403: {
    errorHandler: handler403,
  },
  404: {
    errorHandler: handler404,
  },
  409: {
    errorHandler: handler409,
  },
  500: {
    errorHandler: handle500,
  },
};

/** 컴포넌트에서 재정의한 핸들러 모음 */
const useHandleError = (handlers) => {
  const navigate = useNavigate();

  const handleError = useCallback(
    (error) => {
      alert(error.response.data.message);
      navigate(-1);
    },
    [handlers],
  );

  return { handleError };
};

export default useHandleError;
