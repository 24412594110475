import styled from 'styled-components';
import COLOR from '../../../styles/common/color';

const SlidesContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const HotPickSlide = styled.div`
  width: 100%;
  display: flex;
`;

const LectureSlide = styled.div`
  width: 1312px;
  display: flex;
`;

const BookSlide = styled.div`
  width: 1312px;
  display: flex;
  justify-content: space-between;
  & div:nth-child(1),
  div:nth-child(2),
  div:nth-child(3),
  div:nth-child(4) {
    margin-right: 72px;
  }
`;

const HotPickSlideControllerBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  div:nth-child(1) {
    width: ${({ activeDot1 }) => (activeDot1 ? '16px' : '')};
    height: ${({ activeDot1 }) => (activeDot1 ? '16px' : '')};
    background-color: ${({ activeDot1 }) => (activeDot1 ? COLOR.BLUE_3 : '')};
  }
  div:nth-child(2) {
    width: ${({ activeDot2 }) => (activeDot2 ? '16px' : '')};
    height: ${({ activeDot2 }) => (activeDot2 ? '16px' : '')};
    background-color: ${({ activeDot2 }) => (activeDot2 ? COLOR.BLUE_3 : '')};
  }
`;

const HotPickDot = styled.div`
  width: 13px;
  height: 13px;
  background-color: ${COLOR.GRAY_3};
  border-radius: 50%;
  margin-right: 8px;
  :hover {
    cursor: pointer;
  }
`;

export {
  SlidesContainer,
  HotPickSlide,
  LectureSlide,
  BookSlide,
  HotPickSlideControllerBox,
  HotPickDot,
};
