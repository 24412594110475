import React, { useState } from 'react';
import COLOR from '../../../styles/common/color';
import { CouponModalStyle, CouponBackGround, Content } from './style';
import { SquareInput } from '../../atoms/Input';
import { Button } from '../../atoms/Button';

import { checkCoupon } from '../../../axiosManage/OrderBasket';

const CouponModal = (props) => {
  const handleCoupon = (e) => {
    props.setCouponInfo({
      code: e.target.value,
    });
  };

  const clickUseCoupon = () => {
    checkCoupon(props.couponInfo.code).then((res) => {
      if (res.status === 200) {
        alert('쿠폰 사용이 완료되었습니다.');
        props.setIsCouponUsed(true);
        props.setSalePercent(res.data.sale);
        props.setCouponPopup(false);
      } else {
        alert(res.data.message);
      }
    });
  };

  return (
    <CouponModalStyle>
      <CouponBackGround
        onClick={() => {
          props.setCouponPopup(false);
        }}
      />
      <Content>
        <div>쿠폰 입력</div>
        <div>
          <SquareInput
            width={455}
            height={60}
            placeholder={'사용할 쿠폰 번호를 입력해주세요.'}
            onChangeFunc={handleCoupon}
          />
        </div>
        <div>
          <Button width={130} height={60} bgColor={COLOR.BLUE_4} onClickFunc={clickUseCoupon}>
            쿠폰 사용하기
          </Button>
        </div>
      </Content>
    </CouponModalStyle>
  );
};

export default CouponModal;
