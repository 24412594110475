import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  MyOrderDetailPageContainer,
  MyOrderDetailTitle,
  TopBox,
  PaymentInfoBox,
  ProductInfoBox,
  RefundInfoBox,
  DeliveryInfoBox,
  OrderItems,
  Item,
  CancelItemList,
  CancelItem,
} from './style';

import { Button, CancelButton } from '../../components/atoms/Button';
import DeliveryTrackIcon from '../../assets/icons/icon-DeliveryTrack.png';
import { ReactComponent as GoToTrackingIcon } from '../../assets/icons/icon-GoToTracking.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/icon-OrderDetailPlusIcon.svg';
import { ReactComponent as EqualIcon } from '../../assets/icons/icon-OrderDetailEqualIcon.svg';

import { useRecoilState } from 'recoil';
import { myPageMyOrderAtom } from '../../recoil/MyPageStore/MyPageRecoil';

import { useQuery } from 'react-query';
import { getMyPaymentInfo, getCancelAndChangeStatus } from '../../axiosManage/MyPage';
import useHandleError from '../../hooks/useHandleError';
import { refundOrder } from '../../axiosManage/OrderBasket';

import PaymentCancelModal from '../../components/molecules/Modal';
import useModal from '../../hooks/useModal';
import PaymentCancelInput from '../../components/organisms/PaymentCancelModal';

function MyPageOrderDetailPage({ currentIndex, deliveryStatus }) {
  const { id } = useParams();
  const { handleError } = useHandleError();
  const [modalOption, showModal] = useModal();

  // 교환환불 사유 입력 모달 오픈
  const openPaymentCancelModal = useCallback(
    (id) => {
      showModal(true, null, null, <PaymentCancelInput id={id} showModal={showModal} />);
      document.body.style.overflow = 'hidden';
    },
    [showModal],
  );

  const [myOrderList, setMyOrderList] = useRecoilState(myPageMyOrderAtom);
  const { status: myOrderStatus, refetch: myOrderRefetch } = useQuery(
    'getMyPaymentInfo',
    () => getMyPaymentInfo(localStorage.getItem('startDate'), localStorage.getItem('endDate')),
    {
      retry: 0,
      onSuccess: (data) => {
        setMyOrderList(data.data);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const [currentOrderDetail, setCurrentOrderDetail] = useState([]);
  useEffect(() => {
    myOrderList.forEach((order) => {
      if (order.id === Number(id)) {
        setCurrentOrderDetail(order);
      }
    });
  }, [myOrderList]);

  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    let price = 0;
    if (currentOrderDetail.orderRecords) {
      for (let i = 0; i < currentOrderDetail.orderRecords.length; i++) {
        price += currentOrderDetail.orderRecords[i].price;
      }
    }
    setTotalPrice(price);
  }, [currentOrderDetail]);

  // 구매한 상세 상품 정보
  const [bookProductInfos, setBookProductInfos] = useState([]);
  const [bookSendStatus, setBookSendStatus] = useState([]);
  const [lectureProductInfos, setLectureProductInfos] = useState([]);
  const [passProductInfos, setPassProductInfos] = useState([]);
  useEffect(() => {
    let bookProductInfo = [];
    let bookSendStatus = [];
    let lectureProductInfo = [];
    let passProductInfo = [];

    if (currentOrderDetail.orderRecords) {
      for (let i = 0; i < currentOrderDetail.orderRecords.length; i++) {
        if (currentOrderDetail.orderRecords[i].productType === 'book') {
          bookProductInfo.push([currentOrderDetail.orderRecords[i], false]);
          if (
            currentOrderDetail.parcels[i] !== undefined &&
            currentOrderDetail.parcels[i].isSendPost === true
          ) {
            bookSendStatus.push(currentOrderDetail.parcels[i]);
          }
        } else if (currentOrderDetail.orderRecords[i].productType === 'lecture') {
          lectureProductInfo.push(currentOrderDetail.orderRecords[i]);
        } else if (currentOrderDetail.orderRecords[i].productType === 'pass') {
          passProductInfo.push(currentOrderDetail.orderRecords[i]);
        }
      }

      setBookProductInfos(bookProductInfo);
      setBookSendStatus(bookSendStatus);
      setLectureProductInfos(lectureProductInfo);
      setPassProductInfos(passProductInfo);
    }
  }, [currentOrderDetail]);

  useEffect(() => {
    let newArray = [];
    if (currentOrderDetail.orderRecords) {
      currentOrderDetail.orderRecords.forEach((record) => {
        if (record.productType === 'book') {
          const isSendPostExistBook = bookSendStatus.find(
            (status) => status.orderRecord.id === record.id,
          );

          if (isSendPostExistBook) {
            newArray.push([record, true]);
          } else {
            newArray.push([record, false]);
          }
        }
      });
    }
    setBookProductInfos(newArray);
  }, [currentOrderDetail, bookSendStatus]);

  const [bookPostCodeList, setBookPostCodeList] = useState([]);
  useEffect(() => {
    let newArray = [];
    if (currentOrderDetail.orderRecords) {
      currentOrderDetail.orderRecords.forEach((record) => {
        if (record.productType === 'book') {
          const isSendPostExistBook = bookSendStatus.find(
            (status) => status.orderRecord.id === record.id,
          );

          newArray.push(isSendPostExistBook);
        }
      });
    }
    setBookPostCodeList(newArray);
  }, [currentOrderDetail, bookSendStatus]);

  const postCodeCheck = (postCode) => {
    if (postCode === null) {
      alert('운송장 번호가 아직 등록되지 않았습니다.');
    } else {
      window.open(
        'about:blank',
      ).location.href = `https://tracker.delivery/#/kr.cjlogistics/${postCode}`;
    }
  };

  const getReceipt = (link) => {
    window.open('about:blank').location.href = link;
  };

  // 결제 취소
  const refund = (id, type) => {
    let isYes = confirm('해당 상품의 결제를 취소하시겠습니까?');

    if (isYes) {
      refundOrder(id, type)
        .then((res) => {
          alert('결제가 취소되었습니다.');
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  const [cancelList, setCancelList] = useState([]);
  useEffect(() => {
    getCancelAndChangeStatus(id).then((res) => {
      setCancelList(res.data.list);
    });
  }, [id]);

  return (
    <MyOrderDetailPageContainer>
      <MyOrderDetailTitle>주문 / 배송 조회</MyOrderDetailTitle>
      <PaymentCancelModal modalOption={modalOption} />
      <TopBox>
        {currentOrderDetail ? (
          <>
            <div>
              <p>주문 번호</p>
              <p>{currentOrderDetail.merchantUid}</p>
            </div>
            <div>
              <p>주문일</p>
              <p>{currentOrderDetail.createdAt?.slice(0, 10).replace(/-/g, '.')}</p>
            </div>
            <div>
              <p>배송상태 / 조회</p>
              {deliveryStatus && deliveryStatus[currentIndex] === true ? (
                <div>발송 완료</div>
              ) : currentOrderDetail.parcels &&
                currentOrderDetail.parcels.length >= 1 &&
                deliveryStatus &&
                deliveryStatus[currentIndex] === false ? (
                <div>발송 준비</div>
              ) : currentOrderDetail.orderRecords &&
                currentOrderDetail.orderRecords.length >= 1 &&
                currentOrderDetail.orderRecords[0].isCancel === true ? (
                <div style={{ color: 'red' }}>결제 취소</div>
              ) : currentOrderDetail.parcels && currentOrderDetail.parcels.length < 1 ? (
                <div>결제 완료</div>
              ) : (
                ''
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </TopBox>
      <PaymentInfoBox>
        {currentOrderDetail ? (
          <>
            <div>결제 정보</div>
            <div>
              <div>
                <p>총 주문금액</p>
                <p>{totalPrice && totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</p>
              </div>
              <div>
                <PlusIcon />
              </div>
              <div>
                <p>배송비</p>
                <p>
                  {currentOrderDetail.deliveryCharge
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  원
                </p>
              </div>
              <div>
                <EqualIcon />
              </div>
              <div>
                <p>총 결제금액</p>
                <p>
                  {(totalPrice + currentOrderDetail.deliveryCharge)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                  원{' '}
                </p>
              </div>
            </div>
            <div>
              <p>결제수단</p>
              <p>
                카드 (승인금액:{' '}
                {(totalPrice + currentOrderDetail.deliveryCharge)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                원)
              </p>
              <div>
                <p>증빙서류</p>
                <p>
                  <Button
                    width={124}
                    height={48}
                    onClickFunc={() => getReceipt(currentOrderDetail.receipt)}
                  >
                    신용카드 전표
                  </Button>
                </p>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </PaymentInfoBox>
      <ProductInfoBox>
        <div>
          <p>상품정보</p>
          <p>수량</p>
          <p>결제가</p>
          <p></p>
        </div>
        <OrderItems>
          {bookProductInfos ? (
            <>
              {bookProductInfos.map((book, i) => (
                <Item key={book[0].id}>
                  {/* 상품정보 */}
                  <div>
                    <div>
                      <p>교재</p>
                      <p>{book[0].book.bookName}</p>
                    </div>
                  </div>
                  <div>{book[0].count}</div>
                  <div>{book[0].price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</div>
                  <div>
                    {book[0].isCancel === false && book[1] === true ? (
                      <CancelButton
                        width={120}
                        height={42}
                        onClickFunc={() => openPaymentCancelModal(book[0].id)}
                      >
                        교환/환불 신청
                      </CancelButton>
                    ) : book[0].isCancel === false && book[1] === false ? (
                      <CancelButton
                        width={100}
                        height={42}
                        onClickFunc={() => refund(book[0].id, 'book')}
                      >
                        결제 취소
                      </CancelButton>
                    ) : book[0].isCancel === true ? (
                      <span style={{ color: 'red' }}>결제 취소됨</span>
                    ) : (
                      ''
                    )}
                  </div>
                  {book[0].isCancel === false ? (
                    <div
                      onClick={() => {
                        if (bookPostCodeList[0] === undefined) {
                          alert('운송장 번호가 아직 등록되지 않았습니다.');
                        } else {
                          postCodeCheck(bookPostCodeList[i].postCode);
                        }
                      }}
                    >
                      <p>
                        <img src={DeliveryTrackIcon} />
                      </p>
                      <p>배송조회</p>
                      <p>
                        <GoToTrackingIcon />
                      </p>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Item>
              ))}
            </>
          ) : (
            ''
          )}
          {lectureProductInfos ? (
            <>
              {lectureProductInfos.map((lecture) => (
                <Item key={lecture.id}>
                  {/* 상품정보 */}
                  <div>
                    <div>
                      <p>강의</p>
                      <p>{lecture.lectureInfo.lectureName}</p>
                    </div>
                  </div>
                  <div>{lecture.count}</div>
                  <div>
                    {(lecture.price * lecture.count)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    원
                  </div>
                  <div>
                    {lecture.isCancel === false ? (
                      <CancelButton
                        width={100}
                        height={42}
                        onClickFunc={() => refund(lecture.id, 'lecture')}
                      >
                        결제취소/환불
                      </CancelButton>
                    ) : lecture.isCancel === true ? (
                      <span style={{ color: 'red' }}>결제 취소됨</span>
                    ) : (
                      ''
                    )}
                  </div>
                </Item>
              ))}
            </>
          ) : (
            ''
          )}
          {passProductInfos ? (
            <>
              {passProductInfos.map((pass) => (
                <Item key={pass.id}>
                  {/* 상품정보 */}
                  <div>
                    {/* <div>
                      <p>패스 카테고리</p>
                      <p>패스 교수 이름</p>
                    </div> */}
                    <div>
                      <p>패스</p>
                      <p>{pass.passInfo.passName}</p>
                    </div>
                  </div>
                  <div>{pass.count}</div>
                  <div>
                    {(pass.price * pass.count).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                  </div>
                  <div>
                    {pass.isCancel === false ? (
                      <CancelButton
                        width={100}
                        height={42}
                        onClickFunc={() => refund(pass.id, 'pass')}
                      >
                        결제취소/환불
                      </CancelButton>
                    ) : pass.isCancel === true ? (
                      <span style={{ color: 'red' }}>결제 취소됨</span>
                    ) : (
                      ''
                    )}
                  </div>
                </Item>
              ))}
            </>
          ) : (
            ''
          )}
        </OrderItems>
      </ProductInfoBox>
      {cancelList.length >= 1 ? (
        <RefundInfoBox>
          <div>
            <p>상품정보</p>
            <p>교환/환불 처리 상태</p>
          </div>
          <CancelItemList>
            {cancelList.map((product) => (
              <CancelItem key={product.id}>
                <div>{product.orderRecord.book.bookName}</div>
                {product.isProcessed === true ? (
                  <div style={{ color: 'blue' }}>처리 완료</div>
                ) : (
                  <div>처리 대기</div>
                )}
              </CancelItem>
            ))}
          </CancelItemList>
        </RefundInfoBox>
      ) : (
        ''
      )}
      {currentOrderDetail.parcels && currentOrderDetail.parcels.length >= 1 ? (
        <DeliveryInfoBox>
          <div>배송정보</div>
          <div>
            <p>받으시는 분</p>
            <p>{currentOrderDetail.parcels[0].buyerName}</p>
          </div>
          <div>
            <p>휴대폰 번호</p>
            <p>{currentOrderDetail.parcels[0].phoneNumber}</p>
          </div>
          <div>
            <p>배송주소</p>
            <p>(우편번호) {currentOrderDetail.parcels[0].address}</p>
          </div>
          <div>
            <p>배송메시지</p>
            <p>{currentOrderDetail.parcels[0].parcelDetail}</p>
          </div>
        </DeliveryInfoBox>
      ) : (
        ''
      )}
    </MyOrderDetailPageContainer>
  );
}

export default MyPageOrderDetailPage;
