import React, { useState, useEffect } from 'react';
import { AlarmContainer, Alarm } from './style';

import { ReactComponent as SmallAlarmIcon } from '../../assets/icons/icon-AlarmSmall.svg';
import { ReactComponent as PrevBtn } from '../../assets/icons/icon-PrevBtnFull.svg';
import { ReactComponent as NextBtn } from '../../assets/icons/icon-NextBtnFull.svg';

import { Button } from '../../components/atoms/Button';
import AlarmDetail from '../../components/organisms/AlarmDetail/index';

import { useQuery, useMutation } from 'react-query';
import { getMyNotify, checkNotify, deleteNotify } from '../../axiosManage/Alarm';
import useHandleError from '../../hooks/useHandleError';
import { SpinLoader, typeList } from '../../components/atoms/SpinLoader';

import { useSetRecoilState, useRecoilState } from 'recoil';
import { myPageMyAlarmCountAtom } from '../../recoil/MyPageStore/MyPageRecoil';

function AlarmPage() {
  const { handleError } = useHandleError();

  const [alarmData, setAlarmData] = useState([]);
  let [alarmDataLength, setAlarmDataLength] = useRecoilState(myPageMyAlarmCountAtom);

  /** 읽은 알람 isChecked = true 로 변경 */
  const checkedAlarm = useMutation(
    (id) => {
      checkNotify(id);
    },
    {
      onSuccess: () => {
        myAlarmRefetch();
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  /** 읽은 알람 id를 통해 삭제 */
  const deletingAlarm = useMutation(
    (data) => {
      deleteNotify(data);
    },
    {
      onSuccess: () => {
        getMyNotify().then((res) => {
          setAlarmData(res.data.notifies);
        });
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  /** 내 알람 데이터 */
  const { status: myAlarmStatus, refetch: myAlarmRefetch } = useQuery(
    ['getMyNotify', checkedAlarm, deletingAlarm],
    getMyNotify,
    {
      retry: 0,
      onSuccess: (data) => {
        setAlarmData(data.data.notifies);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const [checkedAlarmLists, setCheckedAlarmLists] = useState([]);
  useEffect(() => {
    let checkedAlarmList = [];
    alarmData.forEach((alarm) => {
      if (alarm.isChecked === true && !checkedAlarmList.includes(alarm.id)) {
        checkedAlarmList.push(alarm.id);
      }
    });
    setCheckedAlarmLists(checkedAlarmList);
  }, [alarmData]);

  const checkedAlarmList = () => {
    const data = {
      ids: checkedAlarmLists,
    };

    deleteNotify(data).then((res) => {
      myAlarmRefetch();
    });
  };

  /* 페이징 */
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [currentPageData, setCurrentPageData] = useState([]);
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  const clickPrevBtn = (e) => {
    setCurrentPage(currentPage - 1);
  };
  const clickNextBtn = (e) => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    const currentPosts = alarmData.slice(indexOfFirst, indexOfLast);
    setCurrentPageData(currentPosts);
  }, [alarmData, currentPage]);

  /* 알림에 대한 상세 페이지 노출 */
  const [openDetailID, setOpenDetailID] = useState(0);
  const [showSelectedDetail, setShowSelectedDetail] = useState(Array(alarmData.length).fill(false));

  const openDetail = (id, index) => {
    setOpenDetailID(id);
    checkNotify(id).then((res) => {
      myAlarmRefetch();
    });

    // if (showSelectedDetail[id] === true) {
    //   const newArr = [...showSelectedDetail];
    //   newArr[id] = false;
    //   setShowSelectedDetail(newArr);
    // } else {
    //   const newArr = Array(alarmData.length).fill(false);
    //   newArr[id] = true;
    //   setShowSelectedDetail(newArr);
    // }
    const newArr = Array(alarmData.length).fill(false);
    newArr[index] = true;
    setShowSelectedDetail(newArr);
  };

  useEffect(() => {
    let alarmDataCount = 0;
    getMyNotify().then((res) => {
      res.data.notifies.forEach((alarm, i) => {
        if (alarm.isChecked === false) {
          alarmDataCount += 1;
        }

        if (alarmDataCount < 1) {
          setAlarmDataLength(0);
        } else {
          setAlarmDataLength(alarmDataCount);
        }
      });
    });
  }, [showSelectedDetail]);

  return (
    <AlarmContainer>
      <div>알림</div>
      <div>
        <Button width={127} height={48} onClickFunc={checkedAlarmList}>
          읽은 알림 삭제
        </Button>
      </div>
      <div>
        {myAlarmStatus === 'loading' ? (
          <SpinLoader type={typeList[2].prop} color={'#000'} width={1089} height={662} />
        ) : (
          <>
            {currentPageData.map((alarm, i) => (
              <div key={alarm.id}>
                {showSelectedDetail[i] ? (
                  <AlarmDetail
                    openDetailID={openDetailID}
                    data={alarm}
                    onClickFunc={() => openDetail(alarm.id, i)}
                  />
                ) : (
                  <Alarm onClick={() => openDetail(alarm.id, i)}>
                    <p>
                      <SmallAlarmIcon />
                    </p>
                    <p>
                      {/* <span>[{alarm.category}]</span> */}
                      {/* <span>[카테고리]</span> */}
                      <span>{alarm.title}</span>
                    </p>
                    <p>{alarm.sendAt?.slice(0, 10).replace(/-/g, '.')}</p>
                  </Alarm>
                )}
              </div>
            ))}
          </>
        )}
      </div>
      <div>
        <PrevBtn
          className="prev-btn"
          onClick={(e) => {
            if (currentPage === 1) {
              e.preventDefault();
            } else {
              clickPrevBtn();
            }
          }}
        />
        <NextBtn
          className="next-btn"
          onClick={(e) => {
            if (currentPage === Math.ceil(alarmData.length / postsPerPage)) {
              e.preventDefault();
            } else {
              clickNextBtn();
            }
          }}
        />
      </div>
    </AlarmContainer>
  );
}

export default AlarmPage;
