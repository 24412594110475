import React from 'react';
import { Line, NowTabContainer } from './style';

const NowTab = ({ tab }) => {
  switch (tab) {
    case 'professor':
      return (
        <NowTabContainer>
          <p>02</p>
          <Line />
          <p>PROFESSOR</p>
        </NowTabContainer>
      );
    case 'curriculum':
      return (
        <NowTabContainer>
          <p>03</p>
          <Line />
          <p>CURRICULUM</p>
        </NowTabContainer>
      );
    case 'review':
      return (
        <NowTabContainer>
          <p>04</p>
          <Line />
          <p>REVIEW</p>
        </NowTabContainer>
      );
    case 'lectures':
      return (
        <NowTabContainer>
          <p>05</p>
          <Line />
          <p>LECTURE</p>
        </NowTabContainer>
      );
    case 'notice':
      return (
        <NowTabContainer>
          <p>06</p>
          <Line />
          <p>NOTICE</p>
        </NowTabContainer>
      );
    case 'question':
      return (
        <NowTabContainer>
          <p>07</p>
          <Line />
          <p>QUESTION</p>
        </NowTabContainer>
      );
    case 'library':
      return (
        <NowTabContainer>
          <p>08</p>
          <Line />
          <p>REFERENCE ROOM</p>
        </NowTabContainer>
      );
    default:
      return (
        <NowTabContainer>
          <p>01</p>
          <Line />
          <p>HOME</p>
        </NowTabContainer>
      );
  }
};

export default NowTab;
