import React, { useState } from 'react';
import {
  ExamInfoContentBox,
  ExamInfoGraphContainer,
  Table,
  TableHeader,
  TableDataSlice,
  PageNumber,
  TableSubHeader,
  TableSubHeaderContainer,
  TableDataContainer,
  Data,
} from '../common/graph-style';

import { ReactComponent as PrevBtn } from '../../../../assets/icons/icon-PrevBtnFullTypeCol.svg';
import { ReactComponent as NextBtn } from '../../../../assets/icons/icon-NextBtnFullTypeCol.svg';

const TableSubHeaderItem1 = [
  {
    title: '모집인원',
  },
  {
    title: '출원인원',
  },
  {
    title: '합격인원',
  },
];

const TableSubHeaderItem2 = [
  {
    title: '경쟁률',
  },
  {
    title: '합격률',
  },
  {
    title: '합격선',
  },
];

const FirstTable = ({ firstTableData }) => {
  return (
    <Table>
      <TableHeader>
        <div>직렬</div>
        <div>
          <div>외교관후보자</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {TableSubHeaderItem1.map((item) => (
              <div key={item.title}>{item.title}</div>
            ))}
          </TableSubHeader>
          <TableSubHeader>
            {TableSubHeaderItem2.map((item) => (
              <div key={item.title}>{item.title}</div>
            ))}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {firstTableData &&
          firstTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data isOne={true}>
                <div>
                  <p>{data.content.외교관후보자.모집인원}</p>
                  <p>{data.content.외교관후보자.출원인원}</p>
                  <p>{data.content.외교관후보자.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.외교관후보자.경쟁률}</p>
                  <p>{data.content.외교관후보자.합격률}</p>
                  <p>{data.content.외교관후보자.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

function Diplomat({ data }) {
  const [currentTableNum, setCurrentTableNum] = useState(1);

  const clickPrevBtn = () => {
    setCurrentTableNum(currentTableNum - 1);
  };
  const clickNextBtn = () => {
    setCurrentTableNum(currentTableNum + 1);
  };

  return (
    <>
      <ExamInfoContentBox>
        <PrevBtn
          onClick={(e) => {
            if (currentTableNum === 1) {
              e.preventDefault();
            } else {
              clickPrevBtn();
            }
          }}
        />
        <ExamInfoGraphContainer>
          <TableDataSlice />
          <FirstTable firstTableData={data} />
          <TableDataSlice />
        </ExamInfoGraphContainer>
        <NextBtn
          onClick={(e) => {
            if (currentTableNum === 6) {
              e.preventDefault();
            } else {
              clickNextBtn();
            }
          }}
        />
      </ExamInfoContentBox>
      <PageNumber>1/1</PageNumber>
    </>
  );
}

export default Diplomat;
