import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  MyLectureDetailPageContainer,
  MyLectureDetailTitle,
  HorizonLine,
  LectureDetailBox,
  LectureInfo,
  LectureProgressBox,
  LectureProgressHeader,
  LectureItems,
} from './style';
import COLOR from '../../styles/common/color';
import { ProgressBar } from '../../components/atoms/ProgressBar';
import { Button } from '../../components/atoms/Button';
import { useRecoilValue } from 'recoil';
import { myPageMyLectureAtom } from '../../recoil/MyPageStore/MyPageRecoil';
import { createPlayerToken, player } from '../../axiosManage/Video';
import { getLectureVideoReference } from '../../axiosManage/MyPage';
import MobileDetect from 'mobile-detect';

var sp = null;
var app = null;

function detectMobileDevice() {
  const md = new MobileDetect(window.navigator.userAgent);
  if (md.mobile() != null || md.phone() != null || md.tablet() != null) {
    return true;
  } else {
    return false;
  }
}

function MyPageLectureDetailPage({ signUpProgressRate, etcProgressRate }) {
  const { id } = useParams();
  const myLecture = useRecoilValue(myPageMyLectureAtom);

  const [currentLectureDetail, setCurrentLectureDetail] = useState([]);
  useEffect(() => {
    myLecture.forEach((lecture) => {
      if (lecture.id === Number(id)) {
        setCurrentLectureDetail(lecture);
      }
    });
  }, [myLecture]);

  // 세부 강의 수강 퍼센테이지 계산 로직
  const [completeLectureCount, setCompleteLectureCount] = useState(0); // 100% 들은 강의 개수
  const [progressRate, setProgressRate] = useState(
    Array(
      currentLectureDetail.learningStatusList && currentLectureDetail.learningStatusList.length,
    ).fill(0),
  );

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const newArr = [...progressRate];
    let completeLectureCount = 0;
    if (currentLectureDetail.learningStatusList) {
      for (let i = 0; i < currentLectureDetail.learningStatusList.length; i++) {
        if (currentLectureDetail.learningStatusList[i]) {
          newArr[i] = ~~(
            (currentLectureDetail.learningStatusList[i].learningTime /
              (currentLectureDetail.lectureInfo.lectures[i].time * 60)) *
            100
          );
          if (
            ~~(
              (currentLectureDetail.learningStatusList[i].learningTime /
                (currentLectureDetail.lectureInfo.lectures[i].time * 60)) *
              100
            ) >= 100
          ) {
            completeLectureCount += 1;
            newArr[i] = 100;
          }
        }
      }
    }
    setCompleteLectureCount(completeLectureCount);
    setProgressRate(newArr);
    setIsMobile(detectMobileDevice());
  }, [currentLectureDetail]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/js/starplayer_app.js';
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      //<![CDATA[
      // eslint-disable-next-line
      // eslint-disable-next-line
      sp = StarPlayerApp;
      sp.license = 'F0C2DA42-98D3-4F1E-BA6E-FB76C3F30B58';
      sp.version = '1.0.0';
      sp.android_version = '1.0.0';
      sp.ios_version = '1.0.0';
      sp.pmp = 'true';
      sp.debug = 'false';
      // sp.referer = window.location.href; //
      sp.referer_return = 'false';
      // sp.referer_return = "false";
      // sp.android_referer_return = "true"; //
      //sp.user_id = "ANONYMOUS"; //
      app = (info_url) => {
        sp.executeApp(info_url);
      };
      //]]>
    };

    script.onerror = () => {
      console.log('load error!');
    };
  }, []);

  // 강의 전체 퍼센테이지 계산 로직
  const [entireProgressRate, setEntireProgressRate] = useState(0);
  const [etcLectureProgressRate, setEtcLectureProgressRate] = useState(0);
  useEffect(() => {
    const lecturePercent = signUpProgressRate.find((item) => item[0] === Number(id));
    setEntireProgressRate(lecturePercent && lecturePercent[1]);

    const etcLecturePercent = etcProgressRate.find((item) => item[0] === Number(id));
    setEtcLectureProgressRate(etcLecturePercent && etcLecturePercent[1]);
  }, [progressRate, currentLectureDetail, signUpProgressRate, etcProgressRate]);

  const clickPlayButton = (lectureVideoId, signUpLectureHistoryId) => {
    createPlayerToken(lectureVideoId, signUpLectureHistoryId)
      .then((res) => {
        if (res.data.success === true && res.data.token) {
          window.open(
            `https://service.labstandard.kr/api/player?token=${res.data.token}`,
            'LABSTANDARD',
            'height=' + screen.height + ',width=' + screen.width + 'fullscreen=yes',
          );
        }
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const clickMobilePlayButton = async (lectureVideoId, signUpLectureHistoryId) => {
    createPlayerToken(lectureVideoId, signUpLectureHistoryId)
      .then((res) => {
        if (res.data.success === true && res.data.token) {
          console.log();
          // eslint-disable-next-line
          app('https://service.labstandard.kr/api/player/mobile?token=' + res.data.token);
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  const clickDownloadVideoReference = (id, filename) => {
    getLectureVideoReference(id, filename)
      .then((res) => { })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const parseProgress = (time, lectureVideoId) => {
    const data = currentLectureDetail.learningStatusList.find(
      (item) => item.lectureVideoId == lectureVideoId,
    );
    if (data) {
      let progress = ~~((data.learningTime / (time * 60)) * 100);
      if (progress >= 100) {
        progress = 100;
      }
      return progress;
    } else {
      return 0;
    }
  };

  const parseLastLearningAt = (lectureVideoId) => {
    const data = currentLectureDetail.learningStatusList.find(
      (item) => item.lectureVideoId == lectureVideoId,
    );
    if (data) {
      return data.lastLearningAt.slice(0, 10).replace(/-/g, '.');
    } else {
      return '';
    }
  };

  return (
    <MyLectureDetailPageContainer>
      <MyLectureDetailTitle>수강 강좌</MyLectureDetailTitle>
      <HorizonLine />
      <LectureDetailBox>
        <LectureInfo>
          {currentLectureDetail &&
            currentLectureDetail.lectureInfo &&
            currentLectureDetail.lectureInfo.professor ? (
            <>
              <div>
                <img src={currentLectureDetail.lectureInfo.lectureImg} />
              </div>
              <div>
                <div>{currentLectureDetail.status}</div>
                <div>{currentLectureDetail.lectureInfo.lectureName}</div>
                <div>
                  {currentLectureDetail.lectureInfo.lectureCategory === 'language-logic' ? (
                    <p>언어논리</p>
                  ) : currentLectureDetail.lectureInfo.lectureCategory === 'data-interpretation' ? (
                    <p>자료해석</p>
                  ) : currentLectureDetail.lectureInfo.lectureCategory ===
                    'situational-judgment' ? (
                    <p>상황판단</p>
                  ) : (
                    ''
                  )}
                  <p>{currentLectureDetail.lectureInfo.professor.professorName}</p>
                </div>
                <div>
                  <p>강좌 현황</p>
                  <p>{currentLectureDetail.status}</p>
                </div>
                <div>
                  <p>과정</p>
                  <p>{currentLectureDetail.lectureInfo.step}</p>
                </div>
                <div>
                  <p>수강기간</p>
                  <p>
                    {~~(
                      (new Date(currentLectureDetail.endAt).getTime() - new Date().getTime()) /
                      (1000 * 60 * 60 * 24)
                    ) + 1}
                    일 남음(
                    {currentLectureDetail.startedAt?.slice(0, 10).replace('-', '.')} ~
                    {currentLectureDetail.endAt?.slice(0, 10).replace('-', '.')} )
                  </p>
                </div>
                <div>
                  <p>수강 진도율 {entireProgressRate || etcLectureProgressRate}%</p>
                  <p>
                    {completeLectureCount}강 / {currentLectureDetail.lectureInfo.lectures.length}강
                  </p>
                </div>
                <div>
                  <ProgressBar
                    percent={entireProgressRate || etcLectureProgressRate}
                    width={746}
                    height={13}
                  />
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </LectureInfo>
        <LectureProgressBox>
          <LectureProgressHeader>
            <p>목차</p>
            <p>강의명</p>
            <p>강의시간</p>
            <p>최근수강일</p>
            <p>학습자료</p>
            <p>수강하기</p>
          </LectureProgressHeader>
          <LectureItems>
            {currentLectureDetail && currentLectureDetail.learningStatusList ? (
              <>
                {console.log(currentLectureDetail)}
                {currentLectureDetail.lectureInfo.lectures.map((lecture, i) => (
                  <div key={lecture.id}>
                    <div>{i + 1}강</div>
                    <div>
                      <div>
                        <p>{lecture.lectureVideoName}</p>
                        <p>{parseProgress(lecture.time, lecture.id)}%</p>
                      </div>
                      <div>
                        <ProgressBar
                          percent={parseProgress(lecture.time, lecture.id)}
                          height={10}
                        />
                      </div>
                    </div>
                    <div>{lecture.time}분</div>
                    <div>{parseLastLearningAt(lecture.id)}</div>
                    <div>
                      {lecture.isExistFile ? (
                        <a
                          onClick={() => clickDownloadVideoReference(lecture.id, lecture.fileName)}
                        >
                          <span>{lecture.fileName}</span>
                        </a>
                      ) : null}
                    </div>
                    <div>
                      {isMobile ? (
                        <Button
                          width={124}
                          height={44}
                          bgColor={COLOR.BLUE_4}
                          onClickFunc={() =>
                            clickMobilePlayButton(lecture.id, currentLectureDetail.id)
                          }
                        >
                          재생
                        </Button>
                      ) : (
                        <Button
                          width={124}
                          height={44}
                          bgColor={COLOR.BLUE_4}
                          onClickFunc={() => clickPlayButton(lecture.id, currentLectureDetail.id)}
                        >
                          재생
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              ''
            )}
          </LectureItems>
        </LectureProgressBox>
      </LectureDetailBox>
    </MyLectureDetailPageContainer>
  );
}

export default MyPageLectureDetailPage;
