import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

const HeaderContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  display: flex;
  flex-direction: column;
  position: sticky;
  z-index: 10;
  top: 0;
  width: 100%;
  height: 111px;
  padding: 15px 279px 29px 304px;
  background-color: ${COLOR.GRAY_0};
  box-shadow: 0px 3px 6px #00000029;

  /* @media screen and (max-width: 1700px) {
    padding: 15px 280px 29px 304px;
  }
  @media screen and (min-width: 2000px) {
    padding: 15px 600px 29px 600px;
  } */

  @media (min-width: 1400px) and (max-width: 2000px) {
    padding: 15px 0px 29px 0px;
  }
`;

const HeaderLogo = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const HeaderBox = styled.div`
  width: 1337px;
  margin: 0 auto;
`;

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  min-width: 1200px;

  & > ul {
    ${FONT_STYLES.P_M}
    width: 1077px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 60px;

    & > li {
      :hover {
        cursor: pointer;
      }
    }
  }
`;

const MainMenu = styled.div`
  width: 130px;
  text-align: center;
  :hover {
    & > div {
      opacity: 1;
    }

    & > ul {
      transform: scaleY(1);
    }
  }
`;

const ActiveLine = styled.div`
  opacity: 0;
  transition: 0.4s;
  width: inherit;
  position: absolute;
  margin-top: 35px;
  background-color: ${COLOR.BLUE_4};
  border: 2.5px solid ${COLOR.BLUE_4};
`;

const SubMenu = styled.ul`
  ${FONT_STYLES.P_M}
  text-align: left;
  width: 130px;
  transform: scaleY(0);
  transform-origin: top left;
  position: absolute;
  font-size: 15px;
  color: ${COLOR.GRAY_0};
  background-color: ${COLOR.BLUE_4};
  border-radius: 0px 0px 11px 11px / 0px 0px 11px 11px;
  margin-top: 40px;
  padding: 20px 15px;
  transition: all 0.4s;

  li:not(:last-of-type) {
    margin-bottom: 17px;
  }
`;

const SubHeader = styled.div`
  & > ul {
    ${FONT_STYLES.N_R}
    font-size: 15px;
    float: right;
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-right: 24px;
    color: ${COLOR.GRAY_5};

    & > div,
    & > a {
      width: 80px;
      text-align: center;
    }

    li {
      position: relative;
      :hover {
        cursor: pointer;
      }

      & > span {
        position: absolute;
        top: 3px;
        right: 195px;
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
        text-align: center;
        padding-top: 7px;
        left: 13px;
      }
    }
    & > div {
      cursor: pointer;
    }
  }
`;

export {
  HeaderContainer,
  HeaderBox,
  SubHeader,
  MainHeader,
  MainMenu,
  ActiveLine,
  HeaderLogo,
  SubMenu,
};
