import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

export const FileUploaderContainer = styled.div`
  input[type='file'] {
    display: none;
  }
`;

export const StyledFileInput = styled.input`
  ${FONT_STYLES.P_M}
  width: ${({ value }) => (value ? '' : '61px')};
  height: 21px;
  background-color: transparent;
  border: none;
  margin-right: 15px;

  ::placeholder {
    ${FONT_STYLES.P_M}
    font-size: 18px;
    color: #716b6b;
  }

  :focus {
    outline: none;
  }
`;

export const StyledFileLabel = styled.label`
  ${FONT_STYLES.P_M}
  width: 86px;
  font-size: 14px;
  background-color: ${COLOR.BLUE_4};
  color: ${COLOR.GRAY_0};
  padding: 9px 18px;
  border-radius: 17px;

  :hover {
    cursor: pointer;
  }
`;
