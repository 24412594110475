import styled from 'styled-components';

const CurriculumContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-top: 70px;
  text-align: center;

  @media screen and (max-width: 1700px) {
    padding-top: 70px;
  }
`;

const CurriculumImgContainer = styled.div`
  padding: 52px 0px 173px 0px;
  img {
    width: 1312px;
    height: 840px;
    object-fit: scale-down;
  }
`;

export { CurriculumContainer, CurriculumImgContainer };
