import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import {
  RegisterJoinPageContainer,
  RegisterPageTitle,
  HorizonLine,
  RegisterContent,
  Border,
} from './style';
import COLOR from '../../styles/common/color';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';
import { DatePickerBirthInput } from '../../components/atoms/Input';

import SearchAddress from '../../components/organisms/SearchAddress';
import ProgressBox from '../../components/molecules/ProgressBox';
import StepIcon1 from '../../assets/icons/icon-assignment.png';
import StepIcon2Selected from '../../assets/icons/icon-join-selected.png';
import StepIcon3 from '../../assets/icons/icon-complete.png';

import { RegisterButton } from '../../components/atoms/Button';
import { SquareInput } from '../../components/atoms/Input';

import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { registerAssignmentAtom } from '../../recoil/RegisterStore/RegisterRecoil';
import { isLoginAtom, registerAccountTokenAtom } from '../../recoil/AuthStore/AuthRecoil';

import {
  requestRegisterLabstandardAccountConfirm,
  confirmRegister,
  registerAccount,
  checkIdExist,
} from '../../axiosManage/Auth';
import { getMyInfo } from '../../axiosManage/MyPage';
import { myInfoAtom } from '../../recoil/MyPageStore/MyPageRecoil';

function RegisterJoinPage() {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [myInfo, setMyInfo] = useRecoilState(myInfoAtom);

  useEffect(() => {
    if (isLogin === true) {
      alert('잘못된 접근입니다.');
      return <Navigate to="/" />;
    }
  }, []);

  // 체크한 약관 리스트를 불러와 필수 약관에 동의가 되어있지 않다면 이전 단계로 돌아가게 하기
  const registerAssignmentState = useRecoilValue(registerAssignmentAtom);
  useEffect(() => {
    if ([...registerAssignmentState][1] === false || [...registerAssignmentState][2] === false) {
      alert('필수 약관에 동의해주세요.');
      navigate('/register/assignment');
    }
  }, [navigate]);

  // 아이디 입력
  const [userId, setUserId] = useState('');
  const handleUserId = (e) => {
    setUserId(e.target.value);
  };

  // 비밀번호 입력
  const [userPassword, setUserPassword] = useState('');
  const handlePassword = (e) => {
    setUserPassword(e.target.value);
  };

  // 비밀번호 확인
  const [userPasswordConfirm, setPasswordConfirm] = useState('');
  const [isPasswordSame, setIsPasswordSame] = useState(false);
  const handleUserPasswordConfirm = (e) => {
    setPasswordConfirm(e.target.value);
  };

  useEffect(() => {
    if (userPassword !== userPasswordConfirm) {
      setIsPasswordSame(false);
    } else if (userPassword === userPasswordConfirm) {
      setIsPasswordSame(true);
    }
  }, [userPasswordConfirm]);

  // 이름 입력
  const [name, setName] = useState('');
  const handleName = (e) => {
    setName(e.target.value);
  };

  // 성별 선택
  const [gender, setGender] = useState('남자');
  const genderSelect = (gen) => {
    if (gen === '남자') {
      setGender('남자');
    } else if (gen === '여자') {
      setGender('여자');
    }
  };

  // 생년월일 입력
  const [birth, setBirth] = useState(new Date());

  // 이메일 입력
  const [firstEmail, setFirstEmail] = useState('');
  const handleFirstEmail = (e) => {
    setFirstEmail(e.target.value);
  };
  const [secondEmail, setSecondEmail] = useState('');
  const handleSecondEmail = (e) => {
    setSecondEmail(e.target.value);
  };

  // 전화 번호 입력
  const [firstPhoneNumber, setFirstPhoneNumber] = useState(''); // 휴대폰 번호
  const handleFirstPhoneNumber = (e) => {
    setFirstPhoneNumber(e.target.value);
  };
  const [secondPhoneNumber, setSecondPhoneNumber] = useState(''); // 휴대폰 번호
  const handleSecondPhoneNumber = (e) => {
    setSecondPhoneNumber(e.target.value);
  };
  const [lastPhoneNumber, setLastPhoneNumber] = useState(''); // 휴대폰 번호
  const handleLastPhoneNumber = (e) => {
    setLastPhoneNumber(e.target.value);
  };

  // 인증번호 입력
  const [certificationNum, setCertificationNum] = useState(''); // 휴대폰 번호
  const handleCertificationNum = (e) => {
    setCertificationNum(e.target.value);
  };

  // 배송지 주소 입력
  const [addressDetail, setAddressDetail] = useState(''); // 상세 주소
  const handleAddressDetail = (e) => {
    setAddressDetail(e.target.value);
  };

  // 우편번호 팝업창
  const [postAddress, setPostAddress] = useState({
    address: '',
    zoneCode: '',
  });
  const [popup, setPopup] = useState(false);
  const handleSearchAddress = (e) => {
    setPostAddress({
      ...postAddress,
      [e.target.name]: e.target.value,
    });
  };

  const openSearchAddressPopUp = (data) => {
    setPopup(true);
  };

  /** 인증 문자 받기 */
  const [registerAccountToken, setRegisterAccountToken] = useRecoilState(registerAccountTokenAtom);
  const messageRequest = () => {
    if (
      firstPhoneNumber === '' ||
      secondPhoneNumber === '' ||
      lastPhoneNumber === '' ||
      Number.isInteger(firstPhoneNumber) ||
      Number.isInteger(secondPhoneNumber) ||
      Number.isInteger(lastPhoneNumber)
    ) {
      alert('휴대폰 번호를 정확하게 입력해주세요.');
    } else {
      requestRegisterLabstandardAccountConfirm({
        phoneNumber: firstPhoneNumber + secondPhoneNumber + lastPhoneNumber,
      })
        .then((res) => {
          if (res.data.success === true) {
            alert('문자가 발송되었습니다');
            setRegisterAccountToken(res.data.registerAccountToken);
            //console.log(res.data.registerAccountToken);
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  /** 입력한 인증번호 검사 */
  const [certificationNumSuccess, setCertificationNumSuccess] = useState(false);
  const certificationNumCheck = () => {
    if (certificationNum === '') {
      alert('인증번호를 입력해주세요.');
    } else {
      confirmRegister({
        registerAccountToken: registerAccountToken,
        randomNumber: certificationNum,
      })
        .then((res) => {
          if (res.data.success === true) {
            alert('인증이 완료되었습니다.');
            setCertificationNumSuccess(true);
          } else if (res.data.success === false) {
            alert('다시 인증을 받아주세요.');
          }
        })
        .catch((error) => {
          //alert(error.response.data.message);
        });
    }
  };

  // 아이디 중복 체크
  const idExistCheck = () => {
    if (idAvailability(userId) === false) {
      alert('영문, 숫자를 포함한 6자-16자를 입력해주세요.');
    } else if (userId === '') {
      alert('아이디를 입력해주세요.');
    } else {
      checkIdExist({
        account: userId,
      })
        .then((res) => {
          if (res.data.isExists === true) {
            alert('이미 존재하는 아이디입니다.');
          } else if (res.data.isExists === false) {
            alert('사용 가능한 아이디입니다.');
          }
        })
        .catch((error) => {
          //alert(error.response.data.message);
        });
    }
  };

  // 아이디 유효성 검사
  const idAvailability = (userId) => {
    const idRegEx = /^[A-Za-z0-9+]{6,16}$/;
    //const idRegEx = /^(?=.*[a-z])|(?=.*[A-Z])(?=.*[0-9])$/g

    return idRegEx.test(userId);
  };

  // 비밀번호 유효성 검사
  const passwordCheck = (password) => {
    // 6-16자에 하나이상의 문자 및 하나의 숫자 및 하나의 특수 문자 포함된 비밀번호인지 체크
    const passwordRegEx = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,16}$/g;
    return passwordRegEx.test(password);
  };

  const emailCheck = (email) => {
    const emailRegex =
      /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;

    return emailRegex.test(email);
  };

  /** 회원가입 데이터 전송 */
  const resetRegisterAssignmentAtom = useResetRecoilState(registerAssignmentAtom);

  const clickRegisterButton = () => {
    if (
      name === '' ||
      emailCheck(firstEmail + '@' + secondEmail) === false ||
      idAvailability(userId) === false ||
      passwordCheck(userPassword) === false ||
      certificationNumSuccess === false ||
      postAddress.address === '' ||
      addressDetail === ''
    ) {
      alert('모든 칸에 값을 입력하거나 문자 인증 혹은 아이디 및 비밀번호 확인을 해주세요.');
    } else {
      registerAccount({
        registerAccountToken: registerAccountToken,
        userId: userId,
        password: userPassword,
        name: name,
        gender: gender,
        birthDay: birth,
        address: JSON.stringify({
          code: postAddress.zoneCode,
          address: postAddress.address,
          detailAddress: addressDetail,
        }),
        email: firstEmail + '@' + secondEmail,
        agreeEmail: registerAssignmentState[5],
        agreeSMS: registerAssignmentState[4],
      }).then((res) => {
        if (res.data.success === true) {
          localStorage.setItem('accessToken', res.data.accessToken);
          localStorage.setItem('refreshToken', res.data.refreshToken);
          setIsLogin(true);
          getMyInfo().then((res) => {
            setMyInfo(res.data.myInfo);
            resetRegisterAssignmentAtom();
            navigate('/register/complete');
          });
        } else if (res.data.success === false) {
          alert('회원가입에 실패했습니다.');
        }
      });
    }
  };

  return (
    <RegisterJoinPageContainer>
      <RegisterPageTitle>랩스탠다드 회원가입</RegisterPageTitle>
      <HorizonLine />
      {popup && (
        <SearchAddress
          postAddress={postAddress}
          setPostAddress={setPostAddress}
          setPopup={setPopup}
        />
      )}
      <ProgressBox
        stepIcon1={StepIcon1}
        stepIcon2={StepIcon2Selected}
        stepIcon3={StepIcon3}
        stepText1={`이용약관 동의`}
        stepText2={`회원정보 입력`}
        stepText3={`회원가입 완료`}
      >
        <RegisterContent isPasswordSame={isPasswordSame}>
          <div>
            <p>아이디</p>
            <SquareInput
              width={313}
              height={62}
              placeholder="영문, 숫자 조합 6 - 16자"
              onChangeFunc={handleUserId}
            />
            <RegisterButton
              width={130}
              height={62}
              bgColor={COLOR.BLUE_4}
              fontSize={14}
              fontWeight={300}
              onClickFunc={idExistCheck}
            >
              아이디 중복 확인
            </RegisterButton>
          </div>
          <div>
            <p>비밀번호</p>
            <SquareInput
              width={455}
              height={62}
              type="password"
              placeholder="문자, 숫자, 특수문자 조합 6 - 16자"
              onChangeFunc={handlePassword}
            />
          </div>
          <div>
            <p>비밀번호 확인</p>
            <SquareInput
              width={455}
              height={62}
              type="password"
              placeholder="문자, 숫자, 특수문자 조합 6 - 16자"
              onChangeFunc={handleUserPasswordConfirm}
            />
            <span>비밀번호가 다릅니다.</span>
          </div>
          <Border />
          <div>
            <p>이름</p>
            <SquareInput width={233} height={62} onChangeFunc={handleName} />
            <span>성별</span>
            <p>
              <RegisterButton
                width={76}
                height={62}
                bgColor={gender === '남자' ? COLOR.BLUE_4 : COLOR.GRAY_0}
                fontWeight={300}
                onClickFunc={() => genderSelect('남자')}
              >
                남자
              </RegisterButton>
            </p>
            <p>
              <RegisterButton
                width={76}
                height={62}
                bgColor={gender === '여자' ? COLOR.BLUE_4 : COLOR.GRAY_0}
                fontWeight={300}
                onClickFunc={() => genderSelect('여자')}
              >
                여자
              </RegisterButton>
            </p>
          </div>
          <div>
            <p>생년월일</p>
            <p>
              <DatePicker
                selected={birth}
                onChange={(date) => setBirth(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormat={'yyyy/MM/dd'}
                dateFormatCalendar={'yyyy년 MM월'}
                customInput={<DatePickerBirthInput />}
                locale={ko}
              />
            </p>
          </div>
          <div>
            <p>이메일</p>
            <SquareInput
              width={231}
              height={62}
              placeholder="labstandard77"
              textAlign={'center'}
              onChangeFunc={handleFirstEmail}
            />
            <span>@</span>
            <SquareInput
              width={187}
              height={62}
              placeholder="gmail.com"
              textAlign={'center'}
              onChangeFunc={handleSecondEmail}
            />
          </div>
          <div>
            <p>휴대폰</p>
            <SquareInput width={135} height={62} onChangeFunc={handleFirstPhoneNumber} />
            <span>-</span>
            <SquareInput width={135} height={62} onChangeFunc={handleSecondPhoneNumber} />
            <span>-</span>
            <SquareInput width={135} height={62} onChangeFunc={handleLastPhoneNumber} />
            <RegisterButton
              width={130}
              height={62}
              bgColor={COLOR.BLUE_4}
              fontSize={14}
              fontWeight={300}
              onClickFunc={messageRequest}
            >
              인증번호 받기
            </RegisterButton>
          </div>
          <div>
            <p>인증번호 입력</p>
            <SquareInput width={295} height={62} onChangeFunc={handleCertificationNum} />
            <RegisterButton
              width={130}
              height={62}
              bgColor={COLOR.BLUE_4}
              fontSize={14}
              fontWeight={300}
              onClickFunc={certificationNumCheck}
            >
              인증번호 확인
            </RegisterButton>
          </div>
          <Border />
          <div>
            <p>배송지 주소 입력</p>
            <SquareInput
              width={313}
              height={62}
              onChangeFunc={handleSearchAddress}
              value={postAddress.address}
            />
            <RegisterButton
              width={130}
              height={62}
              bgColor={COLOR.BLUE_4}
              fontSize={14}
              fontWeight={300}
              onClickFunc={openSearchAddressPopUp}
            >
              우편번호 검색
            </RegisterButton>
          </div>
          <div>
            <p></p>
            <SquareInput width={602} height={62} onChangeFunc={handleAddressDetail} />
          </div>
        </RegisterContent>
      </ProgressBox>
      <RegisterButton
        width={202}
        height={62}
        bgColor={COLOR.BLUE_4}
        fontSize={20}
        onClickFunc={clickRegisterButton}
      >
        가입하기
      </RegisterButton>
    </RegisterJoinPageContainer>
  );
}

export default RegisterJoinPage;
