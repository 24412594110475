import React from 'react';
import { NavContainer, CurrentLocation, ActiveTab, BorderBottom } from './style';
import { ReactComponent as ArrowRight } from '../../../assets/icons/icon-ArrowRight-black.svg';
import { Link } from 'react-router-dom';

/* 수험정보 & 팁 navbar */
const EXAM_INFO_NAV_LIST = [
  {
    name: '직렬별 수험정보',
  },
  {
    name: '학습 TIP',
    url: 'studytip',
  },
  {
    name: '합격 후기',
    url: 'passnote',
  },
];

const ExamInfoNav = ({ tab }) => {
  return (
    <>
      <NavContainer>
        <ul>
          {EXAM_INFO_NAV_LIST.map((menu, i) => (
            <Link to={menu.url || ''} key={menu.name}>
              <ActiveTab className={tab === menu.url ? 'active' : ''}>
                <div>
                  <p>{`0${i + 1}`}</p>
                  <p>{menu.name}</p>
                  <div></div>
                </div>
              </ActiveTab>
            </Link>
          ))}
        </ul>
        <CurrentLocation>
          <p>HOME</p>
          <ArrowRight />
          <p>수험정보&팁</p>
        </CurrentLocation>
      </NavContainer>
      <BorderBottom />
    </>
  );
};

/* 학습지원센터 navbar */
const SUPPORT_CENTER_NAV_LIST = [
  {
    name: '학습지원센터 홈',
  },
  {
    name: '자주 묻는 질문',
    url: 'questions',
  },
  {
    name: '1:1 문의',
    url: 'contact',
  },
  {
    name: '공지사항',
    url: 'notice',
  },
  {
    name: '자료실 & 정오표',
    url: 'errata',
  },
];

const SupportCenterNav = ({ tab }) => {
  return (
    <>
      <NavContainer>
        <ul>
          {SUPPORT_CENTER_NAV_LIST.map((menu, i) => (
            <Link to={menu.url || ''} key={menu.name}>
              <ActiveTab className={tab === menu.url ? 'active' : ''}>
                <div>
                  <p>{`0${i + 1}`}</p>
                  <p>{menu.name}</p>
                  <div></div>
                </div>
              </ActiveTab>
            </Link>
          ))}
        </ul>
        <CurrentLocation>
          <p>HOME</p>
          <ArrowRight />
          <p>학습지원센터</p>
        </CurrentLocation>
      </NavContainer>
      <BorderBottom />
    </>
  );
};

/* 온라인 서점 navbar */
const BOOK_STORE_NAV_LIST = [
  {
    name: '서점 홈',
  },
  {
    name: '전체 교재',
    url: 'entire',
  },
  {
    name: '시리즈별 교재',
    url: 'series',
  },
  {
    name: '과목별 교재',
    url: 'subject',
  },
];

const BookStoreNav = ({ tab }) => {
  return (
    <>
      <NavContainer>
        <ul>
          {BOOK_STORE_NAV_LIST.map((menu, i) => (
            <Link to={menu.url || ''} key={menu.name}>
              <ActiveTab className={tab === menu.url ? 'active' : ''}>
                <div>
                  <p>{`0${i + 1}`}</p>
                  <p>{menu.name}</p>
                  <div></div>
                </div>
              </ActiveTab>
            </Link>
          ))}
        </ul>
        <CurrentLocation>
          <p>HOME</p>
          <ArrowRight />
          <p>온라인 서점</p>
        </CurrentLocation>
      </NavContainer>
      <BorderBottom />
    </>
  );
};

/* 마이페이지 navbar */
const MY_PAGE_NAV_LIST = [
  {
    name: '홈',
  },
  {
    name: '나의 회원정보',
    url: 'myinfo',
  },
  {
    name: '주문 / 배송',
    url: 'myorder',
  },
  {
    name: '내 강의실',
    url: 'mylecture',
  },
];

const MyPageNav = ({ tab }) => {
  return (
    <>
      <NavContainer>
        <ul>
          {MY_PAGE_NAV_LIST.map((menu, i) => (
            <Link to={menu.url || ''} key={menu.name}>
              <ActiveTab className={tab === menu.url ? 'active' : ''}>
                <div>
                  <p>{`0${i + 1}`}</p>
                  <p>{menu.name}</p>
                  <div></div>
                </div>
              </ActiveTab>
            </Link>
          ))}
        </ul>
        <CurrentLocation>
          <p>HOME</p>
          <ArrowRight />
          <p>마이페이지</p>
        </CurrentLocation>
      </NavContainer>
      <BorderBottom />
    </>
  );
};

export { ExamInfoNav, SupportCenterNav, BookStoreNav, MyPageNav };
