import React, { useState } from 'react';
import {
  FindAccountContainer,
  FindAccountPageTitle,
  HorizonLine,
  SubInfoBox,
  MenuBox,
  DetailInfoBox,
  Container,
  Content,
  PassWordContainer,
  PassWordContent,
} from '../FindAccount/style';
import COLOR from '../../styles/common/color';
import { SquareInput } from '../../components/atoms/Input';
import { RegisterButton } from '../../components/atoms/Button';
import { useNavigate, Navigate } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';

import { userIdAtom, findPasswordTokenAtom } from '../../recoil/AuthStore/AuthRecoil';
import { requestFindPasswordConfirm, confirmFindPassword } from '../../axiosManage/Auth';
import FindAccountPage from '../FindAccount/FindAccountPage';

function FindPasswordCertificationPage() {
  const isLogin = useRecoilValue(isLoginAtom);
  const navigate = useNavigate();
  const userId = useRecoilValue(userIdAtom);

  if (isLogin === true) {
    alert('잘못된 접근입니다.');
    return <Navigate to="/" />;
  }

  // 전화 번호 입력
  const [firstPhoneNumber, setFirstPhoneNumber] = useState(''); // 휴대폰 번호
  const handleFirstPhoneNumber = (e) => {
    setFirstPhoneNumber(e.target.value);
  };
  const [secondPhoneNumber, setSecondPhoneNumber] = useState(''); // 휴대폰 번호
  const handleSecondPhoneNumber = (e) => {
    setSecondPhoneNumber(e.target.value);
  };
  const [lastPhoneNumber, setLastPhoneNumber] = useState(''); // 휴대폰 번호
  const handleLastPhoneNumber = (e) => {
    setLastPhoneNumber(e.target.value);
  };

  // 인증번호 입력
  const [certificationNum, setCertificationNum] = useState(''); // 휴대폰 번호
  const handleCertificationNum = (e) => {
    setCertificationNum(e.target.value);
  };

  /** 인증 문자 받기 */
  const [findPasswordToken, setFindPasswordTokenAtom] = useRecoilState(findPasswordTokenAtom);
  const messageRequest = () => {
    if (
      firstPhoneNumber === '' ||
      secondPhoneNumber === '' ||
      lastPhoneNumber === '' ||
      Number.isInteger(firstPhoneNumber) ||
      Number.isInteger(secondPhoneNumber) ||
      Number.isInteger(lastPhoneNumber)
    ) {
      alert('휴대폰 번호를 정확하게 입력해주세요.');
    } else {
      requestFindPasswordConfirm({
        phoneNumber: firstPhoneNumber + secondPhoneNumber + lastPhoneNumber,
        account: userId,
      })
        .then((res) => {
          if (res.data.success === true) {
            alert('문자가 발송되었습니다');
            setFindPasswordTokenAtom(res.data.findPasswordToken);
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  /** 입력한 인증번호 검사 */
  const [certificationNumSuccess, setCertificationNumSuccess] = useState(false);
  const certificationNumCheck = () => {
    if (certificationNum === '') {
      alert('인증번호를 입력해주세요.');
    } else {
      confirmFindPassword({
        findPasswordToken: findPasswordToken,
        randomNumber: certificationNum,
      })
        .then((res) => {
          if (res.data.success === true) {
            alert('인증이 완료되었습니다.');
            setCertificationNumSuccess(true);
          } else if (res.data.success === false) {
            alert('다시 인증을 받아주세요.');
          }
        })
        .catch((error) => {
          //alert(error.response.data.message);
        });
    }
  };

  const goToChangePasswordPage = () => {
    if (certificationNumSuccess === false) {
      alert('휴대폰 인증을 받아주세요.');
    } else {
      navigate('/findaccount/changepassword');
    }
  };

  if (userId === '') {
    alert('잘못된 접근입니다.');
    return <Navigate to="/findaccount" />;
  } else {
    return (
      <FindAccountContainer>
        <FindAccountPageTitle>
          <span>비밀번호 찾기</span>
        </FindAccountPageTitle>
        <HorizonLine />
        <SubInfoBox>
          <DetailInfoBox>
            <Container>
              <Content>
                <div>회원정보에 등록한 휴대전화로 인증</div>
                <div>*회원정보에 등록한 휴대전화 번호가 같아야 인증번호를 받을 수 있습니다.</div>
                <div>
                  <p>휴대폰</p>
                  <SquareInput width={135} height={62} onChangeFunc={handleFirstPhoneNumber} />
                  <span>-</span>
                  <SquareInput width={135} height={62} onChangeFunc={handleSecondPhoneNumber} />
                  <span>-</span>
                  <SquareInput width={135} height={62} onChangeFunc={handleLastPhoneNumber} />
                  <RegisterButton
                    width={130}
                    height={62}
                    bgColor={COLOR.BLUE_4}
                    fontSize={14}
                    fontWeight={300}
                    onClickFunc={messageRequest}
                  >
                    인증번호 받기
                  </RegisterButton>
                </div>
                <div>
                  <p>인증번호 입력</p>
                  <SquareInput width={295} height={62} onChangeFunc={handleCertificationNum} />
                  <RegisterButton
                    width={130}
                    height={62}
                    bgColor={COLOR.BLUE_4}
                    fontSize={14}
                    fontWeight={300}
                    onClickFunc={certificationNumCheck}
                  >
                    인증번호 확인
                  </RegisterButton>
                </div>
              </Content>
            </Container>
          </DetailInfoBox>
        </SubInfoBox>
        <RegisterButton
          width={202}
          height={62}
          bgColor={COLOR.BLUE_4}
          fontSize={20}
          fontWeight={300}
          onClickFunc={goToChangePasswordPage}
        >
          다음 단계로
        </RegisterButton>
      </FindAccountContainer>
    );
  }
}

export default FindPasswordCertificationPage;
