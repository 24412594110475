import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function PageNotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    alert('존재하지 않는 페이지 입니다!');
    navigate(-1);
  }, [navigate]);
}

export default PageNotFound;