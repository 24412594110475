import styled from 'styled-components';

export const ProfessorContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  width: 1920px;
  margin: 0 auto;
  padding-top: 18px;
  padding-bottom: 170px;

  @media screen and (max-width: 1700px) {
    padding-top: 18px;
    padding-bottom: 170px;
  }
`;
