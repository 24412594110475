import { useAxios } from '../../hooks/useAxios';

/** 카카오 로그인 */
export const kakaoOauthCallback = async (code) => {
  return await useAxios.get(`/auth/kakao/callback?code=${code}`);
};

/** 네이버 로그인 */
export const naverOauthCallback = async (code) => {
  return await useAxios.get(`/auth/naver/callback?code=${code}`);
};

/** 랩스탠다드 로그인 */
export const labstandardLogin = async (data) => {
  return await useAxios.post(`/auth/login`, data);
};

/** 랩스탠다드 로그인 아이디 중복 체크*/
export const checkIdExist = async (data) => {
  return await useAxios.post(`/auth/account/check`, data);
};

/** 네이버 & 카카오 회원가입 문자 인증 요청*/
export const requestRegisterSocialAccountConfirm = async (data) => {
  return await useAxios.post(`/auth/register/social/confirm/request`, data);
};

/** 랩스탠다드 회원가입 문자 인증 요청 */
export const requestRegisterLabstandardAccountConfirm = async (data) => {
  return await useAxios.post(`/auth/register/labstandard/confirm/request`, data);
};

/** 인증번호 검사 */
export const confirmRegister = async (data) => {
  return await useAxios.post(`/auth/register/confirm`, data);
};

/** 회원가입하기 */
export const registerAccount = async (data) => {
  return await useAxios.post(`/auth/register`, data);
};

/** 로그아웃 */
export const logout = async () => {
  return await useAxios.get(`/auth/logout`);
};

/** 아이디 찾기를 위한 문자 인증 요청 */
export const findIdMessageRequest = async (data) => {
  return await useAxios.post(`/auth/find/account/confirm/request`, data);
};

/** 문자 인증 받은 후 문자 인증 확인 후 ID 반환 */
export const findAccount = async (data) => {
  return await useAxios.post(`/auth/find/account`, data);
};

/** 패스워드 변경 위한 문자 인증 요청 */
export const requestFindPasswordConfirm = async (data) => {
  return await useAxios.post(`/auth/find/password/confirm/request`, data);
};

/** 패스워드 변경 위한 문자 인증 확인 */
export const confirmFindPassword = async (data) => {
  return await useAxios.post(`/auth/find/password/confirm`, data);
};

/** 패스워드 변경 요청 */
export const findPassword = async (data) => {
  return await useAxios.post(`/auth/find/password`, data);
};

/** 회원 탈퇴 */
export const userWithdrawal = async () => {
  return await useAxios.get(`/myinfo/withdrawal`);
};
