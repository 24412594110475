import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

const BooksContainer = styled.div``;

const MainBookContainer = styled.div`
  width: 512px;
  height: 243px;
  display: flex;
  align-items: center;
  margin: 69px auto 45px auto;
  padding: 35px 99px;
  background-color: ${COLOR.BLUE_5};
  border-radius: 122px;
  :hover {
    cursor: pointer;
  }
  img {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

const MainBookInfo = styled.div`
  flex: 1;
  padding-left: 14px;
  line-height: 1.3;
  p:nth-child(1) {
    ${FONT_STYLES.N_EB}
    font-size: 22px;
    padding-bottom: 19px;
  }
  p:nth-child(2) {
    ${FONT_STYLES.P_R}
    font-size: 15px;
    padding-bottom: 3px;
  }
  p:nth-child(3) {
    ${FONT_STYLES.P_M}
    font-size:20px;
  }
`;

const RemainBooksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: 1.3;
`;

export { BooksContainer, MainBookContainer, MainBookInfo, RemainBooksContainer };
