import styled, { css } from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

export const StyledFilter = styled.div`
  position: relative;

  & > div {
    display: flex;

    & > p {
      margin: 0 55px 0 10px;
    }

    & > div {
      display: flex;
      align-items: center;
    }
  }

  ${({ isHover, isPlusClicked }) =>
    isHover && !isPlusClicked
      ? css`
          width: 185px;
          background-color: ${COLOR.BLUE_4};
          color: ${COLOR.GRAY_0};
          border-radius: 33px;
          padding: 20px 0 20px 24px;
          transition: 0.3s;
        `
      : null}

  ${({ isHover, isPlusClicked }) =>
    isHover && isPlusClicked
      ? css`
          width: 185px;
          height: 173px;
          top: 60px;
          background-color: ${COLOR.BLUE_4};
          color: ${COLOR.GRAY_0};
          border-radius: 22px;
          padding: 20px 0 20px 24px;
        `
      : null}
`;

export const Drawer = styled.span`
  display: none;
  margin-top: 20px;
  border-top: 1px solid ${COLOR.BLUE_3};
  cursor: default;

  & > div {
    display: flex;
    align-items: center;
    margin: 25px 0;

    & > p {
      padding: 0 10px;
      cursor: pointer;
    }
  }

  & > button {
    position: relative;
    left: -12px;
    ${FONT_STYLES.P_R};
    border-radius: 18px;

    :hover {
      transition: none;
      background-color: ${COLOR.GRAY_0};
      color: ${COLOR.BLACK_1};
    }
  }

  ${({ isHover, isPlusClicked }) =>
    isHover && isPlusClicked
      ? css`
          display: block;
        `
      : null}
`;

export const FilterMenuBox = styled.span`
  position: absolute;
  top: 50px;
  left: -323px;
  display: none;
  width: 293px;
  min-height: 250px;
  color: ${COLOR.BLACK_1};
  background-color: ${COLOR.GRAY_0};
  filter: drop-shadow(0px 3px 20px #00000029);
  border-radius: 20px;

  :after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 30px solid transparent;
    border-left-color: ${COLOR.GRAY_0};
    border-right: 0;
    border-top: 0;
    margin-top: -100px;
    margin-right: -30px;
    filter: drop-shadow(0px 3px 20px #00000029);
  }

  & > div {
    margin: 7px 15px;
    & > li {
      list-style: none;
      padding: 12px 35px;
      cursor: pointer;

      &.selected {
        background-color: ${COLOR.BLUE_4};
        border-radius: 20px;
        color: ${COLOR.GRAY_0};
      }
    }
  }

  ${({ isHover, isPlusClicked, isLectureSelectClicked }) =>
    isHover && isPlusClicked && isLectureSelectClicked
      ? css`
          display: block;
        `
      : null}
`;
