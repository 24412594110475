import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

export const MyOrderPageContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  text-align: center;
  padding-bottom: 150px;
  padding-top: 60px;

  @media screen and (max-width: 1700px) {
    padding-top: 60px;
    padding-bottom: 150px;
  }
`;

export const MyOrderTitle = styled.div`
  ${FONT_STYLES.P_B};
  font-size: 36px;
`;

export const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_3};
  margin: 43px auto;
`;

export const OrderStateBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
  padding: 30px 85px;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      height: 90px;
      border-right: 1px solid ${COLOR.GRAY_9};
      padding: 0 50px;

      & > p {
        :nth-child(1) {
          ${FONT_STYLES.P_B}
          font-size: 36px;
          padding-bottom: 40px;
        }
        :nth-child(2),
        :nth-child(3) {
          ${FONT_STYLES.P_R};
          font-size: 18px;
          line-height: normal;
        }
      }

      &.active {
        width: 228px;
        height: 157px;
        padding: 35px 0;
        background-color: ${COLOR.BLUE_5};
        border-right: none;
      }
    }
    :nth-child(6) {
      & > div {
        border-right: none;

        & > p:nth-child(3) {
          ${FONT_STYLES.P_R};
          font-size: 14px;
          color: #716b6b;
        }
      }
    }
  }
`;

export const DurationSettingBox = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0;
  padding: 26px 29px 26px 91px;
  text-align: left;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;

  & > div {
    :nth-child(1) {
      ${FONT_STYLES.P_M}
      font-size: 24px;
      margin-right: 74px;
    }
    :nth-child(2) {
      display: flex;
      width: 475px;
      margin-right: 51px;
      box-shadow: 0px 3px 10px #00000029;
      border-radius: 7px;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        //width: 95px;
        width: 120px;
        height: 48px;
        ${FONT_STYLES.P_R}
        font-size: 18px;
        list-style: none;
        cursor: pointer;

        &.active {
          border-radius: 7px;
          background-color: ${COLOR.BLUE_4};
          color: ${COLOR.GRAY_0};
        }

        :nth-child(5) {
          margin-left: auto;
        }
      }
    }

    :nth-child(3) {
      display: flex;
      align-items: center;
      justify-content: center;

      & > svg {
        margin: 0 5px;
      }
    }
  }

  & > button {
    margin-left: 28px;
    ${FONT_STYLES.P_R}
  }
`;

export const OrderDetailBox = styled.div`
  margin: 40px 0;
  padding: 38px 91px;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;
`;

export const OrderDetailHeader = styled.div`
  display: flex;
  ${FONT_STYLES.P_SB};
  font-size: 20px;
  padding-bottom: 30px;

  & > div {
    :nth-child(1) {
      width: 30%;
      text-align: left;
    }
    :nth-child(2) {
      width: 50%;
      text-align: left;
    }
    :nth-child(3) {
      width: 15%;
      text-align: center;
    }
    :nth-child(4) {
      width: 15%;
      text-align: center;
    }
    :nth-child(5) {
      width: 10%;
      text-align: right;
    }
  }
`;

export const OrderDetailItems = styled.div``;

export const Item = styled.div`
  position: relative;
  ${FONT_STYLES.P_R}
  display: flex;
  align-items: center;
  padding: 30px 0;
  border-top: 1px solid ${COLOR.GRAY_9};
  cursor: pointer;

  & > div {
    :nth-child(1) {
      width: 30%;
      text-align: left;
    }
    :nth-child(2) {
      width: 50%;
      text-align: left;
    }
    :nth-child(3) {
      width: 15%;
      text-align: center;
    }
    :nth-child(4) {
      width: 15%;
      text-align: center;
    }
    :nth-child(5) {
      width: 10%;
      text-align: right;
    }
  }

  & > button {
    position: absolute;
    right: -70px;
  }
`;
