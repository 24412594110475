import React, { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import {
  RegisterCompletePageContainer,
  RegisterPageTitle,
  HorizonLine,
  RegisterContent,
} from './style';
import COLOR from '../../styles/common/color';
import { Button } from '../../components/atoms/Button';

import ProgressBox from '../../components/molecules/ProgressBox';

import StepIcon1 from '../../assets/icons/icon-assignment.png';
import StepIcon2 from '../../assets/icons/icon-join.png';
import StepIcon3Selected from '../../assets/icons/icon-complete-selected.png';

import { ReactComponent as CompleteCheckIcon } from '../../assets/icons/icon-register-complete.svg';
import CompleteTextImg from '../../assets/images/image-register-complete.png';

import { useRecoilValue } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';

import { myInfoAtom } from '../../recoil/MyPageStore/MyPageRecoil';

function RegisterCompletePage() {
  const navigate = useNavigate();
  const isLogin = useRecoilValue(isLoginAtom);
  const myInfo = useRecoilValue(myInfoAtom);

  if (isLogin === false) {
    alert('잘못된 접근입니다.');
    return <Navigate to="/" />;
  }

  const goToMainPage = () => {
    navigate('/');
  };

  return (
    <RegisterCompletePageContainer>
      <RegisterPageTitle>랩스탠다드 회원가입</RegisterPageTitle>
      <HorizonLine />
      <ProgressBox
        stepIcon1={StepIcon1}
        stepIcon2={StepIcon2}
        stepIcon3={StepIcon3Selected}
        stepText1={`이용약관 동의`}
        stepText2={`회원정보 입력`}
        stepText3={`회원가입 완료`}
      >
        <RegisterContent>
          <CompleteCheckIcon />
          <p>
            <img src={CompleteTextImg} />
          </p>
          <p>랩스탠다드 회원가입 완료</p>
          <p>소중한 회원이 되신 {myInfo.name}님, 반가워요!</p>
          <p>랩스탠다드와 함께 날아오를 준비 되셨나요?</p>
        </RegisterContent>
      </ProgressBox>
      <Button
        width={202}
        height={62}
        bgColor={COLOR.BLUE_4}
        fontSize={20}
        onClickFunc={goToMainPage}
      >
        메인페이지로 이동
      </Button>
    </RegisterCompletePageContainer>
  );
}

export default RegisterCompletePage;
