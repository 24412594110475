import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #716b6b;
`;

const PageList = styled.div`
  ${FONT_STYLES.P_EB}
  margin: 0 15px;
  border-radius: 5px;
`;

const PageNum = styled.div`
  cursor: pointer;

  &.active {
    width: 24px;
    height: 24px;
    color: white;
    background-color: ${COLOR.BLUE_4};
    border-radius: 50%;
    padding-top: 3px;
  }
`;

export { PageContainer, PageList, PageNum };
