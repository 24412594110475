import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

export const LectureReviewCardContainer = styled.div`
  width: 424px;
  height: 369px;
  padding: 41px 37px 0px 40px;
`;

export const UserNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 19px;
  border-bottom: 1px solid ${COLOR.BLACK_0};

  p:nth-child(1) {
    ${FONT_STYLES.N_R}
    font-size: 16px;
  }

  p:nth-child(2) {
    ${FONT_STYLES.N_B}
    font-size: 18px;
    color: ${COLOR.BLUE_3};
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2; // 원하는 라인수
    -webkit-box-orient: vertical
  }
`;

export const Contents = styled.div`
  width: 100%;
  padding-top: 39px;
  text-align: center;

  p:nth-child(1) {
    ${FONT_STYLES.N_R}
    width: 100%;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-break: keep-all;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 35px;
    text-align: left;
  }
`;

export const Writer = styled.div`
  display: flex;
  justify-content: space-between;
  div:nth-child(2) {

  }

`