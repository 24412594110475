import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Pagination from '../../../molecules/Pagination';
import {
  ListContainer,
  ListTitle,
  ButtonBox,
  HorizonLine,
  ListTable,
  DataNotFound,
  ListData,
  ListDataInfo,
  PaginationBox,
} from '../common/list-style';

import { ReactComponent as PrevBtn } from '../../../../assets/icons/icon-PrevBtnFull.svg';
import { ReactComponent as NextBtn } from '../../../../assets/icons/icon-NextBtnFull.svg';
import { ReactComponent as Bell } from '../../../../assets/icons/icon-bell.svg';
import Filter from '../../../molecules/Filter';
import Search from '../../../molecules/Search';

import NoticeDetailPage from '../NoticeDetail';
import { SpinLoader, typeList } from '../../../atoms/SpinLoader';
import { useChangeMonthNumToEn } from '../../../../hooks/useChangeMonthNumToEn';

import { useRecoilValue, useRecoilState } from 'recoil';
import {
  supportCenterNoticePageInfoAtom,
  supportCenterNoticeListAtom,
} from '../../../../recoil/SupportCenterStore/SupportCenterRecoil';
import { selectedNoticeFilterMenuAtom } from '../../../../recoil/SelectedFilterStore/SelectedFilterRecoil';

import { useQuery } from 'react-query';
import useHandleError from '../../../../hooks/useHandleError';
import {
  getNoticePageInfo,
  getNotice,
  getTopFixEducationHelpCenterNoticeList,
} from '../../../../axiosManage/SupportCenter';

const FILTER_MENU = [
  {
    id: 0,
    category: '강의',
  },
  {
    id: 1,
    category: '교재',
  },
  {
    id: 2,
    category: '이벤트',
  },
  {
    id: 3,
    category: '소식',
  },
  {
    id: 4,
    category: '학습',
  },
  {
    id: 5,
    category: '모집',
  },
  {
    id: 6,
    category: '개강',
  },
  {
    id: 7,
    category: '일반',
  },
  {
    id: 8,
    category: '운영',
  },
  {
    id: 9,
    category: '기타',
  },
];

function Notice() {
  const { handleError } = useHandleError();
  const { id } = useParams();
  const navigate = useNavigate();

  /** 정오표 상단 고정 게시글 */
  const [supportCenterTopNoticeList, setSupportCenterTopNoticeList] = useState([]);
  const { refetch: supportCenterTopErrataListRefetch } = useQuery(
    'getTopFixEducationHelpCenterNoticeList',
    getTopFixEducationHelpCenterNoticeList,
    {
      retry: 0,
      onSuccess: (data) => {
        setSupportCenterTopNoticeList(data.data.educationHelpCenterNoticeList);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  /* 페이지네이션 */
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(6);

  const clickPrevBtn = (e) => {
    setCurrentPage(currentPage - 1);
  };
  const clickNextBtn = (e) => {
    setCurrentPage(currentPage + 1);
  };

  /** 필터링 & 검색 기능 */
  const [keyword, setKeyword] = useState('');
  const [isSearchBtnClicked, setIsSearchBtnClicked] = useState(false);
  const keywordCheck = (e) => {
    setKeyword(e.currentTarget.value);
  };

  // 1. 유저가 선택한 필터링 카테고리 목록
  const selectedNoticeFilterCategory = useRecoilValue(selectedNoticeFilterMenuAtom);

  // 2. 서버에서 받아온 페이징 정보를 전역 상태에 저장
  const [supportCenterNoticePageInfo, setSupportCenterNoticePageInfo] = useRecoilState(
    supportCenterNoticePageInfoAtom,
  );
  const { refetch: supportCenterNoticePageInfoRefetch } = useQuery(
    ['getNoticePageInfo', selectedNoticeFilterCategory],
    () => getNoticePageInfo(keyword, String([...selectedNoticeFilterCategory]).replace(/,/g, ' ')),
    {
      retry: 0,
      onSuccess: (data) => {
        setSupportCenterNoticePageInfo(data.data.total);
        if (isSearchBtnClicked) {
          setIsSearchBtnClicked(false);
        }
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  useEffect(() => {
    supportCenterNoticePageInfoRefetch();
    setCurrentPage(1);
  }, [isSearchBtnClicked]);

  // 3. 서버에서 받아온 실제 데이터를 전역 상태에 저장
  const [supportCenterNoticeList, setSupportCenterNoticeList] = useRecoilState(
    supportCenterNoticeListAtom,
  );
  const { refetch: supportCenterNoticeListRefetch } = useQuery(
    ['getNotice', selectedNoticeFilterCategory, currentPage],
    () =>
      getNotice(keyword, String([...selectedNoticeFilterCategory]).replace(/,/g, ' '), currentPage),
    {
      retry: 0,
      enabled: false,
      onSuccess: (data) => {
        setSupportCenterNoticeList(data.data.educationHelpCenterNoticeList);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  useEffect(() => {
    supportCenterNoticeListRefetch();
    supportCenterTopErrataListRefetch();
  }, [selectedNoticeFilterCategory, isSearchBtnClicked, currentPage, navigate]);

  // 상세페이지 -> 이전페이지로 이동해도 페이지 유지
  useEffect(() => {
    if (navigate) {
      setCurrentPage(currentPage);
    }
  }, [navigate]);

  return (
    <>
      {id ? (
        <NoticeDetailPage />
      ) : (
        <ListContainer>
          <ListTitle>공지사항</ListTitle>
          <ButtonBox>
            <Filter
              filterMenu={FILTER_MENU}
              filterName={'카테고리'}
              filterLocation={'학습지원센터 공지사항'}
              setCurrentPage={setCurrentPage}
            />
            <Search keywordCheck={keywordCheck} setIsSearchBtnClicked={setIsSearchBtnClicked} />
          </ButtonBox>
          <HorizonLine />
          <ListTable>
            {supportCenterTopNoticeList !== undefined ? (
              <>
                {supportCenterTopNoticeList.map((data, i) => (
                  <ListData
                    key={data.id}
                    onClick={(e) => (navigate(`/supportcenter/notice/${data.id}`))}
                  >
                    <div>
                      <Bell />
                    </div>
                    <div>
                      <p>{useChangeMonthNumToEn(data.createdAt?.slice(5, 7))}</p>
                      <p>{data.createdAt?.slice(8, 10)}</p>
                      <p>{data.createdAt?.slice(0, 4)}</p>
                    </div>
                    <div>
                      <p>{data.category}</p>
                      <p>{data.title}</p>
                    </div>
                    <ListDataInfo>
                      <div>
                        <p>LAB</p>
                        <p>STANDARD</p>
                      </div>
                      <div>
                        <p>조회</p>
                        {data.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </div>
                    </ListDataInfo>
                  </ListData>
                ))}
              </>
            ) : (
              ''
            )}
            {supportCenterNoticeList !== undefined ? (
              supportCenterNoticeList.length < 1 ? (
                <DataNotFound>
                  <p>게시글이 존재하지 않습니다!</p>
                </DataNotFound>
              ) : (
                <>
                  {supportCenterNoticeList.map((data, i) => (
                    <ListData
                      key={data.id}
                      onClick={(e) => (navigate(`/supportcenter/notice/${data.id}`))}
                    >
                      <div>{i + 1}</div>
                      <div>
                        <p>{useChangeMonthNumToEn(data.createdAt.slice(5, 7))}</p>
                        <p>{data.createdAt.slice(8, 10)}</p>
                        <p>{data.createdAt.slice(0, 4)}</p>
                      </div>
                      <div>
                        <p>{data.category}</p>
                        <p>{data.title}</p>
                      </div>
                      <ListDataInfo>
                        <div>
                          <p>LAB</p>
                          <p>STANDARD</p>
                        </div>
                        <div>
                          <p>조회</p>
                          {data.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                      </ListDataInfo>
                    </ListData>
                  ))}
                  <PaginationBox>
                    <PrevBtn
                      className="prev-btn"
                      onClick={(e) => {
                        if (currentPage === 1) {
                          e.preventDefault();
                        } else {
                          clickPrevBtn();
                        }
                      }}
                    />
                    <Pagination
                      currentPage={currentPage}
                      postsPerPage={postsPerPage}
                      totalPosts={supportCenterNoticePageInfo}
                      paginate={setCurrentPage}
                    />
                    <NextBtn
                      className="next-btn"
                      onClick={(e) => {
                        if (currentPage === Math.ceil(supportCenterNoticePageInfo / postsPerPage)) {
                          e.preventDefault();
                        } else {
                          clickNextBtn();
                        }
                      }}
                    />
                  </PaginationBox>
                </>
              )
            ) : (
              <SpinLoader type={typeList[2].prop} color={'#000'} width={1311} height={715} />
            )}
          </ListTable>
        </ListContainer>
      )
      }
    </>
  );
}

export default Notice;
