import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

export const ProfessorCardContainer = styled.div`
  width: 335px;
  height: 347px;
  margin-top: 30px;
  background-image: url(${({ bgImg }) => bgImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right;
  border-radius: 21px;
  padding: 35px;
  margin-left: 10px;
  color: ${COLOR.GRAY_0};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  :hover {
    cursor: pointer;
  }
`;

export const Line = styled.div`
  width: 28px;
  border: 1px solid ${COLOR.GRAY_0};
  background-color: ${COLOR.GRAY_0};
  margin-bottom: 30px;
`;

export const ProfessorName = styled.p`
  ${FONT_STYLES.P_B}
  font-size: 30px;
  margin-bottom: 21px;
`;

export const Title = styled.p`
  ${FONT_STYLES.P_R}
  font-size: 20px;
  white-space: pre;
  line-height: 24px;
`;
