import { useAxios } from '../../hooks/useAxios';

/** 내가 가진 패스 목록 */
export const getPassListOfLecture = async (lectureInfoId) => {
  return await useAxios.get(`/pass/lecture/${lectureInfoId}`);
};

/** 패스 사용하기 */
export const usePass = async (data) => {
  return await useAxios.post(`/pass`, data);
};
