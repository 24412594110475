import React, { useState, useRef } from 'react';
import { ContactContainer, ContactTitle, FormContainer, ButtonContainer } from './style';

import { useMutation } from 'react-query';
import { addOneOnOneInquiry } from '../../../../axiosManage/SupportCenter';

import Input from '../../../atoms/Input';
import { Button, CancelButton } from '../../../atoms/Button/index';
import ToastEditor from '../../../atoms/ToastTextEditor';
import Select from '../../../atoms/Select';
import FileUploader from '../../../atoms/FileUploader';
import COLOR from '../../../../styles/common/color';

import useHandleError from '../../../../hooks/useHandleError';

import { useRecoilValue } from 'recoil';
import { isLoginAtom } from '../../../../recoil/AuthStore/AuthRecoil';
import { myInfoAtom } from '../../../../recoil/MyPageStore/MyPageRecoil';

import { useNavigate, Navigate } from 'react-router-dom';

const SELECT_OPTIONS = [
  { value: '', optionName: '구분' },
  { value: 'PARCEL', optionName: '배송' },
  { value: 'PAYMENT', optionName: '결제/환불' },
  { value: 'VIDEO', optionName: '동영상' },
  { value: 'WEBPAGE', optionName: '사이트 이용' },
  { value: 'MOBILE', optionName: '모바일' },
  { value: 'BOOK', optionName: '교재' },
];

function Contact() {
  const { handleError } = useHandleError();
  const editorRef = useRef();
  const navigate = useNavigate();

  const myInfo = useRecoilValue(myInfoAtom);
  const isLogin = useRecoilValue(isLoginAtom);

  if (isLogin === false) {
    alert('로그인을 해주세요.');
    return <Navigate to="/login" />;
  }

  // 유저가 입력한 서버로 전송할 데이터(제목, 내용, 카테고리)
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('구분');

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  /** 1:1 문의 글 전송 로직 */
  const postInquiry = useMutation(
    (data) => {
      addOneOnOneInquiry(data);
    },
    {
      onSuccess: () => {
        alert('문의 등록이 완료되었습니다!');
        navigate('/mypage');
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const inquirySubmit = () => {
    const data = {
      title: title,
      content: editorRef.current?.getInstance().getHTML(),
      category: category,
    };

    if (
      data.title !== '' &&
      !data.content.includes('위에 구분을 먼저 선택 후 글을 작성해주시기 바랍니다!') &&
      data.category !== '구분'
    ) {
      postInquiry.mutate(data);
    } else {
      alert('제목, 내용, 카테고리를 모두 입력해주세요!');
    }
  };

  return (
    <ContactContainer>
      <ContactTitle>
        1:1 문의
        <p>해결되지 않는 어려움이 있으신가요?</p>
      </ContactTitle>
      <FormContainer>
        <div>
          <Select
            width={313}
            height={58}
            selectOptions={SELECT_OPTIONS}
            onChangeFunc={handleCategoryChange}
          />
          <Input
            width={645}
            height={58}
            placeholder="제목을 입력하세요."
            onChangeFunc={handleTitleChange}
          />
          {isLogin ? (
            <div>이름: {myInfo.name}</div>
          ) : (
            <Input width={313} height={58} placeholder="이름을 입력하세요." />
          )}
        </div>
        <ToastEditor editorRef={editorRef} />
        <div>
          {/* <FileUploader /> */}
          <ButtonContainer>
            {/* <CancelButton width={124} height={48}>
              취소
            </CancelButton> */}
            <Button width={124} height={48} bgColor={COLOR.BLUE_4} onClickFunc={inquirySubmit}>
              제출
            </Button>
          </ButtonContainer>
        </div>
      </FormContainer>
    </ContactContainer>
  );
}

export default Contact;
