import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

const FooterContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  position: relative;
  width: 100%;
  height: 166px;
  left: 0;
  bottom: 0;
  background-color: ${COLOR.BLUE_4};
  display: flex;

  /* @media screen and (max-width: 1700px) {
    padding: 15px 0px 30px 300px;
  } */

  @media screen and (min-width: 1700px) {
    padding: 15px 0px 30px 0px;
  }

  @media screen and (max-width: 1700px) {
    padding: 15px 0px 30px 0px;
  }
`;

const ContentsContainer = styled.div`
  width: 1310px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`;

const TextContainer = styled.div`
  width: 695px;
  text-align: center;
  color: ${COLOR.GRAY_0};
  margin-right: 154px;
  p:nth-child(1) {
    ${FONT_STYLES.P_EB}
    font-size: 18px;
    padding-bottom: 13px;
    span:nth-child(2) {
      margin-left: 71px;
    }

    & > span {
      cursor: pointer;
    }
  }

  p:nth-child(2) {
    ${FONT_STYLES.P_L}
    width: 695px;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    padding-bottom: 4px;
  }

  p:nth-child(3) {
    ${FONT_STYLES.P_L}
    width: 695px;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    padding-bottom: 4px;
    span:nth-child(1) {
      padding-left: 5px;
    }
  }

  p:nth-child(4) {
    ${FONT_STYLES.P_L}
    width: 695px;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    padding-bottom: 15px;
    span:nth-child(1) {
      padding-left: 5px;
    }
  }

  p:nth-child(5) {
    ${FONT_STYLES.P_R}
    font-size: 18px;
  }
`;

export { FooterContainer, TextContainer, ContentsContainer };
