import React from 'react';

const AssignmentContent = ({ content }) => {
  function createMarkup(data) {
    return { __html: data?.replace(/\n/gi, '<br/>') };
  }

  return (
    <div
      dangerouslySetInnerHTML={createMarkup(content)}
      style={{ lineHeight: '1.5', textAlign: 'left' }}
    />
  );
};

export default AssignmentContent;
