import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ExamInfoNav } from '../../components/molecules/SubNavBar';
import ExamInfo from '../../components/organisms/ExamInfoPageElements/ExamInfo';
import StudyTip from '../../components/organisms/ExamInfoPageElements/StudyTip';
import PassNote from '../../components/organisms/ExamInfoPageElements/PassNote';

import PageNotFound from '../../hooks/pageNotFound';

import { ExamInfoPageContainer } from './style';
import Banner from '../../components/organisms/ExamInfoPageElements/Banner';

import { useResetRecoilState } from 'recoil';
import { selectedPassNoteFilterMenuNumAtom } from '../../recoil/SelectedFilterStore/SelectedFilterRecoil';

import PassReviewCreatePage from '../../pages/PassReviewCreate/PassReviewCreatePage';

function ExamInfoPage() {
  const { tab } = useParams();
  const tabs = ['studytip', 'passnote', 'passReviewCreate', undefined];

  if (!tabs.includes(tab)) {
    return <PageNotFound />;
  }

  /** 다른 페이지 갔다오면 각 탭의 필터링 초기화 하는 로직 */
  const resetPassNoteFilter = useResetRecoilState(selectedPassNoteFilterMenuNumAtom);

  useEffect(() => {
    return () => {
      resetPassNoteFilter();
    };
  }, [tab]);

  const renderPage = () => {
    switch (tab) {
      case 'studytip':
        return <StudyTip />;
      case 'passnote':
        return <PassNote />;
      case 'passReviewCreate':
        return <PassReviewCreatePage />;
      default:
        return <ExamInfo />;
    }
  };

  const renderBanner = () => {
    return <Banner />;
  };

  return (
    <ExamInfoPageContainer>
      {renderBanner()}
      <ExamInfoNav tab={tab} />
      {renderPage()}
    </ExamInfoPageContainer>
  );
}

export default ExamInfoPage;
