import React, { useState, useEffect } from 'react';
import { Button } from '../../../atoms/Button';
import {
  HorizonLine,
  BookStoreContainer,
  BookStoreCategoryTitle,
  BookStoreSubMenuBar,
  BookListContainer,
  ButtonContainer,
} from '../common/bookstore-list-style';
import COLOR from '../../../../styles/common/color';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/icon-Filter.svg';
import { getSeriesBookList } from '../../../../axiosManage/BookStore';
import useHandleError from '../../../../hooks/useHandleError';

import { useRecoilValue, useRecoilState } from 'recoil';
import {
  myOrderBasketListAtom,
  myOrderAtom,
} from '../../../../recoil/OrderBasketStore/OrderBasketRecoil';
import { isLoginAtom } from '../../../../recoil/AuthStore/AuthRecoil';

function SeriesBook() {
  const { handleError } = useHandleError();
  const isLogin = useRecoilValue(isLoginAtom);

  const [seriesBookList, setSeriesBookList] = useState([]);
  const [currentBook, setCurrentBook] = useState([]);
  useEffect(() => {
    getSeriesBookList()
      .then((res) => {
        setSeriesBookList(res.data.seriseBookList);
        setCurrentBook(res.data.seriseBookList[0]);
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  const NAV_LIST = seriesBookList.map((item) => ({
    id: item.id,
    name: item.name,
  }));

  /* category select */
  const [currentCategory, setCurrentCategory] = useState(1);
  const clickCategory = (id) => {
    setCurrentCategory(id);
    const currentBook = seriesBookList.find((item) => item.id === id);
    setCurrentBook(currentBook);
  };

  // 장바구니 버튼 클릭 했을 때
  const [selectedProductList, setSelectedProductList] = useRecoilState(myOrderBasketListAtom);
  const clickOrderBasketButton = (seriesBookList) => {
    let bookList = [];

    if (isLogin === false) {
      alert('로그인을 해주세요.');
    }

    if (seriesBookList.length < 1) {
      alert('담을 상품이 없습니다.');
    } else {
      seriesBookList.forEach((book) => {
        bookList.push([book.book, 1]);
      });

      setSelectedProductList(bookList);
      alert('장바구니에 상품이 담겼습니다.');
    }
  };

  return (
    <BookStoreContainer>
      <BookStoreCategoryTitle>시리즈별 교재</BookStoreCategoryTitle>
      <HorizonLine />
      {/* 서브 카테고리 선택 바 */}
      <BookStoreSubMenuBar>
        <li>
          {/* <MenuBtn /> */}
          <FilterIcon />
        </li>
        {NAV_LIST.map((menu) => (
          <div key={menu.id}>
            <li
              className={menu.id === currentCategory ? 'active' : ''}
              onClick={() => clickCategory(menu.id)}
            >
              {menu.name}
            </li>
          </div>
        ))}
      </BookStoreSubMenuBar>
      <BookListContainer>
        {currentBook && <img src={currentBook.introductionImg} />}
      </BookListContainer>
      <ButtonContainer>
        <Button
          width={200}
          height={62}
          bgColor={COLOR.BLUE_4}
          onClickFunc={() => {
            clickOrderBasketButton(currentBook.seriseBookSettings);
          }}
        >
          장바구니에 담기
        </Button>
        {/* <Button
          width={124}
          height={48}
          onClickFunc={() => clickOrderButton(currentBook.seriseBookSettings)}
        >
          구매하기
        </Button> */}
      </ButtonContainer>
    </BookStoreContainer>
  );
}

export default SeriesBook;
