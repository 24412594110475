import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

export const PaymentCancelContainer = styled.div`
  padding-top: 30px;
  text-align: center;
  button {
    ${FONT_STYLES.P_R}
    font-size: 18px;
  }
`;

export const TextInputBox = styled.div`
  margin-top: 30px;
  padding: 20px;

  textarea {
    width: 100%;
    height: 300px;
    resize: none;
    border: 1px solid ${COLOR.GRAY_8};
    :focus {
      outline: none;
    }
  }
`;
