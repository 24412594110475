import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

export const RegularPassPageContainer = styled.div`
  ${({ theme }) => theme.container.mainContainer}
  padding-bottom: 123px;
`;

export const ContentContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-top: 80px;

  & > img {
    width: 100%;
  }

  @media screen and (max-width: 1700px) {
    padding-top: 80px;
  }
`;

export const TabTitle = styled.p`
  ${FONT_STYLES.P_EB}
  text-align: center;
  font-size: 40px;
`;

export const Line = styled.p`
  width: 43px;
  border: 1.5px solid ${COLOR.BLUE_3};
  background-color: ${COLOR.BLUE_3};
  margin: 0 auto;
  margin-top: 39px;
`;

// export const ButtonContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-item: center;
//   margin-left: 30px;
//   margin-top: 40px;

//   button {
//     &:nth-child(1) {
//       margin-right: 17px;
//     }
//   }
// `;

export const InfoBox = styled.div`
  width: 100%;
  margin-top: 50px;
  padding: 50px 70px 20px 70px;

  & > div {
    :nth-child(1) {
      ${FONT_STYLES.P_SB};
      font-size: 36px;
      margin-bottom: 30px;
    }

    :nth-child(3) {
      line-height: 1.5;
      width: 100%;
      border-radius: 7px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      margin-bottom: 30px;
      padding: 20px;
      color: #716b6b;
    }
  }
`;

export const BuyBox = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLOR.BLUE_5};
  height: 100px;
  margin-bottom: 30px;

  & > div {
    :nth-child(1) {
      margin-left: 20px;

      & > span {
        margin-left: 5px;
      }
    }
  }

  & > button {
    margin-left: auto;
    background-color: ${COLOR.BLUE_4};
    color: white;
    ${FONT_STYLES.P_M};
    font-size: 20px;
    width: 200px;
    height: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-left: 30px;
  margin-top: 20px;

  button {
    &:nth-child(1) {
      margin-right: 17px;
    }
  }
`;

export const RefundBox = styled.div`
  margin: 40px 0;
  white-space: pre-line;
  color: #716b6b;
`;
