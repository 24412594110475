import styled from 'styled-components';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const BannerContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  position: relative;
  width: 100%;
  height: 554px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${({ backgroundImg }) => backgroundImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const BannerTextContainer = styled.div`
  p:nth-child(1) {
    ${FONT_STYLES.P_EB}
    font-size: 52px;
    line-height: 63px;
    padding-bottom: 20px;
  }
  p:nth-child(2) {
    ${FONT_STYLES.P_M}
    font-size: 20px;
    line-height: 24px;
  }
  color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export { BannerContainer, BannerTextContainer };