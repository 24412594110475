import { atom } from 'recoil';

/** 수험정보&팁 - 직렬별 수험정보*/
export const examInfoExamPageInfoAtom = atom({
  key: 'examInfoExamPageInfoAtom',
  default: [],
});

/** 수험정보&팁 - 학습 팁 페이징 정보(ex. 필터링된, 검색된) */
export const examInfoStudyTipPageInfoAtom = atom({
  key: 'examInfoStudyTipPageInfoAtom',
  default: [],
});

/** 수험정보&팁 - 학습 팁 데이터 리스트(ex. 필터링된, 검색된) */
export const examInfoStudyTipListAtom = atom({
  key: 'examInfoStudyTipListAtom',
  default: [],
});

/** 수험정보&팁 - 합격후기 페이징 정보(ex. 필터링된, 검색된) */
export const examInfoPassNotePageInfoAtom = atom({
  key: 'supportCenterNoticePageInfoAtom',
  default: [],
});

/** 수험정보&팁 - 합격후기 데이터 리스트(ex. 필터링된, 검색된) */
export const examInfoPassNoteListAtom = atom({
  key: 'supportCenterNoticeListAtom',
  default: [],
});
