import React, { useState } from 'react';
import {
  QuestionDetailBox,
  BottomPadding,
  SmallQuestionDetailBox,
  VerySmallQuestionDetailBox,
} from './style';
import ToastViewer from '../../../atoms/ToastTextViewer';

export function QuestionsDetail({ openDetailContent }) {
  return (
    <>
      <QuestionDetailBox>
        <ToastViewer content={openDetailContent} />
      </QuestionDetailBox>
      <BottomPadding />
    </>
  );
}

export const SmallQuestionDetail = ({ openDetailContent }) => {
  return (
    <SmallQuestionDetailBox>
      <ToastViewer content={openDetailContent} />
    </SmallQuestionDetailBox>
  );
};

export const VerySmallQuestionDetail = ({ openDetailContent }) => {
  return (
    <VerySmallQuestionDetailBox>
      <ToastViewer content={openDetailContent} />
    </VerySmallQuestionDetailBox>
  );
};
