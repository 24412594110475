import styled, { css } from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

export const StyledSearch = styled.div`
  position: relative;

  & > div {
    width: 52px;
  }

  ${({ isHover }) =>
    isHover
      ? css`
          width: 52px;
          height: 52px;
          border-radius: 50%;
          background-color: ${COLOR.BLUE_4};
          transition: 0.3s;

          & > div {
            & > img {
              cursor: pointer;
              margin-top: 15px;
            }
            & > svg {
              margin-top: 20px;
            }
          }
        `
      : null}
`;

export const SearchInputBox = styled.span`
  display: none;
  position: absolute;
  left: -435px;
  width: 410px;
  height: 125px;
  padding: 21px 32px;
  background: #ffffff;
  border-radius: 20px;
  filter: drop-shadow(0px 3px 20px #00000029);

  :after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 25px solid transparent;
    filter: drop-shadow(0px 3px 20px #00000029);
    border-left-color: #ffffff;
    border-right: 0;
    border-top: 0;
    margin-top: -40px;
    margin-right: -25px;
  }

  & > p {
    ${FONT_STYLES.P_B}
    font-size: 20px;
    text-align: left;
  }

  & > div {
    width: 100%;

    & > svg {
      width: 20px;
      position: relative;
      left: -20px;
      margin-top: 27px;
      cursor: pointer;
    }

    & > input {
      margin-top: 27px;
    }
  }

  ${({ isHover, isSearchClicked }) =>
    isHover && isSearchClicked
      ? css`
          display: block;
        `
      : null}
`;
