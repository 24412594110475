import React from 'react';
import { CardContainer, ContentContainer, SmallTitle, TitleContainer } from './style';

const ReviewCard = ({ nickname, keyword, title, content, onClickFunc }) => {
  return (
    <CardContainer onClick={onClickFunc}>
      <TitleContainer>
        <p></p>
        <p>{keyword}</p>
      </TitleContainer>
      {/* <ContentContainer>{content}</ContentContainer> */}
      <SmallTitle>{title}</SmallTitle>
    </CardContainer>
  );
};

export default ReviewCard;
