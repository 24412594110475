import React, { useState, useEffect } from 'react';
import { QuestionsDetail } from '../QuestionsDetail';
import Pagination from '../../../molecules/Pagination';
import {
  QuestionsContainer,
  QuestionsTitle,
  QuestionsCategoryBox,
  QuestionsListBox,
  ActiveTab,
  PaginationBox,
} from './style';

import { SpinLoader, typeList } from '../../../atoms/SpinLoader';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/icon-ArrowDown.svg';
import { ReactComponent as PrevBtn } from '../../../../assets/icons/icon-PrevBtnFull.svg';
import { ReactComponent as NextBtn } from '../../../../assets/icons/icon-NextBtnFull.svg';
import Parcel from '../../../../assets/images/supportcenter-delivery.png';
import Payment from '../../../../assets/images/supportcenter-pay.png';
import Video from '../../../../assets/images/supportcenter-movie.png';
import WebSite from '../../../../assets/images/supportcenter-site.png';
import Mobile from '../../../../assets/images/supportcenter-mobile.png';

import { useFiltering } from '../../../../hooks/useFiltering';

const NAV_LIST = [
  { id: 1, icon: Parcel, name: '배송' },
  { id: 2, icon: Payment, name: '결제/환불' },
  { id: 3, icon: Video, name: '동영상' },
  { id: 4, icon: WebSite, name: '사이트 이용' },
  { id: 5, icon: Mobile, name: '모바일' },
];

function Questions({ frequentQuestion, frequentQuestionDataState }) {
  /** 카테고리 선택 로직*/
  const [currentCategory, setCurrentCategory] = useState('배송');
  const [selectedData, setSelectedData] = useState([]);

  const clickCategory = (name) => {
    setCurrentCategory(name);
  };

  useEffect(() => {
    setSelectedData(useFiltering(frequentQuestion, '배송'));
  }, [frequentQuestion]);

  useEffect(() => {
    if (currentCategory === '배송') {
      setSelectedData(useFiltering(frequentQuestion, '배송'));
    } else if (currentCategory === '결제/환불') {
      setSelectedData(useFiltering(frequentQuestion, '결제/환불'));
    } else if (currentCategory === '동영상') {
      setSelectedData(useFiltering(frequentQuestion, '동영상'));
    } else if (currentCategory === '사이트 이용') {
      setSelectedData(useFiltering(frequentQuestion, '사이트 이용'));
    } else if (currentCategory === '모바일') {
      setSelectedData(useFiltering(frequentQuestion, '모바일'));
    }
  }, [currentCategory]);

  /** 페이징 로직 */
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [postsPerPage, setPostsPerPage] = useState(5); // 한 페이지에서 보여줄 post의 수
  const [currentPageData, setCurrentPageData] = useState([]);

  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  const clickPrevBtn = (e) => {
    setCurrentPage(currentPage - 1);
  };
  const clickNextBtn = (e) => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    if (selectedData !== undefined) {
      const currentPosts = selectedData.slice(indexOfFirst, indexOfLast);
      setCurrentPageData(currentPosts);
    }
  }, [selectedData, currentCategory, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [currentCategory]);

  /** 질문에 대한 상세 페이지 보여주기 */
  const [openDetailID, setOpenDetailID] = useState(0);
  const [showSelectedDetail, setShowSelectedDetail] = useState(
    Array(selectedData.length).fill(false),
  );

  const openDetail = (id) => {
    setOpenDetailID(id);
    if (showSelectedDetail[id] === true) {
      const newArr = [...showSelectedDetail];
      newArr[id] = false;
      setShowSelectedDetail(newArr);
    } else {
      const newArr = Array(selectedData.length).fill(false);
      newArr[id] = true;
      setShowSelectedDetail(newArr);
    }
  };

  useEffect(() => {
    setShowSelectedDetail(Array(selectedData.length).fill(false));
  }, [currentCategory, currentPage]);

  return (
    <QuestionsContainer>
      <QuestionsTitle>자주 묻는 질문</QuestionsTitle>
      <QuestionsCategoryBox>
        {NAV_LIST.map((icon, i) => (
          <ActiveTab
            key={icon.name}
            className={icon.name === currentCategory ? 'active' : ''}
            onClick={() => clickCategory(icon.name)}
          >
            <div>
              <p>
                <img src={icon.icon} />
              </p>
              <p>{icon.name}</p>
            </div>
          </ActiveTab>
        ))}
      </QuestionsCategoryBox>
      <QuestionsListBox>
        {frequentQuestionDataState === 'loading' ? (
          <SpinLoader type={typeList[2].prop} color={'#000'} />
        ) : (
          <>
            {currentPageData.map((data, i) => (
              <div key={i}>
                <div onClick={() => openDetail(i)}>
                  <li>{data.title}</li>
                  <ArrowDown className={showSelectedDetail[i] ? 'active' : ''} />
                </div>
                <div>
                {showSelectedDetail[i] ? (
                  <QuestionsDetail openDetailContent={currentPageData[openDetailID].content} />
                ) : null}
                </div>
              </div>
            ))}
          </>
        )}
      </QuestionsListBox>
      <PaginationBox>
        <PrevBtn
          className="prev-btn"
          onClick={(e) => {
            if (currentPage === 1) {
              e.preventDefault();
            } else {
              clickPrevBtn();
            }
          }}
        />
        <Pagination
          currentPage={currentPage}
          postsPerPage={postsPerPage}
          totalPosts={selectedData.length}
          paginate={setCurrentPage}
        />
        <NextBtn
          className="next-btn"
          onClick={(e) => {
            if (currentPage === Math.ceil(selectedData.length / postsPerPage)) {
              e.preventDefault();
            } else {
              clickNextBtn();
            }
          }}
        />
      </PaginationBox>
    </QuestionsContainer>
  );
}

export default Questions;
