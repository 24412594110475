import React, { useState, useEffect } from 'react';
import Pagination from '../../../molecules/Pagination/index';
import {
  HorizonLine,
  BookStoreContainer,
  BookStoreCategoryTitle,
  BookStoreSubMenuBar,
  BookListContainer,
  Book,
  PaginationBox,
  DataNotFound,
} from '../common/bookstore-list-style';

import { ReactComponent as PrevBtn } from '../../../../assets/icons/icon-PrevBtnFull.svg';
import { ReactComponent as NextBtn } from '../../../../assets/icons/icon-NextBtnFull.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/icon-Filter.svg';
import Search from '../../../molecules/Search';

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import BookStoreDetailPage from '../../../../pages/BookStoreDetail/BookStoreDetailPage';

import { useRecoilState } from 'recoil';
import { selectedSubjectBookCategoryAtom } from '../../../../recoil/SelectedFilterStore/SelectedFilterRecoil';
import { subjectBookListAtom } from '../../../../recoil/BookStoreStore/BookStoreRecoil';

import { useQuery } from 'react-query';
import useHandleError from '../../../../hooks/useHandleError';
import { getBookListByCategory, getBookListByBookName } from '../../../../axiosManage/BookStore';
import { SpinLoader, typeList } from '../../../atoms/SpinLoader';

const NAV_LIST = [
  {
    id: 1,
    name: '언어논리',
  },
  {
    id: 2,
    name: '자료해석',
  },
  {
    id: 3,
    name: '상황판단',
  },
];

function SubjectBook() {
  const { handleError } = useHandleError();
  const { id } = useParams();
  const navigate = useNavigate();

  /* 카테고리 선택 (언어논리, 자료해석, 상황판단) */
  const [currentCategory, setCurrentCategory] = useState(1);
  // 다른 탭 갔다와도 상세페이지 유지하려면 아래 코드 사용, 아니면 삭제 후 해당 atom도 삭제
  //const [currentCategory, setCurrentCategory] = useRecoilState(selectedSubjectBookCategoryAtom);
  const clickCategory = (id) => {
    setCurrentCategory(id);
  };

  const [category, setCategory] = useState('language-logic');
  useEffect(() => {
    if (currentCategory === 1) {
      setCategory('language-logic');
    } else if (currentCategory === 2) {
      setCategory('data-interpretation');
    } else if (currentCategory === 3) {
      setCategory('situational-judgment');
    }
  }, [currentCategory, navigate]);

  const [subjectBookList, setSubjectBookList] = useRecoilState(subjectBookListAtom);

  const { refetch: subjectBookListRefetch } = useQuery(
    ['getBookListByCategory', category],
    () => getBookListByCategory(category),
    {
      retry: 0,
      //enabled: false,
      onSuccess: (data) => {
        setSubjectBookList(data.data.bookList);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  /** 검색 기능 */
  // const [keyword, setKeyword] = useState('');
  // const [isSearchBtnClicked, setIsSearchBtnClicked] = useState(false);
  // const keywordCheck = (e) => {
  //   setKeyword(e.currentTarget.value);
  // };

  // const [searchedSubjectBookList, setSearchedSubjectBookList] = useRecoilState(subjectBookListAtom);
  // const { refetch: searchedEntireBookListRefetch } = useQuery(
  //   ['getBookListByBookName', isSearchBtnClicked],
  //   () => getBookListByBookName(keyword),
  //   {
  //     retry: 0,
  //     enabled: false,
  //     onSuccess: (data) => {
  //       console.log(data.data.bookList);
  //       setSearchedSubjectBookList(data.data.bookList);
  //     },
  //     onError: (error) => {
  //       handleError(error);
  //     },
  //   },
  // );

  /* 페이지네이션 */
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [currentPageData, setCurrentPageData] = useState([]);
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  useEffect(() => {
    const currentPosts = subjectBookList.slice(indexOfFirst, indexOfLast);
    setCurrentPageData(currentPosts);
  }, [subjectBookList, currentPage]);

  const clickPrevBtn = (e) => {
    setCurrentPage(currentPage - 1);
  };
  const clickNextBtn = (e) => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      {id ? (
        <BookStoreDetailPage />
      ) : (
        <BookStoreContainer>
          <BookStoreCategoryTitle>과목별 교재</BookStoreCategoryTitle>
          <HorizonLine />
          {/* 서브 카테고리 선택 바 */}
          <BookStoreSubMenuBar>
            <li>
              <FilterIcon />
            </li>
            {NAV_LIST.map((menu) => (
              <div key={menu.id}>
                <li
                  className={menu.id === currentCategory ? 'active' : ''}
                  onClick={() => clickCategory(menu.id)}
                >
                  {menu.name}
                </li>
              </div>
            ))}
            <li>
              {/* <Search keywordCheck={keywordCheck} setIsSearchBtnClicked={setIsSearchBtnClicked} /> */}
            </li>
          </BookStoreSubMenuBar>
          {/* 책 리스트 컨테이너 */}
          <BookListContainer>
            {currentPageData !== undefined ? (
              currentPageData.length < 1 ? (
                <DataNotFound>
                  <p>교재가 존재하지 않습니다!</p>
                </DataNotFound>
              ) : (
                <>
                  {[...Array(12)].map((x, i) => {
                    return currentPageData[i] === undefined ? (
                      <Book key={`data${i}`}></Book>
                    ) : (
                      <Book
                        key={currentPageData[i].id}
                        onClick={(e) =>
                          (navigate(`/bookstore/entire/${currentPageData[i].id}`))
                        }
                      >
                        <img src={currentPageData[i].bookImg} />
                        <p>{currentPageData[i].bookName}</p>
                        <p>{currentPageData[i].author}</p>
                        {currentPageData[i].sale === 0 ? (
                          <>
                            <p></p>
                            <p>
                              ₩
                              {currentPageData[i].price
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </>
                        ) : (
                          <>
                            <p style={{ textDecoration: 'line-through' }}>
                              ₩
                              {currentPageData[i].price
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                            <p>
                              ₩
                              {(currentPageData[i].price - currentPageData[i].sale)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </>
                        )}
                      </Book>
                    );
                  })}
                </>
              )
            ) : (
              <SpinLoader type={typeList[2].prop} color={'#000'} width={1311} height={715} />
            )}
          </BookListContainer>
          {/* 페이지네이션 */}
          <PaginationBox>
            <PrevBtn
              className="prev-btn"
              onClick={(e) => {
                if (currentPage === 1) {
                  e.preventDefault();
                } else {
                  clickPrevBtn();
                }
              }}
            />
            <Pagination
              currentPage={currentPage}
              postsPerPage={postsPerPage}
              totalPosts={subjectBookList.length}
              paginate={setCurrentPage}
            ></Pagination>
            <NextBtn
              className="next-btn"
              onClick={(e) => {
                if (currentPage === Math.ceil(subjectBookList.length / postsPerPage)) {
                  e.preventDefault();
                } else {
                  clickNextBtn();
                }
              }}
            />
          </PaginationBox>
        </BookStoreContainer>
      )}
    </>
  );
}

export default SubjectBook;
