import React, { useState } from 'react';
import {
  ExamInfoContentBox,
  ExamInfoGraphContainer,
  Table,
  TableHeader,
  TableDataSlice,
  PageNumber,
  TableSubHeader,
  TableSubHeaderContainer,
  TableDataContainer,
  Data,
} from '../common/graph-style';

import { ReactComponent as PrevBtn } from '../../../../assets/icons/icon-PrevBtnFullTypeCol.svg';
import { ReactComponent as NextBtn } from '../../../../assets/icons/icon-NextBtnFullTypeCol.svg';

const TableSubHeaderItem1 = [
  {
    title: '모집인원',
  },
  {
    title: '출원인원',
  },
  {
    title: '합격인원',
  },
];

const TableSubHeaderItem2 = [
  {
    title: '경쟁률',
  },
  {
    title: '합격률',
  },
  {
    title: '합격선',
  },
];

const FirstTable = ({ firstTableData }) => {
  return (
    <Table>
      <TableHeader isThree={true}>
        <div>지역</div>
        <div>
          <div>서울</div>
          <div>부산</div>
          <div>대구</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {firstTableData &&
          firstTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data isThree={true}>
                <div>
                  <p>{data.content.서울.모집인원}</p>
                  <p>{data.content.서울.출원인원}</p>
                  <p>{data.content.서울.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.서울.경쟁률}</p>
                  <p>{data.content.서울.합격률}</p>
                  <p>{data.content.서울.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.부산.모집인원}</p>
                  <p>{data.content.부산.출원인원}</p>
                  <p>{data.content.부산.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.부산.경쟁률}</p>
                  <p>{data.content.부산.합격률}</p>
                  <p>{data.content.부산.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.대구.모집인원}</p>
                  <p>{data.content.대구.출원인원}</p>
                  <p>{data.content.대구.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.대구.경쟁률}</p>
                  <p>{data.content.대구.합격률}</p>
                  <p>{data.content.대구.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const SecondTable = ({ secondTableData }) => {
  return (
    <Table>
      <TableHeader isThree={true}>
        <div>지역</div>
        <div>
          <div>인천</div>
          <div>광주</div>
          <div>대전</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {secondTableData &&
          secondTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data isThree={true}>
                <div>
                  <p>{data.content.인천.모집인원}</p>
                  <p>{data.content.인천.출원인원}</p>
                  <p>{data.content.인천.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.인천.경쟁률}</p>
                  <p>{data.content.인천.합격률}</p>
                  <p>{data.content.인천.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.광주.모집인원}</p>
                  <p>{data.content.광주.출원인원}</p>
                  <p>{data.content.광주.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.광주.경쟁률}</p>
                  <p>{data.content.광주.합격률}</p>
                  <p>{data.content.광주.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.대전.모집인원}</p>
                  <p>{data.content.대전.출원인원}</p>
                  <p>{data.content.대전.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.대전.경쟁률}</p>
                  <p>{data.content.대전.합격률}</p>
                  <p>{data.content.대전.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const ThirdTable = ({ thirdTableData }) => {
  return (
    <Table>
      <TableHeader isThree={true}>
        <div>지역</div>
        <div>
          <div>울산</div>
          <div>세종</div>
          <div>경기</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {thirdTableData &&
          thirdTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data isThree={true}>
                <div>
                  <p>{data.content.울산.모집인원}</p>
                  <p>{data.content.울산.출원인원}</p>
                  <p>{data.content.울산.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.울산.경쟁률}</p>
                  <p>{data.content.울산.합격률}</p>
                  <p>{data.content.울산.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.세종.모집인원}</p>
                  <p>{data.content.세종.출원인원}</p>
                  <p>{data.content.세종.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.세종.경쟁률}</p>
                  <p>{data.content.세종.합격률}</p>
                  <p>{data.content.세종.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.경기.모집인원}</p>
                  <p>{data.content.경기.출원인원}</p>
                  <p>{data.content.경기.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.경기.경쟁률}</p>
                  <p>{data.content.경기.합격률}</p>
                  <p>{data.content.경기.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const FourthTable = ({ fourthTableData }) => {
  return (
    <Table>
      <TableHeader isThree={true}>
        <div>지역</div>
        <div>
          <div>강원</div>
          <div>충북</div>
          <div>충남</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {fourthTableData &&
          fourthTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data isThree={true}>
                <div>
                  <p>{data.content.강원.모집인원}</p>
                  <p>{data.content.강원.출원인원}</p>
                  <p>{data.content.강원.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.강원.경쟁률}</p>
                  <p>{data.content.강원.합격률}</p>
                  <p>{data.content.강원.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.충북.모집인원}</p>
                  <p>{data.content.충북.출원인원}</p>
                  <p>{data.content.충북.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.충북.경쟁률}</p>
                  <p>{data.content.충북.합격률}</p>
                  <p>{data.content.충북.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.충남.모집인원}</p>
                  <p>{data.content.충남.출원인원}</p>
                  <p>{data.content.충남.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.충남.경쟁률}</p>
                  <p>{data.content.충남.합격률}</p>
                  <p>{data.content.충남.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const FifthTable = ({ fifthTableData }) => {
  return (
    <Table>
      <TableHeader isThree={true}>
        <div>지역</div>
        <div>
          <div>전북</div>
          <div>전남</div>
          <div>경북</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {fifthTableData &&
          fifthTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data isThree={true}>
                <div>
                  <p>{data.content.전북.모집인원}</p>
                  <p>{data.content.전북.출원인원}</p>
                  <p>{data.content.전북.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.전북.경쟁률}</p>
                  <p>{data.content.전북.합격률}</p>
                  <p>{data.content.전북.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.전남.모집인원}</p>
                  <p>{data.content.전남.출원인원}</p>
                  <p>{data.content.전남.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.전남.경쟁률}</p>
                  <p>{data.content.전남.합격률}</p>
                  <p>{data.content.전남.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.경북.모집인원}</p>
                  <p>{data.content.경북.출원인원}</p>
                  <p>{data.content.경북.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.경북.경쟁률}</p>
                  <p>{data.content.경북.합격률}</p>
                  <p>{data.content.경북.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const SixthTable = ({ sixthTableData }) => {
  return (
    <Table>
      <TableHeader isTwo={true}>
        <div>지역</div>
        <div>
          <div>경남</div>
          <div>제주</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(2)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(2)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {sixthTableData &&
          sixthTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data isTwo={true}>
                <div>
                  <p>{data.content.경남.모집인원}</p>
                  <p>{data.content.경남.출원인원}</p>
                  <p>{data.content.경남.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.경남.경쟁률}</p>
                  <p>{data.content.경남.합격률}</p>
                  <p>{data.content.경남.합격선}</p>
                </div>
              </Data>
              <Data isTwo={true}>
                <div>
                  <p>{data.content.제주.모집인원}</p>
                  <p>{data.content.제주.출원인원}</p>
                  <p>{data.content.제주.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.전남.경쟁률}</p>
                  <p>{data.content.전남.합격률}</p>
                  <p>{data.content.전남.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

function AdministrativeJobArea({ data }) {
  const [currentTableNum, setCurrentTableNum] = useState(1);

  const clickPrevBtn = () => {
    setCurrentTableNum(currentTableNum - 1);
  };
  const clickNextBtn = () => {
    setCurrentTableNum(currentTableNum + 1);
  };

  return (
    <>
      <ExamInfoContentBox>
        <PrevBtn
          onClick={(e) => {
            if (currentTableNum === 1) {
              e.preventDefault();
            } else {
              clickPrevBtn();
            }
          }}
        />
        <ExamInfoGraphContainer>
          <TableDataSlice />
          {currentTableNum === 1 ? (
            <FirstTable firstTableData={data} />
          ) : currentTableNum === 2 ? (
            <SecondTable secondTableData={data} />
          ) : currentTableNum === 3 ? (
            <ThirdTable thirdTableData={data} />
          ) : currentTableNum === 4 ? (
            <FourthTable fourthTableData={data} />
          ) : currentTableNum === 5 ? (
            <FifthTable fifthTableData={data} />
          ) : currentTableNum === 6 ? (
            <SixthTable sixthTableData={data} />
          ) : (
            ''
          )}
          <TableDataSlice />
        </ExamInfoGraphContainer>
        <NextBtn
          onClick={(e) => {
            if (currentTableNum === 6) {
              e.preventDefault();
            } else {
              clickNextBtn();
            }
          }}
        />
      </ExamInfoContentBox>
      <PageNumber>{currentTableNum}/6</PageNumber>
    </>
  );
}

export default AdministrativeJobArea;
