import React, { useEffect, useState } from 'react';
import DaumPostcode from 'react-daum-postcode';
import { PostModal, PostBackGround, ModalContainer, ModalBackground, ModalContent } from './style';

const SearchAddress = (props) => {
  const complete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    // console.log(data);
    // console.log(fullAddress);
    // console.log(data.zonecode);

    props.setPopup(false);

    props.setPostAddress({
      ...props.postAddress,
      address: fullAddress,
      zoneCode: data.zonecode,
    });
  };

  return (
    <PostModal>
      <PostBackGround
        onClick={() => {
          props.setPopup(false);
        }}
      />
      <DaumPostcode className="postModal" autoClose onComplete={complete} />
    </PostModal>
  );
};

export default SearchAddress;
