import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

export const IntroPageContainer = styled.div``;

export const ImageContainer = styled.div`
  width: 100%;

  & > img {
    width: 100%;
    object-fit: cover;
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 620px;
  background-color: ${COLOR.BLUE_4};
  margin-top: 100px;
`;

export const MapInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Line = styled.div`
  position: relative;
  width: 431px;
  height: 1px;
  top: 103px;
  background-color: ${COLOR.YELLOW_0};
`;

export const MapContents = styled.div``;

export const MapInfoBox = styled.div`
  margin-top: 157px;
  padding-left: 303px;
`;

export const Title = styled.p`
  ${FONT_STYLES.P_B}
  font-size: 36px;
  color: ${COLOR.YELLOW_0};
`;

export const CompanyName = styled.p`
  ${FONT_STYLES.P_B}
  font-size: 24px;
  color: ${COLOR.GRAY_0};
  margin-top: 41px;
  margin-bottom: 92px;
`;

export const CompanyInfo = styled.div`
  color: ${COLOR.GRAY_0};

  p span:nth-child(1) {
    ${FONT_STYLES.P_B}
    font-size: 20px;
    padding-right: 30px;
  }

  p span:nth-child(2) {
    ${FONT_STYLES.P_R}
    font-size: 20px;
  }

  p:not(:last-child) {
    margin-bottom: 39px;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  height: 272px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MapBox = styled.div`
  width: 761px;
  height: 457px;
  background-color: white;
  border-radius: 22px;
  margin-right: 303px;
  margin-top: 82px;
`;
