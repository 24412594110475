import React, { useEffect, useState } from 'react';
import Pagination from '../../../molecules/Pagination';
import { PaginationBox } from '../../ExamInfoPageElements/PassNote/style';
import { Line, TabTitle } from '../Lectures/style';
import { NoticeContainer, NoticeContent, NoticeTable, DataNotFound } from './style';
import { ReactComponent as PrevBtn } from '../../../../assets/icons/icon-PrevBtnFull.svg';
import { ReactComponent as NextBtn } from '../../../../assets/icons/icon-NextBtnFull.svg';
import { ReactComponent as Bell } from '../../../../assets/icons/icon-bell.svg';
import Filter from '../../../molecules/Filter';
import Search from '../../../molecules/Search';
import { ButtonBox } from '../Review/style';

import NoticeDetailPage from '../../../../pages/NoticeDetail/NoticeDetailPage';
import { useParams, useNavigate } from 'react-router-dom';

import { SpinLoader, typeList } from '../../../atoms/SpinLoader';
import { useChangeMonthNumToEn } from '../../../../hooks/useChangeMonthNumToEn';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  professorNoticePageInfoAtom,
  professorNoticeListAtom,
} from '../../../../recoil/ProfessorStore/ProfessorRecoil';
import { selectedProfessorNoticeFilterMenuAtom } from '../../../../recoil/SelectedFilterStore/SelectedFilterRecoil';

import { useQuery } from 'react-query';
import useHandleError from '../../../../hooks/useHandleError';
import {
  getProfessorNoticePageInfo,
  getProfessorNoticeList,
  getTopFixProfessorNoticeList,
} from '../../../../axiosManage/Professor';

const FILTER_MENU = [
  {
    id: 0,
    category: '강의',
  },
  {
    id: 1,
    category: '이벤트',
  },
];

const Notice = ({ professorId }) => {
  const { handleError } = useHandleError();
  const { id } = useParams();
  const navigate = useNavigate();

  /** url을 통한 교수 ID 설정 */
  const [ProfessorId, setProfessorId] = useState(1);
  useEffect(() => {
    setProfessorId(professorId);
  }, [professorId]);

  /** 교수별 공지사항 상단 고정 게시글 */
  const [professorTopNoticeList, setProfessorTopNoticeList] = useState([]);
  const { refetch: professorTopNoticeListRefetch } = useQuery(
    ['getTopFixProfessorNoticeList', ProfessorId],
    () => getTopFixProfessorNoticeList(ProfessorId),
    {
      retry: 0,
      onSuccess: (data) => {
        setProfessorTopNoticeList(data.data.noticeList);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  /* 페이지네이션 */
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(6);

  const clickPrevBtn = (e) => {
    setCurrentPage(currentPage - 1);
  };
  const clickNextBtn = (e) => {
    setCurrentPage(currentPage + 1);
  };

  /** 필터링 & 검색 기능 */
  const [keyword, setKeyword] = useState('');
  const [isSearchBtnClicked, setIsSearchBtnClicked] = useState(false);
  const keywordCheck = (e) => {
    setKeyword(e.currentTarget.value);
  };

  // 1. 유저가 선택한 필터링 카테고리 목록
  const selectedProfessorNoticeFilterCategory = useRecoilValue(
    selectedProfessorNoticeFilterMenuAtom,
  );

  // 2. 서버에서 받아온 페이징 정보를 전역 상태에 저장
  const [professorNoticePageInfo, setProfessorNoticePageInfo] = useRecoilState(
    professorNoticePageInfoAtom,
  );
  const { refetch: professorQuestionPageInfoRefetch } = useQuery(
    ['getProfessorNoticePageInfo', ProfessorId, selectedProfessorNoticeFilterCategory],
    () =>
      getProfessorNoticePageInfo(
        ProfessorId,
        keyword,
        String([...selectedProfessorNoticeFilterCategory]).replace(/,/g, ' '),
      ),
    {
      retry: 0,
      onSuccess: (data) => {
        setProfessorNoticePageInfo(data.data.total);
        if (isSearchBtnClicked) {
          setIsSearchBtnClicked(false);
        }
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  useEffect(() => {
    professorQuestionPageInfoRefetch();
    setCurrentPage(1);
  }, [isSearchBtnClicked]);

  // 3. 서버에서 받아온 실제 데이터를 전역 상태에 저장
  const [professorNoticeList, setProfessorNoticeList] = useRecoilState(professorNoticeListAtom);
  const { refetch: professorQuestionListRefetch } = useQuery(
    ['getProfessorNoticeList', ProfessorId, selectedProfessorNoticeFilterCategory, currentPage],
    () =>
      getProfessorNoticeList(
        ProfessorId,
        currentPage,
        String([...selectedProfessorNoticeFilterCategory]).replace(/,/g, ' '),
        keyword,
      ),
    {
      retry: 0,
      enabled: false,
      onSuccess: (data) => {
        setProfessorNoticeList(data.data.noticeList);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  useEffect(() => {
    professorQuestionListRefetch();
  }, [selectedProfessorNoticeFilterCategory, isSearchBtnClicked, currentPage, navigate]);

  // 상세페이지 -> 이전페이지로 이동해도 페이지 유지
  useEffect(() => {
    if (navigate) {
      setCurrentPage(currentPage);
    }
  }, [navigate]);

  return (
    <>
      {id ? (
        <NoticeDetailPage />
      ) : (
        <NoticeContainer>
          <TabTitle>공지사항</TabTitle>
          <Line />
          <ButtonBox>
            <Filter
              filterMenu={FILTER_MENU}
              filterName={'카테고리'}
              filterLocation={'교수 공지사항'}
              setCurrentPage={setCurrentPage}
            />
            <Search keywordCheck={keywordCheck} setIsSearchBtnClicked={setIsSearchBtnClicked} />
          </ButtonBox>
          <NoticeTable>
            {professorTopNoticeList !== undefined ? (
              <>
                {professorTopNoticeList.map((data) => (
                  <NoticeContent
                    key={data.id}
                    onClick={() =>
                      (navigate(`/lecture/${professorId}/notice/${data.id}`))
                    }
                  >
                    <div>
                      <Bell />
                    </div>
                    <div>
                      <p>{useChangeMonthNumToEn(data.createdAt.slice(5, 7))}</p>
                      <p>{data.createdAt.slice(8, 10)}</p>
                      <p>{data.createdAt.slice(0, 4)}</p>
                    </div>
                    <div>
                      <p>{data.category}</p>
                      <p>{data.title}</p>
                    </div>
                    <div>
                      <p>조회</p>
                      {data.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
                  </NoticeContent>
                ))}
              </>
            ) : (
              ''
            )}
            {professorNoticeList !== undefined ? (
              professorNoticeList.length < 1 ? (
                <DataNotFound>
                  <p>게시글이 존재하지 않습니다!</p>
                </DataNotFound>
              ) : (
                <>
                  {professorNoticeList.map((data) => (
                    <NoticeContent
                      key={data.id}
                      onClick={() =>
                        (navigate(`/lecture/${professorId}/notice/${data.id}`))
                      }
                    >
                      <div>{data.id}</div>
                      <div>
                        <p>{useChangeMonthNumToEn(data.createdAt.slice(5, 7))}</p>
                        <p>{data.createdAt.slice(8, 10)}</p>
                        <p>{data.createdAt.slice(0, 4)}</p>
                      </div>
                      <div>
                        <p>{data.category}</p>
                        <p>{data.title}</p>
                      </div>
                      <div>
                        <p>조회</p>
                        {data.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </div>
                    </NoticeContent>
                  ))}
                </>
              )
            ) : (
              <SpinLoader type={typeList[2].prop} color={'#000'} width={1311} height={715} />
            )}
          </NoticeTable>
          <PaginationBox>
            <PrevBtn
              className="prev-btn"
              onClick={(e) => {
                if (currentPage === 1) {
                  e.preventDefault();
                } else {
                  clickPrevBtn();
                }
              }}
            />
            <Pagination
              currentPage={currentPage}
              postsPerPage={postsPerPage}
              totalPosts={professorNoticePageInfo}
              paginate={setCurrentPage}
            />
            <NextBtn
              className="next-btn"
              onClick={(e) => {
                if (currentPage === Math.ceil(professorNoticePageInfo / postsPerPage)) {
                  e.preventDefault();
                } else {
                  clickNextBtn();
                }
              }}
            />
          </PaginationBox>
        </NoticeContainer>
      )}
    </>
  );
};

export default Notice;
