import React, { useState } from 'react';
import { PaymentCancelContainer, TextInputBox } from './style';
import COLOR from '../../../styles/common/color';
import { Button } from '../../atoms/Button';
import Select from '../../atoms/Select';
import { cancelAndChangeRequest } from '../../../axiosManage/MyPage';

const PaymentCancelModal = ({ id }) => {
  const SELECT_OPTIONS = [
    { value: '', optionName: '구분' },
    { value: '교환', optionName: '교환' },
    { value: '환불', optionName: '환불' },
  ];

  const [category, setCategory] = useState('구분');
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const [reason, setReason] = useState('');
  const handleReason = (e) => {
    setReason(e.target.value);
  };

  // 교환 환불
  const changeAndRefund = () => {
    const data = {
      orderRecordId: id,
      type: category,
      reason: reason,
    };

    if (data.category === '구분' || data.reason === '') {
      alert('카테고리 선택 및 사유를 작성해주세요.');
    } else {
      cancelAndChangeRequest(data)
        .then((res) => {
          if (res.data.success) {
            alert('교환/환불 신청이 완료되었습니다.');
            window.location.reload();
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  return (
    <PaymentCancelContainer>
      <Select
        width={313}
        height={58}
        selectOptions={SELECT_OPTIONS}
        onChangeFunc={handleCategoryChange}
      />
      <TextInputBox>
        <textarea
          placeholder="카테고리 선택 후 교환/환불 사유를 입력해 주세요."
          onChange={handleReason}
        />
      </TextInputBox>
      <Button width={180} height={60} bgColor={COLOR.BLUE_4} onClickFunc={() => changeAndRefund()}>
        교환/환불 신청하기
      </Button>
    </PaymentCancelContainer>
  );
};

export default PaymentCancelModal;
