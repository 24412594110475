import styled from 'styled-components';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const handleColor = (professorName) => {
  switch (professorName) {
    case 'JLJ':
      return '#5C6072';
    case 'HNS':
      return '#3A5860';
    default:
      return '#716b6b;';
  }
};

const NowTabContainer = styled.div`
  ${FONT_STYLES.P_EB}
  font-weight: 900;
  font-size: 18px;
  position: fixed;
  top: 405px;
  right: 140px;
  writing-mode: vertical-rl;
  display: inline-flex;
  align-items: center;
  // color: ${({ professorName }) => handleColor(professorName)};

  // & > div {
  //   background-color: ${({ professorName }) => handleColor(professorName)};
  // }
`;

const Line = styled.div`
  height: 205px;
  width: 1px;
  margin-top: 39px;
  margin-bottom: 35px;
  background-color: #716b6b;
`;

export { NowTabContainer, Line };
