import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

/** 장바구니 - 유저가 담은 상품 목록 */
export const myOrderBasketListAtom = atom({
  key: 'myOrderBasketListAtom',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

/** 장바구니 - 유저가 구매하려고 선택한 상품 목록 */
export const mySelectedProductListAtom = atom({
  key: 'mySelectedProductListAtom',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

/** 장바구니 - 유저가 구매하려고 선택한 상품 목록 총 가격*/
export const mySelectedProductListPriceAtom = atom({
  key: 'mySelectedProductListPriceAtom',
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

/** 유저가 구매하기 버튼을 바로 눌렀을때 담긴 상품 */
export const myOrderAtom = atom({
  key: 'myOrderAtom',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

/** 결제 완료 페이지 데이터 */
export const myOrderInfoAtom = atom({
  key: 'myOrderInfoAtom',
  default: [],
  //effects_UNSTABLE: [persistAtom],
});