import React, { useState, useEffect } from 'react';
import Pagination from '../../../molecules/Pagination/index';
import {
  HorizonLine,
  BookStoreContainer,
  BookStoreCategoryTitle,
  BookStoreSubMenuBar,
  BookListContainer,
  Book,
  PaginationBox,
  DataNotFound,
} from '../common/bookstore-list-style';

import { ReactComponent as PrevBtn } from '../../../../assets/icons/icon-PrevBtnFull.svg';
import { ReactComponent as NextBtn } from '../../../../assets/icons/icon-NextBtnFull.svg';
import Search from '../../../molecules/Search';

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import BookStoreDetailPage from '../../../../pages/BookStoreDetail/BookStoreDetailPage';

import { useRecoilState } from 'recoil';
import { searchedEntireBookListAtom } from '../../../../recoil/BookStoreStore/BookStoreRecoil';

import { useQuery } from 'react-query';
import useHandleError from '../../../../hooks/useHandleError';
import { getBookListByBookName } from '../../../../axiosManage/BookStore';
import { SpinLoader, typeList } from '../../../atoms/SpinLoader';

function EntireBook() {
  
  const { handleError } = useHandleError();
  const { id } = useParams();
  const navigate = useNavigate();

  /** 필터링 & 검색 기능 */
  const [keyword, setKeyword] = useState('');
  const [isSearchBtnClicked, setIsSearchBtnClicked] = useState(false);
  const keywordCheck = (e) => {
    setKeyword(e.currentTarget.value);
  };

  // 3. 서버에서 받아온 실제 데이터를 전역 상태에 저장
  const [searchedEntireBookList, setSearchedEntireBookList] = useRecoilState(
    searchedEntireBookListAtom,
  );
  const { refetch: searchedEntireBookListRefetch } = useQuery(
    ['getBookListByBookName', isSearchBtnClicked],
    () => getBookListByBookName(keyword),
    {
      retry: 0,
      enabled: false,
      onSuccess: (data) => {
        setSearchedEntireBookList(data.data.bookList);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  /* 페이지네이션 */
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [currentPageData, setCurrentPageData] = useState([]);
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  useEffect(() => {
    searchedEntireBookListRefetch();
    setIsSearchBtnClicked(false);
    setCurrentPage(1);
  }, [isSearchBtnClicked, navigate]);

  useEffect(() => {
    const currentPosts = searchedEntireBookList.slice(indexOfFirst, indexOfLast);
    setCurrentPageData(currentPosts);
  }, [searchedEntireBookList, currentPage]);

  const clickPrevBtn = (e) => {
    setCurrentPage(currentPage - 1);
  };
  const clickNextBtn = (e) => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      {id ? (
        <BookStoreDetailPage />
      ) : (
        <BookStoreContainer>
          <BookStoreCategoryTitle>전체 교재</BookStoreCategoryTitle>
          <HorizonLine />
          {/* 서브 카테고리 선택 바 */}
          <BookStoreSubMenuBar>
            <p>
              <Search keywordCheck={keywordCheck} setIsSearchBtnClicked={setIsSearchBtnClicked} />
            </p>
          </BookStoreSubMenuBar>
          {/* 책 리스트 컨테이너 */}
          <BookListContainer>
            {currentPageData !== undefined ? (
              currentPageData.length < 1 ? (
                <DataNotFound>
                  <p>교재가 존재하지 않습니다!</p>
                </DataNotFound>
              ) : (
                <>
                  {[...Array(12)].map((x, i) => {
                    return currentPageData[i] === undefined ? (
                      <Book key={`data${i}`}></Book>
                    ) : (
                      <Book
                        key={currentPageData[i].id}
                        onClick={(e) =>
                          (navigate(`/bookstore/entire/${currentPageData[i].id}`))
                        }
                      >
                        <img src={currentPageData[i].bookImg} />
                        <p>{currentPageData[i].bookName}</p>
                        <p>{currentPageData[i].author}</p>
                        {currentPageData[i].sale === 0 ? (
                          <>
                            <p></p>
                            <p>
                              ₩
                              {currentPageData[i].price
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </>
                        ) : (
                          <>
                            <p style={{ textDecoration: 'line-through' }}>
                              ₩
                              {currentPageData[i].price
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                            <p>
                              ₩
                              {(currentPageData[i].price - currentPageData[i].sale)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </>
                        )}
                      </Book>
                    );
                  })}
                </>
              )
            ) : (
              <SpinLoader type={typeList[2].prop} color={'#000'} width={1311} height={715} />
            )}
          </BookListContainer>
          {/* 페이지네이션 */}
          <PaginationBox>
            <PrevBtn
              className="prev-btn"
              onClick={(e) => {
                if (currentPage === 1) {
                  e.preventDefault();
                } else {
                  clickPrevBtn();
                }
              }}
            />
            <Pagination
              currentPage={currentPage}
              postsPerPage={postsPerPage}
              totalPosts={searchedEntireBookList.length}
              paginate={setCurrentPage}
            ></Pagination>
            <NextBtn
              className="next-btn"
              onClick={(e) => {
                if (currentPage === Math.ceil(searchedEntireBookList.length / postsPerPage)) {
                  e.preventDefault();
                } else {
                  clickNextBtn();
                }
              }}
            />
          </PaginationBox>
        </BookStoreContainer>
      )
      }
    </>
  );
}

export default EntireBook;
