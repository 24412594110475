import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Header from '../components/molecules/Header';
import ExamInfoPage from '../pages/ExamInfo/ExamInfoPage';
import LectureHomePage from '../pages/Lecture/LectureHomePage';
import SupportCenterPage from '../pages/SupportCenter/SupportCenterPage';
import BookStorePage from '../pages/BookStore/BookStorePage';
import LecturePage from '../pages/Lecture/LecturePage';
import MainPage from '../pages/Main/MainPage';
import ScrollToTop from '../hooks/scrollToTop';
import LoginPage from '../pages/Login/LoginPage';
import FindAccountPage from '../pages/FindAccount/FindAccountPage';
import FindIdCompletePage from '../pages/FindIdComplete/FindIdCompletePage';
import FindPasswordCertificationPage from '../pages/FindPasswordCertification/FindPasswordCertificationPage';
import ChangePasswordPage from '../pages/ChangePassword/ChangePasswordPage';
import RegisterPage from '../pages/Register/RegisterPage';
import RegisterAssignmentPage from '../pages/RegisterAssignment/RegisterAssignmentPage';
import RegisterSocialAssignmentPage from '../pages/RegisterSocialAssignment/RegisterSocialAssignmentPage';
import RegisterJoinPage from '../pages/RegisterJoin/RegisterJoinPage';
import RegisterSocialJoinPage from '../pages/RegisterSocialJoin/RegisterSocialJoinPage';
import RegisterCompletePage from '../pages/RegisterComplete/RegisterCompletePage';
import MainLayout from '../pages/MainLayout/MainLayout';
import RightAwayOrderPage from '../pages/RightAwayOrderPage/RightAwayOrderPage';
import OrderBasketPage from '../pages/OrderBasket/OrderBasketPage';
import OrderInfoInputPage from '../pages/OrderInfoInput/OrderInfoInputPage';
import OrderCompletePage from '../pages/OrderComplete/OrderCompletePage';
import MobileOrderCompletePage from '../pages/MobileOrderComplete/MobileOrderCompletePage';
import MyPage from '../pages/MyPage/MyPage';
import KakaoLogin from '../components/organisms/KakaoLogin';
import NaverLogin from '../components/organisms/NaverLogin';
import PaymentRedirect from '../components/organisms/PaymentRedirect';
import RegularPassPage from '../pages/RegularPassPage/RegularPassPage';

import PageNotFound from '../hooks/pageNotFound';
import IntroPage from '../pages/Intro/IntroPage';
import ContactModifyPage from '../pages/ContactModifyPage/ContactModifyPage';
import QuestionModifyPage from '../pages/QuestionModifyPage/QuestionModifyPage';
import ReviewModifyPage from '../pages/ReviewModifyPage/ReviewModifyPage';
import PassReviewModifyPage from '../pages/PassReviewModifyPage/PassReviewModifyPage';

const Router = () => {
  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        {/* Footer가 필요한 페이지들 */}
        <Route element={<MainLayout />}>
          <Route path="/" element={<MainPage />} />
          <Route path="/intro" element={<IntroPage />} />
          <Route path="/lecture/*">
            <Route path="" element={<LectureHomePage />} />
            <Route path=":professorId" element={<LecturePage />}>
              <Route path=":tab" element={<LecturePage />}>
                <Route path=":id" element={<LecturePage />} />
              </Route>
            </Route>
          </Route>
          <Route path="/examinfo" element={<ExamInfoPage />}>
            <Route path=":tab" element={<ExamInfoPage />}>
              <Route path=":id" element={<SupportCenterPage />} />
            </Route>
          </Route>
          <Route path="/supportcenter" element={<SupportCenterPage />}>
            <Route path=":tab" element={<SupportCenterPage />}>
              <Route path=":id" element={<SupportCenterPage />} />
            </Route>
          </Route>
          <Route path="/bookstore" element={<BookStorePage />}>
            <Route path=":tab" element={<BookStorePage />}>
              <Route path=":id" element={<BookStorePage />} />
            </Route>
          </Route>
          <Route path="/mypage" element={<MyPage />}>
            <Route path=":tab" element={<MyPage />}>
              <Route path=":id" element={<MyPage />} />
            </Route>
          </Route>
          <Route path="/order" element={<RightAwayOrderPage />} />
          <Route path="/basket" element={<OrderBasketPage />} />
          <Route path="/basket/order" element={<OrderInfoInputPage />} />
          <Route path="/order/complete" element={<OrderCompletePage />} />
          <Route path="/mobileorder/complete" element={<MobileOrderCompletePage />} />
          <Route path="/contact/modify" element={<ContactModifyPage />} />
          <Route path="/question/modify" element={<QuestionModifyPage />} />
          <Route path="/review/modify" element={<ReviewModifyPage />} />
          <Route path="/passreview/modify" element={<PassReviewModifyPage />} />
          <Route path="/regularpass" element={<RegularPassPage />} />
        </Route>

        {/* Footer가 숨겨져야할 페이지들, Header만 있음 */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/findaccount" element={<FindAccountPage />} />
        <Route path="/findaccount/findIdComplete" element={<FindIdCompletePage />} />
        <Route path="/findaccount/certification" element={<FindPasswordCertificationPage />} />
        <Route path="/findaccount/changepassword" element={<ChangePasswordPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/register/assignment" element={<RegisterAssignmentPage />} />
        <Route path="/register/socialassignment" element={<RegisterSocialAssignmentPage />} />
        <Route path="/register/join" element={<RegisterJoinPage />} />
        <Route path="/register/socialjoin" element={<RegisterSocialJoinPage />} />
        <Route path="/register/complete" element={<RegisterCompletePage />} />

        {/* login redirect */}
        <Route path="/auth/kakao/callback" element={<KakaoLogin />} />
        <Route path="/auth/naver/callback" element={<NaverLogin />} />

        {/* payment redirect */}
        <Route path="/payment/check" element={<PaymentRedirect />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
