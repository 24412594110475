import React from 'react';
import { MyPageContainer } from './style';
import { useParams, Navigate } from 'react-router-dom';

import { MyPageNav } from '../../components/molecules/SubNavBar';
import Home from '../../components/organisms/MyPageElements/Home';
import MyInfo from '../../components/organisms/MyPageElements/MyInfo';
import MyOrder from '../../components/organisms/MyPageElements/MyOrder';
import MyLecture from '../../components/organisms/MyPageElements/MyLecture';
import Banner from '../../components/organisms/MyPageElements/Banner';

import PageNotFound from '../../hooks/pageNotFound';

import { useRecoilValue } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';
import { myInfoAtom } from '../../recoil/MyPageStore/MyPageRecoil';

function MyPage() {
  const isLogin = useRecoilValue(isLoginAtom);
  const myInfo = useRecoilValue(myInfoAtom);

  if (isLogin === false) {
    alert('로그인을 해주세요.');
    return <Navigate to="/login" />;
  }

  const { tab } = useParams();
  const tabs = ['myinfo', 'myorder', 'mylecture', undefined];

  if (!tabs.includes(tab)) {
    return <PageNotFound />;
  }

  const renderPage = () => {
    switch (tab) {
      case 'myinfo':
        return <MyInfo myInfo={myInfo} />;
      case 'myorder':
        return <MyOrder myInfo={myInfo} />;
      case 'mylecture':
        return <MyLecture myInfo={myInfo} />;
      default:
        return <Home myInfo={myInfo} />;
    }
  };

  const renderBanner = () => {
    return <Banner />;
  };

  return (
    <MyPageContainer>
      {renderBanner()}
      <MyPageNav tab={tab} />
      {renderPage()}
    </MyPageContainer>
  );
}

export default MyPage;
