import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Category, Line, ProfessorName, ProfessorNavContainer, ProfessorNavContent } from './style';
import { ReactComponent as MinusIcon } from '../../../assets/icons/icon-Minus.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/icon-Plus.svg';
import { useState } from 'react';

const PROFESSOR_LIST = [
  {
    category: '언어논리',

  },
  {
    category: '자료해석',

  },
  {
    category: '상황판단',

  },
];

const ProfessorNav = (props) => {
  const [Active, setActive] = useState({ 언어논리: true, 자료해석: true, 상황판단: true });

  const activeFunc = (e) => {
    switch (e.target.id) {
      case '언어논리':
        if (Active.언어논리 === true) {
          setActive({ ...Active, 언어논리: false });
        } else {
          setActive({ ...Active, 언어논리: true });
        }
        break;
      case '자료해석':
        if (Active.자료해석 === true) {
          setActive({ ...Active, 자료해석: false });
        } else {
          setActive({ ...Active, 자료해석: true });
        }
        break;
      case '상황판단':
        if (Active.상황판단 === true) {
          setActive({ ...Active, 상황판단: false });
        } else {
          setActive({ ...Active, 상황판단: true });
        }
        break;
      default:
        break;
    }
  };

  return (
    <ProfessorNavContainer>
      <Line />
      <ProfessorNavContent>
        {PROFESSOR_LIST.map((list) => (
          <ul key={list.category}>
            <ul>
              <Category id={list.category} onClick={activeFunc}>
                <span id={list.category}>{list.category}</span>
                {Active[list.category] ? (
                  <MinusIcon id={list.category} />
                ) : (
                  <PlusIcon id={list.category} />
                )}
              </Category>
              {props.professorList.map((professor) => {
                if (professor.category == list.category) {
                  return (
                    <Link key={professor.id} to={String(professor.id)}>
                      <ProfessorName key={professor.id} isActive={Active[list.category]}>
                        - {professor.professorName}
                      </ProfessorName>
                    </Link>
                  )
                }
              }
              )}
            </ul>
          </ul>
        ))}
      </ProfessorNavContent>
    </ProfessorNavContainer>
  );
};

export default ProfessorNav;
