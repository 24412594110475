import styled from 'styled-components';
import { FONT_STYLES } from '../../../../styles/common/font-styles';
import COLOR from '../../../../styles/common/color';

const LibraryContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-top: 80px;
  padding-bottom: 173px;
  text-align: center;

  @media screen and (max-width: 1700px) {
    padding-top: 70px;
    padding-bottom: 173px;
  }
`;

const LibraryTable = styled.div`
  margin-top: 75px;
  margin-bottom: 52px;
  & > div:not(:last-child) {
    border-bottom: 1px solid ${COLOR.GRAY_8};
  }

  a {
    color: red;
  }
`;

const LibraryContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  padding: 31px 18px 31px 36px;
  :hover {
    cursor: pointer;
  }

  div:nth-child(1) {
    ${FONT_STYLES.P_R}
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53px;
    font-size: 18px;
    margin-right: 38px;
  }

  div:nth-child(2) {
    text-align: center;
    margin-right: 40px;

    p:nth-child(1) {
      ${FONT_STYLES.P_SB}
      font-size: 16px;
      line-height: 19px;
    }

    p:nth-child(2) {
      ${FONT_STYLES.P_B}
      font-size: 20px;
      line-height: 24px;
    }
    p:nth-child(3) {
      ${FONT_STYLES.P_R}
      font-size: 14px;
      line-height: 16px;
    }
  }

  div:nth-child(3) {
    flex: 1;
    p:nth-child(1) {
      ${FONT_STYLES.P_R}
      font-size: 15px;
      line-height: 18px;
    }
    p:nth-child(2) {
      ${FONT_STYLES.P_SB}
      font-size: 18px;
      line-height: 21px;
      padding-top: 5px;
    }
  }

  div:nth-child(4) {
    width: 100px;
    ${FONT_STYLES.P_M}
    text-align: center;
    font-size: 18px;
    line-height: 24px;

    &.complete {
      color: ${COLOR.BLUE_2};
    }
  }

  div:nth-child(5) {
    width: 70px;
    ${FONT_STYLES.P_M}
    text-align: center;
    font-size: 18px;
    line-height: 24px;
  }
`;

const DataNotFound = styled.div`
  width: 100%;
  height: 715px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > p {
    ${FONT_STYLES.P_R}
    font-size: 36px;
  }
`;

const ButtonBox = styled.div`
  height: 52px;
  margin-top: 10px;
  margin-bottom: 20px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    margin: 0 5px;

    & > div {
      svg,
      div {
        cursor: pointer;
      }
    }
  }
`;

export { LibraryContainer, LibraryTable, LibraryContent, DataNotFound, ButtonBox };
