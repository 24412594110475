import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  MyOrderPageContainer,
  MyOrderTitle,
  HorizonLine,
  OrderStateBox,
  DurationSettingBox,
  OrderDetailBox,
  OrderDetailHeader,
  OrderDetailItems,
  Item,
} from './style';
import COLOR from '../../../../styles/common/color';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import MyOrderDetailPage from '../../../../pages/MypageOrderDetail/MyPageOrderDetailPage';

import { ReactComponent as Line } from '../../../../assets/icons/icon-MinusBtn.svg';
import { Button } from '../../../atoms/Button';
import { DatePickerCustomInput } from '../../../atoms/Input';

import { useRecoilState } from 'recoil';
import {
  myPageMyOrderAtom,
  myPageMyOrderStartDateAtom,
  myPageMyOrderEndDateAtom,
} from '../../../../recoil/MyPageStore/MyPageRecoil';

import { useQuery } from 'react-query';
import { getMyPaymentInfo } from '../../../../axiosManage/MyPage';
import useHandleError from '../../../../hooks/useHandleError';

const DURATION_LIST = [
  {
    id: 1,
    name: '1주일',
  },
  {
    id: 2,
    name: '1개월',
  },
  {
    id: 3,
    name: '3개월',
  },
  {
    id: 4,
    name: '6개월',
  },
  // {
  //   id: 5,
  //   name: '기간설정',
  // },
];

function MyOrder() {
  const { handleError } = useHandleError();
  const { id } = useParams();
  const navigate = useNavigate();

  /* 날짜 선택 - 기본 값: 1주일*/
  const [startDate, setStartDate] = useRecoilState(myPageMyOrderStartDateAtom);
  const [endDate, setEndDate] = useRecoilState(myPageMyOrderEndDateAtom);

  useEffect(() => {
    if (navigate) {
      setStartDate(startDate);
      setEndDate(endDate);
    }
  }, [navigate]);

  const [calendarStartDate, setCalendarStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7)),
  );
  const [calendarEndDate, setCalendarEndDate] = useState(new Date());

  useEffect(() => {
    setStartDate(moment(calendarStartDate).format('YYYY-MM-DD'));
    setEndDate(moment(calendarEndDate).format('YYYY-MM-DD'));
  }, [calendarStartDate, calendarEndDate]);

  useEffect(() => {
    localStorage.setItem('startDate', startDate);
    localStorage.setItem('endDate', endDate);
  }, [startDate, endDate]);

  const [myOrderList, setMyOrderList] = useRecoilState(myPageMyOrderAtom);
  const [isDurationClicked, setIsDurationClicked] = useState(false);
  const { status: myOrderStatus, refetch: myOrderRefetch } = useQuery(
    'getMyPaymentInfo',
    () => getMyPaymentInfo(startDate, endDate),
    {
      retry: 0,
      onSuccess: (data) => {
        setMyOrderList(data.data);
        setIsDurationClicked(false);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  // 1주일, 1,3,6,9 개월 눌렀을 떄
  const clickedDuration = (duration) => {
    if (duration === '1주일') {
      setStartDate(moment().subtract(1, 'weeks').format('YYYY-MM-DD'));
    } else if (duration === '1개월') {
      setStartDate(moment().subtract(1, 'month').format('YYYY-MM-DD'));
    } else if (duration === '3개월') {
      setStartDate(moment().subtract(3, 'month').format('YYYY-MM-DD'));
    } else if (duration === '6개월') {
      setStartDate(moment().subtract(6, 'month').format('YYYY-MM-DD'));
    }
    setIsDurationClicked(true);
  };

  useEffect(() => {
    myOrderRefetch();
  }, [isDurationClicked]);

  // 기간 설정하고 조회하기 버튼 눌렀을 때
  const inquireBySelectedDate = () => {
    myOrderRefetch();
  };

  /* 카테고리 선택 */
  const [currentCategory, setCurrentCategory] = useState(6);
  const clickCategory = (id) => {
    setCurrentCategory(id);
  };

  // 총 결제 금액 계산 로직
  const [totalPrice, setTotalPrice] = useState(Array(myOrderList.length).fill(0));
  useEffect(() => {
    let prices = [];

    myOrderList.forEach((order) => {
      let price = 0;
      for (let i = 0; i < order.orderRecords.length; i++) {
        price += order.orderRecords[i].price;
      }
      price += order.deliveryCharge;
      prices.push(price);
    });

    setTotalPrice(prices);
  }, [myOrderList, currentCategory]);

  // 배송 상태 검사 로직(parcels의 isSendPost 돌려서 하나라도 true면 발송 완료로 표기 아니면 발송 준비)
  const [deliveryStatus, setDeliveryStatus] = useState(Array(myOrderList.length).fill(false));
  useEffect(() => {
    let newArr = [...deliveryStatus];
    myOrderList.forEach((orders, i) => {
      orders.parcels.forEach((parcel) => {
        if (parcel.isSendPost === true) {
          newArr[i] = true;
        }
      });
    });

    setDeliveryStatus(newArr);
  }, [myOrderList, currentCategory]);

  /* 기간 선택 */
  const [currentDuration, setCurrentDuration] = useState(1);
  const clickDuration = (id) => {
    setCurrentDuration(id);
  };
  const [currentIndex, setCurrentIndex] = useState(0);

  const RenderOrderList = ({ orderList }) => {
    return orderList !== undefined && totalPrice ? (
      <>
        {orderList.map((data, i) => (
          <Item
            key={data.id}
            onClick={(e) => {
              navigate(`/mypage/myorder/${data.id}`);
              setCurrentIndex(i);
            }}
          >
            <div>{data.merchantUid}</div>
            <div>
              {data.orderRecords.length < 1 ? (
                <div>삭제된 상품이 존재합니다.</div>
              ) : (
                <>
                  {data.orderRecords && data.orderRecords.length > 1
                    ? data.orderRecords[0].productType === 'book'
                      ? `${data.orderRecords[0].book && data.orderRecords[0].book.bookName} 외 ${
                          data.orderRecords.length - 1
                        }건`
                      : data.orderRecords[0].productType === 'lecture'
                      ? data.orderRecords[0].lectureInfo &&
                        `${data.orderRecords[0].lectureInfo.lectureName} 외 ${
                          data.orderRecords.length - 1
                        }건`
                      : data.orderRecords[0].productType === 'pass'
                      ? `${
                          data.orderRecords[0].passInfo && data.orderRecords[0].passInfo.passName
                        } 외 ${data.orderRecords.length - 1}건`
                      : ''
                    : data.orderRecords[0].productType === 'book'
                    ? data.orderRecords[0].book && data.orderRecords[0].book.bookName
                    : data.orderRecords[0].productType === 'lecture'
                    ? data.orderRecords[0].lectureInfo &&
                      data.orderRecords[0].lectureInfo.lectureName
                    : data.orderRecords[0].productType === 'pass'
                    ? data.orderRecords[0].passInfo && data.orderRecords[0].passInfo.passName
                    : ''}
                </>
              )}
            </div>
            <div>{data.createdAt?.slice(0, 10).replace(/-/g, '/')}</div>
            <div>
              {totalPrice[i] && totalPrice[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
            </div>
            {deliveryStatus}
            {deliveryStatus && deliveryStatus[i] === true ? (
              <div>발송 완료</div>
            ) : data.parcels &&
              data.parcels.length >= 1 &&
              deliveryStatus &&
              deliveryStatus[i] === false ? (
              <div>발송 준비</div>
            ) : data.orderRecords &&
              data.orderRecords.length >= 1 &&
              data.orderRecords[0].isCancel === true ? (
              <div style={{ color: 'red' }}>결제 취소</div>
            ) : data.parcels && data.parcels.length < 1 ? (
              <div>결제 완료</div>
            ) : (
              ''
            )}
          </Item>
        ))}
      </>
    ) : (
      ''
    );
  };

  return (
    <>
      {id ? (
        <MyOrderDetailPage currentIndex={currentIndex} deliveryStatus={deliveryStatus} />
      ) : (
        <MyOrderPageContainer>
          <MyOrderTitle>주문 / 배송 조회</MyOrderTitle>
          <HorizonLine />
          <DurationSettingBox>
            <div>기간 설정</div>
            <div>
              {DURATION_LIST.map((menu) => (
                <div key={menu.id} onClick={() => clickedDuration(menu.name)}>
                  <li
                    className={menu.id === currentDuration ? 'active' : ''}
                    onClick={() => clickDuration(menu.id)}
                  >
                    {menu.name}
                  </li>
                </div>
              ))}
            </div>
            <div>
              <DatePicker
                selected={calendarStartDate}
                onChange={(date) => setCalendarStartDate(date)}
                dateFormat={'yyyy-MM-dd'}
                dateFormatCalendar={'yyyy년 MM월'}
                customInput={<DatePickerCustomInput />}
              />
              <Line />
              <DatePicker
                selected={calendarEndDate}
                onChange={(date) => setCalendarEndDate(date)}
                dateFormat={'yyyy-MM-dd'}
                dateFormatCalendar={'yyyy년 MM월'}
                customInput={<DatePickerCustomInput />}
              />
            </div>
            <Button
              width={95}
              height={48}
              bgColor={COLOR.BLUE_4}
              onClickFunc={inquireBySelectedDate}
            >
              조회하기
            </Button>
          </DurationSettingBox>
          <OrderDetailBox>
            <OrderDetailHeader>
              <div>주문번호</div>
              <div>주문내역</div>
              <div>결제일</div>
              <div>결제금액</div>
              <div>배송상태</div>
            </OrderDetailHeader>
            <OrderDetailItems>
              <RenderOrderList orderList={myOrderList} />
            </OrderDetailItems>
          </OrderDetailBox>
        </MyOrderPageContainer>
      )}
    </>
  );
}

export default MyOrder;
