import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

export const OrderBasketPageContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding};
  text-align: center;
  padding-top: 60px;
  padding-bottom: 120px;

  @media screen and (max-width: 1700px) {
    padding-top: 60px;
    padding-bottom: 120px;
  }
`;

export const OrderBasketPageTitle = styled.div`
  ${FONT_STYLES.P_M};
  font-size: 36px;
  margin-top: 60px;

  & > span {
    ${FONT_STYLES.P_B};
    color: ${COLOR.BLUE_4};
  }
`;

export const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_4};
  margin: 50px auto;
`;

export const BasketBox = styled.div`
  margin: 0 75px;
`;

export const BasketHeader = styled.div`
  display: flex;

  padding: 20px 0;
  border-bottom: 1px solid ${COLOR.GRAY_9};

  & > p {
    :nth-child(1) {
      text-align: left;
      width: 5%;
    }
    :nth-child(2) {
      text-align: left;
      width: 60%;
    }
    :nth-child(3) {
      width: 10.6%;
    }
    :nth-child(4) {
      width: 12.6%;
    }
    :nth-child(5) {
      width: 11.6%;
    }
  }
`;

export const BasketItems = styled.div``;

export const Item = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLOR.GRAY_9};
  padding: 20px 0;

  & > div {
    :nth-child(1) {
      text-align: left;
      width: 5%;
    }
    :nth-child(2) {
      display: flex;
      align-items: center;
      text-align: left;
      width: 60%;

      & > img {
        width: 140px;
        height: 140px;
        object-fit: scale-down;
      }

      & > div {
        margin: 0 35px;

        :nth-child(2) {
          width: 70px;
          text-align: center;
        }

        :nth-child(3) {
          width: 340px;
          line-height: 1.3;
          & > p {
            :nth-child(2) {
              ${FONT_STYLES.P_SB};
              font-size: 20px;
            }
          }
        }

        & > p {
          padding: 3px 0;
          :nth-child(1) {
            ${FONT_STYLES.P_T};
            font-size: 15px;
          }
          :nth-child(2) {
            ${FONT_STYLES.P_R};
          }
        }
      }
    }
    :nth-child(3) {
      width: 10.6%;
    }
    :nth-child(4) {
      width: 12.6%;
    }
    :nth-child(5) {
      width: 11.6%;

      & > p {
        margin: 15px 0;
      }
    }
  }
`;

export const BasketFooter = styled.div`
  display: flex;
  align-items: center;

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin: 30px 0 10px auto;

    & > p {
      :nth-child(1) {
        ${FONT_STYLES.P_SB};
        padding-right: 43px;
      }
      :nth-child(2) {
        ${FONT_STYLES.P_B};
        font-size: 24px;
      }
    }
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: center;

  & > div {
    margin: 40px 10px 0 10px;
  }
`;

export const CountButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 154px;
  height: 44px;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 7px;

  & > div {
    padding: 0 20px;
    display: flex;
    align-items: center;

    :nth-child(1),
    :nth-child(3) {
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
  }
`;
