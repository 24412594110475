import { useAxios, useDownloadAxios } from '../../hooks/useAxios';
import fileDownload from 'js-file-download';

/** 마이페이지 - 내 강의 API */
export const getMyLecture = async () => {
  return await useAxios.get(`/myinfo/lecture`);
};

/** 마이페이지 - 강의 자료 다운로드 API */
export const getLectureVideoReference = async (id, fileTitle) => {
  return await useDownloadAxios
    .get(`/lecture/video/file/${id}`, { responseType: 'blob' })
    .then((res) => {
      let filename = `${fileTitle}`.replace(/(\r\n\t|\n|\r\t)/gm, '');
      fileDownload(res.data, filename);
    });
};

/** 마이페이지 - 내 일반패스 API */
export const getMyPass = async () => {
  return await useAxios.get(`/myinfo/pass`);
};

/** 내 회원정보 수정 API */
export const modifyMyInfo = async (data) => {
  return await useAxios.put('/myinfo/info', data);
};

/** 내 회원정보 - 번호 수정 문자 인증 요청 API */
export const requestModifyPhoneCertification = async (data) => {
  return await useAxios.post('/auth/phone/modify/request', data);
};

/** 내 회원정보 - 번호 수정 문자 인증 확인 API */
export const requestModifyPhone = async (data) => {
  return await useAxios.post('/auth/phone/modify', data);
};

/** 마이페이지 - 내 결제내역 API */
export const getMyPaymentInfo = async (startAt, endAt) => {
  return await useAxios.get(`/myinfo/payment?startAt=${startAt}&endAt=${endAt}`);
};

/** 마이페이지 - 내 학습질문 API */
export const getMyProfessorQnaList = async () => {
  return await useAxios.get(`/professor/qna/list/my`);
};

/** 마이페이지 - 내 1:1 문의 API */
export const getMyOneOnOneInquiry = async () => {
  return await useAxios.get(`/center/one-on-one-inquiry/list`);
};

/** 마이페이지 - 강의 시작 API */
export const getLectureStart = async (id) => {
  return await useAxios.get(`/lecture/start/${id}`);
};

/** 마이페이지 - 로그인 한 경우 내 정보 가져오기 */
export const getMyInfo = async () => {
  return await useAxios.get(`/myinfo/info`);
};

/** 내 1:1 질문 수정 API */
export const ModifyOneOnOneInquiryRequest = async (data) => {
  return await useAxios.put('/center/one-on-one-inquiry', data);
};

/** 내 1:1 질문 삭제 API */
export const deleteOneOnOneInquiry = async (id) => {
  return await useAxios.delete(`/center/one-on-one-inquiry/${id}`);
};

/** 교환 환불 API */
export const cancelAndChangeRequest = async (data) => {
  return await useAxios.post('/payment/cancel-change', data);
};

/** 교환 환불 취소현황 API */
export const getCancelAndChangeStatus = async (id) => {
  return await useAxios.get(`/payment/cancel-change/status/${id}`);
};
