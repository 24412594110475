import React from 'react';
import {
  StyledBasicCheckBox,
  StyledCircleCheckboxWrapper,
  StyledCircleCheckbox,
  StyledCircleLabel,
} from './style';

export const BasicCheckBox = ({ checkboxName, isChecked, onChangeFunc, onClickFunc }) => {
  return (
    <StyledBasicCheckBox
      name={checkboxName}
      type="checkbox"
      checked={isChecked}
      onChange={onChangeFunc}
      onClick={onClickFunc}
    />
  );
};

export const CircleCheckBox = ({ width, height, isChecked, onClickFunc, onChangeFunc }) => {
  return (
    <StyledCircleCheckboxWrapper width={width} height={height}>
      <StyledCircleCheckbox type="checkbox" isChecked={isChecked} />
      <StyledCircleLabel
        width={width}
        height={height}
        onClick={onClickFunc}
        onChange={onChangeFunc}
        isChecked={isChecked}
      />
    </StyledCircleCheckboxWrapper>
  );
};
