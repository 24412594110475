import React, { useEffect, useRef, useState } from 'react';
import LectureReviewCard from '../../../molecules/LectureReviewCard';
import {
  CardBox,
  LectureReviewControllerBox,
  LectureReviewDot,
  LectureReviewSlide,
  SlidesContainer,
} from './style';

import { useQuery } from 'react-query';
import useHandleError from '../../../../hooks/useHandleError';
import { getBestReviewList } from '../../../../axiosManage/Professor';

const LectureReviewSlides = ({ professorId }) => {
  const { handleError } = useHandleError();

  /** url을 통한 교수 ID 설정 */
  const [ProfessorId, setProfessorId] = useState(1);
  useEffect(() => {
    setProfessorId(professorId);
  }, [professorId]);

  /** 교수별 베스트 수강후기 */
  const [professorBestReviewList, setProfessorBestReviewList] = useState([]);
  const { refetch: professorBestReviewListRefetch } = useQuery(
    ['getBestReviewList', ProfessorId],
    () => getBestReviewList(ProfessorId),
    {
      retry: 0,
      onSuccess: (data) => {
        setProfessorBestReviewList(data.data.reviews);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const [SlideNum, setSlideNum] = useState(0);
  const [ActiveDots, setActiveDots] = useState({ dot1: true, dot2: false, dot3: false });
  const MAX_NUM = professorBestReviewList.length;
  const slideRef = useRef();

  const activeFunc = (e) => {
    for (let key in ActiveDots) {
      if (key === e.target.id) {
        ActiveDots[key] = true;
        setSlideNum(key.slice(3) - 1);
      } else {
        ActiveDots[key] = false;
      }
    }
  };

  useEffect(() => {
    slideRef.current.style.transition = 'all 0.5s ease';
    slideRef.current.style.transform = `translateX(-${SlideNum}00%)`;

    const slideInterval = setInterval(() => {
      setSlideNum(() => {
        if (SlideNum < MAX_NUM - 1) {
          setSlideNum(SlideNum + 1);
        } else {
          setSlideNum(0);
        }
      });
    }, 3000);

    // if (SlideNum === 0) {
    //   setActiveDots({
    //     dot1: true,
    //     dot2: false,
    //     dot3: false,
    //   });
    // } else if (SlideNum === 1) {
    //   setActiveDots({
    //     dot1: false,
    //     dot2: true,
    //     dot3: false,
    //   });
    // } else {
    //   setActiveDots({
    //     dot1: false,
    //     dot2: false,
    //     dot3: true,
    //   });
    // }

    return () => {
      clearInterval(slideInterval);
    };
  }, [SlideNum]);

  return (
    <SlidesContainer>
      <LectureReviewSlide ref={slideRef}>
        {professorBestReviewList !== undefined ? (
          <>
            {professorBestReviewList.map((review) => (
              <div key={review.id}>
                <LectureReviewCard
                  keyword={review.title}
                  content={review.content
                    .replaceAll('<p>', "")
                    .replaceAll("</p>", "")
                    .replaceAll("<br>", "")}
                  title={review.title}
                  writer={review.name}
                />
              </div>
            ))}
          </>
        ) : (
          ''
        )}
      </LectureReviewSlide>
      <LectureReviewControllerBox>
        {/* <LectureReviewDot
          id="dot1"
          className={ActiveDots.dot1 ? 'active' : ''}
          onClick={activeFunc}
        />
        <LectureReviewDot
          id="dot2"
          className={ActiveDots.dot2 ? 'active' : ''}
          onClick={activeFunc}
        />
        <LectureReviewDot
          id="dot3"
          className={ActiveDots.dot3 ? 'active' : ''}
          onClick={activeFunc}
        /> */}
      </LectureReviewControllerBox>
    </SlidesContainer>
  );
};

export default LectureReviewSlides;
