import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

export const CouponModalStyle = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;

  .CouponModal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1089px !important;
  }
`;

export const CouponBackGround = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const Content = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 300px;
  padding: 40px;
  background-color: ${COLOR.GRAY_0};
  box-shadow: 0px 3px 15px #00000029;
  border-radius: 22px;

  & > div {
    :nth-child(1) {
      font-size: 30px;
      ${FONT_STYLES.P_M};
      margin-bottom: 30px;
    }
    :nth-child(2) {
      margin-bottom: 30px;
    }
  }
`;
