import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const QuestionsContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-top: 70px;
  padding-bottom: 120px;
  text-align: center;

  @media screen and (max-width: 1700px) {
    padding-top: 70px;
    padding-bottom: 120px;
  }
`;

const QuestionsTitle = styled.div`
  ${FONT_STYLES.P_B};
  font-size: 40px;
  margin-bottom: 30px;
`;

const QuestionsCategoryBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
`;

const QuestionsListBox = styled.div`
  text-align: left;

  & > div {
    cursor: pointer;
    & > div {
      :nth-child(1) {
        display: flex;
        margin: 40px 0;

        & > svg {
          margin: auto 0 auto auto;

          &.active {
            translate: all 0.5s;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
`;

const ActiveTab = styled.div`
  ${FONT_STYLES.P_L}
  width: 160px;
  height: 190px;
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #716b6b;
  cursor: pointer;

  &.active {
    ${FONT_STYLES.P_B}
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 18px;
    color: ${COLOR.BLACK_1};
  }

  & > div {
    & > p:nth-child(2) {
      padding-top: 15px;
    }
  }
`;

const PaginationBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;

  & > svg {
    cursor: pointer;
  }

  .prev-btn {
    margin-right: 30px;
  }
  .next-btn {
    margin-left: 30px;
  }
`;

export {
  QuestionsContainer,
  QuestionsTitle,
  QuestionsCategoryBox,
  QuestionsListBox,
  ActiveTab,
  PaginationBox,
};
