import styled, { css } from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

const RegisterJoinPageContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding};
  text-align: center;
  padding-top: 70px;
  padding-bottom: 120px;

  & > button {
    margin-top: 40px;
  }

  @media screen and (max-width: 1700px) {
    padding-top: 70px;
    padding-bottom: 120px;
  }
`;

const RegisterPageTitle = styled.div`
  ${FONT_STYLES.P_M};
  font-size: 36px;
`;

const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_4};
  margin: 50px auto;
`;

const RegisterContent = styled.div`
  padding: 0 100px;
  margin-top: 30px;

  & > div {
    ${FONT_STYLES.P_R}
    display: flex;
    align-items: center;
    margin-bottom: 27px;

    & > p {
      :nth-child(1) {
        text-align: left;
        width: 130px;
      }
    }

    :nth-child(3) {
      & > span {
        margin-left: 20px;
        color: red;
        font-size: 14px;
        ${({ isPasswordSame }) =>
          isPasswordSame
            ? css`
                display: none;
              `
            : css`
                display: block;
              `}
      }
    }

    :nth-child(5) {
      & > span {
        margin: 0 20px;
      }

      & > p {
        :nth-child(4) {
          margin-right: 8px;
        }
      }
    }

    :nth-child(7) {
      & > span {
        padding: 0 11px;
      }

      & > select {
        margin-left: 17px;
      }
    }

    :nth-child(8) {
      & > span {
        padding: 0 9px;
      }
      & > button {
        margin-left: 17px;
      }
    }

    :nth-child(9) {
      & > span {
        padding: 0 9px;
      }
      & > button {
        margin-left: 17px;
      }
    }

    :nth-child(10),
    :nth-child(11) {
      margin-bottom: 11px;
    }
  }
`;

const Border = styled.div`
  border-bottom: 1px solid ${COLOR.GRAY_9};
`;

export { RegisterJoinPageContainer, RegisterPageTitle, HorizonLine, RegisterContent, Border };
