import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

const LoginPageContainer = styled.div`
  ${({ theme }) => theme.container.mainContainer};
`;

const LoginContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  margin-top: 150px;
  padding-bottom: 210px;
  text-align: center;

  & > img {
    margin-bottom: 76px;
  }

  & > input {
    display: block;
    margin: 20px auto;
    padding: 10px;
    box-shadow: 0px 3px 10px #00000029;
    border: none;
    border-radius: 7px;
    background-color: ${COLOR.GRAY_0};
  }
`;

const LoginTitle = styled.div`
  ${FONT_STYLES.P_M};
  font-size: 36px;
  margin-bottom: 50px;
`;

const InputBox = styled.input`
  display: block;
  margin: 20px auto;
  width: 450px;
  height: 60px;
  padding: 10px;
  box-shadow: 0px 3px 10px #00000029;
  border: none;
  border-radius: 7px;

  :focus {
    outline: none;
  }
`;

const LoginOptionBox = styled.div`
  ${FONT_STYLES.P_M};
  font-size: 14px;
  display: flex;
  width: 450px;
  margin: 20px auto;

  p:nth-child(1) {
    display: flex;
    align-items: center;
    & > span {
      padding-left: 10px;
    }
  }

  a:nth-child(2) {
    margin-left: auto;
  }
`;

const LoginButton = styled.button`
  width: 450px;
  height: 60px;
  margin: 10px 0 40px 0;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 7px;
  background-color: ${COLOR.BLUE_4};
  ${FONT_STYLES.P_M};
  font-size: 18px;
  color: ${COLOR.GRAY_0};
`;

const OauthLoginBox = styled.div`
  width: 520px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  & > div,
  & > a {
    cursor: pointer;
    :nth-child(1) {
      margin-left: 20px;
      margin-right: 25px;
    }
    & > p {
      ${FONT_STYLES.P_M};
      font-size: 18px;
      padding-top: 17px;
    }
  }
`;

export {
  LoginPageContainer,
  LoginContainer,
  LoginTitle,
  InputBox,
  LoginOptionBox,
  LoginButton,
  OauthLoginBox,
};
