import React, { useEffect, useState } from 'react';
import Pagination from '../../../molecules/Pagination';
import { PaginationBox } from '../../ExamInfoPageElements/PassNote/style';
import { Line, TabTitle } from '../Lectures/style';
import {
  BestTag,
  ReviewContainer,
  ReviewContent,
  ReviewTable,
  ButtonBox,
  ButtonContainer,
  DataNotFound,
} from './style';

import { Button } from '../../../atoms/Button/index';
import { ReactComponent as PrevBtn } from '../../../../assets/icons/icon-PrevBtnFull.svg';
import { ReactComponent as NextBtn } from '../../../../assets/icons/icon-NextBtnFull.svg';
import Filter from '../../../molecules/Filter';
import Search from '../../../molecules/Search';

import { useNavigate, useParams } from 'react-router-dom';
import ReviewDetailPage from '../../../../pages/ReviewDetail/ReviewDetailPage';

import { SpinLoader, typeList } from '../../../atoms/SpinLoader';
import { useChangeMonthNumToEn } from '../../../../hooks/useChangeMonthNumToEn';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  professorReviewPageInfoAtom,
  professorReviewListAtom,
} from '../../../../recoil/ProfessorStore/ProfessorRecoil';
import { selectedProfessorReviewFilterMenuAtom } from '../../../../recoil/SelectedFilterStore/SelectedFilterRecoil';

import { useQuery } from 'react-query';
import useHandleError from '../../../../hooks/useHandleError';
import {
  getReviewPageInfo,
  getReviewList,
  getBestReviewList,
} from '../../../../axiosManage/Professor';

import { isLoginAtom } from '../../../../recoil/AuthStore/AuthRecoil';

const FILTER_MENU = [
  {
    id: 0,
    category: '자료해석 기준',
  },
  {
    id: 1,
    category: '강의1',
  },
  {
    id: 2,
    category: '강의2',
  },
];

const Review = ({ professorId }) => {
  const isLogin = useRecoilValue(isLoginAtom);
  const { handleError } = useHandleError();
  const { id } = useParams();
  const navigate = useNavigate();

  /** url을 통한 교수 ID 설정 */
  const [ProfessorId, setProfessorId] = useState(1);
  useEffect(() => {
    setProfessorId(professorId)
  }, [professorId]);

  /** 교수별 베스트 수강후기 */
  const [professorBestReviewList, setProfessorBestReviewList] = useState([]);
  const { refetch: professorBestReviewListRefetch } = useQuery(
    ['getBestReviewList', ProfessorId],
    () => getBestReviewList(ProfessorId),
    {
      retry: 0,
      onSuccess: (data) => {
        setProfessorBestReviewList(data.data.reviews);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  /* 페이지네이션 */
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(6);

  const clickPrevBtn = (e) => {
    setCurrentPage(currentPage - 1);
  };
  const clickNextBtn = (e) => {
    setCurrentPage(currentPage + 1);
  };

  /** 필터링 & 검색 기능 */
  const [keyword, setKeyword] = useState('');
  const [isSearchBtnClicked, setIsSearchBtnClicked] = useState(false);
  const keywordCheck = (e) => {
    setKeyword(e.currentTarget.value);
  };

  // 1. 유저가 선택한 필터링 카테고리 목록
  const selectedProfessorReviewFilterCategory = useRecoilValue(
    selectedProfessorReviewFilterMenuAtom,
  );

  // 2. 서버에서 받아온 페이징 정보를 전역 상태에 저장
  const [professorReviewPageInfo, setProfessorReviewPageInfo] = useRecoilState(
    professorReviewPageInfoAtom,
  );
  const { refetch: professorQuestionPageInfoRefetch } = useQuery(
    ['getReviewPageInfo', ProfessorId, selectedProfessorReviewFilterCategory],
    () =>
      getReviewPageInfo(
        ProfessorId,
        keyword,
        String([...selectedProfessorReviewFilterCategory]).replace(/,/g, ' '),
      ),
    {
      retry: 0,
      onSuccess: (data) => {
        setProfessorReviewPageInfo(data.data.total);
        if (isSearchBtnClicked) {
          setIsSearchBtnClicked(false);
        }
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  useEffect(() => {
    professorQuestionPageInfoRefetch();
    setCurrentPage(1);
  }, [isSearchBtnClicked]);

  // 3. 서버에서 받아온 실제 데이터를 전역 상태에 저장
  const [professorReviewList, setProfessorReviewList] = useRecoilState(professorReviewListAtom);
  const { refetch: professorQuestionListRefetch } = useQuery(
    ['getReviewList', ProfessorId, selectedProfessorReviewFilterCategory, currentPage],
    () =>
      getReviewList(
        ProfessorId,
        currentPage,
        keyword,
        String([...selectedProfessorReviewFilterCategory]).replace(/,/g, ' '),
      ),
    {
      retry: 0,
      enabled: false,
      onSuccess: (data) => {
        setProfessorReviewList(data.data.reviews);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  useEffect(() => {
    professorQuestionListRefetch();
    professorBestReviewListRefetch();
  }, [selectedProfessorReviewFilterCategory, isSearchBtnClicked, currentPage, navigate]);

  // 상세페이지 -> 이전페이지로 이동해도 페이지 유지
  useEffect(() => {
    if (navigate) {
      setCurrentPage(currentPage);
    }
  }, [navigate]);

  return (
    <>
      {id ? (
        <ReviewDetailPage />
      ) : (
        <ReviewContainer>
          <TabTitle>수강후기</TabTitle>
          <Line />
          <ButtonContainer>
            {isLogin === true ? (
              <Button
                width={124}
                height={48}
                onClickFunc={() =>
                  (navigate(`/lecture/${professorId}/reviewCreate`))
                }
              >
                글쓰기
              </Button>
            ) : (
              ''
            )}
          </ButtonContainer>
          <ButtonBox>
            <Filter
              filterMenu={FILTER_MENU}
              filterName={'카테고리'}
              filterLocation={'교수 수강후기'}
              setCurrentPage={setCurrentPage}
            />
            <Search keywordCheck={keywordCheck} setIsSearchBtnClicked={setIsSearchBtnClicked} />
          </ButtonBox>
          <ReviewTable>
            {professorBestReviewList !== undefined ? (
              <>
                {professorBestReviewList.map((data) => (
                  <ReviewContent
                    key={data.id}
                    onClick={() =>
                      (navigate(`/lecture/${professorId}/review/${data.id}`))
                    }
                  >
                    <BestTag>BEST</BestTag>
                    <div>
                      <p>{useChangeMonthNumToEn(data.createdAt.slice(5, 7))}</p>
                      <p>{data.createdAt.slice(8, 10)}</p>
                      <p>{data.createdAt.slice(0, 4)}</p>
                    </div>
                    <div>
                      <p>{data.lectureName}</p>
                      <p>{data.title}</p>
                    </div>
                    <div>
                      <p>작성자</p>
                      <p>{data.name}</p>
                    </div>
                    <div>
                      <p>조회</p>
                      {data.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
                  </ReviewContent>
                ))}
              </>
            ) : (
              ''
            )}
            {professorReviewList !== undefined ? (
              professorReviewList.length < 1 ? (
                <DataNotFound>
                  <p>게시글이 존재하지 않습니다!</p>
                </DataNotFound>
              ) : (
                <>
                  {professorReviewList.map((data) => (
                    <ReviewContent
                      key={data.id}
                      onClick={() =>
                        (navigate(`/lecture/${professorId}/review/${data.id}`))
                      }
                    >
                      <div>{data.id}</div>
                      <div>
                        <p>{useChangeMonthNumToEn(data.createdAt.slice(5, 7))}</p>
                        <p>{data.createdAt.slice(8, 10)}</p>
                        <p>{data.createdAt.slice(0, 4)}</p>
                      </div>
                      <div>
                        <p>{data.lectureName}</p>
                        <p>{data.title}</p>
                      </div>
                      <div>
                        <p>작성자</p>
                        <p>{data.name}</p>
                      </div>
                      <div>
                        <p>조회</p>
                        {data.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </div>
                    </ReviewContent>
                  ))}
                </>
              )
            ) : (
              <SpinLoader type={typeList[2].prop} color={'#000'} width={1311} height={715} />
            )}
          </ReviewTable>
          <PaginationBox>
            <PrevBtn
              className="prev-btn"
              onClick={(e) => {
                if (currentPage === 1) {
                  e.preventDefault();
                } else {
                  clickPrevBtn();
                }
              }}
            />
            <Pagination
              currentPage={currentPage}
              postsPerPage={postsPerPage}
              totalPosts={professorReviewPageInfo}
              paginate={setCurrentPage}
            />
            <NextBtn
              className="next-btn"
              onClick={(e) => {
                if (currentPage === Math.ceil(professorReviewPageInfo / postsPerPage)) {
                  e.preventDefault();
                } else {
                  clickNextBtn();
                }
              }}
            />
          </PaginationBox>
        </ReviewContainer>
      )}
    </>
  );
};

export default Review;
