import React, { useState, useRef, useEffect } from 'react';
import { ContactContainer, ContactTitle, FormContainer, ButtonContainer } from './style';

import { useMutation } from 'react-query';
import { ModifyOneOnOneInquiryRequest } from '../../axiosManage/MyPage';

import Input from '../../components/atoms/Input';
import { Button, CancelButton } from '../../components/atoms/Button/index';
import ToastEditor from '../../components/atoms/ToastTextEditor';
import Select from '../../components/atoms/Select';
import COLOR from '../../styles/common/color';

import useHandleError from '../../hooks/useHandleError';

import { useRecoilValue, useRecoilState } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';
import { myInfoAtom, myContactAtom } from '../../recoil/MyPageStore/MyPageRecoil';

import { useNavigate, Navigate } from 'react-router-dom';

function ContactModifyPage() {
  const { handleError } = useHandleError();
  const editorRef = useRef();
  const navigate = useNavigate();
  const myInfo = useRecoilValue(myInfoAtom);
  const isLogin = useRecoilValue(isLoginAtom);

  if (isLogin === false) {
    alert('로그인을 해주세요.');
    return <Navigate to="/login" />;
  }

  const [contactContent, setContactContent] = useRecoilState(myContactAtom);

  const SELECT_OPTIONS = [
    {
      value: contactContent.category,
      optionName:
        contactContent.category === 'PARCEL'
          ? '배송'
          : contactContent.category === 'PAYMENT'
          ? '결제/환불'
          : contactContent.category === 'VIDEO'
          ? '동영상'
          : contactContent.category === 'WEBPAGE'
          ? '사이트 이용'
          : contactContent.category === 'MOBILE'
          ? '모바일'
          : contactContent.category === 'BOOK'
          ? '교재'
          : '',
    },
    { value: 'PARCEL', optionName: '배송' },
    { value: 'PAYMENT', optionName: '결제/환불' },
    { value: 'VIDEO', optionName: '동영상' },
    { value: 'WEBPAGE', optionName: '사이트 이용' },
    { value: 'MOBILE', optionName: '모바일' },
    { value: 'BOOK', optionName: '교재' },
  ];

  if (isLogin === false) {
    alert('로그인을 해주세요.');
    return <Navigate to="/login" />;
  }

  // 유저가 입력한 서버로 전송할 데이터(제목, 내용, 카테고리)
  const [title, setTitle] = useState(contactContent.title);
  const [category, setCategory] = useState(contactContent.category);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  // useEffect(() => {
  //   editorRef.current.context = contactContent.question;
  // }, [contactContent]);

  /** 1:1 문의 글 전송 로직 */
  const postModifyInquiry = useMutation(
    (data) => {
      ModifyOneOnOneInquiryRequest(data);
    },
    {
      onSuccess: () => {
        alert('수정이 완료되었습니다!');
        navigate('/mypage');
      },
      onError: (error) => {
        alert('수정에 실패했습니다.');
        handleError(error);
      },
    },
  );

  const modifySubmit = () => {
    const data = {
      id: contactContent.id,
      title: title,
      content: editorRef.current?.getInstance().getHTML(),
      category: category,
    };

    if (
      data.title !== '' &&
      !data.content.includes('위에 구분을 먼저 선택 후 글을 작성해주시기 바랍니다!') &&
      data.category !== '구분'
    ) {
      postModifyInquiry.mutate(data);
    } else {
      alert('제목, 내용, 카테고리를 모두 입력해주세요!');
    }
  };

  const modifyCancel = () => {
    let isYes = confirm('수정을 취소하시겠습니까?');

    if (isYes) {
      alert('수정을 취소하셨습니다.');
      navigate(-1);
    }
  };

  return (
    <ContactContainer>
      <ContactTitle>
        1:1 문의
        <p>1:1 문의 수정하기</p>
      </ContactTitle>
      <FormContainer>
        <div>
          <Select
            width={313}
            height={58}
            selectOptions={SELECT_OPTIONS}
            onChangeFunc={handleCategoryChange}
          />
          <Input
            width={645}
            height={58}
            placeholder="제목을 입력하세요."
            defaultValue={title}
            onChangeFunc={handleTitleChange}
          />
          {isLogin ? (
            <div>이름: {myInfo.name}</div>
          ) : (
            <Input width={313} height={58} placeholder="이름을 입력하세요." />
          )}
        </div>
        <ToastEditor editorRef={editorRef} content={contactContent.question} />
        <div>
          <ButtonContainer>
            <CancelButton
              width={124}
              height={48}
              bgColor={COLOR.BLUE_4}
              onClickFunc={() => modifyCancel()}
            >
              취소
            </CancelButton>
            <Button width={124} height={48} bgColor={COLOR.BLUE_4} onClickFunc={modifySubmit}>
              수정하기
            </Button>
          </ButtonContainer>
        </div>
      </FormContainer>
    </ContactContainer>
  );
}

export default ContactModifyPage;
