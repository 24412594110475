import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

export const MyLecturePageContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-bottom: 150px;
  padding-top: 60px;

  @media screen and (max-width: 1700px) {
    padding-top: 60px;
    padding-bottom: 150px;
  }
`;

export const MyLectureTitle = styled.div`
  ${FONT_STYLES.P_B};
  font-size: 36px;
  text-align: center;
`;

export const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_3};
  margin: 43px auto;
`;

export const LectureBox = styled.div`
  margin: 20px 0;
  padding: 0 39px;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;
`;

export const PassBox = styled.div`
  margin: 20px 0;
  padding: 20px 39px;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;
`;

export const PassItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    :nth-child(1) {
      ${FONT_STYLES.P_M};
      font-size: 25px;
    }
    :nth-child(2) {
      & > span {
        :nth-child(1) {
          padding-right: 10px;
          ${FONT_STYLES.P_SB};
          font-size: 20px;
        }
      }
    }
  }
`;

export const RegularPassBox = styled.div`
  margin: 20px 0;
  padding: 20px 39px;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;
`;

export const LectureBoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 25px 0;
  margin: 0 42px;

  & > div {
    width: 50%;
    padding: 10px 0;
    cursor: pointer;

    :nth-child(1) {
      border-right: 1px solid ${COLOR.GRAY_9};
    }
  }

  li {
    ${FONT_STYLES.P_R}
    color: #716b6b;
    list-style: none;

    & > span {
      font-size: 18px;
      margin: 0 5px;
      cursor: pointer;
    }

    &.active {
      ${FONT_STYLES.P_SB}
      color: ${COLOR.BLACK_1};
    }
  }
`;

export const LectureItems = styled.div``;

export const Lecture = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 60px 20px 18px;
  border-top: 1px solid ${COLOR.GRAY_9};
  cursor: pointer;

  & > div {
    :nth-child(1) {
      width: 30px;
      ${FONT_STYLES.P_R}
    }
    :nth-child(2) {
      width: 150px;
      img {
        width: 139px;
        height: 139px;
        border-radius: 18px;
      }
    }
    :nth-child(3) {
      width: 60px;
      text-align: center;

      & > p {
        padding: 2.5px 0;
        :nth-child(1) {
          ${FONT_STYLES.P_T}
          font-size: 15px;
        }
        :nth-child(2) {
          ${FONT_STYLES.P_R}
        }
      }
    }
    :nth-child(4) {
      width: 300px;
      margin-left: 30px;
      margin-right: 50px;
      text-align: left;
      line-height: 1.2;

      & > p {
        padding: 4px 0;
        :nth-child(1) {
          ${FONT_STYLES.P_L}
          font-size: 13px;
        }
        :nth-child(2) {
          ${FONT_STYLES.P_SB}
          font-size: 20px;
        }
        :nth-child(3) {
          ${FONT_STYLES.P_R}

          & > span {
            color: #716b6b;
            padding-right: 12px;
          }
        }
      }
    }
    :nth-child(5) {
      width: 230px;
      text-align: center;
      margin-right: 58px;

      & > p {
        :nth-child(1) {
          ${FONT_STYLES.P_SB}
          font-size: 20px;
          padding-bottom: 15px;
        }
        :nth-child(2) {
          & > span {
            padding: 0 5px;
          }
        }
      }
    }
    :nth-child(6) {
      width: 120px;
      & > div {
        & > div {
          display: block;

          & > p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            :nth-child(1) {
              top: 35%;
            }
            :nth-child(2) {
              top: 65%;
              left: 55%;
              ${FONT_STYLES.P_B}
              font-size: 20px;
            }
          }
        }
      }
    }
  }
`;

export const GuideBox = styled.div`
  text-align: left;
  margin: 20px 0;
  padding: 28px 62px;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;

  & > div {
    :nth-child(1) {
      ${FONT_STYLES.P_B}
      font-size: 20px;
      margin-bottom: 20px;
    }
    :nth-child(2) {
      ${FONT_STYLES.P_R}
      white-space: pre-line;
      line-height: normal;
    }
  }
`;
