import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

const RegisterCompletePageContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding};
  text-align: center;
  padding-top: 70px;
  padding-bottom: 120px;

  & > button {
    margin-top: 40px;
  }

  @media screen and (max-width: 1700px) {
    padding-top: 70px;
    padding-bottom: 120px;
  }
`;

const RegisterPageTitle = styled.div`
  ${FONT_STYLES.P_M};
  font-size: 36px;
`;

const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_4};
  margin: 50px auto;
`;

const RegisterContent = styled.div`
  margin-top: 30px;

  & > svg {
    margin: 32px 0 31px 0;
  }

  & > p {
    :nth-child(2) {
      margin-bottom: 42px;
    }
    :nth-child(3) {
      ${FONT_STYLES.P_R}
      font-size: 36px;
      margin-bottom: 25px;
    }
    :nth-child(4),
    :nth-child(5) {
      ${FONT_STYLES.P_L}
      font-size: 20px;
      padding-bottom: 10px;
    }
    :nth-child(5) {
      margin-bottom: 52px;
    }
  }
`;

export { RegisterCompletePageContainer, RegisterPageTitle, HorizonLine, RegisterContent };
