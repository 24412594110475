import React, { useState, useEffect } from 'react';
import {
  StudyTipContainer,
  StudyTipTitle,
  HorizonLine,
  MenuBox,
  StudyTipSubTitle,
  StudyTipContentBox,
  StudyTipContent,
  LoadMoreBtn,
} from './style';

import StudyTipDetailPage from '../StudyTipDetail/StudyTipDetailPage';

import Filter from '../../../molecules/Filter';
import Search from '../../../molecules/Search';

import { SpinLoader, typeList } from '../../../atoms/SpinLoader';

import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil';
import {
  examInfoStudyTipPageInfoAtom,
  examInfoStudyTipListAtom,
} from '../../../../recoil/ExamInfoStore/ExamInfoRecoil';
import { selectedStudyTipFilterMenuAtom } from '../../../../recoil/SelectedFilterStore/SelectedFilterRecoil';

import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getTipPageInfo, getTipList } from '../../../../axiosManage/ExamInfo';
import useHandleError from '../../../../hooks/useHandleError';

const FILTER_MENU = [
  {
    id: 0,
    category: '5급 공채',
  },
  {
    id: 1,
    category: '국립외교원',
  },
  {
    id: 2,
    category: '지역인재',
  },
  {
    id: 3,
    category: '7급 공채',
  },
  {
    id: 4,
    category: '공통',
  },
];

function StudyTip() {
  const { handleError } = useHandleError();
  const { id } = useParams();
  const navigate = useNavigate();

  /** 필터링 & 검색 기능 */
  const [keyword, setKeyword] = useState('');
  const [isSearchBtnClicked, setIsSearchBtnClicked] = useState(false);
  const keywordCheck = (e) => {
    setKeyword(e.currentTarget.value);
  };

  // 1. 유저가 선택한 필터링 카테고리 목록
  const selectedStudyTipFilterCategory = useRecoilValue(selectedStudyTipFilterMenuAtom);

  // 2. 서버에서 받아온 페이징 정보를 전역 상태에 저장
  const [examInfoStudyTipPageInfo, setExamInfoStudyTipPageInfo] = useRecoilState(
    examInfoStudyTipPageInfoAtom,
  );
  const { refetch: examInfoStudyTipPageInfoRefetch } = useQuery(
    ['getTipPageInfo', selectedStudyTipFilterCategory],
    () => getTipPageInfo(String([...selectedStudyTipFilterCategory]).replace(/,/g, ' '), keyword),
    {
      retry: 0,
      onSuccess: (data) => {
        setExamInfoStudyTipPageInfo(data.data.total);
        if (isSearchBtnClicked) {
          setIsSearchBtnClicked(false);
        }
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  useEffect(() => {
    examInfoStudyTipPageInfoRefetch();
    //setCurrentPage(1);
  }, [isSearchBtnClicked]);

  /** 더보기 버튼 기능 */
  const [currentPage, setCurrentPage] = useState(1);
  const loadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  // 3. 서버에서 받아온 실제 데이터를 전역 상태에 저장
  const [examInfoStudyTipList, setExamInfoStudyTipList] = useRecoilState(examInfoStudyTipListAtom);
  const { refetch: examInfoStudyTipListRefetch } = useQuery(
    ['getTipList', selectedStudyTipFilterCategory, currentPage],
    () =>
      getTipList(
        currentPage,
        String([...selectedStudyTipFilterCategory]).replace(/,/g, ' '),
        keyword,
      ),
    {
      retry: 0,
      enabled: false,
      onSuccess: (data) => {
        setExamInfoStudyTipList([...examInfoStudyTipList, ...data.data.tips]);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const resetStudyTipList = useResetRecoilState(examInfoStudyTipListAtom);

  useEffect(() => {
    resetStudyTipList();
  }, [selectedStudyTipFilterCategory, isSearchBtnClicked]);

  useEffect(() => {
    examInfoStudyTipListRefetch();
  }, [selectedStudyTipFilterCategory, isSearchBtnClicked, currentPage, navigate]);

  useEffect(() => {
    if (navigate) {
      setCurrentPage(currentPage);
    }
  }, [navigate]);

  return (
    <>
      {id ? (
        <StudyTipDetailPage />
      ) : (
        <StudyTipContainer>
          <StudyTipTitle>랩스탠다드 학습 TIP</StudyTipTitle>
          <StudyTipSubTitle>최고의 교수진이 말하는 꿀팁 받아가세요</StudyTipSubTitle>
          <HorizonLine />
          <MenuBox>
            <Filter
              filterMenu={FILTER_MENU}
              filterName={'카테고리'}
              filterLocation={'수험정보&팁 학습 팁'}
              setCurrentPage={setCurrentPage}
            />
            <Search keywordCheck={keywordCheck} setIsSearchBtnClicked={setIsSearchBtnClicked} />
          </MenuBox>
          {/* 컨텐츠 박스 */}
          <StudyTipContentBox>
            {examInfoStudyTipList !== undefined ? (
              <>
                {examInfoStudyTipList.map((data, i) => (
                  <StudyTipContent
                    key={i}
                    onClick={(e) => (navigate(`/examinfo/studytip/${data.id}`))}
                  >
                    <img src={data.img} />
                    <p>{data.category}</p>
                    <p>{data.title}</p>
                    <p>{data.date}</p>
                  </StudyTipContent>
                ))}
              </>
            ) : (
              <SpinLoader type={typeList[2].prop} color={'#000'} width={1920} height={1000} />
            )}
          </StudyTipContentBox>
          <LoadMoreBtn onClick={loadMore}>더보기 +</LoadMoreBtn>
        </StudyTipContainer>
      )}
    </>
  );
}

export default StudyTip;
