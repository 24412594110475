import React from 'react';
import SearchBar from '../../../molecules/SearchBar'
// 배너 스타일
import { BannerContainer, BannerTextContainer } from './style';
// 배너 이미지 에셋
import SupportCenterBanner from '../../../../assets/images/image-supportcenter-banner.png';

function Banner() {
  return (
    <BannerContainer backgroundImg={SupportCenterBanner}>
      <BannerTextContainer>
        <p>학습지원센터</p>
        <p>무엇을 도와드릴까요?</p>
        {/* <SearchBar/> */}
      </BannerTextContainer>
    </BannerContainer>
  );
}

export default Banner;
