import React, { useState } from 'react';
import {
  ExamInfoContentBox,
  ExamInfoGraphContainer,
  Table,
  TableData,
  TableHeader,
  TableDataSlice,
  PageNumber,
  TableSubHeader,
  TableSubHeaderContainer,
  TableDataContainer,
  Data,
} from '../common/graph-style';

import { ReactComponent as PrevBtn } from '../../../../assets/icons/icon-PrevBtnFullTypeCol.svg';
import { ReactComponent as NextBtn } from '../../../../assets/icons/icon-NextBtnFullTypeCol.svg';

const TableSubHeaderItem1 = [
  {
    title: '모집인원',
  },
  {
    title: '출원인원',
  },
  {
    title: '합격인원',
  },
];

const TableSubHeaderItem2 = [
  {
    title: '경쟁률',
  },
  {
    title: '합격률',
  },
  {
    title: '합격선',
  },
];

const FirstTable = ({ firstTableData }) => {
  return (
    <Table>
      <TableHeader>
        <div>직렬</div>
        <div>
          <div>일반행정</div>
          <div>우정사업본부</div>
          <div>인사조직</div>
          <div>재경</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {firstTableData &&
          firstTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data>
                <div>
                  <p>{data.content.일반행정.모집인원}</p>
                  <p>{data.content.일반행정.출원인원}</p>
                  <p>{data.content.일반행정.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.일반행정.경쟁률}</p>
                  <p>{data.content.일반행정.합격률}</p>
                  <p>{data.content.일반행정.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.우정사업본부.모집인원}</p>
                  <p>{data.content.우정사업본부.출원인원}</p>
                  <p>{data.content.우정사업본부.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.우정사업본부.경쟁률}</p>
                  <p>{data.content.우정사업본부.합격률}</p>
                  <p>{data.content.우정사업본부.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.인사조직.모집인원}</p>
                  <p>{data.content.인사조직.출원인원}</p>
                  <p>{data.content.인사조직.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.인사조직.경쟁률}</p>
                  <p>{data.content.인사조직.합격률}</p>
                  <p>{data.content.인사조직.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.재경.모집인원}</p>
                  <p>{data.content.재경.출원인원}</p>
                  <p>{data.content.재경.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.재경.경쟁률}</p>
                  <p>{data.content.재경.합격률}</p>
                  <p>{data.content.재경.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const SecondTable = ({ secondTableData }) => {
  return (
    <Table>
      <TableHeader>
        <div>직렬</div>
        <div>
          <div>고용노동</div>
          <div>교육행정</div>
          <div>회계</div>
          <div>선거행정</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {secondTableData &&
          secondTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data>
                <div>
                  <p>{data.content.고용노동.모집인원}</p>
                  <p>{data.content.고용노동.출원인원}</p>
                  <p>{data.content.고용노동.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.고용노동.경쟁률}</p>
                  <p>{data.content.고용노동.합격률}</p>
                  <p>{data.content.고용노동.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.교육행정.모집인원}</p>
                  <p>{data.content.교육행정.출원인원}</p>
                  <p>{data.content.교육행정.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.교육행정.경쟁률}</p>
                  <p>{data.content.교육행정.합격률}</p>
                  <p>{data.content.교육행정.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.회계.모집인원}</p>
                  <p>{data.content.회계.출원인원}</p>
                  <p>{data.content.회계.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.회계.경쟁률}</p>
                  <p>{data.content.회계.합격률}</p>
                  <p>{data.content.회계.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.선거행정.모집인원}</p>
                  <p>{data.content.선거행정.출원인원}</p>
                  <p>{data.content.선거행정.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.선거행정.경쟁률}</p>
                  <p>{data.content.선거행정.합격률}</p>
                  <p>{data.content.선거행정.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const ThirdTable = ({ thirdTableData }) => {
  return (
    <Table>
      <TableHeader>
        <div>직렬</div>
        <div>
          <div>세무</div>
          <div>관세</div>
          <div>통계</div>
          <div>감사</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {thirdTableData &&
          thirdTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data>
                <div>
                  <p>{data.content.세무.모집인원}</p>
                  <p>{data.content.세무.출원인원}</p>
                  <p>{data.content.세무.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.세무.경쟁률}</p>
                  <p>{data.content.세무.합격률}</p>
                  <p>{data.content.세무.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.관세.모집인원}</p>
                  <p>{data.content.관세.출원인원}</p>
                  <p>{data.content.관세.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.관세.경쟁률}</p>
                  <p>{data.content.관세.합격률}</p>
                  <p>{data.content.관세.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.통계.모집인원}</p>
                  <p>{data.content.통계.출원인원}</p>
                  <p>{data.content.통계.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.통계.경쟁률}</p>
                  <p>{data.content.통계.합격률}</p>
                  <p>{data.content.통계.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.감사.모집인원}</p>
                  <p>{data.content.감사.출원인원}</p>
                  <p>{data.content.감사.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.감사.경쟁률}</p>
                  <p>{data.content.감사.합격률}</p>
                  <p>{data.content.감사.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const FourthTable = ({ fourthTableData }) => {
  return (
    <Table>
      <TableHeader>
        <div>직렬</div>
        <div>
          <div>교정</div>
          <div>보호</div>
          <div>검찰</div>
          <div>출입국관리</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {fourthTableData &&
          fourthTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data>
                <div>
                  <p>{data.content.교정.모집인원}</p>
                  <p>{data.content.교정.출원인원}</p>
                  <p>{data.content.교정.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.교정.경쟁률}</p>
                  <p>{data.content.교정.합격률}</p>
                  <p>{data.content.교정.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.보호.모집인원}</p>
                  <p>{data.content.보호.출원인원}</p>
                  <p>{data.content.보호.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.보호.경쟁률}</p>
                  <p>{data.content.보호.합격률}</p>
                  <p>{data.content.보호.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.검찰.모집인원}</p>
                  <p>{data.content.검찰.출원인원}</p>
                  <p>{data.content.검찰.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.검찰.경쟁률}</p>
                  <p>{data.content.검찰.합격률}</p>
                  <p>{data.content.검찰.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.출입국관리.모집인원}</p>
                  <p>{data.content.출입국관리.출원인원}</p>
                  <p>{data.content.출입국관리.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.출입국관리.경쟁률}</p>
                  <p>{data.content.출입국관리.합격률}</p>
                  <p>{data.content.출입국관리.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const FifthTable = ({ fifthTableData }) => {
  return (
    <Table>
      <TableHeader>
        <div>직렬</div>
        <div>
          <div>외무영사</div>
          <div>일반기계</div>
          <div>전기</div>
          <div>화공</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {fifthTableData &&
          fifthTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data>
                <div>
                  <p>{data.content.외무영사.모집인원}</p>
                  <p>{data.content.외무영사.출원인원}</p>
                  <p>{data.content.외무영사.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.외무영사.경쟁률}</p>
                  <p>{data.content.외무영사.합격률}</p>
                  <p>{data.content.외무영사.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.일반기계.모집인원}</p>
                  <p>{data.content.일반기계.출원인원}</p>
                  <p>{data.content.일반기계.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.일반기계.경쟁률}</p>
                  <p>{data.content.일반기계.합격률}</p>
                  <p>{data.content.일반기계.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.전기.모집인원}</p>
                  <p>{data.content.전기.출원인원}</p>
                  <p>{data.content.전기.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.전기.경쟁률}</p>
                  <p>{data.content.전기.합격률}</p>
                  <p>{data.content.전기.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.화공.모집인원}</p>
                  <p>{data.content.화공.출원인원}</p>
                  <p>{data.content.화공.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.화공.경쟁률}</p>
                  <p>{data.content.화공.합격률}</p>
                  <p>{data.content.화공.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const SixthTable = ({ sixthTableData }) => {
  return (
    <Table>
      <TableHeader>
        <div>직렬</div>
        <div>
          <div>일반농업</div>
          <div>산림자원</div>
          <div>일반토목</div>
          <div>건축</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {sixthTableData &&
          sixthTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data>
                <div>
                  <p>{data.content.일반농업.모집인원}</p>
                  <p>{data.content.일반농업.출원인원}</p>
                  <p>{data.content.일반농업.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.일반농업.경쟁률}</p>
                  <p>{data.content.일반농업.합격률}</p>
                  <p>{data.content.일반농업.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.산림자원.모집인원}</p>
                  <p>{data.content.산림자원.출원인원}</p>
                  <p>{data.content.산림자원.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.산림자원.경쟁률}</p>
                  <p>{data.content.산림자원.합격률}</p>
                  <p>{data.content.산림자원.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.일반토목.모집인원}</p>
                  <p>{data.content.일반토목.출원인원}</p>
                  <p>{data.content.일반토목.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.일반토목.경쟁률}</p>
                  <p>{data.content.일반토목.합격률}</p>
                  <p>{data.content.일반토목.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.건축.모집인원}</p>
                  <p>{data.content.건축.출원인원}</p>
                  <p>{data.content.건축.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.건축.경쟁률}</p>
                  <p>{data.content.건축.합격률}</p>
                  <p>{data.content.건축.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const SeventhTable = ({ seventhTableData }) => {
  return (
    <Table>
      <TableHeader isThree={true}>
        <div>직렬</div>
        <div>
          <div>방재안전</div>
          <div>전산개발</div>
          <div>전송기술</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {seventhTableData &&
          seventhTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data isThree={true}>
                <div>
                  <p>{data.content.방재안전.모집인원}</p>
                  <p>{data.content.방재안전.출원인원}</p>
                  <p>{data.content.방재안전.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.방재안전.경쟁률}</p>
                  <p>{data.content.방재안전.합격률}</p>
                  <p>{data.content.방재안전.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.전산개발.모집인원}</p>
                  <p>{data.content.전산개발.출원인원}</p>
                  <p>{data.content.전산개발.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.전산개발.경쟁률}</p>
                  <p>{data.content.전산개발.합격률}</p>
                  <p>{data.content.전산개발.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.전송기술.모집인원}</p>
                  <p>{data.content.전송기술.출원인원}</p>
                  <p>{data.content.전송기술.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.전송기술.경쟁률}</p>
                  <p>{data.content.전송기술.합격률}</p>
                  <p>{data.content.전송기술.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

function RecruitmentOf7th({ data }) {
  const [currentTableNum, setCurrentTableNum] = useState(1);

  const clickPrevBtn = () => {
    setCurrentTableNum(currentTableNum - 1);
  };
  const clickNextBtn = () => {
    setCurrentTableNum(currentTableNum + 1);
  };

  return (
    <>
      <ExamInfoContentBox>
        <PrevBtn
          onClick={(e) => {
            if (currentTableNum === 1) {
              e.preventDefault();
            } else {
              clickPrevBtn();
            }
          }}
        />
        <ExamInfoGraphContainer>
          <TableDataSlice />
          {currentTableNum === 1 ? (
            <FirstTable firstTableData={data} />
          ) : currentTableNum === 2 ? (
            <SecondTable secondTableData={data} />
          ) : currentTableNum === 3 ? (
            <ThirdTable thirdTableData={data} />
          ) : currentTableNum === 4 ? (
            <FourthTable fourthTableData={data} />
          ) : currentTableNum === 5 ? (
            <FifthTable fifthTableData={data} />
          ) : currentTableNum === 6 ? (
            <SixthTable sixthTableData={data} />
          ) : currentTableNum === 7 ? (
            <SeventhTable seventhTableData={data} />
          ) : (
            ''
          )}
          <TableDataSlice />
        </ExamInfoGraphContainer>
        <NextBtn
          onClick={(e) => {
            if (currentTableNum === 7) {
              e.preventDefault();
            } else {
              clickNextBtn();
            }
          }}
        />
      </ExamInfoContentBox>
      <PageNumber>{currentTableNum}/7</PageNumber>
    </>
  );
}

export default RecruitmentOf7th;
