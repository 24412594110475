import React from 'react';
import ReactLoading from 'react-loading';
import { SpinLoaderContainer } from './style';

export const typeList = [
  {
    prop: 'balls',
    name: 'Balls',
  },
  {
    prop: 'bars',
    name: 'Bars',
  },
  {
    prop: 'bubbles',
    name: 'Bubbles',
  },
  {
    prop: 'cubes',
    name: 'Cubes',
  },
  {
    prop: 'cylon',
    name: 'Cylon',
  },
  {
    prop: 'spin',
    name: 'Spin',
  },
  {
    prop: 'spinningBubbles',
    name: 'SpinningBubbles',
  },
  {
    prop: 'spokes',
    name: 'Spokes',
  },
];

export const SpinLoader = ({ type, color, width, height}) => (
  <SpinLoaderContainer width={width} height={height}>
    <ReactLoading type={type} color={color} />
  </SpinLoaderContainer>
);
