import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

export const MyOrderDetailPageContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-bottom: 150px;
  padding-top: 60px;

  @media screen and (max-width: 1700px) {
    padding-top: 60px;
    padding-bottom: 150px;
  }
`;

export const MyOrderDetailTitle = styled.div`
  ${FONT_STYLES.P_M};
  font-size: 36px;
  margin-bottom: 48px;
`;

export const TopBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding: 38px 52px;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;

  & > div {
    display: flex;
    align-items: center;

    & > p {
      :nth-child(1) {
        ${FONT_STYLES.P_M}
        font-size: 20px;
        padding-right: 30px;
      }
      :nth-child(2) {
        padding-right: 10px;
      }
    }

    :nth-child(1) {
      width: 35%;
    }
    :nth-child(2) {
      width: 20%;
    }
    :nth-child(3) {
      width: 30%;
    }
    :nth-child(3) {
      margin-left: auto;
      margin-right: 0;
      & > p {
        cursor: pointer;
        :nth-child(1) {
          padding-right: 30px;
        }
        :nth-child(2) {
          padding: 0 13px;
        }
      }
    }
  }
`;

export const PaymentInfoBox = styled.div`
  margin: 40px 0;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;

  & > div {
    display: flex;

    :nth-child(1) {
      padding: 38px 91px;
      ${FONT_STYLES.P_B}
      font-size: 24px;
    }

    :nth-child(2) {
      text-align: center;
      align-items: center;
      justify-content: space-between;
      background-color: ${COLOR.BLUE_5};
      padding: 64px 170px;

      & > div {
        & > p {
          :nth-child(1) {
            ${FONT_STYLES.P_M}
            font-size: 18px;
            margin-bottom: 23px;
          }
          :nth-child(2) {
            ${FONT_STYLES.P_B}
            font-size: 24px;
          }
        }
      }
    }

    :nth-child(3) {
      align-items: center;
      padding: 38px 91px;

      & > p {
        :nth-child(1) {
          ${FONT_STYLES.P_M}
          font-size: 20px;
          margin-right: 50px;
        }
        :nth-child(2) {
          ${FONT_STYLES.P_R}
          font-size: 18px;
        }
      }

      & > div {
        display: flex;
        align-items: center;
        margin-left: auto;

        & > p {
          :nth-child(1) {
            ${FONT_STYLES.P_M}
            font-size: 20px;
            margin-right: 33px;
          }
        }

        button {
          ${FONT_STYLES.P_M}
          margin: 0 10px;
        }
      }
    }
  }
`;

export const ProductInfoBox = styled.div`
  margin: 40px 0;
  padding: 38px 91px 0 91px;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;

  & > div {
    :nth-child(1) {
      display: flex;
      padding-bottom: 20px;
    }

    & > p {
      :nth-child(1) {
        width: 50%;
      }
      :nth-child(2) {
        text-align: center;
        width: 5%;
      }
      :nth-child(3) {
        text-align: center;
        width: 15%;
      }
      :nth-child(4) {
        text-align: center;
        width: 10%;
      }
    }
  }
`;

export const OrderItems = styled.div``;

export const Item = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${COLOR.GRAY_9};
  padding: 40px 0;

  button {
    ${FONT_STYLES.P_R};
  }

  & > div {
    :nth-child(1) {
      display: flex;
      width: 50%;

      & > div {
        :nth-child(1) {
          text-align: left;
          p:nth-child(2) {
            ${FONT_STYLES.P_SB}
            font-size: 20px;
          }
        }

        & > p {
          padding: 3px 0;
          :nth-child(1) {
            ${FONT_STYLES.P_T}
            font-size: 15px;
          }
        }
      }
    }

    :nth-child(2) {
      ${FONT_STYLES.P_R}
      font-size: 18px;
      width: 5%;
      text-align: center;
    }

    :nth-child(3) {
      ${FONT_STYLES.P_SB}
      font-size: 18px;
      width: 15%;
      text-align: center;
    }

    :nth-child(4) {
      ${FONT_STYLES.P_SB}
      font-size: 18px;
      width: 15%;
      text-align: center;
    }

    :nth-child(5) {
      width: 13%;
      display: flex;
      align-items: center;
      cursor: pointer;

      & > p {
        padding: 0 3px;
      }
    }
  }
`;

export const RefundInfoBox = styled.div`
  margin: 40px 0;
  padding: 38px 91px 0 91px;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;

  & > div {
    :nth-child(1) {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid ${COLOR.GRAY_9};

      & > p {
        :nth-child(1) {
          width: 80%;
        }
        :nth-child(2) {
          width: 20%;
          text-align: center;
        }
      }
    }
  }
`;

export const CancelItemList = styled.div``;

export const CancelItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  ${FONT_STYLES.P_L}

  & > div {
    :nth-child(1) {
      width: 80%;
    }
    :nth-child(2) {
      width: 20%;
      text-align: center;
    }
  }
`;

export const DeliveryInfoBox = styled.div`
  margin: 40px 0;
  padding: 38px 91px;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;

  & > div {
    display: flex;

    :nth-child(1) {
      ${FONT_STYLES.P_B}
      font-size: 24px;
      padding-bottom: 20px;
      border-bottom: 1px solid ${COLOR.GRAY_9};
    }

    :nth-child(2) {
      ${FONT_STYLES.P_R}
      font-size: 18px;
      margin-top: 20px;
    }

    :nth-child(3),
    :nth-child(4) {
      ${FONT_STYLES.P_R}
      font-size: 18px;
      margin: 40px 0;
    }

    :nth-child(5) {
      ${FONT_STYLES.P_R}
      font-size: 18px;
    }

    & > p {
      :nth-child(1) {
        width: 100px;
        margin-right: 80px;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;

  & > button {
    margin-right: 20px;
  }
`;
