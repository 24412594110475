import React from 'react';
import { FindIdCompleteContainer, FindAccountPageTitle, HorizonLine, Content } from './style';
import COLOR from '../../styles/common/color';
import { RegisterButton } from '../../components/atoms/Button';
import { useNavigate, Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';

import { foundAccountIdAtom, isSocialAccountAtom } from '../../recoil/AuthStore/AuthRecoil';

function FindIdCompletePage() {
  const isLogin = useRecoilValue(isLoginAtom);
  const navigate = useNavigate();

  const foundedUserId = useRecoilValue(foundAccountIdAtom);
  const isSocialAccount = useRecoilValue(isSocialAccountAtom);

  if (isLogin === true) {
    alert('잘못된 접근입니다.');
    return <Navigate to="/" />;
  }

  return (
    <>
      <FindIdCompleteContainer>
        <FindAccountPageTitle>
          <span>아이디 찾기</span>
        </FindAccountPageTitle>
        <HorizonLine />
        <Content>
          <div>회원정보와 일치하는 아이디 입니다.</div>
          {isSocialAccount === true ? (
            <div>
              <p>ID</p>
              <p>{foundedUserId} 계정으로 가입하셨습니다.</p>
            </div>
          ) : (
            <div>
              <p>ID</p>
              <p>{foundedUserId}</p>
            </div>
          )}
          {/* <div>
            <p>가입일</p>
            <p></p>
          </div> */}
        </Content>
        <RegisterButton
          width={202}
          height={62}
          bgColor={COLOR.BLUE_4}
          fontSize={20}
          fontWeight={300}
          onClickFunc={() => navigate('/login')}
        >
          로그인 페이지로
        </RegisterButton>
      </FindIdCompleteContainer>
    </>
  );
}

export default FindIdCompletePage;
