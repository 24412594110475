import { useAxios } from '../../hooks/useAxios';

/** 온라인 서점 - 신규 교재 데이터 API */
export const getNewBook = async () => {
  return await useAxios.get('/book/new');
};

/** 온라인 서점 - 추천 교재 데이터 API */
export const getRecommendedBook = async () => {
  return await useAxios.get('/book/recommanded');
};

/** 온라인 서점 - 베스트셀러 교재 데이터 API */
export const getBestSellerBook = async () => {
  return await useAxios.get('/book/best');
};

/** 온라인 서점 - 자주 묻는 질문 데이터 API */
export const getFrequentQuestion = async () => {
  return await useAxios.get('/center/frequent-question');
};

/** 온라인 서점 - 공지사항 필터링&검색 데이터 API  */
export const getNotice = async (keyword, category, page) => {
  return await useAxios.get(
    `/center/notice/list?keyword=${keyword}&category=${category}&page=${page}`,
  );
};

/** 온라인 서점 - 전체 교재 데이터 API */
export const getBookAll = async () => {
  return await useAxios.get(`/book/all`);
};

/** 온라인 서점 - 과목별 교재 데이터 API (카테고리별 분류) */
export const getBookListByCategory = async (category) => {
  return await useAxios.get(`/book/category/${category}`);
};

/** 온라인 서점 - 교재 검색 기능 API */
export const getBookListByBookName = async (bookName) => {
  return await useAxios.get(`/book/name?keyword=${bookName}`);
};

/** 온라인 서점 - 교재 상세 페이지 데이터 API */
export const getBookInfo = async (bookId) => {
  return await useAxios.get(`/book/info/${bookId}`);
};

/** 온라인 서점 - 시리즈 교재 API */
export const getSeriesBookList = async () => {
  return await useAxios.get('/book/serise/list');
};

export const getBookStoreBanner = async () => {
  return await useAxios.get('/book-store-banner');
};
