import React, { useState, useEffect } from 'react';
import { StyledSearch, SearchInputBox } from './style';

import { SearchIconInput } from '../../atoms/Input';

import { ReactComponent as SearchIcon } from '../../../assets/icons/icon-Search.svg';
import SearchHoverIcon from '../../../assets/icons/icon-SearchHover.png';
import { ReactComponent as CancelIcon } from '../../../assets/icons/icon-CancelBtnWhite.svg';

// 흰 돋보기 버튼 누르면 보여줄 UI
const SearchSelected = ({ isSearchClicked, setIsSearchClicked }) => {
  const clickSearchIcon = () => {
    setIsSearchClicked((isSearchClicked) => !isSearchClicked);
  };

  return (
    <>
      {isSearchClicked ? (
        <CancelIcon onClick={clickSearchIcon} />
      ) : (
        <img src={SearchHoverIcon} onClick={clickSearchIcon} />
      )}
    </>
  );
};

const Search = ({ keywordCheck, setIsSearchBtnClicked }) => {
  const [isHover, setIsHover] = useState(false);
  const [isSearchClicked, setIsSearchClicked] = useState(false);

  useEffect(() => {
    setIsSearchClicked(false);
  }, [isHover]);

  const clickSearchBtn = () => {
    setIsSearchBtnClicked(true);
    setIsHover(false);
  };
  const SearchByEnter = (e) => {
    if (e.key === 'Enter') {
      setIsSearchBtnClicked(true);
      setIsHover(false);
    }
  };

  return (
    <>
      <StyledSearch
        isHover={isHover}
        onMouseOver={() => setIsHover(true)}
        onMouseOut={() => setIsHover(false)}
      >
        <SearchInputBox isHover={isHover} isSearchClicked={isSearchClicked}>
          <p>SEARCH</p>
          <div>
            <SearchIconInput
              width={320}
              height={30}
              placeholder={'검색어를 입력하세요'}
              onChangeFunc={keywordCheck}
              onKeyPressFunc={SearchByEnter}
            />
            <SearchIcon onClick={clickSearchBtn} />
          </div>
        </SearchInputBox>
        <div>
          {isHover ? (
            <SearchSelected
              isSearchClicked={isSearchClicked}
              setIsSearchClicked={setIsSearchClicked}
            />
          ) : (
            <SearchIcon />
          )}
        </div>
      </StyledSearch>
    </>
  );
};

export default Search;
