import { useAxios, useDownloadAxios } from '../../hooks/useAxios';
import fileDownload from 'js-file-download';

/** 수험정보&팁 - 시험 정보 API */
export const getExamInfo = async () => {
  return await useAxios.get(`/info-tip/exam`);
};

/** 수험정보&팁 - 학습 팁 페이징 API */
export const getTipPageInfo = async (category, keyword) => {
  return await useAxios.get(`/info-tip/tip/page/info?category=${category}&keyword=${keyword}`);
};

/** 수험정보&팁 - 학습 팁 데이터 API */
export const getTipList = async (page, category, keyword) => {
  return await useAxios.get(
    `/info-tip/tip/list?page=${page}&category=${category}&keyword=${keyword}`,
  );
};

/** 수험정보&팁 - 학습 팁 상세페이지 API */
export const getTip = async (studyTipId) => {
  return await useAxios.get(`/info-tip/tip/${studyTipId}`);
};

/** 수헙정보&팁 - 학습 팁 상세페이지 파일 다운로드 API */
export const downloadTipFile = async (id, fileTitle) => {
  return await useDownloadAxios
    .get(`/info-tip/tip/download/${id}`, { responseType: 'blob' })
    .then((res) => {
      let filename = `${fileTitle}`.replace(/(\r\n\t|\n|\r\t)/gm, '');
      fileDownload(res.data, filename);
    });
};

/** 수험정보&팁 - 합격 후기 페이징 API */
export const getPassReviewPageInfo = async (category, keyword) => {
  return await useAxios.get(
    `/info-tip/pass-review/page/info?category=${category}&keyword=${keyword}`,
  );
};

/** 수험정보&팁 - 합격 후기 데이터 API */
export const getPassReviewList = async (page, category, keyword) => {
  return await useAxios.get(
    `/info-tip/pass-review/list?page=${page}&category=${category}&keyword=${keyword}`,
  );
};

/** 수험정보&팁 - 합격 후기 상세페이지 데이터 API */
export const getPassView = async (passId) => {
  return await useAxios.get(`/info-tip/pass-review/${passId}`);
};

/** 합격후기 게시글 작성 API */
export const addPassReview = async (data) => {
  return await useAxios.post(`/info-tip/pass-review`, data);
};

/** 내 합격후기 게시글 수정 API */
export const modifyPassReview = async (id, data) => {
  return await useAxios.put(`/info-tip/pass-review/${id}`, data);
};

/** 내 합격후기 게시글 삭제 API */
export const deletePassReview = async (id) => {
  return await useAxios.delete(`/info-tip/pass-review/${id}`);
};

/** 베스트 합격 후기 API */
export const getBestPassReviewList = async () => {
  return await useAxios.get(`/info-tip/pass-review/list/best`);
};
