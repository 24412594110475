import React from 'react';
import { AlarmDetailBox, AlarmHeader, AlarmContent } from './style';

import { ReactComponent as SmallAlarmIcon } from '../../../assets/icons/icon-AlarmSmall.svg';

function AlarmDetail({ openDetailID, data, onClickFunc }) {
  function createMarkup(data) {
    return { __html: data?.replace(/\n/gi, '<br/>') };
  }

  return (
    <AlarmDetailBox onClick={onClickFunc}>
      <AlarmHeader>
        <p>
          <SmallAlarmIcon />
        </p>
        <p>
          <span>{data.title}</span>
        </p>
        <p>{data.sendAt?.slice(0, 10).replace(/-/g, '.')}</p>
      </AlarmHeader>
      <AlarmContent>
        <div dangerouslySetInnerHTML={createMarkup(data.content)} />
      </AlarmContent>
    </AlarmDetailBox>
  );
}

export default AlarmDetail;
