import React, { useState, useEffect } from 'react';
import { BannerContainer, BannerNewsContainer, BannerTextContainer, RecentNews } from './style';
import COLOR from '../../../../styles/common/color';

import { useQuery } from 'react-query';
import { getProfessorBanner, getProfessorNoticeList } from '../../../../axiosManage/Professor';
import useHandleError from '../../../../hooks/useHandleError';
import { SpinLoader, typeList } from '../../../atoms/SpinLoader';
import { useNavigate } from 'react-router-dom';

const Banner = ({ professorId }) => {
  const navigate = useNavigate();
  const { handleError } = useHandleError();

  const [ProfessorId, setProfessorId] = useState(1);
  const [professorBanner, setProfessorBanner] = useState([]);
  const { status: professorBannerStatus } = useQuery(
    ['getProfessorBanner', ProfessorId],
    () => getProfessorBanner(ProfessorId),
    {
      retry: 0,
      onSuccess: (data) => {
        setProfessorBanner(data.data.professorBanner);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const [professorNotice, setProfessorNotice] = useState([]);
  const { status: professorNoticeStatus } = useQuery(
    ['getProfessorNoticeList', ProfessorId],
    () => getProfessorNoticeList(ProfessorId, 1, '', ''),
    {
      retry: 0,
      onSuccess: (data) => {
        setProfessorNotice(data.data.noticeList.slice(0, 5));
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  useEffect(() => {
    setProfessorId(professorId);
  }, [professorId]);

  let noticeColor;

  switch(professorId){
     
    //case '3' :  noticeColor = '#6b8991'; break; //하나산

    default:
      noticeColor = '#4C4C4C'; break; 
  };


  return (
    <BannerContainer backgroundImg={professorBanner} backgroundColor={COLOR.GRAY_10}>
      <BannerTextContainer></BannerTextContainer>
      <BannerNewsContainer>
        <RecentNews backgroundColor={noticeColor}>
          <p>공지 사항</p>
          <hr />
          {professorNotice !== undefined ? (
            <ul style={{ listStylePosition: 'outside' }}>
              {professorNotice.map((notice) => (
                <li
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  key={notice.id}
                  onClick={() =>
                    (navigate(`/lecture/${professorId}/notice/${notice.id}`))
                  }
                >
                  {notice.title}
                </li>
              ))}
            </ul>
          ) : (
            <SpinLoader type={typeList[2].prop} color={'#000'} width={424} height={200} />
          )}
        </RecentNews>
      </BannerNewsContainer>
    </BannerContainer>
  );
};

export { Banner };
