import React, { useState } from 'react';
import {
  OrderBasketPageContainer,
  OrderBasketPageTitle,
  HorizonLine,
  ContentBox,
  PayInfoBox,
  ButtonBox,
} from './style';
import COLOR from '../../styles/common/color';
import moment from 'moment';

import StepIcon1 from '../../assets/icons/icon-Basket.png';
import StepIcon2 from '../../assets/icons/icon-join.png';
import StepIcon3Selected from '../../assets/icons/icon-complete-selected.png';

import { ReactComponent as CompleteCheckIcon } from '../../assets/icons/icon-register-complete.svg';

import ProgressBox from '../../components/molecules/ProgressBox';
import { Button } from '../../components/atoms/Button';
import { useNavigate, Navigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { myOrderInfoAtom } from '../../recoil/OrderBasketStore/OrderBasketRecoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';
import { myInfoAtom } from '../../recoil/MyPageStore/MyPageRecoil';

function OrderCompletePage() {
  const navigate = useNavigate();
  const payInfo = useRecoilValue(myOrderInfoAtom);
  const myInfo = useRecoilValue(myInfoAtom);

  const isLogin = useRecoilValue(isLoginAtom);

  if (isLogin === false) {
    alert('잘못된 접근입니다.');
    return <Navigate to="/" />;
  }

  // 내 강의실로 이동
  const goToMyLecturePage = () => {
    navigate('/mypage/mylecture');
  };

  return (
    <OrderBasketPageContainer>
      <OrderBasketPageTitle>
        <span>{myInfo.name}</span>님 장바구니
      </OrderBasketPageTitle>
      <HorizonLine />
      <ProgressBox
        stepIcon1={StepIcon1}
        stepIcon2={StepIcon2}
        stepIcon3={StepIcon3Selected}
        stepText1={`장바구니`}
        stepText2={`결제정보 입력`}
        stepText3={`결제 완료`}
      >
        <ContentBox>
          <CompleteCheckIcon />
          <div>결제가 완료되었습니다.</div>
          <PayInfoBox>
            {payInfo && (
              <>
                <div>
                  <p>결제 일시</p>
                  <p>{moment(payInfo[0].createdAt).format('YYYY-MM-DD HH:MM')}</p>
                </div>
                <div>
                  <p>주문 상품</p>
                  {payInfo[0].products && payInfo[0].products.length <= 1 ? (
                    <p>
                      {payInfo[0].products[0] &&
                        (payInfo[0].products[0].lectureName ||
                          payInfo[0].products[0].bookName ||
                          payInfo[0].products[0].passName)}
                    </p>
                  ) : (
                    <p>
                      {payInfo[0].products[0] &&
                        (payInfo[0].products[0].lectureName ||
                          payInfo[0].products[0].bookName ||
                          payInfo[0].products[0].passName)}
                      외 {payInfo.length - 1}건
                    </p>
                  )}
                </div>
                <div>
                  <p>결제 금액</p>
                  {payInfo[0].provider === 'kakaopay' ? (
                    <p>
                      {payInfo[0].products[0] &&
                        (payInfo[0].products[0].price - payInfo[0].products[0].sale)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      원
                    </p>
                  ) : (
                    <p>{payInfo[0].price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</p>
                  )}
                </div>
                <div>
                  <p>결제 방법</p>
                  <p>{payInfo[0].provider || payInfo[0].pay_method}</p>
                </div>
              </>
            )}
          </PayInfoBox>
        </ContentBox>
      </ProgressBox>
      <ButtonBox>
        <div>
          <Button width={202} height={62} onClickFunc={() => navigate('/mypage/myorder')}>
            결제 내역 확인
          </Button>
        </div>
        <div>
          <Button width={202} height={62} bgColor={COLOR.BLUE_4} onClickFunc={goToMyLecturePage}>
            내 강의실로 이동
          </Button>
        </div>
      </ButtonBox>
    </OrderBasketPageContainer>
  );
}

export default OrderCompletePage;
