import React from 'react';
import { useState } from 'react';
import {
  Best5SectionTitle,
  BestLectureContainer,
  BestLectureTabBox,
  BestLectureTabContainer,
} from '../../../pages/Main/style';
import BestLectureContent from '../../molecules/BestLectureContent';
import { LecturesContainer } from './style';
import { SpinLoader, typeList } from '../../atoms/SpinLoader';
import { useNavigate } from 'react-router-dom';

const BestLectures = ({
  bestLanguageLogicLectures,
  bestDataInterpretationLectures,
  bestSituationalJudgmentLectures,
  bestLectureStatus,
}) => {
  const navigate = useNavigate();

  const dataArr = [
    {
      tabName: '언어논리',
      content: bestLanguageLogicLectures,
    },
    {
      tabName: '자료해석',
      content: bestDataInterpretationLectures,
    },
    {
      tabName: '상황판단',
      content: bestSituationalJudgmentLectures,
    },
  ];

  const [CurrentTab, setCurrentTab] = useState(0);

  const selectTab = (index) => {
    setCurrentTab(index);
  };

  const goToLectureDetailPage = (professorId, lectureId) => {
    navigate(`/lecture/${professorId}/lectures/${lectureId}`);
  };

  return (
    <BestLectureContainer>
      <BestLectureTabContainer>
        <Best5SectionTitle>강좌 BEST 5</Best5SectionTitle>
        <BestLectureTabBox>
          {dataArr.length > 0 ? (
            dataArr.map((data, index) => (
              <p
                onClick={() => selectTab(index)}
                key={data.id}
                className={index === CurrentTab ? 'active' : ''}
              >
                {data.tabName}
              </p>
            ))
          ) : (
            <></>
          )}
        </BestLectureTabBox>
      </BestLectureTabContainer>
      <LecturesContainer>
        {bestLectureStatus === 'loading' ? (
          <SpinLoader type={typeList[2].prop} color={'#000'} width={450} height={650} />
        ) : (
          dataArr[CurrentTab].content.map((data) => (
            <BestLectureContent
              key={data.id}
              rank={data.rank}
              name={data.professorName}
              title={data.lectureName}
              smalltitle={data.introduction}
              img={data.professorBannerImg}
              onClickFunc={() => goToLectureDetailPage(data.professorId, data.id)}
            />
          ))
        )}
      </LecturesContainer>
    </BestLectureContainer>
  );
};

export default BestLectures;
