import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { SupportCenterNav } from '../../components/molecules/SubNavBar';
import Home from '../../components/organisms/SupportCenterPageElements/Home';
import Questions from '../../components/organisms/SupportCenterPageElements/Questions';
import Contact from '../../components/organisms/SupportCenterPageElements/Contact';
import Notice from '../../components/organisms/SupportCenterPageElements/Notice';
import Errata from '../../components/organisms/SupportCenterPageElements/Errata';

import PageNotFound from '../../hooks/pageNotFound';

import { ExamInfoPageContainer } from './style';
import Banner from '../../components/organisms/SupportCenterPageElements/Banner';

import { useQuery } from 'react-query';
import { getFrequentQuestion } from '../../axiosManage/SupportCenter';
import useHandleError from '../../hooks/useHandleError';

import { useResetRecoilState } from 'recoil';
import {
  selectedNoticeFilterMenuNumAtom,
  selectedErrataFilterMenuNumAtom,
} from '../../recoil/SelectedFilterStore/SelectedFilterRecoil';

function SupportCenterPage() {
  const { handleError } = useHandleError();
  const { tab } = useParams();
  const tabs = ['questions', 'contact', 'notice', 'errata', undefined];

  if (!tabs.includes(tab)) {
    return <PageNotFound />;
  }

  /** 다른 페이지 갔다오면 공지사항, 정오표 필터링 초기화 하는 로직 */
  const resetNoticeFilter = useResetRecoilState(selectedNoticeFilterMenuNumAtom);
  const resetErrataFilter = useResetRecoilState(selectedErrataFilterMenuNumAtom);

  useEffect(() => {
    return () => {
      resetNoticeFilter();
      resetErrataFilter();
    };
  }, [tab]);

  /** 자주 묻는 질문 전체 데이터 */
  const [frequentQuestion, setFrequentQuestion] = useState([]);
  const { status: frequentQuestionDataState } = useQuery(
    'getFrequentQuestion',
    getFrequentQuestion,
    {
      retry: 0,
      onSuccess: (data) => {
        setFrequentQuestion(data.data.questions);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const renderPage = () => {
    switch (tab) {
      case 'questions':
        return (
          <Questions
            frequentQuestion={frequentQuestion}
            frequentQuestionDataState={frequentQuestionDataState}
          />
        );
      case 'contact':
        return <Contact />;
      case 'notice':
        return <Notice />;
      case 'errata':
        return <Errata />;
      default:
        return (
          <Home
            frequentQuestion={frequentQuestion}
            frequentQuestionDataState={frequentQuestionDataState}
          />
        );
    }
  };

  const renderBanner = () => {
    return <Banner />;
  };

  return (
    <ExamInfoPageContainer>
      {renderBanner()}
      <SupportCenterNav tab={tab} />
      {renderPage()}
    </ExamInfoPageContainer>
  );
}

export default SupportCenterPage;
