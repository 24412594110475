import React, { useState } from 'react';
import { Button } from '../../../../components/atoms/Button';
import {
  QuestionDetailContainer,
  QuestionDetailBox,
  DetailTitle,
  Info,
  Attachments,
  ButtonContainer,
} from '../common/detailPage';
import { ListTitle, HorizonLine } from '../common/list-style';
import COLOR from '../../../../styles/common/color';
import { useParams, useNavigate } from 'react-router-dom';
import ToastViewer from '../../../atoms/ToastTextViewer';

import { SpinLoader, typeList } from '../../../atoms/SpinLoader';
import { useQuery } from 'react-query';
import { getErrataDetail, downloadErrata } from '../../../../axiosManage/SupportCenter';
import useHandleError from '../../../../hooks/useHandleError';
import { useChangeMonthNumToEn } from '../../../../hooks/useChangeMonthNumToEn';

function ErrataDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { handleError } = useHandleError();

  const [errataDetail, setErrataDetail] = useState([]);
  const { status: errataDetailState } = useQuery(
    ['getErrataDetail', id],
    () => getErrataDetail(id),
    {
      retry: 0,
      enabled: !!id,
      onSuccess: (data) => {
        console.log(data.data.errata);
        if (data.data.errata !== undefined) {
          setErrataDetail(data.data.errata);
        }
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const clickDownloadErrata = (filename) => {
    downloadErrata(id, filename);
  };

  return (
    <QuestionDetailContainer>
      <ListTitle>자료실 & 정오표</ListTitle>
      <HorizonLine />
      <QuestionDetailBox>
        {errataDetail !== undefined ? (
          <>
            <DetailTitle>
              [{errataDetail.category}] {errataDetail.title}
            </DetailTitle>
            <Info>
              <div>
                <p>{errataDetail.createdAt?.slice(8, 10)}</p>
                <p>{useChangeMonthNumToEn(errataDetail.createdAt?.slice(5, 7))}</p>
                <p>{errataDetail.createdAt?.slice(0, 4)}</p>
              </div>
              <div>
                <p>작성자</p>
                <p>LAB STANDARD</p>
              </div>
              <div>
                <p>조회</p>
                <p>{errataDetail.views}</p>
              </div>
            </Info>
            <ToastViewer content={errataDetail.content} />
            {errataDetail.existFile ? (
              <Attachments>
                <div>
                  <span>첨부 파일</span>
                  <a onClick={() => clickDownloadErrata(errataDetail.fileName)}>
                    <span>{errataDetail.fileName}</span>
                  </a>
                </div>
              </Attachments>
            ) : null}
          </>
        ) : (
          <SpinLoader type={typeList[2].prop} color={'#000'} width={1131} height={459} />
        )}
      </QuestionDetailBox>
      <ButtonContainer>
        <Button
          width={124}
          height={48}
          bgColor={COLOR.BLUE_4}
          onClickFunc={() => navigate('/supportcenter/errata')}
        >
          목록으로
        </Button>
      </ButtonContainer>
    </QuestionDetailContainer>
  );
}

export default ErrataDetail;
