import React, { useCallback, useEffect, useState } from 'react';
import { ContentsContainer, FooterContainer, TextContainer } from './style';
import Logo from '../../../assets/images/image-Logo-white.png';

import { getAgreeText } from '../../../axiosManage/Assignment';
import AssignmentModal from '../../../components/molecules/Modal';
import useModal from '../../../hooks/useModal';
import AssignmentContent from '../../../components/organisms/AssignmentContent';

const Footer = () => {
  const [modalOption, showModal] = useModal();

  const [personalInformation, setPersonalInformation] = useState('');
  const [labstandard, setLabstandard] = useState('');
  useEffect(() => {
    getAgreeText().then((res) => {
      setPersonalInformation(JSON.parse(res.data.data).personalInformation);
      setLabstandard(JSON.parse(res.data.data).labstandard);
    });
  }, []);

  // 알림 페이지 모달 오픈
  const openPersonalInformationModal = useCallback(
    (content) => {
      showModal(true, null, null, <AssignmentContent content={content} />);
      document.body.style.overflow = 'hidden';
    },
    [showModal],
  );

  const openLabstandardModal = useCallback(
    (content) => {
      showModal(true, null, null, <AssignmentContent content={content} />);
      document.body.style.overflow = 'hidden';
    },
    [showModal],
  );

  return (
    <FooterContainer>
      <AssignmentModal modalOption={modalOption} />
      <AssignmentModal modalOption={modalOption} />
      <ContentsContainer>
        <img src={Logo} width="147px" height="59px" />
        <TextContainer>
          <p>
            <span onClick={() => openLabstandardModal(labstandard)}>이용약관</span>
            <span onClick={() => openPersonalInformationModal(personalInformation)}>
              개인정보처리방침
            </span>
          </p>
          <p>
            <span>(주)랩스탠다드</span>
            <span>대표:김승환</span>
            <span>사업자 등록번호 : 550-87-01937</span>
            <span>통신판매업 신고번호 : 제 2022-서울관악-1783호</span>
          </p>
          <p>
            <span>주소 : 서울특별시 관악구 호암로 26길 25(덕영빌딩) 2층</span>
            <span>Tel. 070-8808-0700</span>
            <span>개인정보관리자 : 박준영</span>
          </p>
          <p>
            <span>이메일: labstandard@naver.com</span>
          </p>
          <p>Copyright Ⓒ (주)랩스탠다드. All Rights Reserved</p>
        </TextContainer>
      </ContentsContainer>
    </FooterContainer>
  );
};

export default Footer;
