import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const ExamInfoContainer = styled.div`
  padding: 0 222px;
  padding-top: 70px;
  padding-bottom: 120px;
  text-align: center;

  @media screen and (max-width: 1700px) {
    padding: 0 20px;
    padding-top: 70px;
    padding-bottom: 120px;
  }
`;

const ExamInfoTitle = styled.div`
  ${FONT_STYLES.P_B}
  font-size: 40px;
  margin-bottom: 40px;
`;

const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_3};
  margin: 40px auto;
`;

const ExamInfoCategoryBar = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #716b6b;

  li {
    ${FONT_STYLES.P_SB}
    margin: 0 20px;
    font-size: 18px;
    cursor: pointer;

    &.active {
      color: ${COLOR.BLACK_1};
    }
  }
`;
  const ExamInfoComment = styled.div`
  
  ${FONT_STYLES.N_B}
      
      font-size : 14px;
      text-align:left;
      width : 300px;
      margin : 0 auto;

      p {
        padding-bottom: 6px;
      
      }
      
  `;

export { ExamInfoContainer, ExamInfoTitle, HorizonLine, ExamInfoCategoryBar,ExamInfoComment };
