import React from 'react';
import {
  BackgroundBox,
  BigTitle,
  CardBox1,
  CardBox2,
  CardContainer,
  ProfessorName,
  SmallContent,
  SmallTitle,
  TextBox,
} from './style';

const NewLectureCard = ({
  backgroundImg,
  professorName,
  bigtitle,
  smalltitle,
  onClickFunc,
  lectureNum,
}) => {
  return (
    <CardContainer backgroundImg={backgroundImg} onClick={onClickFunc} lectureNum={lectureNum}>
      <CardBox1>
        <SmallTitle>{smalltitle}</SmallTitle>
        <ProfessorName lectureNum={lectureNum}>{professorName}</ProfessorName>
      </CardBox1>
      <CardBox2>
        <BackgroundBox />
        <TextBox>
          <SmallContent>{smalltitle}</SmallContent>
          <BigTitle lectureNum={lectureNum}>{bigtitle}</BigTitle>
        </TextBox>
      </CardBox2>
    </CardContainer>
  );
};

export default NewLectureCard;
