import React, { useState, useEffect } from 'react';
import {
  AdContainer,
  AdSection,
  AdTextBox,
  BannerContainer,
  Best5Container,
  Best5Section,
  HotPickContainer,
  HotPickSection,
  LeftAdContainer,
  LeftAdContent,
  LeftAdContent1,
  LeftAdContent2,
  Line,
  MainPageContainer,
  NewLectureContents,
  NewLecturesContainer,
  NewLectureSection,
  RevewTabContainer,
  ReviewBannerContainer,
  ReviewContainer,
  ReviewLinkTabBox,
  ReviewSection,
  ReviewSectionInfo,
  RightAdContainer,
  RightContent,
  SectionInfo,
  SectionTitle,
  TitleBox,
} from './style';
import NewLectureCard from '../../components/molecules/NewLectureCard';
import BestLectures from '../../components/organisms/BestLectures';
import { ReactComponent as SmallArrowBtn } from '../../assets/icons/icon-SmallArrowBtn.svg';
import BestBooks from '../../components/organisms/BestBooks';
import ReviewSlides from '../../components/organisms/ReviewSlides';
import HotPickSlides from '../../components/organisms/HotPickSlides';
import { SpinLoader, typeList } from '../../components/atoms/SpinLoader';

import { useQuery } from 'react-query';
import {
  getNewLecture,
  getHotLecture,
  getHotBook,
  getBestLecture,
  getBestBook,
  getMainBanner,
  getBottomBanner,
} from '../../axiosManage/MainPage';
import { getPassReviewList, getPassView } from '../../axiosManage/ExamInfo';
import useHandleError from '../../hooks/useHandleError';
import { Link, useNavigate } from 'react-router-dom';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useLocation } from 'react-router-dom';

const MainPage = () => {
  const { handleError } = useHandleError();
  const navigate = useNavigate();

  //라이브러리 이벤트 속성과 디자인 요소 스타일링
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  /** 메인 배너 */
  const [mainBanner, setMainBanner] = useState([]);
  const { status: mainBannerStatus } = useQuery('getMainBanner', getMainBanner, {
    retry: 0,

    onSuccess: (data) => {
      setMainBanner(data.data.mainBanners);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  /** 신규 강의 데이터 */
  const [newLecture, setNewLecture] = useState([]);
  const { status: newLectureStatus } = useQuery('getNewLecture', getNewLecture, {
    retry: 0,

    onSuccess: (data) => {
      setNewLecture(data.data.newLectures);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  /** HotPick 강의 데이터 */
  const [hotPickLecture, setHotPickLecture] = useState([]);
  const { status: hotLectureStatus } = useQuery('getHotLecture', getHotLecture, {
    retry: 0,

    onSuccess: (data) => {
      const result = data.data.hotLectures.filter((lecture) => lecture.img !== null);
      setHotPickLecture(result);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  /** HotPick 교재 데이터 */
  const [hotPickBook, setHotPickBook] = useState([]);
  const { status: hotBookStatus } = useQuery('getHotBook', getHotBook, {
    retry: 0,

    onSuccess: (data) => {
      const result = data.data.hotBooks.filter((book) => book.img !== null);
      setHotPickBook(result);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  /** Best 강의 데이터 */
  const [bestLanguageLogicLectures, setBestLanguageLogicLecture] = useState([]);
  const [bestDataInterpretationLectures, setBestDataInterpretationLecture] = useState([]);
  const [bestSituationalJudgmentLectures, setBestSituationalJudgmentLecture] = useState([]);
  const { status: bestLectureStatus } = useQuery('getBestLecture', getBestLecture, {
    retry: 0,

    onSuccess: (data) => {
      setBestLanguageLogicLecture(data.data.bestLanguageLogicLecture);
      setBestDataInterpretationLecture(data.data.bestDataInterpretationLecture);
      setBestSituationalJudgmentLecture(data.data.bestSituationalJudgmentLecture);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  /** Best 교재 데이터 */
  const [bestLanguageLogicBooks, setBestLanguageLogicBooks] = useState([]);
  const [bestDataInterpretationBooks, setBestDataInterpretationBooks] = useState([]);
  const [bestSituationalJudgmentBooks, setBestSituationalJudgmentBooks] = useState([]);
  const { status: bestBookStatus } = useQuery('getBestBook', getBestBook, {
    retry: 0,

    onSuccess: (data) => {
      setBestLanguageLogicBooks(data.data.bestLanguageLogicBook);
      setBestDataInterpretationBooks(data.data.bestDataInterpretationBook);
      setBestSituationalJudgmentBooks(data.data.bestSituationalJudgmentBook);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [firstPassNote, setFirstPassNote] = useState([]);
  const [passNote, setPassNote] = useState([]);
  useEffect(() => {
    getPassReviewList(1, '', '').then((res) => {
      setFirstPassNote(res.data.passReviewList);
    });
  }, []);

  useEffect(() => {
    getPassReviewList(2, '', '').then((res) => {
      setPassNote([...firstPassNote, ...res.data.passReviewList.slice(0, 2)]);
    });
  }, [firstPassNote]);

  const goToLectureDetailPage = (professorId, lectureId) => {
    navigate(`/lecture/${professorId}/lectures/${lectureId}`);
  };

  /** 하단 배너 */
  const [leftTopBanner, setLeftTopBanner] = useState([]);
  const [leftBottomBanner, setBottomTopBanner] = useState([]);
  const [rightBanner, setRightBanner] = useState([]);
  useEffect(() => {
    getBottomBanner()
      .then((res) => {
        setLeftTopBanner(JSON.parse(res.data.data).leftTopBanner);
        setBottomTopBanner(JSON.parse(res.data.data).leftBottomBanner);
        setRightBanner(JSON.parse(res.data.data).rightBanner);
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  // 메인 배너 클릭하면 연결되는 url
  // const bannerLink = (link) => {
  //   window.open(link);
  // };

  // 하단 왼쪽 top url
  const leftTopLink = () => {
    if (leftTopBanner.url) {
      window.location.href = leftTopBanner.url;
    }
  };

  // 하단 왼쪽 bottom url
  const leftBottomLink = () => {
    if (leftBottomBanner.url) {
      window.location.href = leftBottomBanner.url;
    }
  };

  // 하단 오른쪽 url
  const rightLink = () => {
    if (rightBanner.url) {
      window.location.href = rightBanner.url;
    }
  };

  return (
    <MainPageContainer>
      {/* ------------ 메인 배너 -------------- */}
      <BannerContainer>
        <Slider {...settings}>
          {mainBanner &&
            mainBanner.map((banner) => (
              <BannerContainer
                key={banner.id}
                onClick={() => {
                  if (banner.url) {
                    window.location.href = banner.url;
                  }
                }}
              >
                <img src={banner.img} />
              </BannerContainer>
            ))}
        </Slider>
      </BannerContainer>

      {/* ------------ 신규 강의 -------------- */}
      <NewLectureSection>
        <NewLecturesContainer>
          <TitleBox>
            <SectionTitle>신규 강의</SectionTitle>
            <Line />
          </TitleBox>
          <SectionInfo>시험 trend에 맞춘 새로운 강의</SectionInfo>
          <NewLectureContents lectureNum={newLecture.length}>
            {newLecture !== undefined ? (
              <>
                {newLecture.map((lecture, i) => (
                  <NewLectureCard
                    key={i}
                    backgroundImg={lecture.lectureImg}
                    professorName={lecture.professorName}
                    smalltitle={lecture.introduction}
                    bigtitle={lecture.lectureName}
                    smallContent={lecture.shortComment}
                    onClickFunc={() => goToLectureDetailPage(lecture.professorId, lecture.id)}
                    lectureNum={newLecture.length}
                  />
                ))}
              </>
            ) : (
              <SpinLoader type={typeList[2].prop} color={'#000'} width={1313} height={424} />
            )}
          </NewLectureContents>
        </NewLecturesContainer>
      </NewLectureSection>

      {/* ------------ HOT PICK -------------- */}
      <HotPickSection>
        <HotPickContainer>
          {hotLectureStatus !== undefined || hotBookStatus !== undefined ? (
            <>
              <SectionTitle>HOT PICK!</SectionTitle>
              <HotPickSlides hotPickLecture={hotPickLecture} hotPickBook={hotPickBook} />
            </>
          ) : (
            <SpinLoader type={typeList[2].prop} color={'#000'} width={1313} height={360} />
          )}
        </HotPickContainer>
      </HotPickSection>

      {/* ------------ BEST 5 -------------- */}
      <Best5Section>
        <Best5Container>
          <BestLectures
            bestLanguageLogicLectures={bestLanguageLogicLectures}
            bestDataInterpretationLectures={bestDataInterpretationLectures}
            bestSituationalJudgmentLectures={bestSituationalJudgmentLectures}
            bestLectureStatus={bestLectureStatus}
          />
          <BestBooks
            bestLanguageLogicBooks={bestLanguageLogicBooks}
            bestDataInterpretationBooks={bestDataInterpretationBooks}
            bestSituationalJudgmentBooks={bestSituationalJudgmentBooks}
            bestBookStatus={bestBookStatus}
          />
        </Best5Container>
      </Best5Section>

      {/* ------------ Review -------------- */}
      <ReviewSection>
        <ReviewContainer>
          <SectionTitle>합격 후기</SectionTitle>
          <RevewTabContainer>
            <ReviewSectionInfo>랩스탠다드의 빛나는 합격자 배출</ReviewSectionInfo>
            <ReviewLinkTabBox>
              <Link to="/examinfo/passnote">자세한 후기와 꿀팁 확인하러 가기</Link>
              <SmallArrowBtn />
            </ReviewLinkTabBox>
          </RevewTabContainer>
          <ReviewSlides passNote={passNote} />
        </ReviewContainer>
      </ReviewSection>

      {/* ------------ ETC -------------- */}
      <AdSection>
        <AdContainer>
          <LeftAdContainer>
            <LeftAdContent1 backgroundImg={leftTopBanner.img} onClick={() => leftTopLink()} />
            <LeftAdContent2 backgroundImg={leftBottomBanner.img} onClick={() => leftBottomLink()} />
          </LeftAdContainer>
          <RightAdContainer>
            <RightContent backgroundImg={rightBanner.img} onClick={() => rightLink()} />
          </RightAdContainer>
        </AdContainer>
      </AdSection>
    </MainPageContainer>
  );
};

export default MainPage;
