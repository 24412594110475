import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

const RegisterPageContainer = styled.div`
  ${({ theme }) => theme.container.mainContainer};
`;

const RegisterContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  margin-top: 200px;
  padding-bottom: 210px;
  text-align: center;

  & > img {
    margin-bottom: 76px;
  }
`;

const RegisterTitle = styled.div`
  ${FONT_STYLES.P_M};
  font-size: 36px;
  margin-bottom: 50px;
  margin-left: 30px;
`;

const RegisterBtn = styled.div`
  margin: 30px 0;
  cursor: pointer;

  & > a,
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    & > img {
      margin-right: 15px;
    }

    & > p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 378px;
      height: 67px;
      ${FONT_STYLES.P_M};
      font-size: 18px;
      box-shadow: 0px 3px 10px #00000029;
      border-radius: 7px;
    }
  }
`;

const GoToLoginPageBox = styled.div`
  ${FONT_STYLES.P_L}
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  margin: 50px 0 0 50px;

  & > a {
    text-decoration: underline !important;
    margin-left: 20px;
    color: ${COLOR.BLUE_4};
  }
`;

export { RegisterPageContainer, RegisterContainer, RegisterTitle, RegisterBtn, GoToLoginPageBox };
