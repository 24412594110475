import React, { useState, useEffect } from 'react';
import { FindIdCompleteContainer, FindAccountPageTitle, HorizonLine, Content } from './style';
import COLOR from '../../styles/common/color';
import { RegisterButton } from '../../components/atoms/Button';
import { SquareInput } from '../../components/atoms/Input';
import { useNavigate, Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';

import { findPassword } from '../../axiosManage/Auth';
import { findPasswordTokenAtom } from '../../recoil/AuthStore/AuthRecoil';

function ChangePasswordPage() {
  const isLogin = useRecoilValue(isLoginAtom);
  const navigate = useNavigate();

  if (isLogin === true) {
    alert('잘못된 접근입니다.');
    return <Navigate to="/" />;
  }

  // 비밀번호 입력
  const [userPassword, setUserPassword] = useState('');
  const handlePassword = (e) => {
    setUserPassword(e.target.value);
  };

  // 비밀번호 확인
  const [userPasswordConfirm, setPasswordConfirm] = useState('');
  const [isPasswordSame, setIsPasswordSame] = useState(false);
  const handleUserPasswordConfirm = (e) => {
    setPasswordConfirm(e.target.value);
  };

  useEffect(() => {
    if (userPassword !== userPasswordConfirm) {
      setIsPasswordSame(false);
    } else if (userPassword === userPasswordConfirm) {
      setIsPasswordSame(true);
    }
  }, [userPasswordConfirm]);

  // 비밀번호 유효성 검사
  const passwordCheck = (password) => {
    // 6-16자에 하나이상의 문자 및 하나의 숫자 및 하나의 특수 문자 포함된 비밀번호인지 체크
    const passwordRegEx = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,16}$/g;
    return passwordRegEx.test(password);
  };

  const findPasswordToken = useRecoilValue(findPasswordTokenAtom);
  const requestChangePassword = () => {
    if (passwordCheck(userPassword) === false || isPasswordSame === false) {
      alert('비밀번호를 확인해주세요.');
    } else {
      findPassword({
        findPasswordToken: findPasswordToken,
        newPassword: userPassword,
        newPasswordCheck: userPasswordConfirm,
      })
        .then((res) => {
          if (res.data.success === true) {
            alert('비밀번호가 변경되었습니다.');
            navigate('/login');
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  return (
    <>
      <FindIdCompleteContainer>
        <FindAccountPageTitle>
          <span>비밀번호 변경</span>
        </FindAccountPageTitle>
        <HorizonLine />
        <Content isPasswordSame={isPasswordSame}>
          <div>비밀번호 재발급</div>
          <div>
            <p>비밀번호</p>
            <SquareInput
              width={455}
              height={62}
              type="password"
              placeholder="문자, 숫자, 특수문자 조합 6 - 16자"
              onChangeFunc={handlePassword}
            />
          </div>
          <div>
            <p>비밀번호 확인</p>
            <SquareInput
              width={455}
              height={62}
              type="password"
              placeholder="문자, 숫자, 특수문자 조합 6 - 16자"
              onChangeFunc={handleUserPasswordConfirm}
            />
            <span>비밀번호가 다릅니다.</span>
          </div>
        </Content>
        <RegisterButton
          width={202}
          height={62}
          bgColor={COLOR.BLUE_4}
          fontSize={20}
          fontWeight={300}
          onClickFunc={requestChangePassword}
        >
          비밀번호 변경하기
        </RegisterButton>
      </FindIdCompleteContainer>
    </>
  );
}

export default ChangePasswordPage;
