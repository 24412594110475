import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const MainBannerContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  position: relative;
  width: 100%;
  height: 554px;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.GRAY_0};
  margin: 30px auto;
`;

export { MainBannerContainer, HorizonLine };
