import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  SupportCenterHomeContainer,
  ActiveTab,
  SupportCategoryTitle,
  QuestionsContainer,
  QuestionsCategoryList,
  QuestionsContentsList,
  ContentList,
  ContactContainer,
  ErrataContainer,
  NoticeContainer,
  SupportCenterFooter,
  FooterTextContainer,
  FooterLeftContent,
  FooterRightContent,
} from './style';

import { SmallQuestionDetail } from '../QuestionsDetail';

import SupportCenterFooterImg from '../../../../assets/images/image-supportcenter-home-footer.png';
import SupportCenterContactImg from '../../../../assets/images/image-supportcenter-contact.png';
import CallIcon from '../../../../assets/icons/icon-Call.png';
import ContactIcon from '../../../../assets/icons/icon-Contact.png';
import { ReactComponent as ArrowRightSmall } from '../../../../assets/icons/icon-ArrowRightBtn.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/icon-SupportCenterFooterArrowRight.svg';

import { SpinLoader, typeList } from '../../../atoms/SpinLoader';
import { useFiltering } from '../../../../hooks/useFiltering';

import { useRecoilState } from 'recoil';
import {
  supportCenterNoticeListAtom,
  supportCenterErrataListAtom,
} from '../../../../recoil/SupportCenterStore/SupportCenterRecoil';

import { useQuery } from 'react-query';
import useHandleError from '../../../../hooks/useHandleError';
import { getNotice, getErrata } from '../../../../axiosManage/SupportCenter';

const CATEGORY_LIST = [
  {
    id: 1,
    name: '배송',
  },
  {
    id: 2,
    name: '결제/환불',
  },
  {
    id: 3,
    name: '동영상',
  },
  {
    id: 4,
    name: '사이트 이용',
  },
  {
    id: 5,
    name: '모바일',
  },
];

function Home({ frequentQuestion, frequentQuestionDataState }) {
  const { handleError } = useHandleError();

  /** 1:1 문의 박스 */
  const navigate = useNavigate();
  const navigateToContacts = () => {
    navigate('/supportcenter/contact');
  };

  /** 자주 묻는 질문 박스 */
  const [currentCategory, setCurrentCategory] = useState('배송');
  const [selectedData, setSelectedData] = useState([]);
  const [currentCategoryData, setCurrentCategoryData] = useState([]);

  const clickCategory = (name) => {
    setCurrentCategory(name);
  };

  useEffect(() => {
    setSelectedData(useFiltering(frequentQuestion, '배송'));
  }, [frequentQuestion]);

  useEffect(() => {
    if (currentCategory === '배송') {
      setSelectedData(useFiltering(frequentQuestion, '배송'));
    } else if (currentCategory === '결제/환불') {
      setSelectedData(useFiltering(frequentQuestion, '결제/환불'));
    } else if (currentCategory === '동영상') {
      setSelectedData(useFiltering(frequentQuestion, '동영상'));
    } else if (currentCategory === '사이트 이용') {
      setSelectedData(useFiltering(frequentQuestion, '사이트 이용'));
    } else if (currentCategory === '모바일') {
      setSelectedData(useFiltering(frequentQuestion, '모바일'));
    }
  }, [currentCategory]);

  useEffect(() => {
    if (selectedData !== undefined) {
      const currentPosts = selectedData.slice(0, 5);
      setCurrentCategoryData(currentPosts);
    }
  }, [selectedData, currentCategory]);

  /** 자주 묻는 질문에 대한 상세 페이지 보여주기 */
  const [openDetailID, setOpenDetailID] = useState(0);
  const [showSelectedDetail, setShowSelectedDetail] = useState(
    Array(selectedData.length).fill(false),
  );

  const openDetail = (id) => {
    setOpenDetailID(id);
    if (showSelectedDetail[id] === true) {
      const newArr = [...showSelectedDetail];
      newArr[id] = false;
      setShowSelectedDetail(newArr);
    } else {
      const newArr = Array(selectedData.length).fill(false);
      newArr[id] = true;
      setShowSelectedDetail(newArr);
    }
  };

  useEffect(() => {
    setShowSelectedDetail(Array(selectedData.length).fill(false));
  }, [currentCategory]);

  /** 공지사항 박스 */
  const [supportCenterNoticeList, setSupportCenterNoticeList] = useRecoilState(
    supportCenterNoticeListAtom,
  );
  const { status: supportCenterNoticeListStatus } = useQuery(
    'getNotice',
    () => getNotice('', '', 1),
    {
      retry: 0,
      onSuccess: (data) => {
        setSupportCenterNoticeList(data.data.educationHelpCenterNoticeList);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  /** 정오표 박스 */
  const [supportCenterErrataList, setSupportCenterErrataList] = useRecoilState(
    supportCenterErrataListAtom,
  );
  const { status: supportCenterErrataListStatus } = useQuery(
    'getErrata',
    () => getErrata('', '', 1),
    {
      retry: 0,
      onSuccess: (data) => {
        setSupportCenterErrataList(data.data.erratas);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  return (
    <SupportCenterHomeContainer>
      {/* 상위 컨텐츠 2개(자주 묻는 질문 , 1:1 문의) */}
      <div>
        <QuestionsContainer>
          <div>
            <SupportCategoryTitle>자주 묻는 질문</SupportCategoryTitle>
            <QuestionsCategoryList>
              {CATEGORY_LIST.map((category) => (
                <ActiveTab
                  key={category.name}
                  className={category.name === currentCategory ? 'active' : ''}
                  onClick={() => clickCategory(category.name)}
                >
                  {category.name}
                  <div></div>
                </ActiveTab>
              ))}
            </QuestionsCategoryList>
          </div>
          {frequentQuestionDataState === 'loading' ? (
            <SpinLoader type={typeList[2].prop} color={'#000'} />
          ) : (
            <QuestionsContentsList>
              {currentCategoryData.map((data, i) => (
                <div key={i}>
                  <div onClick={() => openDetail(i)}>
                    <li>{data.title}</li>
                    <ArrowRightSmall className={showSelectedDetail[i] ? 'active' : ''} />
                  </div>
                  <div>
                    {showSelectedDetail[i] ? (
                      <SmallQuestionDetail
                        openDetailContent={currentCategoryData[openDetailID].content}
                      />
                    ) : null}
                  </div>
                </div>
              ))}
            </QuestionsContentsList>
          )}
        </QuestionsContainer>
        <ContactContainer onClick={navigateToContacts}>
          <img src={SupportCenterContactImg} />
          <SupportCategoryTitle>1:1 문의</SupportCategoryTitle>
          <p>해결되지 않는 어려움이 있으신가요?</p>
        </ContactContainer>
      </div>
      {/* 하위 컨텐츠 2개(정오표, 공지사항) */}
      <div>
        <ErrataContainer>
          <SupportCategoryTitle>자료실 & 정오표</SupportCategoryTitle>
          {supportCenterErrataListStatus === 'loading' ? (
            <SpinLoader type={typeList[2].prop} color={'#000'} />
          ) : (
            <ContentList>
              {supportCenterErrataList.slice(0, 5).map((data) => (
                <div key={data.id} onClick={() => navigate(`/supportcenter/errata/${data.id}`)}>
                  <p>•</p>
                  <p>{data.title}</p>
                  <ArrowRightSmall />
                </div>
              ))}
            </ContentList>
          )}
        </ErrataContainer>
        <NoticeContainer>
          <SupportCategoryTitle>공지사항</SupportCategoryTitle>
          {supportCenterNoticeListStatus === 'loading' ? (
            <SpinLoader type={typeList[2].prop} color={'#000'} />
          ) : (
            <ContentList>
              {supportCenterNoticeList.slice(0, 5).map((data) => (
                <div key={data.id} onClick={() => navigate(`/supportcenter/notice/${data.id}`)}>
                  <p>•</p>
                  <p>{data.title}</p>
                  <ArrowRightSmall />
                </div>
              ))}
            </ContentList>
          )}
        </NoticeContainer>
      </div>
      {/* 학습지원센터 푸터 */}
      <SupportCenterFooter backgroundImg={SupportCenterFooterImg}>
        <FooterTextContainer>
          <FooterLeftContent>
            <div>
              <p>
                <img src={CallIcon} />
              </p>
              <p>고객센터</p>
            </div>
            <div>
              <p>070-8808-0700</p>
              <p>운영시간 : 평일 09:00 - 18:00(주말/공휴일 제외)</p>
              <p>점심시간 : 12:00 - 13:00</p>
            </div>
          </FooterLeftContent>
          <FooterRightContent>
            <div>
              <p>
                <img src={ContactIcon} />
              </p>
              <p>1:1 문의</p>
            </div>
            <div>
              <p onClick={navigateToContacts}>
                문의하러 가기 <ArrowRight />
              </p>
            </div>
          </FooterRightContent>
        </FooterTextContainer>
      </SupportCenterFooter>
    </SupportCenterHomeContainer>
  );
}

export default Home;
