import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

export const OrderBasketPageContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding};
  text-align: center;
  padding-top: 60px;
  padding-bottom: 120px;

  @media screen and (max-width: 1700px) {
    padding-top: 60px;
    padding-bottom: 120px;
  }
`;

export const OrderBasketPageTitle = styled.div`
  ${FONT_STYLES.P_M};
  font-size: 36px;

  & > span {
    ${FONT_STYLES.P_B};
    color: ${COLOR.BLUE_4};
  }
`;

export const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_4};
  margin: 50px auto;
`;

export const ContentBox = styled.div`
  margin-top: 30px;

  & > div {
    :nth-child(2) {
      ${FONT_STYLES.P_R}
      font-size: 36px;
      margin-bottom: 53px;
    }
  }

  & > svg {
    margin: 32px 0 31px 0;
  }
`;

export const PayInfoBox = styled.div`
  width: 386px;
  margin: 0 auto 70px auto;

  & > div {
    display: flex;

    :nth-child(3) {
      & > p:nth-child(2) {
        ${FONT_STYLES.P_SB};
        font-size: 20px;
        color: ${COLOR.BLUE_4};
      }
    }

    & > p {
      ${FONT_STYLES.P_R};
      padding: 8.5px 0;

      :nth-child(2) {
        margin-left: auto;
      }
    }
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: center;

  & > div {
    margin: 40px 10px 0 10px;
  }
`;
