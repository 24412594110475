import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const SupportCenterHomeContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-top: 70px;

  & > div:nth-child(1) {
    display: flex;
  }

  & > div:nth-child(2) {
    display: flex;
    margin-top: 30px;
  }

  @media screen and (max-width: 1700px) {
    padding-top: 70px;
  }
`;

const SupportCategoryTitle = styled.div`
  ${FONT_STYLES.P_EB}
  font-size: 36px;
`;

const ActiveTab = styled.div`
  padding: 0 20px;
  margin-top: 10px;
  cursor: pointer;

  & > div {
    margin: 10px auto 0 auto;
    border: 2px solid ${COLOR.GRAY_0};
  }

  &.active {
    ${FONT_STYLES.P_EB}
    color: ${COLOR.BLUE_4};
    & > div {
      display: block;
      width: 27px;
      margin: 10px auto 0 auto;
      border: 2px solid ${COLOR.BLUE_4};
    }
  }

  @media screen and (max-width: 1600px) {
    padding: 0 10px;
  }
`;

const QuestionsContainer = styled.div`
  width: 65%;
  height: 450px;
  padding: 40px;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 22px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  & > div:nth-child(1) {
    display: flex;
  }
`;

const QuestionsCategoryList = styled.ul`
  ${FONT_STYLES.P_R}
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-left: auto;

  & li {
    padding: 0 20px;
    cursor: pointer;

    &.active {
      color: ${COLOR.BLUE_4};
    }

    @media screen and (max-width: 1600px) {
      padding: 0 10px;
    }
  }
`;

const ContentList = styled.div`
  width: 100%;
  margin-top: 30px;

  & > div {
    display: flex;
    margin: 30px 0;
    cursor: pointer;

    & > p {
      :nth-child(1) {
        padding-right: 5px;
      }
      :nth-child(2) {
        line-height: 1.3;
      }
    }

    & > svg {
      margin: auto 0 auto auto;
    }
  }
`;
const QuestionsContentsList = styled.div`
  text-align: left;

  & > div {
    cursor: pointer;

    & > div {
      :nth-child(1) {
        display: flex;
        margin: 40px 0;

        & > svg {
          margin: auto 0 auto auto;

          &.active {
            translate: all 0.5s;
            transform: rotate(90deg);
          }
        }
      }
    }
  }
`;

const ContactContainer = styled.div`
  width: 35%;
  height: 450px;
  padding: 40px;
  margin-left: 20px;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 22px;
  text-align: center;
  cursor: pointer;

  & > img {
    display: block;
    margin: auto 120px 20px auto;

    @media screen and (min-width: 1920px) {
      margin: auto auto 20px auto;
    }
  }

  & > p {
    ${FONT_STYLES.P_R}
    font-size: 18px;
    margin-top: 20px;
  }
`;

const ErrataContainer = styled.div`
  width: 40%;
  height: 450px;
  padding: 40px;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 22px;
`;

const NoticeContainer = styled.div`
  width: 60%;
  height: 450px;
  padding: 40px;
  margin-left: 20px;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 22px;
`;

const SupportCenterFooter = styled.div`
  position: relative;
  width: 100%;
  height: 380px;
  margin: 60px 0;
  border-radius: 19px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${({ backgroundImg }) => backgroundImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const FooterTextContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const FooterLeftContent = styled.div`
  display: flex;
  align-items: center;
  height: 187px;
  border-right: 1px solid ${COLOR.GRAY_0};

  & > div:nth-child(1) {
    text-align: center;
    padding-right: 40px;

    & > p:nth-child(2) {
      ${FONT_STYLES.N_B}
      font-size: 32px;
      padding-top: 10px;
    }
  }

  & > div:nth-child(2) {
    margin-right: 50px;
    & > p:nth-child(1) {
      ${FONT_STYLES.N_R}
      font-size: 36px;
      margin-bottom: 25px;
    }
    & > p:nth-child(2),
    & > p:nth-child(3) {
      ${FONT_STYLES.N_R}
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
`;

const FooterRightContent = styled.div`
  display: flex;
  align-items: center;

  & > div:nth-child(1) {
    text-align: center;
    padding-right: 40px;
    margin-left: 50px;

    & > p:nth-child(2) {
      ${FONT_STYLES.N_B}
      font-size: 32px;
      padding-top: 10px;
    }
  }

  & > div:nth-child(2) {
    & > p:nth-child(1) {
      ${FONT_STYLES.N_R}
      font-size: 36px;
      margin-bottom: 25px;
      cursor: pointer;

      & > svg {
        margin-left: 20px;
      }
    }
    & > p:nth-child(2),
    & > p:nth-child(3) {
      ${FONT_STYLES.N_R}
      font-size: 18px;
      margin-bottom: 5px;
    }
  }
`;

export {
  SupportCenterHomeContainer,
  ActiveTab,
  SupportCategoryTitle,
  QuestionsContainer,
  QuestionsCategoryList,
  QuestionsContentsList,
  ContentList,
  ContactContainer,
  ErrataContainer,
  NoticeContainer,
  SupportCenterFooter,
  FooterTextContainer,
  FooterLeftContent,
  FooterRightContent,
};
