import styled, { css } from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

export const MyPageHomeContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-bottom: 150px;
  padding-top: 60px;

  @media screen and (max-width: 1700px) {
    padding-top: 60px;
    padding-bottom: 150px;
  }
`;

export const MainBox = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

export const MainLeft = styled.div`
  width: 35%;
  padding: 30px;
  margin-right: 10px;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 22px;

  & > div {
    display: flex;
    padding: 15px 0;

    :nth-child(1) {
      ${FONT_STYLES.P_R}
      font-size: 36px;
      margin-bottom: 70px;
      span {
        ${FONT_STYLES.P_B}
        color: ${COLOR.BLUE_4};
        padding-right: 5px;
      }
    }
    :nth-child(n + 3) {
      border-top: 1px solid ${COLOR.GRAY_9};
    }

    & > p {
      ${FONT_STYLES.P_M};
      :nth-child(1) {
        font-size: 20px;
      }
      :nth-child(2) {
        font-size: 18px;
        color: ${COLOR.BLUE_4};
        margin-left: auto;
      }
    }
  }
`;

export const MainRight = styled.div`
  width: 65%;
  padding: 30px;
  margin-left: 10px;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 22px;

  & > div {
    cursor: pointer;

    :nth-child(1) {
      ${FONT_STYLES.P_B}
      font-size: 24px;
      color: ${COLOR.BLUE_4};
      padding-bottom: 20px;
      border-bottom: 1px solid ${COLOR.GRAY_9};
    }

    :nth-child(2) {
      display: flex;
      justify-content: center;
      padding-top: 45px;
      border-bottom: 1px solid ${COLOR.GRAY_9};

      & > a {
        display: flex;
        align-items: center;
        padding-bottom: 45px;

        :nth-child(1) {
          padding-right: 70px;
          border-right: 1px solid ${COLOR.GRAY_9};
        }
        :nth-child(2) {
          position: relative;
          padding-left: 70px;

          & > span {
            position: absolute;
            top: 3px;
            right: 195px;
            width: 30px;
            height: 30px;
            background-color: ${COLOR.YELLOW_0};
            border-radius: 50%;
            text-align: center;
            padding-top: 7px;
          }
        }

        & > p {
          width: 130px;
          text-align: left;
        }
      }
    }

    :nth-child(3) {
      display: flex;
      padding-bottom: 15px;
      justify-content: center;

      & > a {
        display: flex;
        align-items: center;
        padding-top: 45px;

        :nth-child(1) {
          padding-right: 70px;
          border-right: 1px solid ${COLOR.GRAY_9};
        }
        :nth-child(2) {
          padding-left: 70px;
        }

        & > p {
          width: 130px;
          text-align: left;
        }
      }
    }
  }
`;

export const LectureBox = styled.div`
  padding: 30px;
  margin-bottom: 40px;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 22px;
  height: 700px;
  overflow-y: scroll;
`;

export const ContentHeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 26px;

  & > div {
    :nth-child(1) {
      & > p {
        ${FONT_STYLES.P_B}
        font-size: 24px;
        margin-bottom: 38px;
      }

      & > div {
        display: flex;
        list-style: none;
        color: #716b6b;

        li {
          cursor: pointer;
          margin-right: 30px;

          &.active {
            ${FONT_STYLES.P_SB};
            color: ${COLOR.BLACK_1};
          }
        }
      }
    }

    :nth-child(2) {
      display: flex;

      & > div {
        text-align: center;
        margin-left: 61px;

        :nth-child(2) {
          margin-right: 61px;
        }

        & > p {
          :nth-child(1) {
            ${FONT_STYLES.P_M};
            font-size: 18px;
            padding-bottom: 14px;
          }
          :nth-child(2) {
            ${FONT_STYLES.P_B};
            font-size: 24px;
          }
        }
      }
    }
  }
`;

export const LectureItems = styled.div``;

export const Lecture = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 60px 20px 18px;
  border-top: 1px solid ${COLOR.GRAY_9};
  cursor: pointer;

  & > div {
    :nth-child(1) {
      width: 30px;
      ${FONT_STYLES.P_R}
    }
    :nth-child(2) {
      width: 150px;
      img {
        width: 139px;
        height: 139px;
        border-radius: 18px;
      }
    }
    :nth-child(3) {
      width: 60px;
      text-align: center;

      & > p {
        padding: 2.5px 0;
        :nth-child(1) {
          ${FONT_STYLES.P_T}
          font-size: 15px;
        }
        :nth-child(2) {
          ${FONT_STYLES.P_R}
        }
      }
    }
    :nth-child(4) {
      width: 300px;
      margin-left: 30px;
      margin-right: 50px;
      text-align: left;
      line-height: 1.2;

      & > p {
        padding: 4px 0;
        :nth-child(1) {
          ${FONT_STYLES.P_L}
          font-size: 13px;
        }
        :nth-child(2) {
          ${FONT_STYLES.P_SB}
          font-size: 20px;
        }
        :nth-child(3) {
          ${FONT_STYLES.P_R}

          & > span {
            color: #716b6b;
            padding-right: 12px;
          }
        }
      }
    }
    :nth-child(5) {
      width: 230px;
      text-align: center;
      margin-right: 58px;

      & > p {
        :nth-child(1) {
          ${FONT_STYLES.P_SB}
          font-size: 20px;
          padding-bottom: 15px;
        }
        :nth-child(2) {
          & > span {
            padding: 0 5px;
          }
        }
      }
    }
    :nth-child(6) {
      width: 120px;
      & > div {
        & > div {
          display: block;

          & > p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            :nth-child(1) {
              top: 35%;
            }
            :nth-child(2) {
              top: 65%;
              left: 55%;
              ${FONT_STYLES.P_B}
              font-size: 20px;
            }
          }
        }
      }
    }
  }
`;

export const CounselBox = styled.div`
  padding: 30px;
  margin-bottom: 40px;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 22px;
  height: 550px;
  overflow-y: scroll;

  & > div:nth-child(3) {
    margin: 10px auto;
    text-align: center;
    color: ${COLOR.GRAY_3};
  }
`;

export const CounselItems = styled.div``;

export const Counsel = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid ${COLOR.GRAY_8};
  cursor: pointer;
  text-align: center;

  & > div:nth-child(1) {
    ${FONT_STYLES.P_L}
    margin: 0 20px;
    width: 6%;
  }

  & > div:nth-child(2) {
    margin: 0 10px;
    width: 10%;

    p:nth-child(1) {
      ${FONT_STYLES.P_SB}
      margin-bottom: 5px;
    }
    p:nth-child(2) {
      ${FONT_STYLES.P_EB}
      font-size: 20px;
      margin-bottom: 5px;
    }
    p:nth-child(3) {
      ${FONT_STYLES.P_L}
      font-size: 14px;
    }
  }

  & > div:nth-child(3) {
    text-align: left;
    margin: 0 10px;
    width: 700px;

    p: nth-child(1) {
      font-size: 15px;
      margin-bottom: 10px;
      float: left;
      ${FONT_STYLES.P_R}
    }
    p: nth-child(2) {
      ${FONT_STYLES.P_SB}
      font-size: 18px;
      clear: both;
    }
  }

  & > div:nth-child(4) {
    margin-left: auto;
    margin-right: 61px;

    &.complete {
      color: ${COLOR.BLUE_2};
    }
  }

  & > div:nth-child(5) {
    & > button:nth-child(1) {
      margin-right: 10px;
    }
  }
`;

export const FooterBox = styled.div`
  width: 100%;

  & > div {
    :nth-child(1) {
      display: flex;
      justify-content: center;
    }

    .border-block {
      margin: 0 10px;
    }
  }
`;

export const FooterContent = styled.div`
  width: 50%;
  height: 286px;
  padding: 30px;
  background-color: ${COLOR.GRAY_0};
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;
  text-align: left;
  overflow-y: scroll;

  & > div {
    :nth-child(1) {
      display: flex;
      justify-content: space-between;

      & > div {
        cursor: pointer;
        :nth-child(1) {
          ${FONT_STYLES.P_B};
          font-size: 24px;
          margin-bottom: 30px;
        }
        :nth-child(2) {
          & > p {
            ${FONT_STYLES.P_R};
            margin-bottom: 10px;
          }
        }
      }
    }

    :nth-child(2) {
      position: relative;
      & > div {
        display: flex;
        cursor: pointer;
        margin-bottom: 25px;

        & > p {
          line-height: 1.3;
          :nth-child(1) {
            padding-right: 5px;
          }
        }
      }
    }

    :nth-child(3) {
      position: relative;
      float: right;
      top: -50px;
      cursor: pointer;

      & > p {
        ${FONT_STYLES.P_R};
        margin-bottom: 10px;
      }
    }
  }
`;
