import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const HomeContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-top: 70px;
  padding-bottom: 242px;

  @media screen and (max-width: 1700px) {
    padding-top: 70px;
    padding-bottom: 242px;
  }
`;

const Section1 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1311px;
  margin: 0 auto;
`;

const ExLecture = styled.div`
  width: 646px;
`;

const BestReview = styled.div`
  width: 648px;
`;

const ReviewContainer = styled.div`
  display: flex;
`;

const ReviewBannerContainer = styled.div``;

const ReviewBanner = styled.div``;

const EventBanner = styled.div`
  background-image: url(${({ backgroundImg }) => backgroundImg});
  width: 203px;
  height: 369px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 21px;
  color: ${COLOR.GRAY_0};
  padding: 40px 0;
  margin-left: 21px;
  text-align: center;

  :hover {
    cursor: pointer;
  }

  & > div:nth-child(1) p:nth-child(1) {
    ${FONT_STYLES.P_SB}
    font-size: 15px;
    line-height: 15px;
  }
  & > div:nth-child(1) p:nth-child(2) {
    ${FONT_STYLES.P_SB}
    font-size: 20px;
    line-height: 24px;
    padding-top: 3px;
  }

  & > div:nth-child(2) {
    color: ${COLOR.BLUE_6};
  }

  & > div:nth-child(2) p:nth-child(1) {
    ${FONT_STYLES.P_R}
    font-size: 12px;
  }
  & > div:nth-child(2) p:nth-child(2) {
    ${FONT_STYLES.P_B}
    font-size: 20px;
    padding-top: 5px;
  }

  & > div:nth-child(3) {
    display: flex;
    justify-content: center;

    p {
      ${FONT_STYLES.P_R}
      font-size: 12px;
      padding-right: 4px;
    }
  }
`;

const Subtitle = styled.p`
  ${FONT_STYLES.P_EB}
  font-size: 24px;
  margin-bottom: 27px;
`;

const VideoContainer = styled.div`
  width: 646px;
  height: 369px;
`;

const Section2 = styled.div`
  width: 1311px;
  padding-top: 60px;
  margin: 0 auto;
  img {
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

const Section3 = styled.div`
  width: 1311px;
  padding-top: 60px;
  margin: 0 auto;
`;

export {
  HomeContainer,
  Subtitle,
  Section1,
  VideoContainer,
  ExLecture,
  BestReview,
  ReviewBannerContainer,
  ReviewBanner,
  ReviewContainer,
  EventBanner,
  Section2,
  Section3,
};
