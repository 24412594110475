import React, { useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';

import { useQuery } from 'react-query';
import { kakaoOauthCallback } from '../../../axiosManage/Auth';
import useHandleError from '../../../hooks/useHandleError';

import { useRecoilState } from 'recoil';
import { isLoginAtom, registerSocialAccountTokenAtom } from '../../../recoil/AuthStore/AuthRecoil';
import { getMyInfo } from '../../../axiosManage/MyPage';
import { myInfoAtom } from '../../../recoil/MyPageStore/MyPageRecoil';

function KakaoLogin() {
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const { handleError } = useHandleError();
  const navigate = useNavigate();
  let authCode = new URL(window.location.href).searchParams.get('code');

  // 로그인 성공한 경우 내 정보 가져오기 위한 api 요청
  const [myInfo, setMyInfo] = useRecoilState(myInfoAtom);

  const [registerSocialAccountToken, setRegisterSocialAccountToken] = useRecoilState(
    registerSocialAccountTokenAtom,
  );

  const { status: kakaoLoginStatus } = useQuery(
    'kakaoOauthCallback',
    () => kakaoOauthCallback(authCode),
    {
      retry: 0,
      enabled: !!authCode,
      onSuccess: (data) => {
        if (data.data.registerKakaoAccountToken) {
          alert('회원가입을 진행해주세요.');
          setRegisterSocialAccountToken(data.data.registerKakaoAccountToken);
          navigate('/register/socialassignment');
        } else {
          localStorage.setItem('accessToken', data.data.accessToken);
          localStorage.setItem('refreshToken', data.data.refreshToken);
          setIsLogin(true);
          navigate('/');
          getMyInfo().then((res) => {
            setMyInfo(res.data.myInfo);
          });
        }
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );
}

export default KakaoLogin;
