import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import {
  OrderBasketPageContainer,
  OrderBasketPageTitle,
  HorizonLine,
  BasketBox,
  BasketHeader,
  BasketItems,
  Item,
  BasketFooter,
  ButtonBox,
  CountButton,
} from './style';
import COLOR from '../../styles/common/color';
import { ReactComponent as PlusBtn } from '../../assets/icons/icon-PlusBtn.svg';
import { ReactComponent as MinusBtn } from '../../assets/icons/icon-MinusBtn.svg';

import ProgressBox from '../../components/molecules/ProgressBox';
import { BasicCheckBox } from '../../components/atoms/CheckBox';
import { Button } from '../../components/atoms/Button';

import StepIcon1Selected from '../../assets/icons/icon-BasketSelected.png';
import StepIcon2 from '../../assets/icons/icon-join.png';
import StepIcon3 from '../../assets/icons/icon-complete.png';

import { useRecoilState, useRecoilValue } from 'recoil';
import {
  myOrderBasketListAtom,
  mySelectedProductListAtom,
  mySelectedProductListPriceAtom,
} from '../../recoil/OrderBasketStore/OrderBasketRecoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';
import { myInfoAtom } from '../../recoil/MyPageStore/MyPageRecoil';

function OrderBasketPage() {
  const navigate = useNavigate();
  const isLogin = useRecoilValue(isLoginAtom);
  const myInfo = useRecoilValue(myInfoAtom);

  if (isLogin === false) {
    alert('로그인을 해주세요.');
    return <Navigate to="/login" />;
  }

  const [selectedProductList, setSelectedProductList] = useRecoilState(myOrderBasketListAtom);
  const [selectedProducts, setSelectedProducts] = useRecoilState(mySelectedProductListAtom);

  // 상품 선택 로직(부분선택, 전체선택)
  const [isChecked, setIsChecked] = useState(Array(selectedProductList.length + 1).fill(false));
  const [isAllSelected, setIsAllSelected] = useState(false);
  //const [clickedBuyAllProducts, setClickedBuyAllProducts] = useState(false);

  // 전체 선택, 해제 할 때마다 검사
  useEffect(() => {
    if (isAllSelected === true) {
      const newArr = Array(selectedProductList.length + 1).fill(true);
      setIsChecked(newArr);
    } else if (isAllSelected === false) {
      if ([...isChecked][0] === false) {
        const newArr = [...isChecked];
        setIsChecked(newArr);
      } else {
        const newArr = Array(selectedProductList.length + 1).fill(false);
        setIsChecked(newArr);
      }
    }
  }, [isAllSelected]);

  const onClickCheck = (id) => {
    // 전체 선택하는 경우
    if (id === 0) {
      setIsAllSelected((isAllSelected) => !isAllSelected);
    }
    // 부분 선택하는 경우
    else {
      const newArr = [...isChecked];
      // 전체 선택 눌렀다가 다른 선택 취소하면 전체 선택 체크박스 해제
      if (isAllSelected === true) {
        newArr[0] = false;
        setIsAllSelected(false);
      }
      newArr[id] = !isChecked[id];
      setIsChecked(newArr);
    }
  };

  // 교재 구매 수량 증감
  let finalProducts = Array(selectedProductList.length);

  const clickPlus = (id) => {
    selectedProductList.forEach((product, i) => {
      let bookCount = product[1];
      if (product.length > 1 && product[0].id === id) {
        bookCount = product[1];
        finalProducts[i] = [product[0], (bookCount += 1)];
      } else {
        finalProducts[i] = product;
      }
    });
    setSelectedProductList(finalProducts);
  };
  const clickMinus = (id) => {
    selectedProductList.forEach((product, i) => {
      let bookCount = product[1];
      if (product.length > 1 && product[0].id === id) {
        bookCount = product[1];
        finalProducts[i] = [product[0], (bookCount -= 1)];
      } else {
        finalProducts[i] = product;
      }
    });
    setSelectedProductList(finalProducts);
  };

  // 선택한 상품의 총 결제 금액 계산하는 로직
  const [totalPrice, setTotalPrice] = useRecoilState(mySelectedProductListPriceAtom);
  useEffect(() => {
    let total = 0;
    isChecked.forEach((data, i) => {
      if (data) {
        if (i - 1 >= 0) {
          if (selectedProductList[i - 1].length > 1) {
            total +=
              (selectedProductList[i - 1][0].price - selectedProductList[i - 1][0].sale) *
              selectedProductList[i - 1][1];
          } else {
            total +=
              (selectedProductList[i - 1].price - selectedProductList[i - 1].sale) *
              (selectedProductList[i - 1].count || 1);
          }
        }
      }
    });
    setTotalPrice(total);
  }, [isChecked]);

  // 장바구니에 있는 전체 상품의 총 가격(전체 상품 구매하기 눌렀을때 비교하려고)
  const [totalBasketPrice, setTotalBasketPrice] = useState(0);
  useEffect(() => {
    let basketTotal = 0;
    selectedProductList.forEach((data) => {
      basketTotal += (data.price - data.sale) * data.count;
    });
    setTotalBasketPrice(basketTotal);
  }, [selectedProductList]);

  // 선택한 상품 구매하기 버튼 기능
  const buySelectedProducts = () => {
    if (isChecked.includes(true)) {
      let products = [];
      isChecked.forEach((data, index) => {
        if (data) {
          if (index - 1 >= 0) {
            products.push(selectedProductList[index - 1]);
          }
        }
      });
      setSelectedProducts(products);
      setSelectedProductList(selectedProductList);
      navigate('/basket/order');
    } else {
      alert('선택한 상품이 없습니다.');
    }
  };

  // 상품 삭제 버튼 기능
  const deleteProduct = (index) => {
    const newSelectedProductList = [...selectedProductList];
    newSelectedProductList.splice(index, 1);
    setSelectedProductList(newSelectedProductList);
    alert('상품이 삭제되었습니다.');
  };

  const RenderOrderBasket = () => {
    return (
      <OrderBasketPageContainer>
        <OrderBasketPageTitle>
          <span>{myInfo.name}</span>님 장바구니
        </OrderBasketPageTitle>
        <HorizonLine />
        <ProgressBox
          stepIcon1={StepIcon1Selected}
          stepIcon2={StepIcon2}
          stepIcon3={StepIcon3}
          stepText1={`장바구니`}
          stepText2={`결제정보 입력`}
          stepText3={`결제 완료`}
        >
          <BasketBox>
            <BasketHeader>
              <p>
                <BasicCheckBox isChecked={isChecked[0]} onChangeFunc={() => onClickCheck(0)} />
              </p>
              <p>상품정보</p>
              <p>수량</p>
              <p>결제가</p>
              <p>상품 삭제</p>
            </BasketHeader>
            <BasketItems>
              {selectedProductList.map((product, index) => (
                <>
                  {product.length > 1 && product[0].book ? (
                    <Item key={product[0].book.id}>
                      <div>
                        <BasicCheckBox
                          isChecked={isChecked[index + 1]}
                          onChangeFunc={() => onClickCheck(index + 1)}
                        />
                      </div>
                      {/* 상품정보 */}
                      <div>
                        <img src={product[0].book.bookImg} />
                        <div>
                          {product[0].book.bookCategory === 'language-logic' ? (
                            <p>언어논리</p>
                          ) : product[0].book.bookCategory === 'data-interpretation' ? (
                            <p>자료해석</p>
                          ) : product[0].book.bookCategory === 'situational-judgment' ? (
                            <p>상황판단</p>
                          ) : (
                            ''
                          )}
                          <p>{product[0].book.author}</p>
                        </div>
                        <div>
                          <p>교재</p>
                          <p>{product[0].book.bookName}</p>
                        </div>
                      </div>
                      {/* <div>{product[1]}</div> */}
                      <CountButton>
                        <div
                          onClick={(e) => {
                            if (product[1] === 1) {
                              e.preventDefault();
                            } else {
                              clickMinus(product.book.id);
                            }
                          }}
                        >
                          <MinusBtn />
                        </div>
                        <div>{product[1]}</div>
                        <div onClick={() => clickPlus(product[0].book.id)}>
                          <PlusBtn />
                        </div>
                      </CountButton>
                      {product[0].book.sale === 0 ? (
                        <div>
                          <div></div>
                          {(product[0].book.price * product[1])
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          원
                        </div>
                      ) : (
                        <div>
                          <div
                            style={{
                              textDecoration: 'line-through',
                              opacity: '0.5',
                              marginBottom: '5px',
                            }}
                          >
                            {(product[0].book.price * product[1])
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            원
                          </div>
                          {((product[0].book.price - product[0].book.sale) * product[1])
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          원
                        </div>
                      )}
                      <div>
                        <p>
                          <Button
                            width={124}
                            height={48}
                            bgColor={COLOR.GRAY_3}
                            onClickFunc={() => deleteProduct(index)}
                          >
                            삭제
                          </Button>
                        </p>
                      </div>
                    </Item>
                  ) : product.length > 1 && product[0].bookName ? (
                    <Item key={product[0].id}>
                      <div>
                        <BasicCheckBox
                          isChecked={isChecked[index + 1]}
                          onChangeFunc={() => onClickCheck(index + 1)}
                        />
                      </div>
                      {/* 상품정보 */}
                      <div>
                        <img src={product[0].bookImg} />
                        <div>
                          {product[0].bookCategory === 'language-logic' ? (
                            <p>언어논리</p>
                          ) : product[0].bookCategory === 'data-interpretation' ? (
                            <p>자료해석</p>
                          ) : product[0].bookCategory === 'situational-judgment' ? (
                            <p>상황판단</p>
                          ) : (
                            ''
                          )}
                          <p>{product[0].author}</p>
                        </div>
                        <div>
                          {product[0].lectureName ? <p>강의</p> : <p>교재</p>}
                          <p>{product[0].bookName}</p>
                        </div>
                      </div>
                      {/* <div>{product[1]}</div> */}
                      <CountButton>
                        <div
                          onClick={(e) => {
                            if (product[1] === 1) {
                              e.preventDefault();
                            } else {
                              clickMinus(product[0].id);
                            }
                          }}
                        >
                          <MinusBtn />
                        </div>
                        <div>{product[1]}</div>
                        <div onClick={() => clickPlus(product[0].id)}>
                          <PlusBtn />
                        </div>
                      </CountButton>
                      {product[0].sale === 0 ? (
                        <div>
                          <div></div>
                          {(product[0].price * product[1])
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          원
                        </div>
                      ) : (
                        <div>
                          <div
                            style={{
                              textDecoration: 'line-through',
                              opacity: '0.5',
                              marginBottom: '5px',
                            }}
                          >
                            {(product[0].price * product[1])
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            원
                          </div>
                          {((product[0].price - product[0].sale) * product[1])
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          원
                        </div>
                      )}
                      <div>
                        <p>
                          <Button
                            width={124}
                            height={48}
                            bgColor={COLOR.GRAY_3}
                            onClickFunc={() => deleteProduct(index)}
                          >
                            삭제
                          </Button>
                        </p>
                      </div>
                    </Item>
                  ) : (
                    <Item key={product.id}>
                      <div>
                        <BasicCheckBox
                          isChecked={isChecked[index + 1]}
                          onChangeFunc={() => onClickCheck(index + 1)}
                        />
                      </div>
                      {/* 상품정보 */}
                      <div>
                        {product.passName ? (
                          <>
                            <div>
                              <p>패스</p>
                              <p>{product.passName}</p>
                            </div>
                          </>
                        ) : (
                          <>
                            <img src={product.lectureImg || product.bookImg} />
                            <div>
                              {product.lectureCategory === 'language-logic' ||
                              product.bookCategory === 'language-logic' ? (
                                <p>언어논리</p>
                              ) : product.lectureCategory === 'data-interpretation' ||
                                product.bookCategory === 'data-interpretation' ? (
                                <p>자료해석</p>
                              ) : product.lectureCategory === 'situational-judgment' ||
                                product.bookCategory === 'situational-judgment' ? (
                                <p>상황판단</p>
                              ) : (
                                ''
                              )}
                              <p>
                                {(product.professor && product.professor.professorName) ||
                                  product.author}
                              </p>
                            </div>
                            <div>
                              {product.lectureName ? <p>강의</p> : <p>교재</p>}
                              <p>{product.lectureName || product.bookName}</p>
                            </div>
                          </>
                        )}
                      </div>
                      {/* <div>{product.count || 1}</div> */}
                      {!product.lectureName && !product.passName ? (
                        <CountButton>
                          <div onClick={clickMinus}>
                            <MinusBtn />
                          </div>
                          <div>{product.count || 1}</div>
                          <div onClick={clickPlus}>
                            <PlusBtn />
                          </div>
                        </CountButton>
                      ) : (
                        <div>{product.count || 1}</div>
                      )}

                      {product.sale === 0 ? (
                        <div>
                          {(product.price * (product.count || 1))
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          원
                        </div>
                      ) : (
                        <div>
                          <div
                            style={{
                              textDecoration: 'line-through',
                              opacity: '0.5',
                              marginBottom: '5px',
                            }}
                          >
                            {(product.price * (product.count || 1))
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            원
                          </div>
                          {((product.price - product.sale) * (product.count || 1))
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          원
                        </div>
                      )}

                      <div>
                        <p>
                          <Button
                            width={124}
                            height={48}
                            bgColor={COLOR.GRAY_3}
                            onClickFunc={() => deleteProduct(index)}
                          >
                            삭제
                          </Button>
                        </p>
                      </div>
                    </Item>
                  )}
                </>
              ))}
            </BasketItems>
            <BasketFooter>
              <div></div>
              <div>
                <p>결제 금액</p>
                <p>{totalPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</p>
              </div>
            </BasketFooter>
          </BasketBox>
        </ProgressBox>
        <ButtonBox>
          <div>
            <Button
              width={202}
              height={62}
              bgColor={COLOR.BLUE_4}
              onClickFunc={buySelectedProducts}
            >
              선택한 상품 구매하기
            </Button>
          </div>
          {/* <div>
            <Button width={202} height={62} bgColor={COLOR.BLUE_4} onClickFunc={buyAllProducts}>
              전체 상품 구매하기
            </Button>
          </div> */}
        </ButtonBox>
      </OrderBasketPageContainer>
    );
  };

  useEffect(() => {
    RenderOrderBasket();
  }, [deleteProduct]);

  return <RenderOrderBasket />;
}

export default OrderBasketPage;
