import React, { useState, useRef, useEffect } from 'react';
import {
  QuestionModifyContainer,
  QuestionModifyTitle,
  FormContainer,
  ButtonContainer,
} from './style';

import { useMutation } from 'react-query';
import { modifyProfessorQna } from '../../axiosManage/Professor';

import Input from '../../components/atoms/Input';
import { BasicCheckBox } from '../../components/atoms/CheckBox';
import { Button, CancelButton } from '../../components/atoms/Button/index';
import ToastEditor from '../../components/atoms/ToastTextEditor';
import Select from '../../components/atoms/Select';
import COLOR from '../../styles/common/color';

import useHandleError from '../../hooks/useHandleError';

import { useRecoilValue, useRecoilState } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';
import { myInfoAtom, myContactAtom, myQuestionId } from '../../recoil/MyPageStore/MyPageRecoil';

import { useNavigate, Navigate } from 'react-router-dom';

function QuestionModifyPage() {
  const { handleError } = useHandleError();
  const editorRef = useRef();
  const navigate = useNavigate();
  const myInfo = useRecoilValue(myInfoAtom);
  const isLogin = useRecoilValue(isLoginAtom);

  if (isLogin === false) {
    alert('로그인을 해주세요.');
    return <Navigate to="/login" />;
  }

  const [questionContent, setQuestionContent] = useRecoilState(myContactAtom);
  const [questionId, setQuestionContentId] = useRecoilState(myQuestionId);

  const SELECT_OPTIONS = [
    {
      value: questionContent.category,
      optionName:
        questionContent.category === 'LECTURE'
          ? '강의'
          : questionContent.category === 'BOOK'
          ? '교재'
          : questionContent.category === 'CURRICULUM'
          ? '커리큘럼'
          : '',
    },
    { value: 'LECTURE', optionName: '강의' },
    { value: 'BOOK', optionName: '교재' },
    { value: 'CURRICULUM', optionName: '커리큘럼' },
  ];

  // 유저가 입력한 서버로 전송할 데이터(제목, 내용, 카테고리)
  const [title, setTitle] = useState(questionContent.title);
  const [category, setCategory] = useState(questionContent.category);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  // 비밀 글 설정 여부
  const [isSecret, setIsSecret] = useState(questionContent.isSecret);
  const checkSecret = () => {
    setIsSecret((isSecret) => !isSecret);
  };

  /** 1:1 문의 글 전송 로직 */
  const postModifyQuestion = useMutation(
    (data) => {
      modifyProfessorQna(questionId, data);
    },
    {
      onSuccess: () => {
        alert('수정이 완료되었습니다!');
        navigate(-1);
      },
      onError: (error) => {
        alert('수정에 실패했습니다.');
        handleError(error);
      },
    },
  );

  const modifySubmit = () => {
    const data = {
      title: title,
      content: editorRef.current?.getInstance().getHTML(),
      category: category,
      isSecret: isSecret,
    };

    if (
      data.title !== '' &&
      !data.content.includes('위에 구분을 먼저 선택 후 글을 작성해주시기 바랍니다!') &&
      data.category !== '구분'
    ) {
      postModifyQuestion.mutate(data);
    } else {
      alert('제목, 내용, 카테고리를 모두 입력해주세요!');
    }
  };

  const modifyCancel = () => {
    let isYes = confirm('수정을 취소하시겠습니까?');

    if (isYes) {
      alert('수정을 취소하셨습니다.');
      navigate(-1);
    }
  };

  return (
    <QuestionModifyContainer>
      <QuestionModifyTitle>
        학습질문
        <p>학습질문 수정하기</p>
      </QuestionModifyTitle>
      <FormContainer>
        <div>
          <Select
            width={313}
            height={58}
            selectOptions={SELECT_OPTIONS}
            onChangeFunc={handleCategoryChange}
          />
          <Input
            width={645}
            height={58}
            placeholder="제목을 입력하세요."
            defaultValue={title}
            onChangeFunc={handleTitleChange}
          />
          {isLogin ? (
            <div>이름: {myInfo.name}</div>
          ) : (
            <Input width={313} height={58} placeholder="이름을 입력하세요." />
          )}
        </div>
        <ToastEditor editorRef={editorRef} content={questionContent.content} />
        <div>
          <ButtonContainer>
            <div>
              <BasicCheckBox isChecked={isSecret} onClickFunc={checkSecret} />
              <span>비밀글 설정</span>
            </div>
            <div>
              <CancelButton
                width={124}
                height={48}
                bgColor={COLOR.BLUE_4}
                onClickFunc={() => modifyCancel()}
              >
                취소
              </CancelButton>
              <Button width={124} height={48} bgColor={COLOR.BLUE_4} onClickFunc={modifySubmit}>
                수정하기
              </Button>
            </div>
          </ButtonContainer>
        </div>
      </FormContainer>
    </QuestionModifyContainer>
  );
}

export default QuestionModifyPage;
