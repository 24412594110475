import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  BookStoreDetailContainer,
  MainInfoBox,
  SubInfoBox,
  BookImg,
  MainInfoRightBox,
  TitleBox,
  ShortInfoBox,
  PriceBox,
  BuyInfoBox,
  CountButton,
  ButtonBox,
  MenuBox,
  DetailInfoBox,
  DetailIntroduce,
  DetailIndexBox,
  DetailIndexContent,
  DetailEtcBox,
} from './style';
import COLOR from '../../styles/common/color';
import { Button } from '../../components/atoms/Button';

import { ReactComponent as StudyMaterialIcon } from '../../assets/icons/icon-Study-Material.svg';
import { ReactComponent as PlusBtn } from '../../assets/icons/icon-PlusBtn.svg';
import { ReactComponent as MinusBtn } from '../../assets/icons/icon-MinusBtn.svg';

import { SpinLoader, typeList } from '../../components/atoms/SpinLoader';
import { useQuery } from 'react-query';
import { getBookInfo } from '../../axiosManage/BookStore';
import { getDeliveryCharge } from '../../axiosManage/OrderBasket';
import useHandleError from '../../hooks/useHandleError';

import { useRecoilState, useRecoilValue } from 'recoil';
import {
  myOrderBasketListAtom,
  myOrderAtom,
} from '../../recoil/OrderBasketStore/OrderBasketRecoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';
import { getRefundText } from '../../axiosManage/Assignment';

const NAV_LIST = [
  {
    id: 1,
    name: '책 소개',
  },
  {
    id: 2,
    name: '목차',
  },
  {
    id: 3,
    name: '배송 / 교환 / 환불',
  },
];

// 책 소개 부분 렌더링 함수
const RenderIntroduce = ({ data }) => {
  return <DetailIntroduce>{data && <div>{data.description}</div>}</DetailIntroduce>;
};

function createMarkup(data) {
  return { __html: data };
}

// 책 목차 부분 렌더링 함수
const RenderIndex = ({ data }) => {
  return (
    <DetailIndexBox>
      {data && (
        <>
          <DetailIndexContent>
            {data.tableOfContents &&
              JSON.parse(data.tableOfContents).map((chapter, i) => (
                <div key={chapter.id}>
                  <p>CHAPTER {i + 1}.</p>
                  <div dangerouslySetInnerHTML={createMarkup(chapter.content)} />
                </div>
              ))}
          </DetailIndexContent>
        </>
      )}
    </DetailIndexBox>
  );
};

// 배송, 교환, 환불 부분 렌더링 함수
const RenderEtc = ({ data }) => {
  return (
    <DetailEtcBox>
      <div dangerouslySetInnerHTML={createMarkup(data)} />
    </DetailEtcBox>
  );
};

function BookStoreDetail() {
  const { handleError } = useHandleError();
  const { id } = useParams();
  const navigate = useNavigate();

  const isLogin = useRecoilValue(isLoginAtom);

  const [bookDetail, setBookDetail] = useState([]);
  const { status: bookDetailStatus } = useQuery(['getBookInfo', id], () => getBookInfo(id), {
    retry: 0,
    enabled: !!id,
    onSuccess: (data) => {
      if (data.data.book !== undefined) {
        setBookDetail(data.data.book);
      }
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [deliveryFee, setDeliveryFee] = useState(null);
  const [noDeliveryChargeAmount, setNoDeliveryChargeAmount] = useState(null);
  useEffect(() => {
    getDeliveryCharge()
      .then((res) => {
        setDeliveryFee(res.data.deliveryCharge);
        setNoDeliveryChargeAmount(res.data.noDeliveryChargeAmount);
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  const [refundText, setRefundText] = useState('');
  useEffect(() => {
    getRefundText()
      .then((res) => {
        setRefundText(JSON.parse(res.data.data));
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  // 교재 구매 수량 증감
  const [counter, setCounter] = useState(1);
  const clickPlus = () => {
    setCounter(counter + 1);
  };
  const clickMinus = (e) => {
    if (counter === 1) {
      e.preventDefault();
    } else {
      setCounter(counter - 1);
    }
  };

  /* 메뉴 선택 */
  const [currentCategory, setCurrentCategory] = useState(1);
  const clickCategory = (id) => {
    setCurrentCategory(id);
  };

  // 장바구니 버튼 클릭 했을 때
  const [selectedProductList, setSelectedProductList] = useRecoilState(myOrderBasketListAtom);
  const clickOrderBasketButton = () => {
    if (isLogin === false) {
      alert('로그인을 해주세요.');
    } else if (isLogin === true) {
      let checkProduct = [];
      selectedProductList.forEach((product) => {
        if (product.length > 1) {
          if ('bookName' in product[0]) {
            checkProduct.push(product[0].bookName);
          }
        } else {
          if ('bookName' in product) {
            checkProduct.push(product.bookName);
          }
        }
      });

      if (checkProduct.includes(bookDetail.bookName)) {
        alert('이미 장바구니에 동일한 상품이 있습니다.');
      } else {
        setSelectedProductList([...selectedProductList, [bookDetail, counter]]);
        alert('장바구니에 상품이 담겼습니다.');
      }
    }
  };

  // 구매하기 버튼 클릭 했을 때
  const [selectedProduct, setSelectedProduct] = useRecoilState(myOrderAtom);
  const clickOrderButton = (book) => {
    if (isLogin === false) {
      alert('로그인을 해주세요.');
    } else {
      setSelectedProduct([book, counter]);
      navigate(`/order`);
    }
  };

  const clickErrata = () => {
    navigate('/supportcenter/errata');
  };

  return (
    <BookStoreDetailContainer>
      <MainInfoBox>
        <BookImg>
          <img src={bookDetail.bookImg} />
        </BookImg>
        <MainInfoRightBox>
          <TitleBox>
            <div>
              {bookDetail.bookCategory === 'language-logic' ? (
                <span>언어논리</span>
              ) : bookDetail.bookCategory === 'data-interpretation' ? (
                <span>자료해석</span>
              ) : bookDetail.bookCategory === 'situational-judgment' ? (
                <span>상황판단</span>
              ) : (
                ''
              )}
            </div>
            <div>{bookDetail.bookName}</div>
            <div>
              <p>{bookDetail.author}</p>
              <p>{bookDetail.publishingCompany}</p>
              <p>{bookDetail.publishDate?.replace(/-/g, '.')} 발행</p>
            </div>
          </TitleBox>
          <ShortInfoBox>
            <div>
              <div>
                <p>소개</p>
                <p>{bookDetail.introduction}</p>
              </div>
              <div>
                <p>쪽수</p>
                <p>{bookDetail.pages}P</p>
              </div>
            </div>
            <div>
              <div>
                <p>과정</p>
                <p>{bookDetail.step}</p>
              </div>
              <div>
                <p>ISBN</p>
                <p>{bookDetail.isbn}</p>
              </div>
            </div>
            <div>
              <p>학습자료</p>
              <p>
                <StudyMaterialIcon />
              </p>
              <p onClick={clickErrata}>정오표</p>
            </div>
          </ShortInfoBox>
          <PriceBox>
            <p>판매가</p>
            {bookDetail.sale === 0 ? (
              <>
                <p></p>
                <p>{bookDetail.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</p>
              </>
            ) : (
              <>
                <p style={{ textDecoration: 'line-through' }}>
                  {bookDetail.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                </p>
                <p>
                  {(bookDetail.price - bookDetail.sale)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  원
                </p>
              </>
            )}
            <p>
              배송료 {deliveryFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원 (주문시 결제,{' '}
              {noDeliveryChargeAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원 이상 구매
              시 무료 배송)
            </p>
          </PriceBox>
          <BuyInfoBox>
            <div>
              <p>구매수량</p>
              <CountButton>
                <div onClick={clickMinus}>
                  <MinusBtn />
                </div>
                <div>{counter}</div>
                <div onClick={clickPlus}>
                  <PlusBtn />
                </div>
              </CountButton>
            </div>
            <div>
              <p>합계</p>
              <p>
                {((bookDetail.price - bookDetail.sale) * counter)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                원
              </p>
            </div>
          </BuyInfoBox>
          <ButtonBox>
            <Button height={60} onClickFunc={clickOrderBasketButton}>
              장바구니
            </Button>
            <Button
              height={60}
              bgColor={COLOR.BLUE_4}
              onClickFunc={() => clickOrderButton(bookDetail)}
            >
              구매하기
            </Button>
          </ButtonBox>
        </MainInfoRightBox>
      </MainInfoBox>
      <SubInfoBox>
        <MenuBox>
          {NAV_LIST.map((menu) => (
            <div key={menu.id}>
              <li
                className={menu.id === currentCategory ? 'active' : ''}
                onClick={() => clickCategory(menu.id)}
              >
                <p>{menu.name}</p>
              </li>
            </div>
          ))}
        </MenuBox>
        <DetailInfoBox>
          {currentCategory === 1 ? (
            <RenderIntroduce data={bookDetail} />
          ) : currentCategory === 2 ? (
            <RenderIndex data={bookDetail} />
          ) : currentCategory === 3 ? (
            <RenderEtc data={refundText.book} />
          ) : (
            ''
          )}
        </DetailInfoBox>
      </SubInfoBox>
    </BookStoreDetailContainer>
  );
}

export default BookStoreDetail;
