import React, { useState } from 'react';
import { InquiryDetailContainer, InquiryDetailBox, DetailTitle, Info, DataNotFound } from './style';
import ToastViewer from '../../components/atoms/ToastTextViewer';

import { SpinLoader, typeList } from '../../components/atoms/SpinLoader';
import { useChangeMonthNumToEn } from '../../hooks/useChangeMonthNumToEn';

const InquiryPage = ({ content }) => {
  return (
    <InquiryDetailContainer>
      <InquiryDetailBox>
        {content !== undefined ? (
          <>
            <DetailTitle>{content.title}</DetailTitle>
            <Info>
              <div>
                <p>{content.createdAt?.slice(8, 10)}</p>
                <p>{useChangeMonthNumToEn(content.createdAt?.slice(5, 7))}</p>
                <p>{content.createdAt?.slice(0, 4)}</p>
              </div>
              {/* <div>
                <p>작성자</p>
                <p>LAB STANDARD</p>
              </div>
              <div>
                <p>조회</p>
                <p>{content.views}</p>
              </div> */}
            </Info>
            <ToastViewer content={content.question} />
          </>
        ) : (
          <SpinLoader type={typeList[2].prop} color={'#000'} width={1131} height={459} />
        )}
      </InquiryDetailBox>
      {/* 답변 영역 */}
      <InquiryDetailBox>
        {content.answer === null ? (
          <DataNotFound>
            <p>답변이 존재하지 않습니다!</p>
          </DataNotFound>
        ) : (
          <>
            <DetailTitle>RE: {content.title}</DetailTitle>
            <Info>
              <div>
                <p>{content.createdAt?.slice(8, 10)}</p>
                <p>{useChangeMonthNumToEn(content.createdAt?.slice(5, 7))}</p>
                <p>{content.createdAt?.slice(0, 4)}</p>
              </div>
              {/* <div>
                <p>작성자</p>
                <p>LAB STANDARD</p>
              </div>
              <div>
                <p>조회</p>
                <p>{content.views}</p>
              </div> */}
            </Info>
            <ToastViewer content={content.answer} />
          </>
        )}
      </InquiryDetailBox>
    </InquiryDetailContainer>
  );
};

export default InquiryPage;
