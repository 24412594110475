import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const PassNoteContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-top: 70px;
  padding-bottom: 120px;
  text-align: center;

  @media screen and (max-width: 1700px) {
    padding-top: 70px;
    padding-bottom: 120px;
  }
`;

const PassNoteTitle = styled.div`
  ${FONT_STYLES.P_B}
  font-size: 40px;
  margin-bottom: 40px;
`;

const PassNoteSubTitle = styled.div`
  ${FONT_STYLES.P_M}
  font-size: 22px;
`;

const ButtonContainer = styled.div`
  float: left;

  & > button {
    border-radius: 24px;
    box-shadow: none;
    border: 1px solid #707070;
  }
`;

const ButtonBox = styled.div`
  height: 52px;
  margin-top: 10px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    margin: 0 5px;

    & > div {
      svg,
      div {
        cursor: pointer;
      }
    }
  }
`;

const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_3};
  margin: 40px auto;
`;

const PassNoteTable = styled.div`
  clear: both;
  margin-top: 50px;
`;

const PassNoteData = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid ${COLOR.GRAY_8};
  cursor: pointer;

  & > div:nth-child(1) {
    width: 53px;
    ${FONT_STYLES.P_R}
    margin: 0 20px;
  }

  & > div:nth-child(2) {
    width: 70px;
    margin: 0 10px;

    p:nth-child(1) {
      ${FONT_STYLES.P_SB}
      margin-bottom: 5px;
    }
    p:nth-child(2) {
      ${FONT_STYLES.P_B}
      font-size: 20px;
      margin-bottom: 5px;
    }
    p:nth-child(3) {
      ${FONT_STYLES.P_R}
      font-size: 14px;
    }
  }

  & > div:nth-child(3) {
    margin: 0 10px;
    text-align: left;

    p: nth-child(1) {
      font-size: 15px;
      margin-bottom: 10px;
      float: left;
    }
    p: nth-child(2) {
      ${FONT_STYLES.P_SB}
      font-size: 18px;
      clear: both;
    }
  }
`;

const PassNoteDataInfo = styled.div`
  ${FONT_STYLES.P_M}
  display: flex;
  margin-left: auto;
  font-size: 18px;

  & > div {
    margin: 0 10px;

    p:nth-child(1) {
      padding-bottom: 5px;
    }
  }
`;

const PaginationBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;

  & > svg {
    cursor: pointer;
  }

  .prev-btn {
    margin-right: 30px;
  }
  .next-btn {
    margin-left: 30px;
  }
`;

const DataNotFound = styled.div`
  width: 100%;
  height: 715px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > p {
    ${FONT_STYLES.P_R}
    font-size: 36px;
  }
`;

const BestTag = styled.div`
  ${FONT_STYLES.P_M}
  width: 53px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border: 1px solid ${COLOR.BLUE_4};
  border-radius: 7px;
  color: ${COLOR.BLUE_4};

  span {
    font-size: 14px;
    border-bottom: 1px solid ${COLOR.BLUE_4};
  }
`;

export {
  PassNoteContainer,
  PassNoteTitle,
  HorizonLine,
  ButtonBox,
  ButtonContainer,
  PassNoteSubTitle,
  PassNoteTable,
  PassNoteData,
  PassNoteDataInfo,
  PaginationBox,
  DataNotFound,
  BestTag,
};
