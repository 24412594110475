import styled, { css } from 'styled-components';
import COLOR from '../../../styles/common/color';

export const StyledProgressCircle = styled.div`
  position: relative;
  width: 108px;
  height: 108px;
  border-radius: 50%;
  background: ${COLOR.BLUE_5};
  overflow: hidden;

  ${({ percent }) =>
    percent
      ? css`
          background: conic-gradient(
            ${COLOR.BLUE_4} ${percent / 1.4}%,
            ${COLOR.BLUE_5} ${percent}%,
            ${COLOR.BLUE_5} 0
          );
        `
      : null}
`;

export const StyledProgressCircleInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85px;
  height: 85px;
  background: ${COLOR.GRAY_0};
  border-radius: 50%;
`;
