import React, { useState } from 'react';
import {
  ExamInfoContentBox,
  ExamInfoGraphContainer,
  Table,
  TableHeader,
  TableDataSlice,
  PageNumber,
  TableSubHeader,
  TableSubHeaderContainer,
  TableDataContainer,
  Data,
} from '../common/graph-style';

import { ReactComponent as PrevBtn } from '../../../../assets/icons/icon-PrevBtnFullTypeCol.svg';
import { ReactComponent as NextBtn } from '../../../../assets/icons/icon-NextBtnFullTypeCol.svg';

const TableSubHeaderItem1 = [
  {
    title: '모집인원',
  },
  {
    title: '출원인원',
  },
  {
    title: '합격인원',
  },
];

const TableSubHeaderItem2 = [
  {
    title: '경쟁률',
  },
  {
    title: '합격률',
  },
  {
    title: '합격선',
  },
];

const FirstTable = ({ firstTableData }) => {
  return (
    <Table>
      <TableHeader>
        <div>직렬</div>
        <div>
          <div>일반행정</div>
          <div>인사조직</div>
          <div>법무행정</div>
          <div>재경</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {firstTableData &&
          firstTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data>
                <div>
                  <p>{data.content.일반행정.모집인원}</p>
                  <p>{data.content.일반행정.출원인원}</p>
                  <p>{data.content.일반행정.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.일반행정.경쟁률}</p>
                  <p>{data.content.일반행정.합격률}</p>
                  <p>{data.content.일반행정.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.인사조직.모집인원}</p>
                  <p>{data.content.인사조직.출원인원}</p>
                  <p>{data.content.인사조직.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.인사조직.경쟁률}</p>
                  <p>{data.content.인사조직.합격률}</p>
                  <p>{data.content.인사조직.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.법무행정.모집인원}</p>
                  <p>{data.content.법무행정.출원인원}</p>
                  <p>{data.content.법무행정.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.법무행정.경쟁률}</p>
                  <p>{data.content.법무행정.합격률}</p>
                  <p>{data.content.법무행정.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.재경.모집인원}</p>
                  <p>{data.content.재경.출원인원}</p>
                  <p>{data.content.재경.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.재경.경쟁률}</p>
                  <p>{data.content.재경.합격률}</p>
                  <p>{data.content.재경.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const SecondTable = ({ secondTableData }) => {
  return (
    <Table>
      <TableHeader>
        <div>직렬</div>
        <div>
          <div>국제통상</div>
          <div>교육행정</div>
          <div>사회복지</div>
          <div>교정</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {secondTableData &&
          secondTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data>
                <div>
                  <p>{data.content.국제통상.모집인원}</p>
                  <p>{data.content.국제통상.출원인원}</p>
                  <p>{data.content.국제통상.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.국제통상.경쟁률}</p>
                  <p>{data.content.국제통상.합격률}</p>
                  <p>{data.content.국제통상.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.교육행정.모집인원}</p>
                  <p>{data.content.교육행정.출원인원}</p>
                  <p>{data.content.교육행정.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.교육행정.경쟁률}</p>
                  <p>{data.content.교육행정.합격률}</p>
                  <p>{data.content.교육행정.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.사회복지.모집인원}</p>
                  <p>{data.content.사회복지.출원인원}</p>
                  <p>{data.content.사회복지.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.사회복지.경쟁률}</p>
                  <p>{data.content.사회복지.합격률}</p>
                  <p>{data.content.사회복지.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.교정.모집인원}</p>
                  <p>{data.content.교정.출원인원}</p>
                  <p>{data.content.교정.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.교정.경쟁률}</p>
                  <p>{data.content.교정.합격률}</p>
                  <p>{data.content.교정.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const ThirdTable = ({ thirdTableData }) => {
  return (
    <Table>
      <TableHeader isThree={true}>
        <div>직렬</div>
        <div>
          <div>보호</div>
          <div>검찰</div>
          <div>출입국관리</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {thirdTableData &&
          thirdTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data isThree={true}>
                <div>
                  <p>{data.content.보호.모집인원}</p>
                  <p>{data.content.보호.출원인원}</p>
                  <p>{data.content.보호.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.보호.경쟁률}</p>
                  <p>{data.content.보호.합격률}</p>
                  <p>{data.content.보호.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.검찰.모집인원}</p>
                  <p>{data.content.검찰.출원인원}</p>
                  <p>{data.content.검찰.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.검찰.경쟁률}</p>
                  <p>{data.content.검찰.합격률}</p>
                  <p>{data.content.검찰.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.출입국관리.모집인원}</p>
                  <p>{data.content.출입국관리.출원인원}</p>
                  <p>{data.content.출입국관리.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.출입국관리.경쟁률}</p>
                  <p>{data.content.출입국관리.합격률}</p>
                  <p>{data.content.출입국관리.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

function AdministrativeJobWide({ data }) {
  const [currentTableNum, setCurrentTableNum] = useState(1);

  const clickPrevBtn = () => {
    setCurrentTableNum(currentTableNum - 1);
  };
  const clickNextBtn = () => {
    setCurrentTableNum(currentTableNum + 1);
  };

  return (
    <>
      <ExamInfoContentBox>
        <PrevBtn
          onClick={(e) => {
            if (currentTableNum === 1) {
              e.preventDefault();
            } else {
              clickPrevBtn();
            }
          }}
        />
        <ExamInfoGraphContainer>
          <TableDataSlice />
          {currentTableNum === 1 ? (
            <FirstTable firstTableData={data} />
          ) : currentTableNum === 2 ? (
            <SecondTable secondTableData={data} />
          ) : currentTableNum === 3 ? (
            <ThirdTable thirdTableData={data} />
          ) : (
            ''
          )}
          <TableDataSlice />
        </ExamInfoGraphContainer>
        <NextBtn
          onClick={(e) => {
            if (currentTableNum === 3) {
              e.preventDefault();
            } else {
              clickNextBtn();
            }
          }}
        />
      </ExamInfoContentBox>
      
      <PageNumber>{currentTableNum}/3</PageNumber>
    </>
  );
}

export default AdministrativeJobWide;
