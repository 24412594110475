import React, { useEffect, useRef, useState } from 'react';
import LectureCard from '../../molecules/HotPickCard/LectureCard';
import HotPickJLJ from '../../../assets/images/image-hotpick-JLJ.png';
import HotPickKSH from '../../../assets/images/image-hotpick-KSH.png';
import HotPickHNS from '../../../assets/images/image-hotpick-HNS.png';
import COLOR from '../../../styles/common/color';
import {
  BookSlide,
  HotPickDot,
  HotPickSlide,
  HotPickSlideControllerBox,
  LectureSlide,
  SlidesContainer,
} from './style';
import BookCard from '../../molecules/HotPickCard/BookCard';
import {
  HotPickTab,
  HotPickTabBox,
  HotPickTabContainer,
  SectionInfo,
} from '../../../pages/Main/style';
import { useNavigate } from 'react-router-dom';

const HotPickSlides = ({ hotPickLecture, hotPickBook }) => {
  const [SlideNum, setSlideNum] = useState(0);
  const [ActiveDot1, setActiveDot1] = useState(false);
  const [ActiveDot2, setActiveDot2] = useState(false);
  const MAX_NUM = 1;
  const slideRef = useRef();

  const navigate = useNavigate();

  const goToLectureDetailPage = (professorName, lectureId) => {
    if (professorName === '주이재') {
      navigate(`/lecture/JLJ/lectures/${lectureId}`);
    } else if (professorName === '김승환') {
      navigate(`/lecture/KSH/lectures/${lectureId}`);
    } else if (professorName === '하나산') {
      navigate(`/lecture/HNS/lectures/${lectureId}`);
    }
  };

  const goToBookDetailPage = (bookId) => {
    navigate(`/bookstore/entire/${bookId}`);
  };

  const handleLeft = () => {
    if (SlideNum <= 0) {
      setSlideNum(SlideNum);
    } else {
      setSlideNum(SlideNum - 1);
    }
  };

  const handleRight = () => {
    if (SlideNum >= MAX_NUM) {
      setSlideNum(SlideNum);
    } else {
      setSlideNum(SlideNum + 1);
    }
  };

  useEffect(() => {
    slideRef.current.style.transition = 'all 0.5s ease';
    slideRef.current.style.transform = `translateX(-${SlideNum}00%)`;
    if (SlideNum === 1) {
      setActiveDot2(true);
      setActiveDot1(false);
    } else {
      setActiveDot2(false);
      setActiveDot1(true);
    }
    const slideInterval = setInterval(() => {
      if (SlideNum === 1) {
        handleLeft();
      } else {
        handleRight();
      }
    }, 4000);
    return () => {
      clearInterval(slideInterval);
    };
  }, [SlideNum]);

  return (
    <SlidesContainer>
      <HotPickTabContainer>
        <SectionInfo>랩스탠다드 수강생들의 많은 선택</SectionInfo>
        <HotPickTabBox activeDot1={ActiveDot1} activeDot2={ActiveDot2}>
          <HotPickTab onClick={handleLeft}>강의</HotPickTab>
          <HotPickTab onClick={handleRight}>교재</HotPickTab>
        </HotPickTabBox>
      </HotPickTabContainer>
      <HotPickSlide ref={slideRef}>
        <LectureSlide>
          {hotPickLecture.map((lecture, i) => (
            <LectureCard
              key={i}
              backgroundImg={lecture.img}
              onClickFunc={() => {
                if (lecture.url) {
                  window.location.href = lecture.url;
                }
              }}
            />
          ))}
        </LectureSlide>
        <BookSlide>
          {hotPickBook.map((book, i) => (
            <BookCard
              key={i}
              bookImg={book.img}
              onClickFunc={() => {
                if (book.url) {
                  window.location.href = book.url;
                }
              }}
            />
          ))}
        </BookSlide>
      </HotPickSlide>
      <HotPickSlideControllerBox activeDot1={ActiveDot1} activeDot2={ActiveDot2}>
        <HotPickDot onClick={handleLeft} />
        <HotPickDot onClick={handleRight} />
      </HotPickSlideControllerBox>
    </SlidesContainer>
  );
};

export default HotPickSlides;
