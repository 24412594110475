import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

const CardContainer = styled.div`
  position: relative;
  width: ${({ lectureNum }) => `${1275 / lectureNum}px`};
  height: 424px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-size: cover;
  background-position: center;
  color: ${COLOR.GRAY_0};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  :hover {
    cursor: pointer;
  }
`;

const CardBox1 = styled.div`
  padding-top: 53px;
  padding-left: 30px;
`;

const CardBox2 = styled.div`
  width: 100%;
  height: 139px;
`;

const BackgroundBox = styled.div`
  position: absolute;
  width: 100%;
  height: 139px;
  backdrop-filter: blur(30px);
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 0.54;
`;

const TextBox = styled.div`
  position: absolute;
  padding: 23px 38px;
`;

const SmallTitle = styled.p`
  ${FONT_STYLES.P_M}
  font-size: 19px;
  padding-bottom: 15px;
`;

const ProfessorName = styled.p`
  ${FONT_STYLES.P_B}
  font-size: ${({ lectureNum }) => `${120 / lectureNum}px`};
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 48px;
`;

const SmallContent = styled.p`
  ${FONT_STYLES.P_M}
  font-size: 19px;
  padding-bottom: 12px;
`;

const BigTitle = styled.p`
  ${FONT_STYLES.N_EB}
  width: ${({ lectureNum }) => `${1050 / lectureNum}px`};
  font-size: 22px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export {
  CardContainer,
  CardBox1,
  CardBox2,
  TextBox,
  BackgroundBox,
  ProfessorName,
  SmallTitle,
  BigTitle,
  SmallContent,
};
