import React from 'react';
import LecturesTable from './LecturesTable';
import { LecturesContainer, LectureTableContainer, Line, TabTitle } from './style';
import { useParams } from 'react-router-dom';
import LectureDetailPage from '../../../../pages/LectureDetail/LectureDetailPage';

const Lectures = ({ professorId }) => {
  const { id } = useParams();

  return (
    <>
      {id ? (
        <LectureDetailPage />
      ) : (
        <LecturesContainer>
          <TabTitle>개설 강좌</TabTitle>
          <Line />
          <LectureTableContainer>
            <LecturesTable professorId={professorId} />
          </LectureTableContainer>
        </LecturesContainer>
      )}
    </>
  );
};

export default Lectures;
