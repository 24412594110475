import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

const CardContainer = styled.div`
  width: 313px;
  height: 298px;
  padding: 37px 34px 36px 30px;
  background-color: ${COLOR.BLUE_4};
  border-radius: 41px;
  margin-right: 15px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 25px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 19px;
  border-bottom: 2px solid white;
  p:nth-child(1) {
    ${FONT_STYLES.P_R}
    font-size: 16px;
    color: ${COLOR.GRAY_0};
  }
  p:nth-child(2) {
    ${FONT_STYLES.P_M}
    font-size: 16px;
    color: ${COLOR.YELLOW_0};
  }
`;

const ContentContainer = styled.div`
  ${FONT_STYLES.N_R}
  font-size: 18px;
  line-height: 22px;
  color: ${COLOR.GRAY_0};
  text-align: center;
  padding: 37px 30px;
`;

const SmallTitle = styled.p`
  ${FONT_STYLES.P_B}
  font-size: 20px;
  text-align: center;
  color: ${COLOR.YELLOW_0};
  padding: 37px 0px;
  line-height: 1.4;
`;

export { CardContainer, TitleContainer, ContentContainer, SmallTitle };
