import React, { useState } from 'react';
import {
  ExamInfoContentBox,
  ExamInfoGraphContainer,
  Table,
  TableHeader,
  TableDataSlice,
  PageNumber,
  TableSubHeader,
  TableSubHeaderContainer,
  TableDataContainer,
  Data,
} from '../common/graph-style';

import { ReactComponent as PrevBtn } from '../../../../assets/icons/icon-PrevBtnFullTypeCol.svg';
import { ReactComponent as NextBtn } from '../../../../assets/icons/icon-NextBtnFullTypeCol.svg';

const TableSubHeaderItem1 = [
  {
    title: '모집인원',
  },
  {
    title: '출원인원',
  },
  {
    title: '합격인원',
  },
];

const TableSubHeaderItem2 = [
  {
    title: '경쟁률',
  },
  {
    title: '합격률',
  },
  {
    title: '합격선',
  },
];

const FirstTable = ({ firstTableData }) => {
  return (
    <Table>
      <TableHeader>
        <div>직렬</div>
        <div>
          <div>일반기계</div>
          <div>전기</div>
          <div>화공</div>
          <div>일반농업</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {firstTableData &&
          firstTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data>
                <div>
                  <p>{data.content.일반기계.모집인원}</p>
                  <p>{data.content.일반기계.출원인원}</p>
                  <p>{data.content.일반기계.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.일반기계.경쟁률}</p>
                  <p>{data.content.일반기계.합격률}</p>
                  <p>{data.content.일반기계.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.전기.모집인원}</p>
                  <p>{data.content.전기.출원인원}</p>
                  <p>{data.content.전기.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.전기.경쟁률}</p>
                  <p>{data.content.전기.합격률}</p>
                  <p>{data.content.전기.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.화공.모집인원}</p>
                  <p>{data.content.화공.출원인원}</p>
                  <p>{data.content.화공.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.화공.경쟁률}</p>
                  <p>{data.content.화공.합격률}</p>
                  <p>{data.content.화공.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.일반농업.모집인원}</p>
                  <p>{data.content.일반농업.출원인원}</p>
                  <p>{data.content.일반농업.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.일반농업.경쟁률}</p>
                  <p>{data.content.일반농업.합격률}</p>
                  <p>{data.content.일반농업.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const SecondTable = ({ secondTableData }) => {
  return (
    <Table>
      <TableHeader>
        <div>직렬</div>
        <div>
          <div>산림자원</div>
          <div>일반수산</div>
          <div>일반환경</div>
          <div>기상</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {secondTableData &&
          secondTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data>
                <div>
                  <p>{data.content.산림자원.모집인원}</p>
                  <p>{data.content.산림자원.출원인원}</p>
                  <p>{data.content.산림자원.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.산림자원.경쟁률}</p>
                  <p>{data.content.산림자원.합격률}</p>
                  <p>{data.content.산림자원.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.일반수산.모집인원}</p>
                  <p>{data.content.일반수산.출원인원}</p>
                  <p>{data.content.일반수산.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.일반수산.경쟁률}</p>
                  <p>{data.content.일반수산.합격률}</p>
                  <p>{data.content.일반수산.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.일반환경.모집인원}</p>
                  <p>{data.content.일반환경.출원인원}</p>
                  <p>{data.content.일반환경.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.일반환경.경쟁률}</p>
                  <p>{data.content.일반환경.합격률}</p>
                  <p>{data.content.일반환경.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.기상.모집인원}</p>
                  <p>{data.content.기상.출원인원}</p>
                  <p>{data.content.기상.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.기상.경쟁률}</p>
                  <p>{data.content.기상.합격률}</p>
                  <p>{data.content.기상.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const ThirdTable = ({ thirdTableData }) => {
  return (
    <Table>
      <TableHeader>
        <div>직렬</div>
        <div>
          <div>일반토목</div>
          <div>건축</div>
          <div>시설조경</div>
          <div>방재안전</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(4)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {thirdTableData &&
          thirdTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data>
                <div>
                  <p>{data.content.일반토목.모집인원}</p>
                  <p>{data.content.일반토목.출원인원}</p>
                  <p>{data.content.일반토목.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.일반토목.경쟁률}</p>
                  <p>{data.content.일반토목.합격률}</p>
                  <p>{data.content.일반토목.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.건축.모집인원}</p>
                  <p>{data.content.건축.출원인원}</p>
                  <p>{data.content.건축.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.건축.경쟁률}</p>
                  <p>{data.content.건축.합격률}</p>
                  <p>{data.content.건축.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.시설조경.모집인원}</p>
                  <p>{data.content.시설조경.출원인원}</p>
                  <p>{data.content.시설조경.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.시설조경.경쟁률}</p>
                  <p>{data.content.시설조경.합격률}</p>
                  <p>{data.content.시설조경.합격선}</p>
                </div>
              </Data>
              <Data>
                <div>
                  <p>{data.content.방재안전.모집인원}</p>
                  <p>{data.content.방재안전.출원인원}</p>
                  <p>{data.content.방재안전.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.방재안전.경쟁률}</p>
                  <p>{data.content.방재안전.합격률}</p>
                  <p>{data.content.방재안전.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

const FourthTable = ({ fourthTableData }) => {
  return (
    <Table>
      <TableHeader isThree={true}>
        <div>직렬</div>
        <div>
          <div>전산개발</div>
          <div>정보보호</div>
          <div>통신기술</div>
        </div>
      </TableHeader>
      <TableSubHeaderContainer>
        <div>항목</div>
        <div>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem1.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
          <TableSubHeader>
            {[...Array(3)].map((x) =>
              TableSubHeaderItem2.map((item) => <div key={item.title}>{item.title}</div>),
            )}
          </TableSubHeader>
        </div>
      </TableSubHeaderContainer>
      <TableDataContainer>
        {fourthTableData &&
          fourthTableData.map((data, i) => (
            <div key={i}>
              <div>{data.year}</div>
              <Data isThree={true}>
                <div>
                  <p>{data.content.전산개발.모집인원}</p>
                  <p>{data.content.전산개발.출원인원}</p>
                  <p>{data.content.전산개발.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.전산개발.경쟁률}</p>
                  <p>{data.content.전산개발.합격률}</p>
                  <p>{data.content.전산개발.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.정보보호.모집인원}</p>
                  <p>{data.content.정보보호.출원인원}</p>
                  <p>{data.content.정보보호.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.정보보호.경쟁률}</p>
                  <p>{data.content.정보보호.합격률}</p>
                  <p>{data.content.정보보호.합격선}</p>
                </div>
              </Data>
              <Data isThree={true}>
                <div>
                  <p>{data.content.통신기술.모집인원}</p>
                  <p>{data.content.통신기술.출원인원}</p>
                  <p>{data.content.통신기술.합격인원}</p>
                </div>
                <div>
                  <p>{data.content.통신기술.경쟁률}</p>
                  <p>{data.content.통신기술.합격률}</p>
                  <p>{data.content.통신기술.합격선}</p>
                </div>
              </Data>
            </div>
          ))}
      </TableDataContainer>
    </Table>
  );
};

function TechnicalJob({ data }) {
  const [currentTableNum, setCurrentTableNum] = useState(1);

  const clickPrevBtn = () => {
    setCurrentTableNum(currentTableNum - 1);
  };
  const clickNextBtn = () => {
    setCurrentTableNum(currentTableNum + 1);
  };

  return (
    <>
      <ExamInfoContentBox>
        <PrevBtn
          onClick={(e) => {
            if (currentTableNum === 1) {
              e.preventDefault();
            } else {
              clickPrevBtn();
            }
          }}
        />
        <ExamInfoGraphContainer>
          <TableDataSlice />
          {currentTableNum === 1 ? (
            <FirstTable firstTableData={data} />
          ) : currentTableNum === 2 ? (
            <SecondTable secondTableData={data} />
          ) : currentTableNum === 3 ? (
            <ThirdTable thirdTableData={data} />
          ) : currentTableNum === 4 ? (
            <FourthTable fourthTableData={data} />
          ) : (
            ''
          )}
          <TableDataSlice />
        </ExamInfoGraphContainer>
        <NextBtn
          onClick={(e) => {
            if (currentTableNum === 4) {
              e.preventDefault();
            } else {
              clickNextBtn();
            }
          }}
        />
      </ExamInfoContentBox>
      <PageNumber>{currentTableNum}/4</PageNumber>
    </>
  );
}

export default TechnicalJob;
