import styled, { css } from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

export const UserInfoPageContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  text-align: center;
  padding-bottom: 150px;
  padding-top: 60px;

  @media screen and (max-width: 1700px) {
    padding-top: 60px;
    padding-bottom: 150px;
  }
`;

export const UserInfoContent = styled.div`
  padding: 0 91px 22px 91px;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 22px;

  & > div {
    ${FONT_STYLES.P_R}
    display: flex;
    align-items: center;
    margin-bottom: 27px;

    & > p {
      :nth-child(1) {
        text-align: left;
        width: 130px;
      }
    }

    :nth-child(2) {
      height: 62px;
      & > div {
        padding-left: 30px;
      }
    }

    :nth-child(4) {
      & > span {
        margin-left: 20px;
        color: red;
        font-size: 14px;
        ${({ isPasswordSame }) =>
          isPasswordSame
            ? css`
                display: none;
              `
            : css`
                display: block;
              `}
      }
    }

    :nth-child(6) {
      & > div {
        width: 135px;
        height: 62px;
        border: none;
        padding: 20px 30px;
        border-radius: 7px;
        background-color: #f5f7fd;
      }
      & > span {
        margin: 0 20px;
      }

      & > p {
        :nth-child(4) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 76px;
          height: 62px;
          background-color: ${COLOR.BLUE_4};
          color: ${COLOR.GRAY_0};
          border-radius: 7px;
          border: 1px solid ${COLOR.BLUE_4};
          margin-right: 8px;
        }
      }
    }

    :nth-child(7) {
      & > div {
        ${FONT_STYLES.P_R}
        width: 455px;
        height: 62px;
        border: none;
        padding: 20px 30px;
        border-radius: 7px;
        background-color: #f5f7fd;
      }
    }

    :nth-child(8) {
      & > span {
        padding: 0 11px;
      }

      & > select {
        margin-left: 17px;
      }
    }

    :nth-child(9) {
      & > div {
        width: 135px;
        height: 62px;
        border: none;
        padding: 20px 30px;
        border-radius: 7px;
        background-color: #f5f7fd;
      }
      & > span {
        padding: 0 9px;
      }
      & > button {
        margin-left: 17px;
      }
    }

    :nth-child(11),
    :nth-child(12) {
      margin-bottom: 11px;
    }
  }
`;

export const UserInfoTitle = styled.div`
  padding: 42px 0;
  border-bottom: 1px solid ${COLOR.GRAY_9};
  ${FONT_STYLES.P_M};
  font-size: 36px;
`;

export const Border = styled.div`
  border-bottom: 1px solid ${COLOR.GRAY_9};
`;

export const ButtonContainer = styled.div`
  margin-top: 40px;

  & > button {
    margin-right: 20px;

    :nth-child(1) {
      width: 202px;
      height: 62px;
      background-color: red;
      color: white;
      border-radius: 7px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      ${FONT_STYLES.P_M}
      font-size: 16px;
    }
  }
`;
