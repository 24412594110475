import { atom } from 'recoil';

/** 학습지원센터 - 공지사항 페이징 정보(ex. 필터링된, 검색된) */
export const supportCenterNoticePageInfoAtom = atom({
  key: 'supportCenterNoticePageInfoAtom',
  default: [],
});

/** 학습지원센터 - 공지사항 데이터 리스트(ex. 필터링된, 검색된) */
export const supportCenterNoticeListAtom = atom({
  key: 'supportCenterNoticeListAtom',
  default: [],
});

/** 학습지원센터 - 정오표 페이징 정보(ex. 필터링된, 검색된) */
export const supportCenterErrataPageInfoAtom = atom({
  key: 'supportCenterErrataPageInfoAtom',
  default: [],
});

/** 학습지원센터 - 정오표 데이터 리스트(ex. 필터링된, 검색된) */
export const supportCenterErrataListAtom = atom({
  key: 'supportCenterErrataListAtom',
  default: [],
});