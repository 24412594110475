import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';
import SelectArrow from '../../../assets/icons/icon-SelectArrow.svg';

export const StyledSelect = styled.select`
  ${FONT_STYLES.P_M}
  width: ${({ width }) => (width ? `${width}px` : '313px')};
  height: ${({ height }) => (height ? `${height}px` : '58px')};
  background: url(${SelectArrow}) no-repeat center right 30px;
  font-size: 18px;
  color: #716b6b;
  border-radius: 34px;
  border: none;
  padding: 19px 33px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  -webkit-appearance: none;

  :focus {
    outline: none;
  }
`;

export const StyledSquareSelect = styled.select`
  ${FONT_STYLES.P_R}
  width: ${({ width }) => (width ? `${width}px` : '313px')};
  height: ${({ height }) => (height ? `${height}px` : '58px')};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : `${COLOR.GRAY_0}`)};
  color: ${({ bgColor }) => (bgColor === COLOR.BLUE_4 ? COLOR.GRAY_0 : '#716b6b')};
  border-radius: 7px;
  border: none;
  border-right: 15px solid transparent;
  padding: 20px;
  cursor: pointer;

  :focus {
    outline: none;
  }
`;
