import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

const SearchBarContainer = styled.div`
  margin-top: 55px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg, img {
    position: absolute;
    left: 0;
    margin-left: 45px;
  }
`;

const SearchBarInput = styled.input`
  width: 640px;
  height: 70px;
  padding-left: 85px;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 34px;
  overflow: auto;

  :focus {
    outline: none;
  }
`;

const SearchBtn = styled.button`
  ${FONT_STYLES.P_EB}
  position: absolute;
  right: 0;
  margin-right: 10px;
  width: 140px;
  height: 50px;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 34px;
  color: white;
  font-size: 18px;
  background-color: ${COLOR.BLUE_4};
`;

export { SearchBarContainer, SearchBarInput, SearchBtn };
