import React from 'react';
import { StyledProgressCircle, StyledProgressCircleInner } from './style';

export const ProgressCircle = ({ children, percent }) => {
  return (
    <StyledProgressCircle percent={percent}>
      <StyledProgressCircleInner>{children}</StyledProgressCircleInner>
    </StyledProgressCircle>
  );
};
