import { useAxios } from '../../hooks/useAxios';

export const createPlayerToken = async (lectureVideoId, signUpLectureHistoryId) => {
  return await useAxios.get(
    `/player/token?lectureVideoId=${lectureVideoId}&signUpLectureHistoryId=${signUpLectureHistoryId}`,
  );
};

export const player = async (token) => {
  return await useAxios.get(`/player?token=${token}`);
};

export const showOT = async (lectureVideoId) => {
  return await useAxios.get(`/player/ot?lectureVideoId=${lectureVideoId}`);
};

export const showPreview = async (lectureVideoId) => {
  return await useAxios.get(`/player/preview?lectureVideoId=${lectureVideoId}`);
};

/** 교수 홈 맛보기 강의 */
export const getProfessorPreviewLecture = async (professorId) => {
  return await useAxios.get(`/professor/preview/${professorId}`);
};
