import React from 'react';
import { Line, ProfessorCardContainer, ProfessorName, Title } from './style';

const ProfessorCard = ({ backgroundImg, professorName, title, onClickFunc }) => {
  function createMarkup(data) {
    return { __html: data };
  }

  return (
    <ProfessorCardContainer bgImg={backgroundImg} onClick={onClickFunc}>
      <Line />
      <ProfessorName>{professorName}</ProfessorName>
      <Title>
        <div dangerouslySetInnerHTML={createMarkup(title)} />
      </Title>
    </ProfessorCardContainer>
  );
};

export default ProfessorCard;
