import { atom, selector } from 'recoil';

/** 회원가입 약관동의 페이지에서 체크한 약관 리스트 상태 */
export const registerAssignmentAtom = atom({
  key: 'registerAssignmentAtom',
  default: Array(6).fill(false),
});

/** 회원가입 약관동의 페이지에서 전체 동의 체크 여부 */
export const registerAssignmentIsAllCheckAtom = atom({
  key: 'registerAssignmentIsAllCheckAtom',
  default: false,
});
