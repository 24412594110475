import { ThemeProvider } from 'styled-components';
import Router from './routes/router';
import GlobalStyle from './styles/global';
import theme from './styles/common/theme';
import { useAxiosInterceptor } from './hooks/useAxiosInterceptor';

function App() {
  useAxiosInterceptor();

  return (
    <ThemeProvider theme={theme['main']}>
      <GlobalStyle />
      <Router />
    </ThemeProvider>
  );
}

export default App;
