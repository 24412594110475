import React, { useState, useEffect } from 'react';
import {
  QuestionDetailContainer,
  QuestionDetailBox,
  DetailTitle,
  Info,
  Attachments,
  HorizonLine,
  ButtonContainer,
  AnswerBox,
  DataNotFound,
} from './style';
import { TabTitle } from '../../components/organisms/LecturePageElements/Lectures/style';
import { Button, CancelButton } from '../../components/atoms/Button';
import COLOR from '../../styles/common/color';
import { useNavigate, useParams } from 'react-router-dom';

import ToastViewer from '../../components/atoms/ToastTextViewer';

import { SpinLoader, typeList } from '../../components/atoms/SpinLoader';
import { useChangeMonthNumToEn } from '../../hooks/useChangeMonthNumToEn';
import { useQuery } from 'react-query';
import { getProfessorQna, downloadQuestion, deleteProfessorQna } from '../../axiosManage/Professor';

import { useRecoilState } from 'recoil';
import { myContactAtom, myQuestionId } from '../../recoil/MyPageStore/MyPageRecoil';

import useHandleError from '../../hooks/useHandleError';

const questionDetailPage = () => {
  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const { id } = useParams();

  const [questionId, setQuestionContentId] = useRecoilState(myQuestionId);
  const [questionDetail, setQuestionDetail] = useState([]);
  const [answerDetail, setAnswerDetail] = useState([]);
  const [isWriter, setIsWriter] = useState(false);

  useEffect(() => {
    getProfessorQna(id).then((res) => {
      if (res.status === 200) {
        setQuestionContentId(id);
        if (res.data.isWriter === true) {
          setIsWriter(true);
        }
        if (res.data.question !== undefined) {
          setQuestionDetail(res.data.question);
        }
        if (res.data.answer !== undefined) {
          setAnswerDetail(res.data.answer);
        }
      } else {
        alert('비공개 게시물입니다.');
        navigate(-1);
      }
    });
  }, [id]);

  const clickDownloadQuestion = (filename) => {
    downloadQuestion(id, filename);
  };

  const [questionContent, setQuestionContent] = useRecoilState(myContactAtom);
  const clickModify = (content) => {
    setQuestionContent(content);
    navigate('/question/modify');
  };

  const clickDelete = (questionId) => {
    let isYes = confirm('해당 학습질문을 삭제하시겠습니까?');

    if (isYes) {
      deleteProfessorQna(questionId)
        .then((res) => {
          alert('삭제되었습니다.');
          navigate(-1);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  return (
    <QuestionDetailContainer>
      <TabTitle>학습 질문</TabTitle>
      <HorizonLine />
      <QuestionDetailBox>
        {questionDetail !== undefined ? (
          <>
            <DetailTitle>{questionDetail.title}</DetailTitle>
            <Info>
              <div>
                <p>{questionDetail.createdAt?.slice(8, 10)}</p>
                <p>{useChangeMonthNumToEn(questionDetail.createdAt?.slice(5, 7))}</p>
                <p>{questionDetail.createdAt?.slice(0, 4)}</p>
              </div>
              <div>
                <p>작성자</p>
                <p>{questionDetail.name}</p>
              </div>
              {/* <div>
                <p>조회</p>
                <p>{questionDetail.views}</p>
              </div> */}
            </Info>
            <ToastViewer content={questionDetail.content} />
            {questionDetail.existFile ? (
              <Attachments>
                <span>첨부 파일</span>
                <a onClick={() => clickDownloadQuestion(questionDetail.fileName)}>
                  <span>{questionDetail.fileName}</span>
                </a>
              </Attachments>
            ) : null}
          </>
        ) : (
          <SpinLoader type={typeList[2].prop} color={'#000'} width={1131} height={459} />
        )}
      </QuestionDetailBox>
      {/* 답변 영역 */}
      <QuestionDetailBox>
        {answerDetail === null ? (
          <DataNotFound>
            <p>답변이 존재하지 않습니다!</p>
          </DataNotFound>
        ) : (
          <>
            <DetailTitle>답변: {questionDetail.title}</DetailTitle>
            <Info>
              {/* <div>
                <p>{answerDetail.createdAt?.slice(8, 10)}</p>
                <p>{useChangeMonthNumToEn(answerDetail.createdAt?.slice(5, 7))}</p>
                <p>{answerDetail.createdAt?.slice(0, 4)}</p>
              </div>
              <div>
                <p>작성자</p>
                <p>LAB STANDARD</p>
              </div>
              <div>
                <p>조회</p>
                <p>{answerDetail.views}</p>
              </div> */}
            </Info>
            <ToastViewer content={answerDetail.content} />
            {answerDetail.existFile ? (
              <Attachments>
                <span>첨부 파일</span>
                <a onClick={() => clickDownloadQuestion(answerDetail.fileName)}>
                  <span>{answerDetail.fileName}</span>
                </a>
              </Attachments>
            ) : null}
          </>
        )}
      </QuestionDetailBox>
      <ButtonContainer>
        {isWriter ? (
          <>
            <Button width={120} height={48} onClickFunc={() => clickModify(questionDetail)}>
              수정
            </Button>
            <Button width={120} height={48} onClickFunc={() => clickDelete(Number(id))}>
              삭제
            </Button>
          </>
        ) : (
          ''
        )}
        <Button width={124} height={48} bgColor={COLOR.BLUE_4} onClickFunc={() => navigate(-1)}>
          목록으로
        </Button>
      </ButtonContainer>
    </QuestionDetailContainer>
  );
};

export default questionDetailPage;
