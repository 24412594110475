import React, { useState, useEffect, useCallback } from 'react';
import {
  OrderBasketPageContainer,
  OrderBasketPageTitle,
  HorizonLine,
  OrderInfoBox,
  OrderInfoHeader,
  OrderItems,
  Item,
  InfoInputBox,
  InfoInputContent,
  PaySelectBox,
  PaySelectLeft,
  PayMenuBox,
  PaySelectRight,
  SubTitle,
} from './style';
import COLOR from '../../styles/common/color';

import ProgressBox from '../../components/molecules/ProgressBox';
import { Button, RegisterButton } from '../../components/atoms/Button';
import { SquareInput } from '../../components/atoms/Input';
import { SquareSelect } from '../../components/atoms/Select';

import StepIcon1 from '../../assets/icons/icon-Basket.png';
import StepIcon2Selected from '../../assets/icons/icon-join-selected.png';
import StepIcon3 from '../../assets/icons/icon-complete.png';

import SearchAddress from '../../components/organisms/SearchAddress';
import CouponModal from '../../components/molecules/CouponModal';

import { useMutation } from 'react-query';
import { createOrder, paymentCallback } from '../../axiosManage/OrderBasket';
import useHandleError from '../../hooks/useHandleError';
import { useNavigate, Navigate } from 'react-router-dom';

import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { myOrderInfoAtom } from '../../recoil/OrderBasketStore/OrderBasketRecoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';
import { myInfoAtom } from '../../recoil/MyPageStore/MyPageRecoil';

import {
  myOrderBasketListAtom,
  mySelectedProductListAtom,
  mySelectedProductListPriceAtom,
} from '../../recoil/OrderBasketStore/OrderBasketRecoil';
import { checkPaymentSuccess } from '../../axiosManage/OrderBasket';
import { getDeliveryCharge } from '../../axiosManage/OrderBasket';

function OrderInfoInputPage() {
  const navigate = useNavigate();
  const { handleError } = useHandleError();

  const resetOrderBasket = useResetRecoilState(myOrderBasketListAtom);
  const [selectedProducts, setSelectedProducts] = useRecoilState(mySelectedProductListAtom);
  const prevTotalPrice = useRecoilValue(mySelectedProductListPriceAtom);
  const isLogin = useRecoilValue(isLoginAtom);
  const myInfo = useRecoilValue(myInfoAtom);

  if (isLogin === false) {
    alert('잘못된 접근입니다.');
    return <Navigate to="/" />;
  }

  // 결제 방법 선택
  const [currentCategory, setCurrentCategory] = useState(1);
  const clickCategory = (id) => {
    setCurrentCategory(id);
  };

  // 입력한 배송 정보
  const [receiver, setReceiver] = useState(''); // 받으시는 분
  const handleReceiver = (e) => {
    setReceiver(e.target.value);
  };

  const [firstPhoneNumber, setFirstPhoneNumber] = useState(''); // 휴대폰 번호
  const handleFirstPhoneNumber = (e) => {
    setFirstPhoneNumber(e.target.value);
  };
  const [secondPhoneNumber, setSecondPhoneNumber] = useState(''); // 휴대폰 번호
  const handleSecondPhoneNumber = (e) => {
    setSecondPhoneNumber(e.target.value);
  };
  const [lastPhoneNumber, setLastPhoneNumber] = useState(''); // 휴대폰 번호
  const handleLastPhoneNumber = (e) => {
    setLastPhoneNumber(e.target.value);
  };

  const [addressDetail, setAddressDetail] = useState(''); // 상세 주소
  const handleAddressDetail = (e) => {
    setAddressDetail(e.target.value);
  };

  // 우편번호 팝업창
  const [postAddress, setPostAddress] = useState({
    address: '',
    zoneCode: '',
  });
  const [popup, setPopup] = useState(false);
  const handleSearchAddress = (e) => {
    setPostAddress({
      ...postAddress,
      [e.target.name]: e.target.value,
    });
  };

  const openSearchAddressPopUp = () => {
    setPopup(true);
  };

  const [isAutoAddress, setIsAutoAddress] = useState(false);
  const clickAddressAutoInput = () => {
    setIsAutoAddress((isAutoAddress) => !isAutoAddress);
    setPostAddress({
      address: JSON.parse(myInfo.address).address,
      zoneCode: JSON.parse(myInfo.address).code,
    });
    setAddressDetail(JSON.parse(myInfo.address).detailAddress);
  };

  useEffect(() => {
    if (isAutoAddress === false) {
      setPostAddress({
        address: '',
        zoneCode: '',
      });
      setAddressDetail('');
    }
  }, [isAutoAddress]);

  // 쿠폰 번호 입력 팝업창
  const [couponPopup, setCouponPopup] = useState(false);
  const [couponInfo, setCouponInfo] = useState({
    code: '',
  });
  const [couponProduct, setCouponProduct] = useState([]); // 쿠폰 사용할 상품 이름
  const [isCouponUsed, setIsCouponUsed] = useState(false); // 쿠폰을 사용 했는가?
  const [couponUsedProduct, setCouponUsedProduct] = useState([]); // 쿠폰 사용 완료된 상품
  const [salePercent, setSalePercent] = useState(null); // 쿠폰 사용 완료시 response(할인가, 쿠폰이름, 쿠폰 id)

  const openCouponPopUp = (product) => {
    setCouponPopup(true);
    setCouponProduct(product);
    setCouponInfo({
      code: '',
    });
  };

  useEffect(() => {
    if (isCouponUsed === true) {
      setCouponUsedProduct(couponProduct);
    }
  }, [isCouponUsed]);

  const [deliveryFee, setDeliveryFee] = useState(null);
  const [noDeliveryChargeAmount, setNoDeliveryChargeAmount] = useState(null);
  useEffect(() => {
    getDeliveryCharge()
      .then((res) => {
        setDeliveryFee(res.data.deliveryCharge);
        setNoDeliveryChargeAmount(res.data.noDeliveryChargeAmount);
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  // 쿠폰 적용된 최종 가격 계산
  const [totalPrice, setTotalPrice] = useState(0);
  const [lectureAndPassTotal, setLectureAndPassTotal] = useState(0);
  const [bookTotal, setBookTotal] = useState(0);
  const [productOfCouponUsed, setProductOfCouponUsed] = useState([]);
  useEffect(() => {
    let bookTotal = 0;
    let lectureAndPassTotal = 0;
    let productList = [];

    selectedProducts.forEach((item) => {
      if (item.length > 1 && item[0].bookName) {
        bookTotal += (item[0].price - item[0].sale) * item[1];
      } else if (item.bookName) {
        bookTotal += item.price - item.sale;
      } else if (item.lectureName || item.passName) {
        productList.push(item);
        lectureAndPassTotal += item.price - item.sale;
      }
    });

    setLectureAndPassTotal(lectureAndPassTotal);
    setBookTotal(bookTotal);

    if (isCouponUsed === true) {
      setProductOfCouponUsed(productList);
      setTotalPrice(
        Math.ceil((lectureAndPassTotal * (1 - salePercent / 100)).toFixed(1)) + bookTotal,
      );
    } else {
      setTotalPrice(bookTotal + lectureAndPassTotal);
    }
  }, [selectedProducts, salePercent, isCouponUsed]);

  // 쿠폰 사용 취소
  const cancelUseCoupon = () => {
    if (isCouponUsed === true) {
      alert('쿠폰 사용이 취소되었습니다.');
      setIsCouponUsed(false);
      setCouponProduct([]);
      setCouponUsedProduct([]);
      setProductOfCouponUsed([]);
      setTotalPrice(prevTotalPrice);
      setCouponInfo({
        code: '',
      });
    } else {
      alert('적용된 쿠폰이 없습니다.');
    }
  };

  /** 결제할 상품 정보 */
  const [books, setBooks] = useState([]);
  const [lectures, setLectures] = useState([]);
  const [passes, setPasses] = useState([]);
  useEffect(() => {
    let books = [];
    let lectures = [];
    let passes = [];

    selectedProducts.forEach((product) => {
      if (product.bookName) {
        books.push({ bookId: product.id, count: 1 });
      } else if (product[0]) {
        books.push({ bookId: product[0].id, count: product[1] });
      } else if (product.lectureName) {
        lectures.push(product.id);
      } else if (product.passName) {
        passes.push(product.id);
      }
    });

    setBooks(books);
    setLectures(lectures);
    setPasses(passes);
  }, [selectedProducts]);

  /** 결제 정보 데이터 */
  const [payInfo, setPayInfo] = useRecoilState(myOrderInfoAtom);

  /** 아임포트 결제 정보 */
  const onClickPayment = (merchantUid) => {
    /* 1. 가맹점 식별하기 */
    const { IMP } = window;
    IMP.init(process.env.REACT_APP_IMPORT_IMP);

    /* 2. 결제 데이터 정의하기 */
    const data = {
      pg: 'html5_inicis',
      pay_method: 'card',
      merchant_uid: merchantUid,
      name: 'LANSTANDARD 결제',
      amount:
        books && books.length >= 1 && bookTotal && bookTotal < noDeliveryChargeAmount
          ? Number(totalPrice) + deliveryFee
          : (bookTotal && bookTotal >= noDeliveryChargeAmount) || (books && books.length < 1)
            ? Number(totalPrice)
            : '',
      tax_free:
        books && books.length >= 1 && bookTotal && bookTotal < noDeliveryChargeAmount
          ? Number(totalPrice) + deliveryFee
          : (bookTotal && bookTotal >= noDeliveryChargeAmount) || (books && books.length < 1)
            ? Number(totalPrice)
            : '',
      buyer_email: myInfo.email,
      buyer_name: receiver,
      buyer_tel: `${firstPhoneNumber}-${secondPhoneNumber}-${lastPhoneNumber}`,
      buyer_addr: postAddress.address + addressDetail,
      buyer_postcode: postAddress.zoneCode,
      custom_data: { parcelDetail: '' },
      m_redirect_url: process.env.REACT_APP_PAYMENT_REDIRECT_URI,
    };

    /* 4. 결제 창 호출하기 */
    IMP.request_pay(data, callback);
  };

  /* 3. 콜백 함수 정의하기 - 검증*/
  const callback = (response) => {
    const {
      success,
      imp_uid,
      merchant_uid,
      error_msg,
      emb_pg_provider,
      pay_method,
      paid_at,
      paid_amount,
      apply_num,
    } = response;

    if (success == true) {
      setPayInfo([
        {
          createdAt: new Date(paid_at * 1000),
          products: selectedProducts,
          price: paid_amount,
          provider: emb_pg_provider,
          pay_method: pay_method,
        },
      ]);

      resetOrderBasket();
      navigate('/order/complete');
    } else {
      checkPaymentSuccess(imp_uid, merchant_uid)
        .then((res) => {
          alert('결제에 실패하였습니다. 에러 내용: ' + error_msg);
        })
        .catch((error) => {
          //console.log(error.response.data.message);
        });
    }
  };

  /** 자체 서버로 결제 요청 전송 로직 */
  const postOrder = useMutation(
    (data) => {
      createOrder(data)
        .then((res) => {
          if (res.status == 200) {
            onClickPayment(res.data.merchantUid);
          }
          else {
            alert("결제 요청에 실패하였습니다.");
          }
        })
        .catch((error) => {
          alert('결제에 실패하였습니다.');
        });
    },
    {
      onSuccess: () => { },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  function isCheckNum(str) {
    var checkReg = /^[-0-9]*$/;
    return checkReg.test(str);
  }

  const orderSubmit = () => {
    if (
      receiver === '' ||
      firstPhoneNumber === '' ||
      secondPhoneNumber === '' ||
      lastPhoneNumber === '' ||
      postAddress.address === '' ||
      addressDetail === '' ||
      isCheckNum(firstPhoneNumber + secondPhoneNumber + lastPhoneNumber) === false
    ) {
      alert('배송 정보를 정확하게 입력해주세요.');
    } else {
      if (isCouponUsed === true) {
        const productData = {
          books: books,
          lectures: lectures,
          passes: passes,
          coupon: couponInfo,
        };

        postOrder.mutate(productData);
      } else {
        const productData = {
          books: books,
          lectures: lectures,
          passes: passes,
        };

        postOrder.mutate(productData);
      }
    }
  };

  return (
    <OrderBasketPageContainer>
      {popup && (
        <SearchAddress
          postAddress={postAddress}
          setPostAddress={setPostAddress}
          setPopup={setPopup}
        />
      )}
      {couponPopup && (
        <CouponModal
          setCouponPopup={setCouponPopup}
          couponInfo={couponInfo}
          setCouponInfo={setCouponInfo}
          setIsCouponUsed={setIsCouponUsed}
          setSalePercent={setSalePercent}
        />
      )}
      <OrderBasketPageTitle>
        <span>{myInfo.name}</span>님 장바구니
      </OrderBasketPageTitle>
      <HorizonLine />
      <ProgressBox
        stepIcon1={StepIcon1}
        stepIcon2={StepIcon2Selected}
        stepIcon3={StepIcon3}
        stepText1={`장바구니`}
        stepText2={`결제정보 입력`}
        stepText3={`결제 완료`}
      >
        <OrderInfoBox>
          <SubTitle>주문 정보</SubTitle>
          <OrderInfoHeader>
            <p>상품정보</p>
            <p>수량</p>
            <p>결제가</p>
            <p></p>
          </OrderInfoHeader>
          <OrderItems>
            {selectedProducts.map((product) => (
              <>
                {product.length > 1 ? (
                  <Item key={product[0].id}>
                    <div>
                      <div>
                        {product[0].bookCategory === 'language-logic' ? (
                          <p>언어논리</p>
                        ) : product[0].bookCategory === 'data-interpretation' ? (
                          <p>자료해석</p>
                        ) : product[0].bookCategory === 'situational-judgment' ? (
                          <p>상황판단</p>
                        ) : (
                          ''
                        )}
                        <p>{product[0].author}</p>
                      </div>
                      <div>
                        {product[0].lectureName ? <p>강의</p> : <p>교재</p>}
                        <p>{product[0].bookName}</p>
                      </div>
                    </div>
                    <div>{product[1]}</div>
                    {product[0].sale === 0 ? (
                      <div>
                        <div></div>
                        {(product[0].price * product[1])
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        원
                      </div>
                    ) : (
                      <div>
                        <div
                          style={{
                            textDecoration: 'line-through',
                            opacity: '0.5',
                            marginBottom: '5px',
                          }}
                        >
                          {(product[0].price * product[1])
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          원
                        </div>
                        {((product[0].price - product[0].sale) * product[1])
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        원
                      </div>
                    )}

                    <div></div>
                  </Item>
                ) : (
                  <>
                    {product.passName ? (
                      <Item key={product.id}>
                        <div>
                          <div style={{ textAlign: 'left' }}>
                            <p>패스</p>
                            <p>{product.passName}</p>
                          </div>
                        </div>
                        <div>{product.count || 1}</div>
                        {/* 강의 쿠폰 사용해도 주문 목록의 가격은 안바뀌게  */}
                        <div>
                          {product.sale === 0 ? (
                            <div>
                              <div></div>
                              {(product.price * (product.count || 1))
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              원
                            </div>
                          ) : (
                            <div>
                              <div
                                style={{
                                  textDecoration: 'line-through',
                                  opacity: '0.5',
                                  marginBottom: '5px',
                                }}
                              >
                                {(product.price * (product.count || 1))
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                원
                              </div>
                              {((product.price - product.sale) * (product.count || 1))
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              원
                            </div>
                          )}
                        </div>
                      </Item>
                    ) : (
                      <Item key={product.id}>
                        <div>
                          <div>
                            {product.lectureCategory === 'language-logic' ||
                              product.bookCategory === 'language-logic' ? (
                              <p>언어논리</p>
                            ) : product.lectureCategory === 'data-interpretation' ||
                              product.bookCategory === 'data-interpretation' ? (
                              <p>자료해석</p>
                            ) : product.lectureCategory === 'situational-judgment' ||
                              product.bookCategory === 'situational-judgment' ? (
                              <p>상황판단</p>
                            ) : (
                              ''
                            )}
                            <p>
                              {(product.professor && product.professor.professorName) ||
                                product.author}
                            </p>
                          </div>
                          <div>
                            {product.lectureName ? <p>강의</p> : <p>교재</p>}
                            <p>{product.lectureName || product.bookName}</p>
                          </div>
                        </div>
                        <div>{product.count || 1}</div>
                        {/* 강의 쿠폰 사용해도 주문 목록의 가격은 안바뀌게 */}
                        <div>
                          {product.sale === 0 ? (
                            <div>
                              <div></div>
                              {(product.price * (product.count || 1))
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              원
                            </div>
                          ) : (
                            <div>
                              <div
                                style={{
                                  textDecoration: 'line-through',
                                  opacity: '0.5',
                                  marginBottom: '5px',
                                }}
                              >
                                {(product.price * (product.count || 1))
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                원
                              </div>
                              {((product.price - product.sale) * (product.count || 1))
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              원
                            </div>
                          )}
                        </div>
                      </Item>
                    )}
                  </>
                )}
              </>
            ))}
          </OrderItems>
        </OrderInfoBox>
      </ProgressBox>
      <InfoInputBox>
        <SubTitle>
          <p>배송 정보 입력</p>
          <p>
            <Button
              width={180}
              height={48}
              bgColor={COLOR.BLUE_4}
              onClickFunc={clickAddressAutoInput}
            >
              내 배송지 입력하기
            </Button>
            <Button width={180} height={48} onClickFunc={clickAddressAutoInput}>
              배송지 새로 입력하기
            </Button>
          </p>
        </SubTitle>
        <InfoInputContent>
          <div>
            <p>받으시는 분</p>
            <SquareInput width={455} height={62} onChangeFunc={handleReceiver} />
          </div>
          <div>
            <p>휴대폰 번호</p>
            <SquareInput width={140} height={62} onChangeFunc={handleFirstPhoneNumber} />
            <span>-</span>
            <SquareInput width={140} height={62} onChangeFunc={handleSecondPhoneNumber} />
            <span>-</span>
            <SquareInput width={140} height={62} onChangeFunc={handleLastPhoneNumber} />
            <p>* 주문 / 배송 정보를 안내드리오니 정확하게 입력해주세요!</p>
          </div>
          {isAutoAddress === true ? (
            <>
              <div>
                <p>배송지 주소 입력</p>
                <SquareInput
                  width={325}
                  height={62}
                  onChangeFunc={handleSearchAddress}
                  value={JSON.parse(myInfo.address).address}
                />
                <RegisterButton
                  width={130}
                  height={62}
                  bgColor={COLOR.BLUE_4}
                  fontSize={14}
                  fontWeight={300}
                  onClickFunc={openSearchAddressPopUp}
                >
                  우편번호 검색
                </RegisterButton>
                <p>* 원활한 배송을 위해 주소를 정확하게 입력해주세요!</p>
              </div>
              <div>
                <p></p>
                <SquareInput
                  width={1009}
                  height={62}
                  onChangeFunc={handleAddressDetail}
                  value={JSON.parse(myInfo.address).detailAddress}
                />
              </div>
            </>
          ) : (
            <>
              <div>
                <p>배송지 주소 입력</p>
                <SquareInput
                  width={325}
                  height={62}
                  onChangeFunc={handleSearchAddress}
                  value={postAddress.address}
                />
                <RegisterButton
                  width={130}
                  height={62}
                  bgColor={COLOR.BLUE_4}
                  fontSize={14}
                  fontWeight={300}
                  onClickFunc={openSearchAddressPopUp}
                >
                  우편번호 검색
                </RegisterButton>
                <p>* 원활한 배송을 위해 주소를 정확하게 입력해주세요!</p>
              </div>
              <div>
                <p></p>
                <SquareInput
                  width={1009}
                  height={62}
                  onChangeFunc={handleAddressDetail}
                  value={addressDetail}
                />
              </div>
            </>
          )}
        </InfoInputContent>
      </InfoInputBox>
      <PaySelectBox>
        <div>
          <PaySelectLeft>
            <p>결제</p>
            <p>
              * 쿠폰은 단과 강좌 및 패스 상품에만 사용 가능하며, 결제 건당 하나의 쿠폰만 적용할 수
              있습니다.
            </p>
            {isCouponUsed === true ? (
              <Button
                width={100}
                height={60}
                bgColor={COLOR.BLUE_4}
                fontSize={13}
                onClickFunc={cancelUseCoupon}
              >
                쿠폰 사용 취소
              </Button>
            ) : (
              <Button width={100} height={60} bgColor={COLOR.BLUE_4} onClickFunc={openCouponPopUp}>
                쿠폰 입력
              </Button>
            )}
            {isCouponUsed ? (
              <div>
                <p>쿠폰이 사용 된 상품명</p>
                {productOfCouponUsed.map((item, i) => (
                  <p key={item.id}>
                    {i + 1}. {item.lectureName || item.passName}
                  </p>
                ))}
              </div>
            ) : (
              <div>
                <p></p>
                <p></p>
              </div>
            )}
          </PaySelectLeft>
          <PaySelectRight>
            <div>
              <p>총 합계</p>
              <p>{totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</p>
            </div>
            <div>
              <p>배송비</p>
              {books && books.length >= 1 && bookTotal && bookTotal < noDeliveryChargeAmount ? (
                <p>{deliveryFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</p>
              ) : (bookTotal && bookTotal >= noDeliveryChargeAmount) ||
                (books && books.length < 1) ? (
                <p>0 원</p>
              ) : (
                ''
              )}
            </div>
            {isCouponUsed === true ? (
              <div>
                <p>할인</p>
                <p>
                  -{' '}
                  {(lectureAndPassTotal * (salePercent / 100))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                  원
                </p>
              </div>
            ) : (
              <div>
                <p></p>
                <p></p>
              </div>
            )}

            <div></div>
            <div>
              <p>합계</p>
              {books && books.length >= 1 && bookTotal && bookTotal < noDeliveryChargeAmount ? (
                <p>
                  {(Number(totalPrice) + deliveryFee)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  원
                </p>
              ) : (bookTotal && bookTotal >= noDeliveryChargeAmount) ||
                (books && books.length < 1) ? (
                <p>{(Number(totalPrice) + 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</p>
              ) : (
                ''
              )}
            </div>
            <div>총 결제 금액</div>
            {books && books.length >= 1 && bookTotal && bookTotal < noDeliveryChargeAmount ? (
              <div>
                {(Number(totalPrice) + deliveryFee)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                원
              </div>
            ) : (bookTotal && bookTotal >= noDeliveryChargeAmount) ||
              (books && books.length < 1) ? (
              <div>
                {(Number(totalPrice) + 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
              </div>
            ) : (
              ''
            )}
            <div>
              <Button width={313} height={60} onClickFunc={orderSubmit}>
                결제하기
              </Button>
            </div>
          </PaySelectRight>
        </div>
      </PaySelectBox>
    </OrderBasketPageContainer>
  );
}

export default OrderInfoInputPage;
