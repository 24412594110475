import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

export const AlarmDetailBox = styled.div`
  width: 100%;
  padding: 20px;
  background-color: ${COLOR.GRAY_0};
  box-shadow: 0px 3px 10px #00000029;
`;

export const AlarmHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 27px 17px 27px;
  border-bottom: 1px solid ${COLOR.BLACK_1};

  & > p {
    :nth-child(1) {
      width: 5%;
    }
    :nth-child(2) {
      width: 100%;
    }
    :nth-child(3) {
      text-align: right;
      width: 20%;
    }
  }
`;

export const AlarmContent = styled.div`
  ${FONT_STYLES.P_R};
  padding: 23px 47px;
  line-height: normal;
`;
