import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

export const OrderBasketPageContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding};
  text-align: center;
  padding-top: 60px;
  padding-bottom: 120px;

  @media screen and (max-width: 1700px) {
    padding-top: 60px;
    padding-bottom: 120px;
  }
`;

export const OrderBasketPageTitle = styled.div`
  ${FONT_STYLES.P_M};
  font-size: 36px;

  & > span {
    ${FONT_STYLES.P_B};
    color: ${COLOR.BLUE_4};
  }
`;

export const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_4};
  margin: 50px auto;
`;

export const OrderInfoBox = styled.div`
  padding: 0 75px;
`;

export const OrderInfoHeader = styled.div`
  display: flex;
  padding: 15px 0;

  & > p {
    :nth-child(1) {
      width: 60%;
    }
    :nth-child(2) {
      width: 20%;
    }
    :nth-child(3) {
      width: 20%;
    }
  }
`;

export const OrderItems = styled.div``;

export const Item = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${COLOR.GRAY_9};
  padding: 40px 0;

  & > div {
    :nth-child(1) {
      display: flex;
      width: 60%;

      & > div {
        :nth-child(1) {
          margin-right: 64px;
          p:nth-child(2) {
            ${FONT_STYLES.P_R}
          }
        }
        :nth-child(2) {
          text-align: left;
          p:nth-child(2) {
            ${FONT_STYLES.P_SB}
            font-size: 20px;
          }
        }

        & > p {
          padding: 3px 0;
          :nth-child(1) {
            ${FONT_STYLES.P_T}
            font-size: 15px;
          }
        }
      }
    }

    :nth-child(2) {
      ${FONT_STYLES.P_R}
      font-size: 18px;
      width: 20%;
    }

    :nth-child(3) {
      ${FONT_STYLES.P_SB}
      font-size: 18px;
      width: 20%;
    }
  }
`;

export const InfoInputBox = styled.div`
  width: 100%;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;
  padding: 16px 105px;
  margin: 20px auto;
`;

export const InfoInputContent = styled.div`
  text-align: left;
  padding: 10px 0;

  & > div {
    display: flex;
    align-items: center;
    margin: 13px 0;

    & > p {
      :nth-child(1) {
        width: 130px;
      }

      :nth-child(4),
      :nth-child(7) {
        ${FONT_STYLES.P_R}
        font-size: 15px;
        color: #716b6b;
        margin-left: 28px;
      }
    }

    :nth-child(2) {
      & > span {
        margin: 0 5px;
      }
    }
  }
`;

export const PaySelectBox = styled.div`
  width: 100%;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 22px;
  margin: 0 auto;

  & > div {
    display: flex;
  }
`;

export const PaySelectLeft = styled.div`
  width: 71.5%;
  text-align: left;
  padding: 0 0 36px 105px;

  @media screen and (max-width: 1700px) {
    width: 75%;
  }

  & > p {
    :nth-child(1) {
      ${FONT_STYLES.P_B}
      font-size: 24px;
      padding: 36px 0 20px 0;
    }

    :nth-child(2) {
      font-size: 15px;
      margin-bottom: 21px;
      color: ${COLOR.GRAY_3};
    }
  }

  & > div {
    :nth-child(3) {
      display: flex;
      align-items: center;

      & > p {
        :nth-child(1) {
          margin-right: 20px;
        }
      }
    }

    :nth-child(4) {
      margin-top: 50px;
      ${FONT_STYLES.P_SB}
      font-size: 15px;
      margin-bottom: 12px;

      & > p {
        ${FONT_STYLES.P_R}
        line-height: 1.5;
        :nth-child(1) {
          font-size: 20px;
          padding-bottom: 10px;
        }
      }
    }

    :nth-child(5) {
      ${FONT_STYLES.P_R}
      font-size: 15px;
      color: ${COLOR.GRAY_4};
      width: 96%;
      white-space: pre-line;
      line-height: normal;
    }
  }
`;

export const PayMenuBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96%;
  height: 108px;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 7px;
  margin-bottom: 117px;

  & > div {
    width: 100%;

    :nth-last-child(1) {
      & > li {
        & > p {
          border-right: none;
        }
      }
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 108px;
    ${FONT_STYLES.P_SB}
    color: ${COLOR.BLACK_1};
    list-style: none;

    & > p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
      font-size: 18px;
      cursor: pointer;
      border-right: 1px solid ${COLOR.GRAY_9};
    }

    &.active {
      height: 108px;
      ${FONT_STYLES.P_SB};
      color: ${COLOR.GRAY_0};
      background-color: ${COLOR.BLUE_4};
      box-shadow: 0px 3px 10px #00000029;
      border-radius: 5px;

      & > p {
        border-right: none;
      }
    }
  }
`;

export const PaySelectRight = styled.div`
  width: 28.5%;
  color: ${COLOR.GRAY_0};
  background-color: #5c6072;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  padding: 30px 60px;

  & > div {
    display: flex;
    flex-wrap: wrap;

    :nth-child(1),
    :nth-child(2),
    :nth-child(3),
    :nth-child(5) {
      margin: 15px 0;
    }

    :nth-child(4) {
      border-bottom: 1px solid ${COLOR.GRAY_0};
    }

    :nth-child(6) {
      margin-top: 150px;
      justify-content: center;
      ${FONT_STYLES.P_B}
      font-size: 24px;
      margin-bottom: 15px;
    }

    :nth-child(7) {
      justify-content: center;
      ${FONT_STYLES.P_B}
      font-size: 36px;
    }

    :nth-child(8) {
      display: block;
      position: relative;
      margin-top: 130px;
      left: -28px;
    }

    & > p {
      flex-basis: 50%;

      :nth-child(1) {
        text-align: left;
        padding-left: 20px;
      }
      :nth-child(2) {
        text-align: right;
        padding-right: 20px;
      }
    }

    @media screen and (max-width: 1400px) {
      :nth-child(7) {
        display: block;
        position: relative;
        margin-top: 130px;
        left: 5px;
      }
    }
  }
`;

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
  ${FONT_STYLES.P_B}
  font-size: 24px;
  text-align: left;
  padding: 20px 0;
  border-bottom: 1px solid ${COLOR.GRAY_3};

  & > p:nth-child(2) {
    margin-left: auto;
  }

  button {
    ${FONT_STYLES.P_R}
    font-size: 15px;
    margin: 0 8px;
  }
`;
