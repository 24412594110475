import { useAxios } from '../../hooks/useAxios';

/** 내 알람 조회 API */
export const getMyNotify = async () => {
  return await useAxios.get(`/notify`);
};

/** 알람 읽기 API */
export const checkNotify = async (alarmId) => {
  return await useAxios.get(`/notify/check?id=${alarmId}`);
};

/** 읽은 알람 삭제 API */
export const deleteNotify = async (alarmArray) => {
  return await useAxios.post(`/notify/delete`, alarmArray);
};
