import React, { useState, useEffect, useRef } from 'react';
import Input from '../../components/atoms/Input';
import { Button, CancelButton } from '../../components/atoms/Button/index';
import ToastEditor from '../../components/atoms/ToastTextEditor';
import {
  ButtonContainer,
  ContentContainer,
  FormContainer,
  QuestionCreatePageContainer,
  SubTitle,
  Title,
} from './style';
import Select from '../../components/atoms/Select';
import COLOR from '../../styles/common/color';

import { useQuery } from 'react-query';
import { addReview } from '../../axiosManage/Professor';
import { getMyLecture } from '../../axiosManage/MyPage';
import useHandleError from '../../hooks/useHandleError';
import { useNavigate, Navigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';
import { myInfoAtom } from '../../recoil/MyPageStore/MyPageRecoil';

const QuestionCreatePage = ({ professorId }) => {
  const myInfo = useRecoilValue(myInfoAtom);
  const isLogin = useRecoilValue(isLoginAtom);

  if (isLogin === false) {
    alert('로그인을 해주세요.');
    return <Navigate to="/login" />;
  }

  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const editorRef = useRef();

  const [ProfessorId, setProfessorId] = useState(1);
  useEffect(() => {
    setProfessorId(professorId)
  }, [professorId]);

  const [myLectureInfo, setMyLectureInfo] = useState([]);
  const { status: myLectureStatus } = useQuery('getMyLecture', getMyLecture, {
    retry: 0,
    onSuccess: (data) => {
      let myLectureList = [];
      data.data.myLectures.forEach((lecture) => {
        if (lecture.status === '수강 중' || lecture.status === '수강 종료') {
          myLectureList.push(lecture);
          //console.log(lecture);
        }
      });
      setMyLectureInfo(myLectureList);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const SELECT_OPTIONS = myLectureInfo.map((lecture) => ({
    value: parseInt(lecture.lectureInfo.id),
    optionName: lecture.lectureInfo.lectureName,
  }));

  // 유저가 입력한 서버로 전송할 데이터(제목, 내용, 카테고리)
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState(
    myLectureInfo[0] && myLectureInfo[0].lectureInfo.lectureName,
  );

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const [lectureId, setLectureId] = useState(myLectureInfo[0] && myLectureInfo[0].lectureInfo.id);
  const handleCategoryChange = (e) => {
    setLectureId(e.target.value);
    setCategory(e.target.value);
  };

  useEffect(() => {
    setCategory(myLectureInfo[0] && myLectureInfo[0].lectureInfo.lectureName);
    setLectureId(myLectureInfo[0] && myLectureInfo[0].lectureInfo.id);
  }, [myLectureInfo]);

  const passReviewSubmit = () => {
    if (
      title !== '' &&
      !editorRef.current
        ?.getInstance()
        .getHTML()
        .includes('위에 구분을 먼저 선택 후 글을 작성해주시기 바랍니다!') &&
      category !== '구분'
    ) {
      addReview({
        lectureId: lectureId,
        title: title,
        content: editorRef.current?.getInstance().getHTML(),
      })
        .then((res) => {
          if (res.data.success === true) {
            alert('수강후기 등록이 완료되었습니다!');
            navigate(`/lecture/${professorId}/review`);
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else {
      alert('제목, 내용, 카테고리를 모두 입력해주세요!');
    }
  };

  return (
    <QuestionCreatePageContainer>
      <ContentContainer>
        <Title>수강후기</Title>
        <SubTitle>수강후기 작성하기</SubTitle>
        <FormContainer>
          <div>
            <Select
              width={313}
              height={58}
              selectOptions={SELECT_OPTIONS}
              onChangeFunc={handleCategoryChange}
            />
            <Input
              width={645}
              height={58}
              placeholder="제목을 입력하세요."
              onChangeFunc={handleTitleChange}
            />
            {isLogin ? (
              <div>이름: {myInfo.name}</div>
            ) : (
              <Input width={313} height={58} placeholder="이름을 입력하세요." />
            )}
          </div>
          <ToastEditor editorRef={editorRef} />
          <div>
            {/* <FileUploader /> */}
            <ButtonContainer>
              {/* <CancelButton width={124} height={48}>
                취소
              </CancelButton> */}
              <Button width={124} height={48} bgColor={COLOR.BLUE_4} onClickFunc={passReviewSubmit}>
                제출
              </Button>
            </ButtonContainer>
          </div>
        </FormContainer>
      </ContentContainer>
    </QuestionCreatePageContainer>
  );
};

export default QuestionCreatePage;
