import { atom } from 'recoil';

/** 5급 강의 - 교수 학습질문 페이징 정보(ex. 필터링된, 검색된) */
export const professorQuestionPageInfoAtom = atom({
  key: 'professorQuestionPageInfoAtom',
  default: [],
});

/** 5급 강의 - 교수 학습질문 데이터 리스트(ex. 필터링된, 검색된) */
export const professorQuestionListAtom = atom({
  key: 'professorQuestionListAtom',
  default: [],
});

/** 5급 강의 - 교수 수강후기 페이징 정보(ex. 필터링된, 검색된) */
export const professorReviewPageInfoAtom = atom({
  key: 'professorReviewPageInfoAtom',
  default: [],
});

/** 5급 강의 - 교수 수강후기 데이터 리스트(ex. 필터링된, 검색된) */
export const professorReviewListAtom = atom({
  key: 'professorReviewListAtom',
  default: [],
});

/** 5급 강의 - 교수 자료실 페이징 정보(ex. 필터링된, 검색된) */
export const professorReferencePageInfoAtom = atom({
  key: 'professorReferencePageInfoAtom',
  default: [],
});

/** 5급 강의 - 교수 자료실 데이터 리스트(ex. 필터링된, 검색된) */
export const professorReferenceListAtom = atom({
  key: 'professorReferenceListAtom',
  default: [],
});

/** 5급 강의 - 교수 공지사항 페이징 정보(ex. 필터링된, 검색된) */
export const professorNoticePageInfoAtom = atom({
  key: 'professorNoticePageInfoAtom',
  default: [],
});

/** 5급 강의 - 교수 공지사항 데이터 리스트(ex. 필터링된, 검색된) */
export const professorNoticeListAtom = atom({
  key: 'professorNoticeListAtom',
  default: [],
});
