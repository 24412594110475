import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const BookStoreContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-top: 70px;
  padding-bottom: 120px;
  text-align: center;

  li {
    list-style: none;
  }

  @media screen and (max-width: 1700px) {
    padding-top: 70px;
    padding-bottom: 120px;
  }
`;

const BookStoreCategoryTitle = styled.div`
  ${FONT_STYLES.P_EB}
  font-size: 40px;
`;

const HorizonLine = styled.div`
  width: 40px;
  border-bottom: 3px solid ${COLOR.BLUE_3};
  margin: 40px auto;
`;

const BookStoreSubMenuBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  color: #716b6b;
  margin-bottom: 30px;
  height: 52px;

  & > p {
    margin-left: auto;
  }

  li {
    ${FONT_STYLES.P_R}
    margin: 0 20px;
    cursor: pointer;

    :nth-child(5) {
      margin-left: auto;
    }

    &.active {
      ${FONT_STYLES.P_B}
      color: ${COLOR.BLACK_1};
    }
  }
`;

const BookListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const Book = styled.div`
  flex: 1 1 20%;
  margin: 10px 10px 50px 10px;
  cursor: pointer;

  & > img {
    width: 230px;
    height: 350px;
    object-fit: scale-down;
  }

  & > p {
    padding: 5px;

    :nth-child(2) {
      margin-top: 30px;
      ${FONT_STYLES.P_M}
      line-height: 1.3;
    }
    :nth-child(3) {
      ${FONT_STYLES.P_R}
      font-size: 14px;
      color: #716b6b;
    }
    :nth-child(4) {
      ${FONT_STYLES.P_R}
      font-size: 14px;
      color: ${COLOR.BLACK_2};
    }
  }
`;

const PaginationBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  & > svg {
    cursor: pointer;
  }

  .prev-btn {
    margin-right: 30px;
  }
  .next-btn {
    margin-left: 30px;
  }
`;

const DataNotFound = styled.div`
  width: 100%;
  height: 715px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > p {
    ${FONT_STYLES.P_R}
    font-size: 36px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-left: 30px;
  margin-top: 40px;

  button {
    font-size: 20px;
  }
`;

export {
  HorizonLine,
  BookStoreContainer,
  BookStoreCategoryTitle,
  BookStoreSubMenuBar,
  BookListContainer,
  Book,
  PaginationBox,
  DataNotFound,
  ButtonContainer,
};
