import React from 'react';
import { StyledSelect, StyledSquareSelect } from './style';

const Select = ({ selectName, width, height, selectOptions, onChangeFunc }) => {
  return (
    <StyledSelect name={selectName} width={width} height={height} onChange={onChangeFunc}>
      {selectOptions.map((option) => (
        <option key={option.optionName} value={option.value}>
          {option.optionName}
        </option>
      ))}
    </StyledSelect>
  );
};

export const SquareSelect = ({
  selectName,
  width,
  height,
  selectOptions,
  onChangeFunc,
  bgColor,
}) => {
  return (
    <StyledSquareSelect
      name={selectName}
      width={width}
      height={height}
      onChange={onChangeFunc}
      bgColor={bgColor}
    >
      {selectOptions.map((option) => (
        <option key={option.optionName} value={option.value}>
          {option.optionName}
        </option>
      ))}
    </StyledSquareSelect>
  );
};

export default Select;
