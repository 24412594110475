import { useAxios, useDownloadAxios } from '../../hooks/useAxios';
import fileDownload from 'js-file-download';

/** 5급 강의 - 강의 홈 배너 API */
export const getLectureBanner = async () => {
  return await useAxios.get(`/lecture-banner`);
};

/** 5급 강의 - 정기 패스 API */
export const getRecurringPassInfo = async () => {
  return await useAxios.get(`/pass/recurring`);
};

/** 5급 강의 - 강의 교수 이미지 API */
export const getProfessors = async () => {
  return await useAxios.get(`/professor/list`);
};

/** 5급 강의 - 교수별 메인 배너 API */
export const getProfessorBanner = async (professorId) => {
  return await useAxios.get(`/professor/banner/${professorId}`);
};

/** 5급 강의 - 교수별 이벤트 배너 API */
export const getProfessorSideBanner = async (professorId) => {
  return await useAxios.get(`/professor/side-banner/${professorId}`);
};

/** 5급 강의 - 교수별 교수 소개 API */
export const getProfessorIntroduction = async (professorId) => {
  return await useAxios.get(`/professor/introduction/${professorId}`);
};

/** 5급 강의 - 교수별 커리큘럼 API */
export const getProfessorCurriculum = async (professorId) => {
  return await useAxios.get(`/professor/curriculum/${professorId}`);
};

/** 5급 강의 - 교수별 수강후기 페이징 정보 API */
export const getReviewPageInfo = async (professorId, keyword, lectureId) => {
  return await useAxios.get(
    `/professor/review/page/info/${professorId}?keyword=${keyword}&lectureId=${lectureId}`,
  );
};

/** 5급 강의 - 교수별 수강후기 페이징 데이터 리스트 API */
export const getReviewList = async (professorId, page, keyword, lectureId) => {
  return await useAxios.get(
    `/professor/review/list/${professorId}?page=${page}&keyword=${keyword}&lectureId=${lectureId}`,
  );
};

/** 5급 강의 - 교수별 수강후기 상세페이지 API */
export const getReview = async (reviewId) => {
  return await useAxios.get(`/professor/review/${reviewId}`);
};

/** 5급 강의 - 교수별 수강후기 글 작성 API */
export const addReview = async (data) => {
  return await useAxios.post(`/professor/review`, data);
};

/** 5급 강의 - 교수별 베스트 수강후기 API */
export const getBestReviewList = async (professorId) => {
  return await useAxios.get(`/professor/review/list/best/${professorId}`);
};

/** 내 수강후기 게시글 수정 API */
export const modifyReview = async (id, data) => {
  return await useAxios.put(`/professor/review/${id}`, data);
};

/** 내 수강후기 게시글 삭제 API */
export const deleteReview = async (id) => {
  return await useAxios.delete(`/professor/review/${id}`);
};

/** 5급 강의 - 교수별 개설 강좌(단과) API */
export const getLectureListByProfessor = async (professorId) => {
  return await useAxios.get(`/lecture/professor/${professorId}`);
};

/** 5급 강의 - 교수별 개설 강좌(패키지) API */
export const getPassList = async (professorId) => {
  return await useAxios.get(`/pass/list?professorId=${professorId}`);
};

/** 5급 강의 - 강좌 상세페이지 API */
export const getLectureInfoById = async (lectureId) => {
  return await useAxios.get(`/lecture/info/${lectureId}`);
};

/** 5급 강의 - 교수별 패스 안에 담긴 강좌 리스트 API */
export const getPassDetail = async (id) => {
  return await useAxios.get(`/pass/${id}`);
};

/** 5급 강의 - 교수별 학습질문 전송 API */
export const addProfessorQna = async (data) => {
  return await useAxios.post('/professor/qna', data);
};

/** 5급 강의 - 교수별 학습질문 데이터 페이징 정보 APIx */
export const getProfessorQnaPageInfo = async (professorId, category, keyword) => {
  return await useAxios.get(
    `/professor/qna/page/info?professorId=${professorId}&category=${category}&keyword=${keyword}`,
  );
};

/** 5급 강의 - 교수별 학습질문 데이터 API */
export const getProfessorQnaList = async (professorId, category, keyword, page) => {
  return await useAxios.get(
    `/professor/qna/list/${professorId}?category=${category}&keyword=${keyword}&page=${page}`,
  );
};

/** 5급 강의 - 교수별 학습질문 상세페이지 API */
export const getProfessorQna = async (qnaId) => {
  return await useAxios.get(`/professor/qna/${qnaId}`);
};

/** 5급 강의 - 교수별 학습질문 파일 다운로드 API */
export const downloadQuestion = async (id, fileTitle) => {
  return await useDownloadAxios
    .get(`/professor/qna/download/${id}`, { responseType: 'blob' })
    .then((res) => {
      let filename = `${fileTitle}`.replace(/(\r\n\t|\n|\r\t)/gm, '');
      fileDownload(res.data, filename);
    });
};

/** 내 학습질문 게시글 수정 API */
export const modifyProfessorQna = async (id, data) => {
  return await useAxios.put(`/professor/qna/${id}`, data);
};

/** 내 학습질문 게시글 삭제 API */
export const deleteProfessorQna = async (id) => {
  return await useAxios.delete(`/professor/qna/${id}`);
};

/** 5급 강의 - 교수별 자료실 페이징 정보 API */
export const getReferencePageInfo = async (professorId, category, keyword) => {
  return await useAxios.get(
    `/professor/reference/page/info/${professorId}?category=${category}&keyword=${keyword}`,
  );
};

/** 5급 강의 - 교수별 자료실 페이징 데이터 리스트 API */
export const getProfessorReferenceList = async (professorId, page, keyword, category) => {
  return await useAxios.get(
    `/professor/reference/list/${professorId}?page=${page}&keyword=${keyword}&category=${category}`,
  );
};

/** 5급 강의 - 교수별 자료실 상세페이지 API */
export const getReference = async (referenceId) => {
  return await useAxios.get(`/professor/reference/${referenceId}`);
};

/** 5급 강의 - 자료실 파일 다운로드 API */
export const downloadReference = async (id, fileTitle) => {
  return await useDownloadAxios
    .get(`/professor/reference/download/${id}`, { responseType: 'blob' })
    .then((res) => {
      let filename = `${fileTitle}`.replace(/(\r\n\t|\n|\r\t)/gm, '');
      fileDownload(res.data, filename);
    });
};

/** 5급 강의 - 교수별 자료실 상단 고정 게시글 API */
export const getTopFixProfessorReferenceList = async (professorId) => {
  return await useAxios.get(`/professor/reference/list/top/${professorId}`);
};

/** 5급 강의 - 교수별 공지사항 페이징 정보 API */
export const getProfessorNoticePageInfo = async (professorId, keyword, category) => {
  return await useAxios.get(
    `/professor/notice/page/info/${professorId}?keyword=${keyword}&category=${category}`,
  );
};

/** 5급 강의 - 교수별 공지사항 페이징 데이터 리스트 API */
export const getProfessorNoticeList = async (professorId, page, category, keyword) => {
  return await useAxios.get(
    `/professor/notice/list/${professorId}?page=${page}&category=${category}&keyword=${keyword}`,
  );
};

/** 5급 강의 - 교수별 공지사항 상세페이지 API */
export const getProfessorNotice = async (noticeId) => {
  return await useAxios.get(`/professor/notice/${noticeId}`);
};

/** 5급 강의 - 공지사항 파일 다운로드 API */
export const downloadNotice = async (id, fileTitle) => {
  return await useDownloadAxios
    .get(`/professor/notice/download/${id}`, { responseType: 'blob' })
    .then((res) => {
      let filename = `${fileTitle}`.replace(/(\r\n\t|\n|\r\t)/gm, '');
      fileDownload(res.data, filename);
    });
};

/** 5급 강의 - 교수별 공지사항 상단 고정 게시글 API */
export const getTopFixProfessorNoticeList = async (professorId) => {
  return await useAxios.get(`/professor/notice/list/top/${professorId}`);
};
