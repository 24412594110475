import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

export const AlarmContainer = styled.div`
  height: 570px;
  overflow-y: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  & > div {
    clear: both;
    margin: 14px 20px;
    cursor: pointer;

    :nth-child(1) {
      ${FONT_STYLES.P_L}
      font-size: 36px;
      margin-bottom: 22px;
    }
    :nth-child(2) {
      & > button {
        ${FONT_STYLES.P_M}
        font-size: 18px;
        margin-right: 10px;
      }
    }
    :nth-child(3) {
    }

    :nth-child(4) {
      display: flex;
      position: fixed;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0);

      & > svg {
        margin-right: 41px;
        cursor: pointer;
      }
    }
  }
`;

export const Alarm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 23px 27px;
  margin: 14px 0;
  background-color: ${COLOR.BLUE_5};
  border-radius: 7px;
  cursor: pointer;

  & > p {
    :nth-child(1) {
      width: 5%;
    }
    :nth-child(2) {
      width: 100%;
    }
    :nth-child(3) {
      text-align: right;
      width: 20%;
    }
  }
`;
