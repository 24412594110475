import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const CardContainer = styled.div`
  position: relative;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 263px;
  height: 360px;
  cursor: pointer;
`;

const ContentBox = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 131px;
  background-color: rgba(0, 0, 0, 55%);
  color: ${COLOR.GRAY_0};
  padding: 18px 27px 22px 27px;

  & svg {
    display: none;
    position: absolute;
    bottom: 17px;
    right: 25px;
    :hover {
      cursor: pointer;
    }
  }
`;

const Title = styled.p`
  ${FONT_STYLES.N_B}
  font-size: 18px;
  line-height: 33px;
  white-space: pre;
`;

const Info = styled.p`
  ${FONT_STYLES.P_R}
  font-size: 15px;
  padding-top: 15px;
`;

export { CardContainer, ContentBox, Title, Info };
