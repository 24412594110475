import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

const LecturePageContainer = styled.div`
  ${({ theme }) => theme.container.mainContainer}
`;

const LectureHomePageContainer = styled.div`
  ${({ theme }) => theme.container.mainContainer};
`;

const LectureHomeBanner = styled.div`
  width: 100%;
  height: 551px;
  display: flex;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(${({ backgroundImg }) => backgroundImg});
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;

  div {
    text-align: center;
    color: ${COLOR.GRAY_0};
  }

  p:nth-child(1) {
    ${FONT_STYLES.P_B}
    font-size: 52px;
    padding-bottom: 27px;
  }
  p:nth-child(2) {
    ${FONT_STYLES.P_M}
    font-size: 20px;
  }
`;

const LectureHomeContents = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  margin-top: 40px;
  margin-bottom: 304px;
`;

const LectureHomeAdBanner = styled.div`
  width: 1312px;
  height: 165px;
  margin: 0 auto;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 22px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  p:nth-child(1) {
    ${FONT_STYLES.P_M}
    font-size: 18px;
    line-height: 24px;
    color: ${COLOR.GRAY_0};
    padding-bottom: 17px;
  }
  p:nth-child(2) {
    ${FONT_STYLES.P_EB}
    font-weight: 900;
    font-size: 32px;
    color: #aaa3ff;
  }
`;

const ProfessorContents = styled.div`
  width: 1312px;
  display: flex;
  padding-top: 53px;
  margin: 0 auto;
`;

const ProfessorCardBox = styled.div`
  width: 1080px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 64px;
`;

export {
  LecturePageContainer,
  LectureHomePageContainer,
  LectureHomeBanner,
  LectureHomeContents,
  LectureHomeAdBanner,
  ProfessorCardBox,
  ProfessorContents,
};
