import React, { useState } from 'react';
import { Button } from '../../components/atoms/Button';
import { TabTitle } from '../../components/organisms/LecturePageElements/Lectures/style';
import COLOR from '../../styles/common/color';
import { ButtonContainer } from '../QuestionDetail/style';
import {
  NoticeDetailContainer,
  NoticeDetailBox,
  DetailTitle,
  Info,
  Attachments,
  HorizonLine,
} from './style';
import { useNavigate, useParams } from 'react-router-dom';
import ToastViewer from '../../components/atoms/ToastTextViewer';

import { SpinLoader, typeList } from '../../components/atoms/SpinLoader';
import { useChangeMonthNumToEn } from '../../hooks/useChangeMonthNumToEn';
import { useQuery } from 'react-query';
import { getProfessorNotice, downloadNotice } from '../../axiosManage/Professor';
import useHandleError from '../../hooks/useHandleError';

const NoticeDetailPage = () => {
  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const { id } = useParams();

  const [noticeDetail, setNoticeDetail] = useState([]);
  const { status: noticeDetailStatus } = useQuery(
    ['getProfessorNotice', id],
    () => getProfessorNotice(id),
    {
      retry: 0,
      enabled: !!id,
      onSuccess: (data) => {
        if (data.data.notice !== undefined) {
          setNoticeDetail(data.data.notice);
        }
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const clickDownloadNotice = (filename) => {
    downloadNotice(id, filename);
  };

  return (
    <NoticeDetailContainer>
      <TabTitle>공지사항</TabTitle>
      <HorizonLine />
      <NoticeDetailBox>
        {noticeDetail !== undefined ? (
          <>
            <DetailTitle>{noticeDetail.title}</DetailTitle>
            <Info>
              <div>
                <p>{noticeDetail.createdAt?.slice(8, 10)}</p>
                <p>{useChangeMonthNumToEn(noticeDetail.createdAt?.slice(5, 7))}</p>
                <p>{noticeDetail.createdAt?.slice(0, 4)}</p>
              </div>
              <div>
                <p>조회</p>
                <p>{noticeDetail.views}</p>
              </div>
            </Info>
            <ToastViewer content={noticeDetail.content} />
            {noticeDetail.isExistFile ? (
              <Attachments>
                <span>첨부 파일</span>
                <a onClick={() => clickDownloadNotice(noticeDetail.fileName)}>
                  <span>{noticeDetail.fileName}</span>
                </a>
              </Attachments>
            ) : null}
          </>
        ) : (
          <SpinLoader type={typeList[2].prop} color={'#000'} width={1131} height={459} />
        )}
      </NoticeDetailBox>
      <ButtonContainer>
        <Button width={124} height={48} bgColor={COLOR.BLUE_4} onClickFunc={() => navigate(-1)}>
          목록으로
        </Button>
      </ButtonContainer>
    </NoticeDetailContainer>
  );
};

export default NoticeDetailPage;
