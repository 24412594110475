import styled from 'styled-components';
import { FONT_STYLES } from '../../styles/common/font-styles';

export const QuestionCreatePageContainer = styled.div`
  ${({ theme }) => theme.container.mainContainer}
  padding-bottom: 136px;
`;

export const ContentContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-top: 88px;

  @media screen and (max-width: 1700px) {
    padding-top: 88px;
  }
`;

export const Title = styled.p`
  ${FONT_STYLES.P_B}
  font-size: 40px;
  padding-bottom: 36px;
`;

export const SubTitle = styled.p`
  ${FONT_STYLES.P_M}
  font-size: 20px;
`;

export const FormContainer = styled.div`
  width: 100%;
  padding-top: 57px;

  & > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 31px;

    & > select {
      width: 20%;
      margin-right: 10px;
    }
    & > input {
      width: 60%;
      margin-right: 10px;
    }

    & > div {
      width: 20%;
      ${FONT_STYLES.P_M}
      color: #716b6b;
      width: 313px;
      height: 58px;
      text-align: left;
      font-size: 18px;
      border: none;
      padding: 19px 43px;
      border-radius: 34px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
  }

  & > div:nth-child(3) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;

    button:nth-child(1) {
      margin-right: 13px;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-left: auto;
`;
