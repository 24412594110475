import React, { useState, useEffect } from 'react';
import { StyledFilter, Drawer, FilterMenuBox } from './style';

import { ReactComponent as FilterIcon } from '../../../assets/icons/icon-Filter.svg';
import { ReactComponent as FilterIconSelected } from '../../../assets/icons/icon-Filter-Selected.svg';
import { ReactComponent as FilterPlusBtn } from '../../../assets/icons/icon-FilterPlusBtn.svg';
import { ReactComponent as FilterMinusBtn } from '../../../assets/icons/icon-FilterMinusBtn.svg';
import { ReactComponent as LineWhite } from '../../../assets/icons/icon-LineWhite.svg';
import ArrowLeftWhite from '../../../assets/icons/icon-ArrowLeftWhite.png';
import { Button } from '../../atoms/Button/index';

// 게시글 필터링 위한 전역 상태변수 선언
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  selectedNoticeFilterMenuAtom,
  selectedNoticeFilterMenuNumAtom,
  selectedErrataFilterMenuAtom,
  selectedErrataFilterMenuNumAtom,
  selectedProfessorQuestionFilterMenuNumAtom,
  selectedProfessorQuestionFilterMenuAtom,
  selectedProfessorReviewFilterMenuNumAtom,
  selectedProfessorReviewFilterMenuAtom,
  selectedProfessorReferenceFilterMenuNumAtom,
  selectedProfessorReferenceFilterMenuAtom,
  selectedProfessorNoticeFilterMenuNumAtom,
  selectedProfessorNoticeFilterMenuAtom,
  selectedPassNoteFilterMenuNumAtom,
  selectedPassNoteFilterMenuAtom,
  selectedStudyTipFilterMenuNumAtom,
  selectedStudyTipFilterMenuAtom,
} from '../../../recoil/SelectedFilterStore/SelectedFilterRecoil';
import { useNavigate } from 'react-router-dom';

/** 필터 버튼 누르면 보여줄 UI */
const FilterSelected = ({ isPlusClicked, setIsPlusClicked }) => {
  const clickPlus = (e) => {
    e.stopPropagation();
    setIsPlusClicked((isPlusClicked) => !isPlusClicked);
  };

  return (
    <>
      <FilterIconSelected />
      <p>필터</p>
      {isPlusClicked ? (
        <div onClick={clickPlus}>
          <FilterMinusBtn />
        </div>
      ) : (
        <FilterPlusBtn onClick={clickPlus} />
      )}
    </>
  );
};

/** 필터 UI 메인 */
const Filter = ({ onClickFunc, filterMenu, filterName, filterLocation, setCurrentPage }) => {
  const navigate = useNavigate();

  const [isHover, setIsHover] = useState(false);
  // 필터 옆 +, - 버튼 누름 상태
  const [isPlusClicked, setIsPlusClicked] = useState(false);
  // 강의 선택 누름 상태
  const [isLectureSelectClicked, setIsLectureSelectClicked] = useState(false);
  // 강의 선택 누르면 보여줄 UI 상태 설정
  const ClickLectureSelect = (e) => {
    e.stopPropagation();
    setIsHover(true);
    setIsLectureSelectClicked((isLectureSelectClicked) => !isLectureSelectClicked);
  };

  useEffect(() => {
    setIsPlusClicked(false);
    setIsLectureSelectClicked(false);
  }, [isHover]);

  /** 정오표 필터링 */
  const setSelectedErrataFilterRecoil = useSetRecoilState(selectedErrataFilterMenuAtom);
  const [selectedErrataFilterNumRecoil, setSelectedErrataFilterNumRecoil] = useRecoilState(
    selectedErrataFilterMenuNumAtom,
  );

  /** 공지사항 필터링 */
  const setSelectedNoticeFilterRecoil = useSetRecoilState(selectedNoticeFilterMenuAtom);
  const [selectedNoticeFilterNumRecoil, setSelectedNoticeFilterNumRecoil] = useRecoilState(
    selectedNoticeFilterMenuNumAtom,
  );

  /** 5급 강의 교수 학습질문 필터링 */
  const setSelectedProfessorQuestionFilterRecoil = useSetRecoilState(
    selectedProfessorQuestionFilterMenuAtom,
  );
  const [selectedProfessorQuestionFilterNumRecoil, setSelectedProfessorQuestionFilterNumRecoil] =
    useRecoilState(selectedProfessorQuestionFilterMenuNumAtom);

  /** 5급 강의 교수 수강후기 필터링 */
  const setSelectedProfessorReviewFilterRecoil = useSetRecoilState(
    selectedProfessorReviewFilterMenuAtom,
  );
  const [selectedProfessorReviewFilterNumRecoil, setSelectedProfessorReviewFilterNumRecoil] =
    useRecoilState(selectedProfessorReviewFilterMenuNumAtom);

  /** 5급 강의 교수 자료실 필터링 */
  const setSelectedProfessorReferenceFilterRecoil = useSetRecoilState(
    selectedProfessorReferenceFilterMenuAtom,
  );
  const [selectedProfessorReferenceFilterNumRecoil, setSelectedProfessorReferenceFilterNumRecoil] =
    useRecoilState(selectedProfessorReferenceFilterMenuNumAtom);

  /** 5급 강의 교수 공지사항 필터링 */
  const setSelectedProfessorNoticeFilterRecoil = useSetRecoilState(
    selectedProfessorNoticeFilterMenuAtom,
  );
  const [selectedProfessorNoticeFilterNumRecoil, setSelectedProfessorNoticeFilterNumRecoil] =
    useRecoilState(selectedProfessorNoticeFilterMenuNumAtom);

  /** 수험정보&팁 학습 팁 필터링 */
  const setSelectedStudyTipFilterRecoil = useSetRecoilState(selectedStudyTipFilterMenuAtom);
  const [selectedStudyTipFilterNumRecoil, setSelectedStudyTipFilterNumRecoil] = useRecoilState(
    selectedStudyTipFilterMenuNumAtom,
  );

  /** 수험정보&팁 합격후기 필터링 */
  const setSelectedPassNoteFilterRecoil = useSetRecoilState(selectedPassNoteFilterMenuAtom);
  const [selectedPassNoteFilterNumRecoil, setSelectedPassNoteFilterNumRecoil] = useRecoilState(
    selectedPassNoteFilterMenuNumAtom,
  );

  /** 아래는 공통 로직 */
  // 유저가 선택한 필터의 id를 통해 선택여부를 저장할 곳
  const [selectedFilterCategory, setSelectedFilterCategory] = useState(
    Array(filterMenu.length).fill(false),
  );
  // 뒤로가기해도 필터링 되어있게 하기 위해 전역 상태에 저장 대신 다른 탭 갔다오면 초기화 되어 있어야함
  useEffect(() => {
    if (filterLocation === '학습지원센터 정오표') {
      setSelectedErrataFilterNumRecoil(selectedFilterCategory);
    } else if (filterLocation === '학습지원센터 공지사항') {
      setSelectedNoticeFilterNumRecoil(selectedFilterCategory);
    } else if (filterLocation === '교수 학습질문') {
      setSelectedProfessorQuestionFilterNumRecoil(selectedFilterCategory);
    } else if (filterLocation === '교수 수강후기') {
      setSelectedProfessorReviewFilterNumRecoil(selectedFilterCategory);
    } else if (filterLocation === '교수 자료실') {
      setSelectedProfessorReferenceFilterNumRecoil(selectedFilterCategory);
    } else if (filterLocation === '교수 공지사항') {
      setSelectedProfessorNoticeFilterNumRecoil(selectedFilterCategory);
    } else if (filterLocation === '수험정보&팁 합격후기') {
      setSelectedPassNoteFilterNumRecoil(selectedFilterCategory);
    } else if (filterLocation === '수험정보&팁 학습 팁') {
      setSelectedStudyTipFilterNumRecoil(selectedFilterCategory);
    }
  }, [selectedFilterCategory]);

  // 상세페이지에서 뒤로가기 하면 필터링 초기화 X
  useEffect(() => {
    if (filterLocation === '학습지원센터 정오표') {
      setSelectedFilterCategory(selectedErrataFilterNumRecoil);
    } else if (filterLocation === '학습지원센터 공지사항') {
      setSelectedFilterCategory(selectedNoticeFilterNumRecoil);
    } else if (filterLocation === '교수 학습질문') {
      setSelectedFilterCategory(selectedProfessorQuestionFilterNumRecoil);
    } else if (filterLocation === '교수 수강후기') {
      setSelectedFilterCategory(selectedProfessorReviewFilterNumRecoil);
    } else if (filterLocation === '교수 자료실') {
      setSelectedFilterCategory(selectedProfessorReferenceFilterNumRecoil);
    } else if (filterLocation === '교수 공지사항') {
      setSelectedFilterCategory(selectedProfessorNoticeFilterNumRecoil);
    } else if (filterLocation === '수험정보&팁 합격후기') {
      setSelectedFilterCategory(selectedPassNoteFilterNumRecoil);
    } else if (filterLocation === '수험정보&팁 학습 팁') {
      setSelectedFilterCategory(selectedStudyTipFilterNumRecoil);
    }
  }, [navigate]);

  const onClickCheck = (id) => {
    const newArr = [...selectedFilterCategory];
    newArr[id] = !selectedFilterCategory[id];
    setSelectedFilterCategory(newArr);
    // 필터링 적용하면 1페이지부터 보이게 하기
    setCurrentPage(1);
  };

  useEffect(() => {
    // 현재 위치가 학습지원센터 - 공지사항 탭
    if (filterLocation === '학습지원센터 공지사항') {
      const replaceFilteredId = [];
      selectedFilterCategory.forEach((category, i) => {
        if (category === true) {
          if (i === 0) {
            replaceFilteredId.push('강의');
          } else if (i === 1) {
            replaceFilteredId.push('교재');
          } else if (i === 2) {
            replaceFilteredId.push('이벤트');
          } else if (i === 3) {
            replaceFilteredId.push('소식');
          } else if (i === 4) {
            replaceFilteredId.push('학습');
          } else if (i === 5) {
            replaceFilteredId.push('모집');
          } else if (i === 6) {
            replaceFilteredId.push('개강');
          } else if (i === 7) {
            replaceFilteredId.push('일반');
          } else if (i === 8) {
            replaceFilteredId.push('운영');
          } else if (i === 9) {
            replaceFilteredId.push('기타');
          }
        }
      });
      setSelectedNoticeFilterRecoil(replaceFilteredId);
    } // 현재 위치가 학습지원센터 - 정오표 탭
    else if (filterLocation === '학습지원센터 정오표') {
      const replaceFilteredId = [];
      selectedFilterCategory.forEach((category, i) => {
        if (category === true) {
          if (i === 0) {
            replaceFilteredId.push('언어논리');
          } else if (i === 1) {
            replaceFilteredId.push('자료해석');
          } else if (i === 2) {
            replaceFilteredId.push('상황판단');
          } else if (i === 3) {
            replaceFilteredId.push('공통');
          }
        }
      });
      setSelectedErrataFilterRecoil(replaceFilteredId);
    } // 현재 위치가 5급 강의 - 교수 학습질문 탭
    else if (filterLocation === '교수 학습질문') {
      const replaceFilteredId = [];
      selectedFilterCategory.forEach((category, i) => {
        if (category === true) {
          if (i === 0) {
            replaceFilteredId.push('LECTURE');
          } else if (i === 1) {
            replaceFilteredId.push('BOOK');
          } else if (i === 2) {
            replaceFilteredId.push('CURRICULUM');
          } else if (i === 3) {
            replaceFilteredId.push('ETC');
          }
        }
      });
      setSelectedProfessorQuestionFilterRecoil(replaceFilteredId);
    } // 현재 위치가 5급 강의 - 교수 수강후기 탭
    else if (filterLocation === '교수 수강후기') {
      const replaceFilteredId = [];
      selectedFilterCategory.forEach((category, i) => {
        if (category === true) {
          if (i === 0) {
            replaceFilteredId.push('1');
          } else if (i === 1) {
            replaceFilteredId.push('2');
          } else if (i === 2) {
            replaceFilteredId.push('3');
          }
        }
      });
      setSelectedProfessorReviewFilterRecoil(replaceFilteredId);
    } // 현재 위치가 5급 강의 - 교수 자료실 탭
    else if (filterLocation === '교수 자료실') {
      const replaceFilteredId = [];
      selectedFilterCategory.forEach((category, i) => {
        if (category === true) {
          if (i === 0) {
            replaceFilteredId.push('강의');
          } else if (i === 1) {
            replaceFilteredId.push('교재');
          }
        }
      });
      setSelectedProfessorReferenceFilterRecoil(replaceFilteredId);
    } // 현재 위치가 5급 강의 - 교수 공지사항 탭
    else if (filterLocation === '교수 공지사항') {
      const replaceFilteredId = [];
      selectedFilterCategory.forEach((category, i) => {
        if (category === true) {
          if (i === 0) {
            replaceFilteredId.push('강의');
          } else if (i === 1) {
            replaceFilteredId.push('이벤트');
          }
        }
      });
      setSelectedProfessorNoticeFilterRecoil(replaceFilteredId);
    } // 현재 위치가 수험정보&팁 - 학습 팁 탭
    else if (filterLocation === '수험정보&팁 학습 팁') {
      const replaceFilteredId = [];
      selectedFilterCategory.forEach((category, i) => {
        if (category === true) {
          if (i === 0) {
            replaceFilteredId.push('5급공채');
          } else if (i === 1) {
            replaceFilteredId.push('국립외교원');
          } else if (i === 2) {
            replaceFilteredId.push('지역인재');
          } else if (i === 3) {
            replaceFilteredId.push('7급공채');
          } else if (i === 4) {
            replaceFilteredId.push('공통');
          }
        }
      });
      setSelectedStudyTipFilterRecoil(replaceFilteredId);
    } // 현재 위치가 수험정보&팁 - 합격후기 탭
    else if (filterLocation === '수험정보&팁 합격후기') {
      const replaceFilteredId = [];
      selectedFilterCategory.forEach((category, i) => {
        if (category === true) {
          if (i === 0) {
            replaceFilteredId.push('5급공채');
          } else if (i === 1) {
            replaceFilteredId.push('국립외교원');
          } else if (i === 2) {
            replaceFilteredId.push('지역인재');
          } else if (i === 3) {
            replaceFilteredId.push('7급공채');
          }
        }
      });
      setSelectedPassNoteFilterRecoil(replaceFilteredId);
    }
  }, [selectedFilterCategory]);

  // 필터 초기화 버튼 기능
  const clickResetFilter = (e) => {
    e.stopPropagation();
    setSelectedFilterCategory([]);
    setCurrentPage(1);
  };

  return (
    <>
      <StyledFilter
        onClick={onClickFunc}
        isHover={isHover}
        isPlusClicked={isPlusClicked}
        onMouseOver={() => setIsHover(true)}
        onMouseOut={() => setIsHover(false)}
      >
        {/* 강의 선택 누르면 보여줄 UI */}
        <FilterMenuBox
          isHover={isHover}
          isPlusClicked={isPlusClicked}
          isLectureSelectClicked={isLectureSelectClicked}
          onMouseOver={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
        >
          {filterMenu.map((list) => (
            <div key={list.id}>
              <li
                onClick={() => onClickCheck(list.id)}
                className={selectedFilterCategory[list.id] ? 'selected' : ''}
              >
                {list.category}
              </li>
            </div>
          ))}
        </FilterMenuBox>
        <div>
          {isHover ? (
            <FilterSelected isPlusClicked={isPlusClicked} setIsPlusClicked={setIsPlusClicked} />
          ) : (
            <FilterIcon />
          )}
        </div>
        <Drawer isHover={isHover} isPlusClicked={isPlusClicked}>
          <div>
            <img src={ArrowLeftWhite} />
            <p onClick={ClickLectureSelect}>{filterName} 선택</p>
            <LineWhite />
          </div>
          <Button width={162} height={35} fontSize={14} onClickFunc={clickResetFilter}>
            필터 초기화
          </Button>
        </Drawer>
      </StyledFilter>
    </>
  );
};

export default Filter;
