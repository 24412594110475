import React from 'react';
// 배너 스타일
import { BannerContainer, BannerTextContainer } from './style';
// 배너 이미지 에셋
import MyPageBanner from '../../../../assets/images/image-mypage-banner.png';

function Banner() {
  return (
    <BannerContainer backgroundImg={MyPageBanner}>
      <BannerTextContainer>
        <p>마이페이지</p>
        <p>합격을 위한 발걸음</p>
      </BannerTextContainer>
    </BannerContainer>
  );
}

export default Banner;
