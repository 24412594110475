import React from 'react';
import { Content, ContentContainer, Rank } from './style';

const BestBookContent = ({ rank, subtitle, title, img, onClickFunc }) => {
  return (
    <ContentContainer onClick={onClickFunc}>
      <Rank>{rank}</Rank>
      <Content>
        <p>{title}</p>
      </Content>
      <img src={img} width="62px" height="76px" />
    </ContentContainer>
  );
};

export default BestBookContent;
