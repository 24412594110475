import React, { useState, useEffect } from 'react';
import {
  OrderBasketPageContainer,
  OrderBasketPageTitle,
  HorizonLine,
  ContentBox,
  PayInfoBox,
  ButtonBox,
} from './style';
import COLOR from '../../styles/common/color';
import moment from 'moment';

import StepIcon1 from '../../assets/icons/icon-Basket.png';
import StepIcon2 from '../../assets/icons/icon-join.png';
import StepIcon3Selected from '../../assets/icons/icon-complete-selected.png';

import { ReactComponent as CompleteCheckIcon } from '../../assets/icons/icon-register-complete.svg';

import ProgressBox from '../../components/molecules/ProgressBox';
import { Button } from '../../components/atoms/Button';
import { useNavigate, Navigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';
import { myInfoAtom } from '../../recoil/MyPageStore/MyPageRecoil';
import { paymentBilling } from '../../axiosManage/OrderBasket';

function MobileOrderCompletePage() {
  const navigate = useNavigate();
  const myInfo = useRecoilValue(myInfoAtom);
  const isLogin = useRecoilValue(isLoginAtom);

  if (isLogin === false) {
    alert('잘못된 접근입니다.');
    return <Navigate to="/" />;
  }

  let customerUid = new URL(window.location.href).searchParams.get('customer_uid');
  let price = new URL(window.location.href).searchParams.get('price');
  let resume = new URL(window.location.href).searchParams.get('resume');

  const [isPaySuccess, setIsPaySuccess] = useState(false);

  useEffect(() => {
    paymentBilling({ customerUid: customerUid })
      .then((res) => {
        setIsPaySuccess(true);
        if (resume == 'true') {
          alert('구독종료가 철회되었습니다.');
          navigate('/mypage/mylecture');
        }
        else {
          alert('결제가 완료되었습니다.');
        }
      })
      .catch((error) => {
        alert('결제에 실패하였습니다.');
        if (resume == 'true') {
          navigate('/mypage/mylecture');
        }
      });
  }, []);

  // 내 강의실로 이동
  const goToMyLecturePage = () => {
    navigate('/mypage/mylecture');
  };

  if (resume == 'true') {
    return <></>
  }
  return (
    <OrderBasketPageContainer>
      <OrderBasketPageTitle>
        <span>{myInfo.name}</span>님 장바구니
      </OrderBasketPageTitle>
      <HorizonLine />
      <ProgressBox
        stepIcon1={StepIcon1}
        stepIcon2={StepIcon2}
        stepIcon3={StepIcon3Selected}
        stepText1={`장바구니`}
        stepText2={`결제정보 입력`}
        stepText3={`결제 완료`}
      >
        <ContentBox>
          <CompleteCheckIcon />
          <div>결제가 완료되었습니다.</div>
          <PayInfoBox>
            {isPaySuccess && (
              <>
                <div>
                  <p>결제 일시</p>
                  <p>{moment(new Date()).format('YYYY-MM-DD HH:MM')}</p>
                </div>
                <div>
                  <p>주문 상품</p>
                  <p>랩스탠다드 정기 패스</p>
                </div>
                <div>
                  <p>결제 금액</p>
                  <p>{price}</p>
                </div>
                <div>
                  <p>결제 방법</p>
                  <p>카카오페이</p>
                </div>
              </>
            )}
          </PayInfoBox>
        </ContentBox>
      </ProgressBox>
      <ButtonBox>
        <div>
          <Button width={202} height={62} onClickFunc={() => navigate('/mypage/myorder')}>
            결제 내역 확인
          </Button>
        </div>
        <div>
          <Button width={202} height={62} bgColor={COLOR.BLUE_4} onClickFunc={goToMyLecturePage}>
            내 강의실로 이동
          </Button>
        </div>
      </ButtonBox>
    </OrderBasketPageContainer>
  );
}

export default MobileOrderCompletePage;
