import React, { useState, useEffect, useRef } from 'react';
import Input from '../../components/atoms/Input';
import { Button, CancelButton } from '../../components/atoms/Button/index';
import ToastEditor from '../../components/atoms/ToastTextEditor';
import {
  ButtonContainer,
  ContentContainer,
  FormContainer,
  QuestionCreatePageContainer,
  SubTitle,
  Title,
} from './style';
import Select from '../../components/atoms/Select';
import { BasicCheckBox } from '../../components/atoms/CheckBox';
import COLOR from '../../styles/common/color';

import { useMutation } from 'react-query';
import { addProfessorQna } from '../../axiosManage/Professor';
import useHandleError from '../../hooks/useHandleError';
import { useNavigate, Navigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';
import { myInfoAtom } from '../../recoil/MyPageStore/MyPageRecoil';

const SELECT_OPTIONS = [
  { value: '', optionName: '구분' },
  { value: 'LECTURE', optionName: '강의' },
  { value: 'BOOK', optionName: '교재' },
  { value: 'CURRICULUM', optionName: '커리큘럼' },
  { value: 'ETC', optionName: '기타' },
];

const QuestionCreatePage = ({ professorId }) => {
  const myInfo = useRecoilValue(myInfoAtom);
  const isLogin = useRecoilValue(isLoginAtom);

  if (isLogin === false) {
    alert('로그인을 해주세요.');
    return <Navigate to="/login" />;
  }

  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const editorRef = useRef();

  const [ProfessorId, setProfessorId] = useState(1);
  useEffect(() => {
    setProfessorId(professorId);
  }, [professorId]);

  // 유저가 입력한 서버로 전송할 데이터(제목, 내용, 카테고리)
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('구분');

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  // 비밀 글 설정 여부
  const [isSecret, setIsSecret] = useState(false);
  const checkSecret = () => {
    setIsSecret((isSecret) => !isSecret);
  };

  const inquirySubmit = () => {
    if (
      title !== '' &&
      !editorRef.current
        ?.getInstance()
        .getHTML()
        .includes('위에 구분을 먼저 선택 후 글을 작성해주시기 바랍니다!') &&
      category !== '구분'
    ) {
      addProfessorQna({
        professorId: ProfessorId,
        title: title,
        content: editorRef.current?.getInstance().getHTML(),
        category: category,
        isSecret: isSecret,
      })
        .then((res) => {
          if (res.data.success === true) {
            alert('학습질문 등록이 완료되었습니다!');
            navigate(`/lecture/${professorId}/question`);
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else {
      alert('제목, 내용, 카테고리를 모두 입력해주세요!');
    }
  };

  return (
    <QuestionCreatePageContainer>
      <ContentContainer>
        <Title>학습질문</Title>
        <SubTitle>교수님께 질문하기</SubTitle>
        <FormContainer>
          <div>
            <Select
              width={313}
              height={58}
              selectOptions={SELECT_OPTIONS}
              onChangeFunc={handleCategoryChange}
            />
            <Input
              width={645}
              height={58}
              placeholder="제목을 입력하세요."
              onChangeFunc={handleTitleChange}
            />
            {isLogin ? (
              <div>이름: {myInfo.name}</div>
            ) : (
              <Input width={313} height={58} placeholder="이름을 입력하세요." />
            )}
          </div>
          <ToastEditor editorRef={editorRef} />
          <ButtonContainer>
            <div>
              <BasicCheckBox isChecked={isSecret} onClickFunc={checkSecret} />
              <span>비밀글 설정</span>
            </div>
            <div>
              <Button width={124} height={48} bgColor={COLOR.BLUE_4} onClickFunc={inquirySubmit}>
                제출
              </Button>
            </div>
          </ButtonContainer>
        </FormContainer>
      </ContentContainer>
    </QuestionCreatePageContainer>
  );
};

export default QuestionCreatePage;
