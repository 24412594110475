import styled from 'styled-components';
import COLOR from '../../../styles/common/color';

const SliderContainer = styled.div`
  width: 100%;

  .slick-track {
    padding-top: 30px;
  }

  .slick-dot li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
  }

  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
  }

  .slick-dots li button:before {
    font-size: 15px;
    line-height: 20px;
    position: absolute;
    top: -20px;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 1;
    color: ${COLOR.GRAY_3};
  }
  .slick-dots li.slick-active button:before {
    font-size: 18px;
    opacity: 1;
    color: ${COLOR.BLUE_3};
  }

  .slick-arrow::before {
    display: none;
  }
`;

const ReviewSlide = styled.div`
  display: flex;
`;

const ReviewSlideControllerBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  div:nth-child(1) {
    width: ${({ activeDot1 }) => (activeDot1 ? '16px' : '')};
    height: ${({ activeDot1 }) => (activeDot1 ? '16px' : '')};
    background-color: ${({ activeDot1 }) => (activeDot1 ? COLOR.BLUE_3 : '')};
  }

  div:nth-child(2) {
    width: ${({ activeDot2 }) => (activeDot2 ? '16px' : '')};
    height: ${({ activeDot2 }) => (activeDot2 ? '16px' : '')};
    background-color: ${({ activeDot2 }) => (activeDot2 ? COLOR.BLUE_3 : '')};
  }
`;

const ReviewDot = styled.div`
  width: 13px;
  height: 13px;
  background-color: ${COLOR.GRAY_3};
  border-radius: 50%;
  margin-right: 8px;
  :hover {
    cursor: pointer;
  }
`;

// 슬라이드 하나
const ReviewBox = styled.div`
  display: flex;
  width: 1312px;
  height: 370px;

  & > div {
    display: flex;
  }

  // 짝수번째의 카드 색상 바꾸기
  & div > div:nth-child(2n) {
    background-color: ${COLOR.GRAY_0};

    & div:nth-child(1) {
      border-bottom: 2px solid ${COLOR.BLACK_0};
    }

    & > div p:nth-child(1) {
      color: ${COLOR.BLACK_0};
    }

    & > div p:nth-child(2) {
      color: ${COLOR.BLUE_3};
    }

    & > div:nth-child(2) {
      color: ${COLOR.BLACK_0};
    }

    & > p {
      color: ${COLOR.BLUE_3};
    }
  }
`;

export { SliderContainer, ReviewSlide, ReviewBox, ReviewDot, ReviewSlideControllerBox };
