import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

export const ProfessorNavContainer = styled.div`
  width: 176px;
`;

export const Line = styled.div`
  width: 27px;
  background-color: #707070;
  border: 1px solid #707070;
  margin: 0px auto 19px auto;
`;

export const Category = styled.li`
  ${FONT_STYLES.P_SB}
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;

  :hover {
    cursor: pointer;
  }
`;

export const ProfessorName = styled.li`
  ${FONT_STYLES.P_R}
  font-size: 15px;
  margin-top: 32px;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

export const ProfessorNavContent = styled.div`
  height: 350px;
  ul:not(:last-child) {
    border-bottom: 1px solid ${COLOR.GRAY_9};
  }

  ul > ul {
    padding: 28px 0 31px 0;
  }
`;
