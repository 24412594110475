import { css } from 'styled-components';

// 전체 컨테이너 정의
const mainTheme = {
  container: {
    mainContainer: css`
      // width: 100%;
      // height: 100%;
      //padding-top: 111px;
    `,
    sidePadding: css`
      padding: 0px 305px 0px 304px;
      @media screen and (max-width: 1700px) and (min-width: 1400px) {
        padding: 0 100px;
      }
    `,
  },
};

const theme = {
  main: mainTheme,
};

export default theme;
