import { useAxios, useDownloadAxios } from '../../hooks/useAxios';

/** 장바구니 - 결제 요청 */
export const createOrder = async (data) => {
  return await useAxios.post(`/payment/order`, data);
};

/** 장바구니 - 결제 검증 */
export const paymentCallback = async (data) => {
  return await useAxios.post(`/payment/callback`, data);
};

/** 장바구니 - 쿠폰 검증 */
export const checkCoupon = async (code) => {
  return await useAxios.get(`/payment/coupon?code=${code}`);
};

/** 결제 - 결제 성공실패여부 서버로 전송 */
export const checkPaymentSuccess = async (imp_uid, merchant_uid) => {
  return await useAxios.get(`/payment/fail?merchantUid=${merchant_uid}&impUid=${imp_uid}`);
};

/** 결제 - 정기결제 */
export const paymentBilling = async (data) => {
  return await useAxios.post(`/payment/billing`, data);
};

/** 배송비 */
export const getDeliveryCharge = async () => {
  return await useAxios.get(`/payment/delivery-charge`);
};

/** 결제 취소 */
export const refundOrder = async (id, type) => {
  return await useAxios.get(`/payment/refund/${id}?type=${type}`);
};
