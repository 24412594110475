import React, { useState, useEffect } from 'react';

import { useQuery } from 'react-query';
import { getProfessorCurriculum } from '../../../../axiosManage/Professor';
import useHandleError from '../../../../hooks/useHandleError';
import { SpinLoader, typeList } from '../../../atoms/SpinLoader';

const CurriculumImg = ({ professorId }) => {
  const { handleError } = useHandleError();

  const [ProfessorId, setProfessorId] = useState(1);
  const [curriculum, setCurriculum] = useState([]);
  const { status: curriculumStatus } = useQuery(
    ['getProfessorCurriculum', ProfessorId],
    () => getProfessorCurriculum(ProfessorId),
    {
      retry: 0,
      onSuccess: (data) => {
        setCurriculum(data.data.professorCurriculum);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  useEffect(() => {
    setProfessorId(professorId)
  }, [professorId]);


  return (
    <>
      {curriculumStatus === 'loading' ? (
        <SpinLoader type={typeList[2].prop} color={'#000'} width={1312} height={840} />
      ) : (
        <img src={curriculum} />
      )}
    </>
  );
};

export default CurriculumImg;
