import React from 'react';
import { Content, ContentContainer, Img, Rank } from './style';

const BestLectureContent = ({ rank, name, title, smalltitle, img, onClickFunc }) => {
  return (
    <ContentContainer onClick={onClickFunc}>
      <Rank>{rank}</Rank>
      <Content>
        <div>
          <p>{smalltitle}</p>
          <p>{name}</p>
        </div>
        <p>{title}</p>
      </Content>
      <Img src={img} />
    </ContentContainer>
  );
};

export default BestLectureContent;
