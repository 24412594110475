import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LectureNav } from '../../components/molecules/LectureNav';
import Home from '../../components/organisms/LecturePageElements/Home';
import Curriculum from '../../components/organisms/LecturePageElements/Curriculum';
import Lectures from '../../components/organisms/LecturePageElements/Lectures';
import PassDetailPage from '../../pages/PassDetail/PassDetailPage';
import { LecturePageContainer } from './style';
import { Banner, ReviewMainBanner } from '../../components/organisms/LecturePageElements/Banner';
import Review from '../../components/organisms/LecturePageElements/Review';
import NowTab from '../../components/organisms/LecturePageElements/NowTab';
import Notice from '../../components/organisms/LecturePageElements/Notice';
import Question from '../../components/organisms/LecturePageElements/Question';
import Library from '../../components/organisms/LecturePageElements/Library';
import Professor from '../../components/organisms/LecturePageElements/Professor';
import ReviewCreatePage from '../ReviewCreate/ReviewCreatePage';
import QuestionCreatePage from '../QuestionCreate/QuestionCreatePage';

import PageNotFound from '../../hooks/pageNotFound';

import { useResetRecoilState } from 'recoil';
import {
  selectedProfessorQuestionFilterMenuNumAtom,
  selectedProfessorReviewFilterMenuNumAtom,
  selectedProfessorReferenceFilterMenuNumAtom,
  selectedProfessorNoticeFilterMenuNumAtom,
} from '../../recoil/SelectedFilterStore/SelectedFilterRecoil';

const LecturePage = ({ props }) => {
  const { tab, professorId } = useParams();

  const tabs = [
    'professor',
    'curriculum',
    'review',
    'reviewCreate',
    'lectures',
    'passes',
    'notice',
    'question',
    'questionCreate',
    'library',
    undefined,
  ];

  if (!tabs.includes(tab)) {
    return <PageNotFound />;
  }

  /** 다른 페이지 갔다오면 각 탭의 필터링 초기화 하는 로직 */
  const resetStudyQuestionFilter = useResetRecoilState(selectedProfessorQuestionFilterMenuNumAtom);
  const resetReviewFilter = useResetRecoilState(selectedProfessorReviewFilterMenuNumAtom);
  const resetReferenceFilter = useResetRecoilState(selectedProfessorReferenceFilterMenuNumAtom);
  const resetNoticeFilter = useResetRecoilState(selectedProfessorNoticeFilterMenuNumAtom);

  useEffect(() => {
    return () => {
      resetStudyQuestionFilter();
      resetReviewFilter();
      resetReferenceFilter();
      resetNoticeFilter();
    };
  }, [tab]);

  const renderPage = () => {
    switch (tab) {
      case 'professor':
        return <Professor professorId={professorId} />;
      case 'curriculum':
        return <Curriculum professorId={professorId} />;
      case 'review':
        return <Review professorId={professorId} />;
      case 'reviewCreate':
        return <ReviewCreatePage professorId={professorId} />;
      case 'lectures':
        return <Lectures professorId={professorId} />;
      case 'passes':
        return <PassDetailPage professorId={professorId} />;
      case 'notice':
        return <Notice professorId={professorId} />;
      case 'question':
        return <Question professorId={professorId} />;
      case 'questionCreate':
        return <QuestionCreatePage professorId={professorId} />;
      case 'library':
        return <Library professorId={professorId} />;
      default:
        return <Home professorId={professorId} />;
    }
  };

  const renderBanner = () => {
    return <Banner professorId={professorId} />;
  };

  return (
    <>
      {tab === 'passes' ? (
        <PassDetailPage professorId={professorId} />
      ) : (
        <LecturePageContainer>
          <NowTab tab={tab} professorId={professorId} />
          {renderBanner()}
          <LectureNav tab={tab} professorId={professorId} />
          {renderPage()}
        </LecturePageContainer>
      )}
    </>
  );
};

export default LecturePage;
