import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

export const StyledButton = styled.button`
  ${FONT_STYLES.P_B}
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  margin-left: ${({ marginLeft }) => `${marginLeft}px`};
  margin-right: ${({ marginRight }) => `${marginRight}px`};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : `${16}px`)};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : COLOR.GRAY_0)};
  color: ${({ bgColor }) => (bgColor ? COLOR.GRAY_0 : COLOR.BLACK_1)};
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  :hover {
    cursor: pointer;
    transition: 0.3s;
    background-color: ${({ bgColor }) => (bgColor ? COLOR.BLUE_4 : COLOR.BLUE_4)};
    color: ${({ bgColor }) => (bgColor ? COLOR.GRAY_0 : COLOR.GRAY_0)};
  }
`;

export const StyledCancelButton = styled.button`
  ${FONT_STYLES.P_B}
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  font-size: 16px;
  background-color: red;
  color: ${COLOR.GRAY_0};
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

export const StyledRegisterButton = styled.button`
  ${FONT_STYLES.P}
  font-weight: ${({ fontWeight }) => (fontWeight ? `${fontWeight}` : `${700}`)};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : `${16}px`)};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : COLOR.GRAY_0)};
  color: ${({ bgColor }) => (bgColor !== COLOR.GRAY_0 ? COLOR.GRAY_0 : COLOR.BLUE_4)};
  border-radius: 7px;
  border: 1px solid ${COLOR.BLUE_4};
`;
