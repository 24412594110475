import React, { useEffect, useState } from 'react';

function KakaoMap() {
  const { kakao } = window;
  const [checkMap, setCheckMap] = useState(true);

  useEffect(() => {
    const container = document.querySelector('.kakao-map');
    let options = {
      center: new kakao.maps.LatLng(37.47060820313192, 126.93512046452658),
      level: 3,
    };

    const map = new kakao.maps.Map(container, options);

    /* 지도에 해당 위치 마커 생성 */
    let markerPosition = new kakao.maps.LatLng(37.47060820313192, 126.93512046452658);
    let marker = new kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(map);

    /* 마커 텍스트 생성 */
    let iwContent =
        '<div style="width:150px;padding:5px;font-weight:700;color:black;text-align:center;">랩스탠다드</div>',
      iwPosition = new kakao.maps.LatLng(37.47060820313192, 126.93512046452658);
    let infowindow = new kakao.maps.InfoWindow({
      position: iwPosition,
      content: iwContent,
    });
    infowindow.open(map, marker);
  }, [checkMap]);

  return (
    <div
      className="kakao-map"
      style={{ width: '100%', height: '100%', borderRadius: '22px' }}
    ></div>
  );
}

export default KakaoMap;
