import React, { useState, useEffect } from 'react';
import {
    ContentContainer,
    TabTitle,
    Line,
    LectureDetailContainer,
    LectureDetailPageContainer,
    LectureIsEnd,
    LecturePrice,
    LectureSection1,
    LectureSection1ButtonContainer,
    LectureSection1Table,
    LectureSection1TableContainer,
    LectureSection2,
    LectureSection2ButtonContainer,
    LectureSection2ItemLecture,
    LectureSection2ItemBook,
    LectureSection2ItemBox1,
    LectureSection2ItemBox2,
    LectureSection2Table,
    LectureSection3,
    LectureSection3TableContent,
    LectureSection4,
    LectureSection4Table,
    LectureSection4TableContent,
    LectureSection4TableContentContainer,
    LectureSection4TableTitle,
    LectureSectionTitle,
    LectureSubTitleBox,
    LectureTitleBox,
    LectureTitleContainer,
    TitleLine,
    PassListContainer,
    PassAndButtonContainer,
} from './style';

import { Button } from '../../components/atoms/Button';
import COLOR from '../../styles/common/color';
import Select from '../../components/atoms/Select';
import { BasicCheckBox } from '../../components/atoms/CheckBox';
import MobileDetect from 'mobile-detect';
import { SpinLoader, typeList } from '../../components/atoms/SpinLoader';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getLectureInfoById } from '../../axiosManage/Professor';
import { getPassListOfLecture, usePass } from '../../axiosManage/MyPass';
import useHandleError from '../../hooks/useHandleError';

import { useRecoilState, useRecoilValue } from 'recoil';
import {
    myOrderBasketListAtom,
    myOrderAtom,
} from '../../recoil/OrderBasketStore/OrderBasketRecoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';

import { showOT, showPreview } from '../../axiosManage/Video';
import { getRefundText } from '../../axiosManage/Assignment';

var sp = null;
var app = null;

function detectMobileDevice() {
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.mobile() != null || md.phone() != null || md.tablet() != null) {
        return true;
    } else {
        return false;
    }
}

const LectureDetailPage = () => {
    const { handleError } = useHandleError();
    const { id } = useParams();
    const navigate = useNavigate();
    const isLogin = useRecoilValue(isLoginAtom);
    const [isMobile, setIsMobile] = useState(false);
    const [lectureDetail, setLectureDetail] = useState([]);
    const { status: lectureDetailStatus } = useQuery(
        ['getLectureInfoById', id],
        () => getLectureInfoById(id),
        {
            retry: 0,
            enabled: !!id,
            onSuccess: (data) => {
                if (data.data.lectureInfo !== undefined) {
                    setLectureDetail(data.data.lectureInfo);
                }
            },
            onError: (error) => {
                handleError(error);
            },
        },
    );

    /** 환불 정책 */
    const [refundText, setRefundText] = useState('');
    useEffect(() => {
        getRefundText()
            .then((res) => {
                setRefundText(JSON.parse(res.data.data));
            })
            .catch((error) => {
                handleError(error);
            });
    }, []);

    /** OT, 맛보기 동영상 뽑기 */
    const [OtLecture, setOtLecture] = useState(null);
    const [previewLecture, setPreviewLecture] = useState(null);

    useEffect(() => {
        if (lectureDetail && lectureDetail.lectures) {
            lectureDetail.lectures.forEach((lecture) => {
                if (lecture.isOt == true) {
                    setOtLecture(lecture);
                }
                if (lecture.isPreview == true) {
                    setPreviewLecture(lecture);
                }
            });
        }
        setIsMobile(detectMobileDevice());
    }, [lectureDetail]);
    /** 패스 선택하기 */
    const [passList, setPassList] = useState([]);
    const { status: passListStatus } = useQuery(
        ['getPassListOfLecture', id],
        () => getPassListOfLecture(id),
        {
            retry: 0,
            enabled: !!id,
            onSuccess: (data) => {
                setPassList(data.data);
            },
            onError: (error) => {
                handleError(error);
            },
        },
    );

    const SELECT_OPTIONS = passList.map((item) => ({
        value: parseInt(item.id),
        optionName: item.passName,
    }));

    // 선택한 패스
    const [selectedPassId, setSelectedPassId] = useState(0);
    const handlePassChange = (e) => {
        setSelectedPassId(e.target.value);
    };

    useEffect(() => {
        if (passList && passList[0]) {
            setSelectedPassId(passList[0].id);
        }
    }, [passList]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = '/js/starplayer_app.js';
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
            //<![CDATA[
            // eslint-disable-next-line
            sp = StarPlayerApp;
            sp.license = 'F0C2DA42-98D3-4F1E-BA6E-FB76C3F30B58';
            sp.version = '1.0.0';
            sp.android_version = '1.0.0';
            sp.ios_version = '1.0.0';
            sp.pmp = 'true';
            sp.debug = 'false';
            sp.referer_return = 'false';
            app = (info_url) => {
                sp.executeApp(info_url);
            };
            //]]>
        };

        script.onerror = () => {
            console.log('load error!');
        };
    }, []);

    const usingPass = () => {
        usePass({ passId: selectedPassId, lectureInfoId: parseInt(id) })
            .then((res) => {
                if (res.data.success === true) alert('패스 사용이 완료되었습니다.');
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
    };

    // 총 금액 계산 로직
    const [totalPrice, setTotalPrice] = useState(0);

    // 강좌 or 교재에 체크했는지 검사하는 로직
    const [isCheckedList, setIsCheckedList] = useState([]);

    const [isCheckedLecture, setIsCheckedLecture] = useState([]);
    const [isCheckedBook, setIsCheckedBook] = useState([]);
    const productSelect = (type, productId, product) => {
        const lectureCheckedIdList = [...isCheckedLecture];
        const bookCheckedIdList = [...isCheckedBook];

        if (type === 'lecture') {
            if (!lectureCheckedIdList.includes(productId)) {
                lectureCheckedIdList.push(productId);
                setTotalPrice((totalPrice) => totalPrice + (product.price - product.sale));
            } else {
                for (let i = 0; i < lectureCheckedIdList.length; i++) {
                    if (lectureCheckedIdList[i] === productId) {
                        lectureCheckedIdList.splice(i, 1);
                        i--;
                    }
                }
                setTotalPrice((totalPrice) => totalPrice - (product.price - product.sale));
            }

            setIsCheckedLecture(lectureCheckedIdList);
        } else if (type === 'book') {
            if (!bookCheckedIdList.includes(productId)) {
                bookCheckedIdList.push(productId);
                setTotalPrice((totalPrice) => totalPrice + (product.price - product.sale));
            } else {
                for (let i = 0; i < bookCheckedIdList.length; i++) {
                    if (bookCheckedIdList[i] === productId) {
                        bookCheckedIdList.splice(i, 1);
                        i--;
                    }
                }
                setTotalPrice((totalPrice) => totalPrice - (product.price - product.sale));
            }
            setIsCheckedBook(bookCheckedIdList);
        }
    };

    useEffect(() => {
        setIsCheckedList([...isCheckedLecture, ...isCheckedBook]);
    }, [isCheckedLecture, isCheckedBook]);

    // 장바구니 버튼 클릭 했을 때
    const [selectedProductList, setSelectedProductList] = useRecoilState(myOrderBasketListAtom);
    const [isProductAlreadyInclude, setIsProductAlreadyInclude] = useState(false);
    const clickOrderBasketButton = () => {
        if (isLogin === false) {
            alert('로그인을 해주세요.');
        } else {
            const selectedIdList = [...selectedProductList];
            let checkProduct = [];
            let checkSelectedProduct = [];

            selectedProductList.forEach((product) => {
                if ('lectureName' in product) {
                    checkProduct.push(product.lectureName);
                } else if (product.length > 1 && 'bookName' in product[0]) {
                    checkProduct.push(product[0].bookName);
                } else if (product.length <= 1 && 'bookName' in product) {
                    checkProduct.push(product.bookName);
                }
            });

            if (lectureDetail.books) {
                if (isCheckedLecture.includes(lectureDetail.id)) {
                    selectedIdList.push(lectureDetail);
                    checkSelectedProduct.push(lectureDetail);
                }
                for (let i = 0; i < lectureDetail.books.length; i++) {
                    if (isCheckedBook.includes(lectureDetail.books[i].id)) {
                        selectedIdList.push([lectureDetail.books[i], 1]);
                        checkSelectedProduct.push([lectureDetail.books[i], 1]);
                    }
                }
            }

            checkSelectedProduct.forEach((selected) => {
                if (checkProduct.includes(selected.bookName)) {
                    setIsProductAlreadyInclude(true);
                } else if (checkProduct.includes(selected.lectureName)) {
                    setIsProductAlreadyInclude(true);
                }
            });

            if (isProductAlreadyInclude === true) {
                alert('이미 장바구니에 동일한 강좌 혹은 교재가 있습니다.');
            } else {
                if (checkSelectedProduct.length >= 1) {
                    setSelectedProductList(selectedIdList);
                    alert('장바구니에 상품이 담겼습니다.');
                    setIsProductAlreadyInclude(false);
                } else {
                    alert('선택한 상품이 없습니다.');
                }
            }
        }
    };

    // 구매하기 버튼 클릭 했을 때
    const [selectedProduct, setSelectedProduct] = useRecoilState(myOrderAtom);
    const clickOrderButton = (lecture) => {
        if (isLogin === false) {
            alert('로그인을 해주세요.');
        } else {
            const selectedIdList = [];
            let checkSelectedProduct = [];

            if (lecture.books) {
                if (isCheckedList.includes(lecture.id)) {
                    selectedIdList.push(lecture);
                    checkSelectedProduct.push(lecture);
                }
                for (let i = 0; i < lecture.books.length; i++) {
                    if (isCheckedList.includes(lecture.books[i].id)) {
                        selectedIdList.push(lecture.books[i]);
                        checkSelectedProduct.push(lectureDetail.books[i]);
                    }
                }
            }

            setSelectedProduct(selectedIdList);

            if (checkSelectedProduct.length < 1) {
                alert('선택한 상품이 없습니다.');
            } else {
                navigate(`/order`);
            }
        }
    };

    const clickOT = (lectureVideoId) => {
        if (OtLecture) {
            showOT(lectureVideoId)
                .then((res) => {
                    if (isMobile) {
                        app(
                            `https://service.labstandard.kr/api/player/ot/mobile?lectureVideoId=${lectureVideoId}`,
                        );
                    } else {
                        window.open(
                            `https://service.labstandard.kr/api/player/ot?lectureVideoId=${lectureVideoId}`,
                        );
                    }
                })
                .catch((error) => {
                    alert('등록된 OT 영상이 없습니다.');
                    //alert(error.response.data.message);
                });
        }
        else {
            alert('등록된 OT 영상이 없습니다.');
        }
    };

    const clickPreview = (lectureVideoId) => {
        if (previewLecture) {
            showPreview(lectureVideoId)
                .then((res) => {
                    if (isMobile) {
                        app(
                            `https://service.labstandard.kr/api/player/preview/mobile?lectureVideoId=${lectureVideoId}`,
                        );
                    } else {
                        window.open(
                            `https://service.labstandard.kr/api/player/preview?lectureVideoId=${lectureVideoId}`,
                        );
                    }
                })
                .catch((error) => {
                    alert('등록된 맛보기 영상이 없습니다.');
                    //alert(error.response.data.message);
                });
        }
        else {
            alert('등록된 맛보기 영상이 없습니다.');
        }
    };

    function createMarkup(data) {
        return { __html: data?.replace(/\n/gi, '<br/>') };
    }
    return (
        <LectureDetailPageContainer>
            <ContentContainer>
                <TabTitle>개설 강좌</TabTitle>
                <Line />
                <LectureDetailContainer>
                    <LectureSection1>
                        <img src={lectureDetail.lectureImg} />
                        <LectureTitleContainer>
                            <LectureIsEnd>{lectureDetail.status}</LectureIsEnd>
                            <LectureTitleBox>
                                <p>{lectureDetail.lectureName}</p>
                                <TitleLine />
                            </LectureTitleBox>
                            <LectureSubTitleBox>
                                {lectureDetail.lectureCategory === 'language-logic' ? (
                                    <span>언어논리</span>
                                ) : lectureDetail.lectureCategory === 'data-interpretation' ? (
                                    <span>자료해석</span>
                                ) : lectureDetail.lectureCategory === 'situational-judgment' ? (
                                    <span>상황판단</span>
                                ) : (
                                    ''
                                )}
                                <span>{lectureDetail.professor?.professorName}</span>
                            </LectureSubTitleBox>
                            <LectureSection1TableContainer>
                                <LectureSection1Table>
                                    <div>
                                        <p>과정</p>
                                        <p>{lectureDetail.step}</p>
                                    </div>
                                    <div>
                                        <p>교재</p>
                                        {lectureDetail.books !== undefined ? (
                                            <>
                                                {lectureDetail.books[0] !== undefined && lectureDetail.books?.length > 1 ? (
                                                    <p>{lectureDetail.books[0].bookName} 외 1권</p>
                                                ) : (
                                                    <>
                                                        {lectureDetail.books[0] === undefined ? (
                                                            <p>교재 없음</p>
                                                        ) : (
                                                            <p>{lectureDetail.books[0].bookName}</p>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div>
                                        <p>수강 기간</p>
                                        <p>{lectureDetail.period}일</p>
                                    </div>
                                    <div>
                                        <p>개강일</p>
                                        <p>{lectureDetail.startDate?.replace(/-/g, '.')} </p>
                                    </div>
                                    <div>
                                        <p>강좌구성</p>
                                        <p>총 {lectureDetail.lectureCount}강</p>
                                    </div>
                                    <div>
                                        <p>강좌 현황</p>
                                        <p>{lectureDetail.status}</p>
                                    </div>
                                </LectureSection1Table>
                                <LectureSection1ButtonContainer>
                                    <Button width={124} height={48} onClickFunc={() => {
                                        if (OtLecture) {
                                            clickOT(OtLecture.id);
                                        }
                                        else {
                                            alert('등록된 OT 영상이 없습니다.');
                                        }
                                    }}>
                                        OT
                                    </Button>
                                    <Button
                                        width={124}
                                        height={48}
                                        bgColor={COLOR.BLUE_4}
                                        onClickFunc={() => {
                                            if (previewLecture) {
                                                clickPreview(previewLecture.id)
                                            }
                                            else {
                                                alert('등록된 맛보기 영상이 없습니다.');
                                            }
                                        }}
                                    >
                                        맛보기
                                    </Button>
                                </LectureSection1ButtonContainer>
                            </LectureSection1TableContainer>
                        </LectureTitleContainer>
                    </LectureSection1>
                    <LectureSection2>
                        <LectureSection2Table>
                            <LectureSection2ItemBox1>
                                <LectureSection2ItemLecture>
                                    <div>
                                        <div>
                                            <BasicCheckBox
                                                onClickFunc={() => {
                                                    productSelect('lecture', lectureDetail.id, lectureDetail);
                                                }}
                                            />
                                            <span>강좌</span>
                                            <p>{lectureDetail.lectureName}</p>
                                        </div>
                                    </div>
                                    <LecturePrice>
                                        {lectureDetail.sale === 0 ? (
                                            <>
                                                <span></span>
                                                <span>
                                                    {lectureDetail.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <span style={{ textDecoration: 'line-through' }}>
                                                    {lectureDetail.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                                                </span>
                                                <span>
                                                    {(lectureDetail.price - lectureDetail.sale)
                                                        ?.toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </span>
                                            </>
                                        )}

                                        <span> 원</span>
                                    </LecturePrice>
                                </LectureSection2ItemLecture>
                                <LectureSection2ItemBook>
                                    <div>
                                        {lectureDetail.books !== undefined ? (
                                            <>
                                                {lectureDetail.books.map((book) => (
                                                    <div key={book.id}>
                                                        <BasicCheckBox
                                                            onClickFunc={() => {
                                                                productSelect('book', book.id, book);
                                                            }}
                                                        />
                                                        <span>교재</span>
                                                        <p>{book.bookName}</p>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <LecturePrice>
                                        {lectureDetail.books !== undefined ? (
                                            <>
                                                {lectureDetail.books.map((book) => (
                                                    <div key={book.id}>
                                                        {book.sale === 0 ? (
                                                            <span>
                                                                {book.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원
                                                            </span>
                                                        ) : (
                                                            <>
                                                                <span style={{ textDecoration: 'line-through' }}>
                                                                    {book.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원
                                                                </span>
                                                                <span>
                                                                    {(book.price - book.sale)
                                                                        .toString()
                                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                                                                    원
                                                                </span>
                                                            </>
                                                        )}
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </LecturePrice>
                                </LectureSection2ItemBook>
                            </LectureSection2ItemBox1>
                            <LectureSection2ItemBox2>
                                <div>
                                    <p>총 금액</p>
                                    <LecturePrice>
                                        <span></span>
                                        <span>{totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                        <span> 원</span>
                                    </LecturePrice>
                                </div>
                            </LectureSection2ItemBox2>
                        </LectureSection2Table>
                        <PassAndButtonContainer>
                            {isLogin === true ? (
                                <PassListContainer>
                                    <Select
                                        width={400}
                                        height={58}
                                        selectOptions={SELECT_OPTIONS}
                                        onChangeFunc={handlePassChange}
                                    />
                                    <Button width={100} height={48} bgColor={COLOR.BLUE_4} onClickFunc={usingPass}>
                                        패스 사용
                                    </Button>
                                </PassListContainer>
                            ) : (
                                <PassListContainer />
                            )}
                            <LectureSection2ButtonContainer>
                                <Button width={124} height={48} onClickFunc={clickOrderBasketButton}>
                                    장바구니
                                </Button>
                                <Button
                                    width={124}
                                    height={48}
                                    bgColor={COLOR.BLUE_4}
                                    onClickFunc={() => clickOrderButton(lectureDetail)}
                                >
                                    구매하기
                                </Button>
                            </LectureSection2ButtonContainer>
                        </PassAndButtonContainer>
                    </LectureSection2>
                    <LectureSectionTitle>
                        <p>강좌 정보</p>
                    </LectureSectionTitle>
                    <LectureSection3>
                        <LectureSection3TableContent>
                            <div>
                                <p>강좌 내용</p>
                                <div dangerouslySetInnerHTML={createMarkup(lectureDetail.description)} />
                            </div>
                            <div>
                                <p>강좌 효과</p>
                                <div dangerouslySetInnerHTML={createMarkup(lectureDetail.lectureEffect)} />
                            </div>
                            <div>
                                <p>수강 대상</p>
                                <div dangerouslySetInnerHTML={createMarkup(lectureDetail.lectureTarget)} />
                                {/* <div>
                  <p>{lectureDetail.lectureTarget}</p>
                </div> */}
                            </div>
                        </LectureSection3TableContent>
                    </LectureSection3>
                    <LectureSection4>
                        <LectureSectionTitle>강의 구성</LectureSectionTitle>
                        <LectureSection4Table>
                            <LectureSection4TableTitle>
                                <div>
                                    <p>회차</p>
                                    <p>강의명</p>
                                    <p>강의시간</p>
                                </div>
                            </LectureSection4TableTitle>
                            <LectureSection4TableContentContainer>
                                {lectureDetail.lectures !== undefined ? (
                                    <>
                                        {lectureDetail.lectures.map((lecture, i) => (
                                            <LectureSection4TableContent key={lecture.id}>
                                                <p>{i + 1}강</p>
                                                <p>{lecture.lectureVideoName}</p>
                                                <p>{lecture.time}분</p>
                                            </LectureSection4TableContent>
                                        ))}
                                    </>
                                ) : (
                                    ''
                                )}
                            </LectureSection4TableContentContainer>
                        </LectureSection4Table>
                    </LectureSection4>
                    <LectureSection4>
                        <div dangerouslySetInnerHTML={createMarkup(refundText.lecture)} />
                        {/* {refundText &&
              refundText.lecture.split('\n').map((content, j) => {
                return <p key={j}>{content}</p>;
              })} */}
                    </LectureSection4>
                </LectureDetailContainer>
            </ContentContainer>
        </LectureDetailPageContainer>
    );
};

export default LectureDetailPage;
