import React, { useState, useEffect } from 'react';
import SubBanner from '../SubBanner';
import {
  BookStoreHomeContainer,
  NewBookContainer,
  NewBookLeft,
  NewBookRight,
  BestSellerContainer,
  BestSellerBox,
  BestSellerList,
  BestSeller,
  RecommendedBookContainer,
  RecommendedBookBox,
  BookStoreHomeFooter,
  FooterBox,
  SubTitle,
  BestSellerSubTitle,
  HorizonLine,
} from './style';
import { VerySmallQuestionDetail } from '../../SupportCenterPageElements/QuestionsDetail';

import { SpinLoader, typeList } from '../../../atoms/SpinLoader';

import NewBookRightBannerBG from '../../../../assets/images/image-newbook-right-bg.png';
import NewBookRightBannerBookImg from '../../../../assets/images/image-newbook-right-book.png';
import GoToIcon from '../../../../assets/images/goto.png';

import { ReactComponent as ArrowIcon } from '../../../../assets/icons/icon-ArrowRight-black.svg';
import { Button } from '../../../atoms/Button';
import { useNavigate } from 'react-router-dom';

import { useQuery } from 'react-query';
import useHandleError from '../../../../hooks/useHandleError';
import {
  getFrequentQuestion,
  getNotice,
  getBookStoreBanner,
} from '../../../../axiosManage/BookStore';

function Home({
  newBook,
  bestSellerBook,
  bestBookStatus,
  recommendedBook,
  recommendedBookState,
  currentCategory,
  setCurrentCategory,
}) {
  const { handleError } = useHandleError();
  const navigate = useNavigate();

  const [eventBanner, setEventBanner] = useState([]);
  useEffect(() => {
    getBookStoreBanner().then((res) => {
      setEventBanner(JSON.parse(res.data.data));
    });
  }, []);

  /** 자주 묻는 질문 데이터 */
  const [frequentQuestionList, setFrequentQuestionList] = useState([]);
  const { status: frequentQuestionListState } = useQuery(
    'getFrequentQuestion',
    getFrequentQuestion,
    {
      retry: 0,
      onSuccess: (data) => {
        setFrequentQuestionList(data.data.questions.slice(0, 4));
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  /** 공지사항 박스 */
  const [noticeList, setNoticeList] = useState([]);
  const { status: noticeListStatus } = useQuery('getNotice', () => getNotice('', '', 1), {
    retry: 0,
    onSuccess: (data) => {
      setNoticeList(data.data.educationHelpCenterNoticeList.slice(0, 4));
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [currentNewBookTab, setCurrentNewBookTab] = useState(0);
  const clickPrevNewBook = () => {
    setCurrentNewBookTab(currentNewBookTab - 1);
  };
  const clickNextNewBook = () => {
    setCurrentNewBookTab(currentNewBookTab + 1);
  };

  /* 현재 선택된 추천 교재 카테고리 */
  const [currentRecommendBookTab, setCurrentRecommendBookTab] = useState(0);

  const clickCategory = (name, i) => {
    setCurrentCategory(name);
    setCurrentRecommendBookTab(i);
  };

  /** 자주 묻는 질문에 대한 상세 페이지 보여주기 */
  const [openDetailID, setOpenDetailID] = useState(0);
  const [showSelectedDetail, setShowSelectedDetail] = useState(
    Array(frequentQuestionList.length).fill(false),
  );

  const openDetail = (id) => {
    setOpenDetailID(id);
    if (showSelectedDetail[id] === true) {
      const newArr = [...showSelectedDetail];
      newArr[id] = false;
      setShowSelectedDetail(newArr);
    } else {
      const newArr = Array(frequentQuestionList.length).fill(false);
      newArr[id] = true;
      setShowSelectedDetail(newArr);
    }
  };

  return (
    <>
      <BookStoreHomeContainer>
        <SubBanner />
        {/* 신규 교재 */}
        <NewBookContainer>
          <HorizonLine />
          <SubTitle>
            신규 교재
            <p>최신 트렌드를 담은 랩스탠다드의 신작!</p>
          </SubTitle>
          <div>
            <NewBookLeft>
              {newBook[currentNewBookTab] !== undefined ? (
                <>
                  <div>
                    <p></p>
                  </div>
                  <div>
                    <div>
                      <img src={newBook[currentNewBookTab] && newBook[currentNewBookTab].bookImg} />
                    </div>
                    <div>
                      <div>
                        <p>0{currentNewBookTab + 1}</p>
                        <p>
                          <ArrowIcon
                            onClick={(e) => {
                              if (currentNewBookTab === 0) {
                                e.preventDefault();
                              } else {
                                clickPrevNewBook();
                              }
                            }}
                          />
                          <ArrowIcon
                            onClick={(e) => {
                              if (currentNewBookTab === newBook.length - 1) {
                                e.preventDefault();
                              } else {
                                clickNextNewBook();
                              }
                            }}
                          />
                        </p>
                      </div>
                      <div>{newBook[currentNewBookTab] && newBook[currentNewBookTab].bookName}</div>
                      <div>
                        {newBook[currentNewBookTab] && newBook[currentNewBookTab].introduction}
                      </div>
                      <div>
                        {newBook[currentNewBookTab] && newBook[currentNewBookTab].description}
                      </div>
                      <div>
                        {newBook[currentNewBookTab] && newBook[currentNewBookTab].author} 저
                      </div>
                      <div>
                        {newBook[currentNewBookTab].sale === 0 ? (
                          <>
                            <p></p>
                            <p>
                              ₩
                              {newBook[currentNewBookTab].price
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </>
                        ) : (
                          <>
                            <p style={{ textDecoration: 'line-through' }}>
                              ₩
                              {newBook[currentNewBookTab].price
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                            <p>
                              ₩
                              {(newBook[currentNewBookTab].price - newBook[currentNewBookTab].sale)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </>
                        )}
                        <p></p>
                        <Button
                          width={110}
                          height={37}
                          onClickFunc={(e) =>
                            navigate(`/bookstore/entire/${newBook[currentNewBookTab].id}`)
                          }
                        >
                          자세히 보기
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <SpinLoader type={typeList[2].prop} color={'#000'} width={979} height={452} />
              )}
            </NewBookLeft>
            <NewBookRight
              backgroundImg={eventBanner.img}
              onClick={() => {
                if (eventBanner.url) {
                  window.location.href = eventBanner.url;
                }
              }}
            ></NewBookRight>
          </div>
        </NewBookContainer>
        {/* 베스트 셀러 */}
        <BestSellerContainer>
          <HorizonLine />
          <BestSellerSubTitle>
            BEST SELLER
            <p>랩스탠다드의 베스트셀러를 만나보세요</p>
          </BestSellerSubTitle>
          <BestSellerBox>
            {bestSellerBook !== undefined ? (
              <BestSellerList>
                {bestSellerBook &&
                  bestSellerBook.map((data, i) => {
                    if (data != undefined) {
                      return (
                        <BestSeller
                          key={data.id}
                          onClick={(e) => navigate(`/bookstore/entire/${data.id}`)}
                        >
                          <div>
                            {/* <p>TOP</p>
                            <p>0{i + 1}</p> */}
                          </div>
                          <div>
                            <p>
                              <img src={data.bookImg} />
                            </p>
                            <div>
                              <p>{data.bookName}</p>
                              <p>{data.author} 저</p>
                              <p>{data.introduction}</p>
                            </div>
                          </div>
                        </BestSeller>
                      );
                    }
                  })}
              </BestSellerList>
            ) : (
              <SpinLoader type={typeList[2].prop} color={'#000'} width={1311} height={650} />
            )}
          </BestSellerBox>
        </BestSellerContainer>
      </BookStoreHomeContainer>
      {/* 추천 교재 */}
      <RecommendedBookContainer>
        <HorizonLine />
        <SubTitle>추천 교재</SubTitle>
        <RecommendedBookBox>
          <div># MUST HAVE IT</div>
          {recommendedBook !== undefined ? (
            <>
              <div>
                {recommendedBook &&
                  recommendedBook.map((book, i) => (
                    <div key={book.id}>
                      <li
                        className={book.title === currentCategory ? 'active' : ''}
                        onClick={() => clickCategory(book.title, i)}
                      >
                        {book.title}
                        <div></div>
                      </li>
                    </div>
                  ))}
              </div>
              {recommendedBook[currentRecommendBookTab] !== undefined ? (
                <div>
                  <p>
                    <img
                      src={
                        recommendedBook[currentRecommendBookTab] &&
                        recommendedBook[currentRecommendBookTab].bookImg
                      }
                    />
                  </p>
                  <p>
                    {recommendedBook[currentRecommendBookTab] &&
                      recommendedBook[currentRecommendBookTab].bookName}
                  </p>
                  <p>
                    {recommendedBook[currentRecommendBookTab] &&
                      recommendedBook[currentRecommendBookTab].introduction}
                  </p>
                  <Button
                    width={95}
                    height={37}
                    onClickFunc={(e) =>
                      navigate(
                        `/bookstore/entire/${recommendedBook[currentRecommendBookTab].bookId}`,
                      )
                    }
                  >
                    자세히 보기
                  </Button>
                </div>
              ) : (
                <SpinLoader type={typeList[2].prop} color={'#000'} />
              )}
            </>
          ) : (
            <SpinLoader type={typeList[2].prop} color={'#000'} width={1920} height={720} />
          )}
        </RecommendedBookBox>
      </RecommendedBookContainer>

      <BookStoreHomeContainer>
        {/* FAQ, 공지사항 */}
        <BookStoreHomeFooter>
          <div>
            <FooterBox>
              <div>
                <div>FAQ</div>
                <div onClick={() => navigate('/supportcenter/questions')}>
                  <p>전체보기</p>
                  <img src={GoToIcon} />
                </div>
              </div>
              {frequentQuestionList !== undefined ? (
                <>
                  <div>
                    {frequentQuestionList &&
                      frequentQuestionList.map((data, i) => (
                        <>
                          <div key={data.id} onClick={() => openDetail(i)}>
                            <p>•</p>
                            <p>{data.title}</p>
                          </div>
                          <div style={{ marginBottom: '0px' }}>
                            {showSelectedDetail[i] ? (
                              <VerySmallQuestionDetail
                                openDetailContent={frequentQuestionList[openDetailID].content}
                              />
                            ) : null}
                          </div>
                        </>
                      ))}
                  </div>
                </>
              ) : (
                <SpinLoader type={typeList[2].prop} color={'#000'} />
              )}
            </FooterBox>
            <FooterBox>
              <div>
                <div>공지사항</div>
                <div onClick={() => navigate('/supportcenter/notice')}>
                  <p>전체보기</p>
                  <img src={GoToIcon} />
                </div>
              </div>
              {noticeList !== undefined ? (
                <>
                  <div>
                    {noticeList &&
                      noticeList.map((data) => (
                        <div
                          key={data.id}
                          onClick={() => navigate(`/supportcenter/notice/${data.id}`)}
                        >
                          <p>•</p>
                          <p>
                            [{data.category}] {data.title}
                          </p>
                        </div>
                      ))}
                  </div>
                </>
              ) : (
                <SpinLoader type={typeList[2].prop} color={'#000'} />
              )}
            </FooterBox>
          </div>
        </BookStoreHomeFooter>
      </BookStoreHomeContainer>
    </>
  );
}

export default Home;
