import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

/** 로그인 여부 판단 */
export const isLoginAtom = atom({
  key: 'isLoginAtom',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

/** 로그인 시 아이디 저장 체크 여부 */
export const isSaveIdChecked = atom({
  key: 'isSaveIdChecked',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

/** 회원가입시 registerSocialAccountToken 저장 */
export const registerSocialAccountTokenAtom = atom({
  key: 'registerSocialAccountTokenAtom',
  default: '',
});

/** 회원가입시 registerAccountToken 저장 */
export const registerAccountTokenAtom = atom({
  key: 'registerAccountTokenAtom',
  default: '',
});

/** 계정 찾기 요청 시 findAccountToken 저장 */
export const findAccountTokenAtom = atom({
  key: 'findAccountTokenAtom',
  default: '',
});

/** 계정 찾기 요청 시 찾은 계정 ID 저장 */
export const foundAccountIdAtom = atom({
  key: 'foundAccountIdAtom',
  default: '',
});

/** 계정 찾기 요청 시 찾은 계정이 소셜 로그인 계정인 경우 */
export const isSocialAccountAtom = atom({
  key: 'isSocialAccountAtom',
  default: false,
});

/** 비밀번호 변경 요청 시 적은 userId 저장 */
export const userIdAtom = atom({
  key: 'userIdAtom',
  default: '',
});

/** 비밀번호 변경 요청 시 findPasswordToken 저장 */
export const findPasswordTokenAtom = atom({
  key: 'findPasswordTokenAtom',
  default: '',
});