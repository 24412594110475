import React, { useState, useEffect } from 'react';
import { ProfessorContainer } from './style';

import { useQuery } from 'react-query';
import { getProfessorIntroduction } from '../../../../axiosManage/Professor';
import useHandleError from '../../../../hooks/useHandleError';
import { SpinLoader, typeList } from '../../../atoms/SpinLoader';

const Professor = ({ professorId }) => {
  const { handleError } = useHandleError();

  const [ProfessorId, setProfessorId] = useState(1);
  const [introduction, setIntroduction] = useState([]);
  const { status: introductionStatus } = useQuery(
    ['getProfessorIntroduction', ProfessorId],
    () => getProfessorIntroduction(ProfessorId),
    {
      retry: 0,
      onSuccess: (data) => {
        setIntroduction(data.data.professorIntroduction);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  useEffect(() => {
    setProfessorId(professorId);
  }, [professorId]);

  return (
    <>
      {introductionStatus === 'loading' ? (
        <SpinLoader type={typeList[2].prop} color={'#000'} width={1312} height={840} />
      ) : (
        <ProfessorContainer>
          <img src={introduction} />
        </ProfessorContainer>
      )}
    </>
  );

};

export default Professor;
