import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { RegularPassPageContainer, ContentContainer, InfoBox, BuyBox } from './style';
import moment from 'moment';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';
import { myOrderInfoAtom } from '../../recoil/OrderBasketStore/OrderBasketRecoil';
import { useNavigate } from 'react-router-dom';

import { getRefundText } from '../../axiosManage/Assignment';
import { paymentBilling } from '../../axiosManage/OrderBasket';
import { getRecurringPassInfo } from '../../axiosManage/Professor';
import useHandleError from '../../hooks/useHandleError';
import { myInfoAtom } from '../../recoil/MyPageStore/MyPageRecoil';
import { BasicCheckBox } from '../../components/atoms/CheckBox';

function RegularPassPage() {
  const isLogin = useRecoilValue(isLoginAtom);
  const myInfo = useRecoilValue(myInfoAtom);
  const navigate = useNavigate();
  const { handleError } = useHandleError();

  const [regularPass, setRegularPass] = useState([]);
  useEffect(() => {
    getRecurringPassInfo().then((res) => {
      setRegularPass(res.data.pass);
    });
  }, []);

  const [refundText, setRefundText] = useState('');
  useEffect(() => {
    getRefundText()
      .then((res) => {
        setRefundText(JSON.parse(res.data.data));
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  const [isAgree, setIsAgree] = useState(false);
  const clickAgree = () => {
    setIsAgree((isAgree) => !isAgree);
  };

  /** 결제 정보 데이터 */
  const [payInfo, setPayInfo] = useRecoilState(myOrderInfoAtom);

  // 결제하기 버튼 클릭 했을 때
  let customer_uid = uuidv4();
  const clickOrderButton = () => {
    if (isLogin === false) {
      alert('로그인을 해주세요.');
    } else if (isAgree === false) {
      alert('상품 이용관련 상품안내 및 환불규정에 동의해주세요.');
    } else {
      /* 1. 가맹점 식별하기 */
      const { IMP } = window;
      IMP.init(process.env.REACT_APP_IMPORT_IMP);

      /* 2. 결제 데이터 정의하기 */
      const data = {
        pg: 'kakaopay.CAJBIUO02K',
        pay_method: 'card',
        name: '랩스탠다드 정기 패스',
        amount: 0,
        customer_uid,
        buyer_email: myInfo.email,
        buyer_name: myInfo.name,
        buyer_tel: myInfo.phoneNumber,
        buyer_addr: JSON.parse(myInfo.address).address + JSON.parse(myInfo.address).detailAddress,
        buyer_postcode: JSON.parse(myInfo.address).code,
        custom_data: { parcelDetail: '' },
        m_redirect_url: `https://labstandard.kr/mobileorder/complete?customer_uid=${customer_uid}&price=${regularPass.price - regularPass.sale
          }`,
      };

      /* 4. 결제 창 호출하기 */
      IMP.request_pay(data, callback);
    }
  };

  /* 3. 콜백 함수 정의하기 - 검증*/
  const callback = (response) => {
    const {
      success,
      imp_uid,
      merchant_uid,
      error_msg,
      emb_pg_provider,
      pay_method,
      paid_at,
      paid_amount,
      pg_provider,
      apply_num,
    } = response;

    const data = {
      customerUid: customer_uid,
    };

    if (success) {
      paymentBilling(data).then((res) => {
        setPayInfo([
          {
            createdAt: new Date(paid_at * 1000),
            products: [regularPass],
            price: paid_amount,
            provider: pg_provider,
            pay_method: pay_method,
          },
        ]);

        navigate('/order/complete');
      });
    } else {
      //alert('결제에 실패하였습니다. 에러 내용: ' + error_msg);
      alert('결제에 실패하였습니다.');
    }
  };

  function createMarkup(data) {
    return { __html: data?.replace(/\n/gi, '<br/>') };
  }

  return (
    <RegularPassPageContainer>
      <ContentContainer>
        <img src={regularPass.introductionImg} style={{ marginTop: '50px' }} />
        <InfoBox>
          <div>이용권 구매하기</div>
          <BuyBox>
            <div>
              <BasicCheckBox
                onClickFunc={() => {
                  clickAgree();
                }}
              />
              <span>상품 이용관련 상품안내 및 환불규정을 모두 확인하였고, 이에 동의합니다.</span>
            </div>
            <button onClick={clickOrderButton}>결제하기</button>
          </BuyBox>
          <div dangerouslySetInnerHTML={createMarkup(refundText.pass)} />
        </InfoBox>
      </ContentContainer>
    </RegularPassPageContainer>
  );
}

export default RegularPassPage;
