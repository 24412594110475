import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

const RegisterAssignmentPageContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding};
  text-align: center;
  padding-top: 70px;
  padding-bottom: 120px;

  & > button {
    margin-top: 40px;
  }

  @media screen and (max-width: 1700px) {
    padding-top: 70px;
    padding-bottom: 120px;
  }
`;

const RegisterPageTitle = styled.div`
  ${FONT_STYLES.P_M};
  font-size: 36px;
`;

const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_4};
  margin: 50px auto;
`;

const RegisterContent = styled.div`
  width: 100%;
  margin-top: 30px;

  & > div {
    display: flex;
    align-items: center;
    padding: 0 100px;
    margin: 40px 0;

    :nth-child(3) {
      & > div {
        display: flex;
        align-items: center;
        ${FONT_STYLES.P_EL}
        font-size: 18px;

        :nth-child(3),
        :nth-child(4) {
          margin-left: 30px;
        }

        & > p {
          cursor: pointer;
          margin-left: 13px;
        }
      }
    }

    :nth-child(4) {
      display: block;

      & > span {
        display: block;
        ${FONT_STYLES.P_EL};
        font-size: 14px;
        padding-left: 65px;
        line-height: 1.3;
        //white-space: pre-line;
        text-align: left;

        :nth-child(2),
        :nth-child(3) {
          margin-left: 10px;
        }
      }
    }

    :nth-child(5) {
      width: 90%;
      margin: auto;
      padding: 30px 0 10px 30px;
      border-top: 1px solid ${COLOR.GRAY_9};

      & > span {
        ${FONT_STYLES.P_M};
        font-size: 20px;
        margin-left: 30px;
        cursor: pointer;
      }

      @media screen and (max-width: 1700px) {
        padding: 30px 0 10px 17px;
      }
    }

    & > p {
      cursor: pointer;
      :nth-child(2) {
        ${FONT_STYLES.P_L}
        margin-left: 30px;
        font-size: 18px;
      }
      :nth-child(3) {
        ${FONT_STYLES.P_R};
        margin-left: auto;
        font-size: 14px;
        color: #716b6b;
        cursor: pointer;
      }
    }
  }
`;

export { RegisterAssignmentPageContainer, RegisterPageTitle, HorizonLine, RegisterContent };
