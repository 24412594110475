import styled from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';

export const StyledInput = styled.input`
  ${FONT_STYLES.P_M}
  width: ${({ width }) => (width ? `${width}px` : '200px')};
  height: ${({ height }) => (height ? `${height}px` : '58px')};
  font-size: 18px;
  border: none;
  padding: 19px 43px;
  border-radius: 34px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  ::placeholder {
    color: #716b6b;
  }

  :focus {
    outline: none;
  }
`;

export const StyledSquareInput = styled.input`
  ${FONT_STYLES.P_R}
  width: ${({ width }) => (width ? `${width}px` : '200px')};
  height: ${({ height }) => (height ? `${height}px` : '58px')};
  border: none;
  padding: 20px 30px;
  border-radius: 7px;
  background-color: #f5f7fd;

  ::placeholder {
    color: #716b6b;
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : '')};
  }

  :focus {
    outline: none;
  }
`;

export const StyledSearchIconInput = styled.input`
  width: ${({ width }) => (width ? `${width}px` : '200px')};
  height: ${({ height }) => (height ? `${height}px` : '58px')};
  border: none;
  border-bottom: 1px solid ${COLOR.GRAY_4};

  ::placeholder {
    color: ${COLOR.BLACK_1};
    ${FONT_STYLES.P_M};
  }

  :focus {
    outline: none;
  }
`;

export const StyledCustomInput = styled.div`
  ${FONT_STYLES.P_R};
  width: 165px;
  height: 48px;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify=content: center;
  padding: 0 0 0 15px;
  cursor: pointer;

  & > div {
    :nth-child(2) {
      display: flex;
      width: 47px;
      height: 48px;
      background-color: ${COLOR.GRAY_9};
      border-radius: 7px;
      margin-left: auto;

      & > img {
        display: block;
        margin: auto;
      }
    }
  }
`;

export const StyledCustomBirthInput = styled.div`
  cursor: pointer;
  ${FONT_STYLES.P_R}
  width: 455px;
  height: 62px;
  border: none;
  padding: 20px 30px;
  border-radius: 7px;
  background-color: #f5f7fd;

  ::placeholder {
    color: #716b6b;
    text-align: center;
  }

  :focus {
    outline: none;
  }
`;
