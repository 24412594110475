import { useAxios, useDownloadAxios } from '../../hooks/useAxios';
import fileDownload from 'js-file-download';

/** 학습지원센터 - 자주 묻는 질문 데이터 API */
export const getFrequentQuestion = async () => {
  return await useAxios.get('/center/frequent-question');
};

/** 학습지원센터 - 공지사항 필터링&검색 페이징 정보 API */
export const getNoticePageInfo = async (keyword, category) => {
  return await useAxios.get(`/center/notice/page/info?keyword=${keyword}&category=${category}`);
};

/** 학습지원센터 - 공지사항 필터링&검색 데이터 API  */
export const getNotice = async (keyword, category, page) => {
  return await useAxios.get(
    `/center/notice/list?keyword=${keyword}&category=${category}&page=${page}`,
  );
};

/** 학습지원센터 - 공지사항 상세 페이지 데이터 API */
export const getNoticeDetail = async (id) => {
  return await useAxios.get(`/center/notice/${id}`);
};

/** 학습지원센터 - 공지사항 파일 다운로드 API */
export const downloadNotice = async (id, fileTitle) => {
  return await useDownloadAxios
    .get(`/center/notice/download/${id}`, { responseType: 'blob' })
    .then((res) => {
      let filename = `${fileTitle}`.replace(/(\r\n\t|\n|\r\t)/gm, '');
      fileDownload(res.data, filename);
    });
};

/** 학습지원센터 - 공지사항 상단 고정 게시글 조회 API */
export const getTopFixEducationHelpCenterNoticeList = async () => {
  return await useAxios.get(`/center/notice/list/top`);
};

/** 학습지원센터 - 정오표 필터링&검색 페이징 정보 API */
export const getErrataPageInfo = async (keyword, category) => {
  return await useAxios.get(`/center/errata/page/info?keyword=${keyword}&category=${category}`);
};

/** 학습지원센터 - 정오표 필터링&검색 데이터 API  */
export const getErrata = async (keyword, category, page) => {
  return await useAxios.get(
    `/center/errata/list?keyword=${keyword}&category=${category}&page=${page}`,
  );
};

/** 학습지원센터 - 정오표 상세 페이지 API */
export const getErrataDetail = async (id) => {
  return await useAxios.get(`/center/errata/${id}`);
};

/** 학습지원센터 - 정오표 파일 다운로드 API */
export const downloadErrata = async (id, fileTitle) => {
  return await useDownloadAxios
    .get(`/center/errata/download/${id}`, { responseType: 'blob' })
    .then((res) => {
      let filename = `${fileTitle}`.replace(/(\r\n\t|\n|\r\t)/gm, '');
      fileDownload(res.data, filename);
    });
};

/** 학습지원센터 - 정오표 상단 고정 게시글 조회 API */
export const getTopFixErrataList = async () => {
  return await useAxios.get(`/center/errata/list/top`);
};

/** 학습지원센터 - 1:1 문의 전송 API */
export const addOneOnOneInquiry = async (data) => {
  return await useAxios.post('/center/one-on-one-inquiry', data);
};
