import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
  ActiveLine,
  HeaderBox,
  HeaderContainer,
  HeaderLogo,
  MainHeader,
  MainMenu,
  SubHeader,
  SubMenu,
} from './style';
import { ReactComponent as Bell } from '../../../assets/icons/icon-bell.svg';
import Logo from '../../../assets/images/image-Logo-black.png';
import { Link, useNavigate } from 'react-router-dom';

// 알림 모달창
import Modal from '../Modal';
import useModal from '../../../hooks/useModal';
import AlarmPage from '../../../pages/Alarm/AlarmPage';

import { useRecoilState, useResetRecoilState, useRecoilValue } from 'recoil';
import { isLoginAtom } from '../../../recoil/AuthStore/AuthRecoil';
import { myInfoAtom, myPageMyAlarmCountAtom } from '../../../recoil/MyPageStore/MyPageRecoil';
import { useQuery } from 'react-query';
import useHandleError from '../../../hooks/useHandleError';
import { logout } from '../../../axiosManage/Auth';

import { getMyNotify } from '../../../axiosManage/Alarm';

const Header = () => {
  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  //const alarmCount = useRecoilValue(myPageMyAlarmCountAtom);
  let [alarmDataLength, setAlarmDataLength] = useRecoilState(myPageMyAlarmCountAtom);

  const [modalOption, showModal] = useModal();
  const Header = useRef(null);

  // 알림 페이지 모달 오픈
  const openAlarm = useCallback(() => {
    showModal(true, null, null, <AlarmPage />);
    document.body.style.overflow = 'hidden';
  }, [showModal]);

  /** 내 알람 데이터 */
  //const [alarmCount, setAlarmCount] = useState(0);
  // if (isLogin === true) {
  //   getMyNotify()
  //     .then((res) => {
  //       setAlarmCount(res.data.notifies.length);
  //     })
  //     .catch((error) => {
  //       handleError(error);
  //     });
  // }

  useEffect(() => {
    if (isLogin === true) {
      let alarmDataCount = 0;
      getMyNotify().then((res) => {
        res.data.notifies.forEach((alarm, i) => {
          if (alarm.isChecked === false) {
            alarmDataCount += 1;
          }

          if (alarmDataCount < 1) {
            setAlarmDataLength(0);
          } else {
            setAlarmDataLength(alarmDataCount);
          }
        });
      });
    }
  }, [navigate]);

  const resetMyInfoAtom = useResetRecoilState(myInfoAtom);

  const clickLogout = () => {
    logout()
      .then((res) => {
        alert('로그아웃 되었습니다.');
        navigate('/');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setIsLogin(false);
        resetMyInfoAtom();
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
    // logout();
    // localStorage.removeItem('accessToken');
    // localStorage.removeItem('refreshToken');
    // setIsLogin(false);
    // resetMyInfoAtom();
    // alert('로그아웃 되었습니다.');
    // navigate('/');
  };

  return (
    <HeaderContainer ref={Header}>
      {/* 알림 모달창 */}
      <Modal modalOption={modalOption} />
      <HeaderBox>
        <SubHeader>
          <ul>
            {isLogin === true ? (
              <div onClick={clickLogout}>로그아웃</div>
            ) : (
              <Link to="/login">로그인</Link>
            )}
            {isLogin === true ? '' : <Link to="/register">회원가입</Link>}
            {isLogin === true ? <div onClick={() => navigate('/mypage')}>마이페이지</div> : ''}
            {isLogin === true ? <div onClick={() => navigate('/basket')}>장바구니</div> : ''}
            {isLogin === true ? (
              <li onClick={openAlarm}>
                <Bell width={16} height={23} />
                {alarmDataLength && alarmDataLength > 0 ? <span></span> : ''}
              </li>
            ) : (
              ''
            )}
          </ul>
        </SubHeader>
        <MainHeader>
          <div onClick={() => navigate('/')}>
            <HeaderLogo>
              <img alt="logo" src={Logo} width="110px" height="34px" />
            </HeaderLogo>
          </div>
          <ul>
            <li>
              <div onClick={() => navigate('/intro')}>
                <MainMenu>
                  랩스탠다드
                  <ActiveLine />
                </MainMenu>
              </div>
            </li>
            <li>
              <div onClick={() => navigate('/lecture')}>
                <MainMenu>
                  5급 강의 <ActiveLine />
                </MainMenu>
              </div>
            </li>
            <li>
              <MainMenu>
                <div onClick={() => navigate('/examinfo')}>
                  수험정보 & 팁 <ActiveLine />
                </div>
                <SubMenu>
                  <li>
                    <div onClick={() => navigate('/examinfo')}>직렬별 수험정보</div>
                  </li>
                  <li>
                    <div onClick={() => navigate('/examinfo/studytip')}>학습 TIP</div>
                  </li>
                  <li>
                    <div onClick={() => navigate('/examinfo/passnote')}>합격 후기</div>
                  </li>
                </SubMenu>
              </MainMenu>
            </li>
            <li>
              <MainMenu>
                <div onClick={() => navigate('/supportcenter')}>
                  학습지원센터 <ActiveLine />
                </div>
                <SubMenu>
                  <li>
                    <div onClick={() => navigate('/supportcenter')}>홈</div>
                  </li>
                  <li>
                    <div onClick={() => navigate('/supportcenter/questions')}>자주 묻는 질문</div>
                  </li>
                  <li>
                    <div onClick={() => navigate('/supportcenter/contact')}>1:1 문의</div>
                  </li>
                  <li>
                    <div onClick={() => navigate('/supportcenter/notice')}>공지사항</div>
                  </li>
                  <li>
                    <div onClick={() => navigate('/supportcenter/errata')}>자료실 & 정오표</div>
                  </li>
                </SubMenu>
              </MainMenu>
            </li>
            <li>
              <MainMenu>
                <div onClick={() => navigate('/bookstore')}>
                  온라인 서점 <ActiveLine />
                </div>
                <SubMenu>
                  <li>
                    <div onClick={() => navigate('/bookstore')}>홈</div>
                  </li>
                  <li>
                    <div onClick={() => navigate('/bookstore/entire')}>전체 교재</div>
                  </li>
                  <li>
                    <div onClick={() => navigate('/bookstore/series')}>시리즈별 교재</div>
                  </li>
                  <li>
                    <div onClick={() => navigate('/bookstore/subject')}>과목 교재</div>
                  </li>
                </SubMenu>
              </MainMenu>
            </li>
          </ul>
        </MainHeader>
      </HeaderBox>
    </HeaderContainer>
  );
};

export default Header;
