import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

export const PassNoteDetailPageContainer = styled.div`
  ${({ theme }) => theme.container.mainContainer}
  padding-bottom: 162px;
`;

export const PassNoteDetailContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-top: 70px;
  padding-bottom: 120px;
  text-align: center;

  @media screen and (max-width: 1700px) {
    padding-top: 70px;
    padding-bottom: 120px;
  }
`;

export const PassNoteDetailBox = styled.div`
  width: 100%;
  min-height: 500px;
  padding: 30px 90px;
  margin-bottom: 41px;
  background-color: ${COLOR.GRAY_0};
  filter: drop-shadow(0px 3px 20px #00000029);
  position: relative;
  border-radius: 20px;
  text-align: left;
`;

export const DetailTitle = styled.div`
  width: 100%;
  ${FONT_STYLES.P_SB}
  font-size: 24px;
  margin-bottom: 28px;
`;

export const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.BLUE_3};
  margin: 40px auto;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  & > p:not(:last-child) {
    padding-right: 22px;
  }
  border-bottom: 1px solid ${COLOR.GRAY_8};

  & > div {
    display: flex;
    margin-right: 25px;

    :nth-child(1) {
      & > p {
        :nth-child(1) {
          ${FONT_STYLES.P_SB}
          font-size: 18px;
        }
        :nth-child(2) {
          ${FONT_STYLES.P_B}
          font-size: 18px;
          padding: 0px 10px 0px 12px;
        }
        :nth-child(3) {
          ${FONT_STYLES.P_R}
          font-size: 18px;
        }
      }
    }
    :nth-child(2) {
      p:nth-child(2) {
        ${FONT_STYLES.P_SB}
        padding-left: 5px;
      }
    }
    :nth-child(3) {
      ${FONT_STYLES.P_M}
      font-size: 18px;

      p:nth-child(2) {
        padding-left: 5px;
      }
    }
  }
`;

export const DetailContent = styled.div`
  ${FONT_STYLES.P_R}
  font-size: 16px;
  line-height: 24px;
  padding: 40px 0;
`;

export const Attachments = styled.div`
  width: 1132px;
  ${FONT_STYLES.P_M}
  font-size: 18px;
  padding-top: 20px;
  border-top: 1px solid ${COLOR.GRAY_8};

  a span {
    color: #716b6b;
    padding-left: 27px;
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  text-align: right;

  & > button {
    :nth-child(1),
    :nth-child(2) {
      margin-right: 20px;
    }
  }
`;

export const TabTitle = styled.p`
  ${FONT_STYLES.P_EB}
  text-align: center;
  font-size: 40px;
`;

export const DataNotFound = styled.div`
  width: 100%;
  //height: 715px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > p {
    ${FONT_STYLES.P_R}
    font-size: 36px;
  }
`;

export const CommentBox = styled.div`
  margin-top: 30px;
`;
