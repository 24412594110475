import React, { useEffect } from 'react';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';

import { imageUpload } from '../../../axiosManage/ImageUpload';

export default function ToastEditor({ editorRef, content }) {
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.getInstance().removeHook('addImageBlobHook');
      editorRef.current.getInstance().addHook('addImageBlobHook', (blob, callback) => {
        (async () => {
          let formData = new FormData();
          formData.append('file', blob);

          imageUpload(formData)
            .then((res) => {
              callback(res.data.url, 'image');
            })
            .catch((error) => {
              alert(error.response.data.message);
            });
        })();

        return false;
      });
    }
    return () => {};
  }, [editorRef]);

  return (
    <div>
      <Editor
        ref={editorRef}
        placeholder={`본문 내용\n\n * 위에 구분을 먼저 선택 후 글을 작성해주시기 바랍니다!`}
        initialValue={content}
        previewStyle="vertical"
        hideModeSwitch="true"
        height="500px"
        initialEditType="wysiwyg"
        toolbarItems={[
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task'],
          //['table', 'image', 'link'],
          ['table', 'link'],
          ['code', 'codeblock'],
        ]}
        plugins={[colorSyntax]}
        useCommandShortcut={false}
      ></Editor>
    </div>
  );
}
