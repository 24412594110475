import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const CardContainer = styled.div`
  width: 212px;
  text-align: center;
`;

const BookImg = styled.img`
  width: 100%;
  height: 277px;
`;

const SmallTitle = styled.p`
  ${FONT_STYLES.P_R}
  font-size: 16px;
  line-height: 19px;
  color: ${COLOR.BLUE_3};
  padding-top: 30px;
`;

const Title = styled.p`
  ${FONT_STYLES.P_M}
  font-size: 20px;
  line-height: 24px;
  padding-top: 6px;
`;

export { CardContainer, BookImg, SmallTitle, Title };
