import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const LecturesContainer = styled.div`
  ${({ theme }) => theme.container.sidePadding}
  padding-top: 80px;
  padding-bottom: 173px;
  text-align: center;

  @media screen and (max-width: 1700px) {
    padding-top: 70px;
    padding-bottom: 173px;
  }
`;

const TabTitle = styled.p`
  ${FONT_STYLES.P_EB}
  text-align: center;
  font-size: 40px;
`;

const Line = styled.p`
  width: 43px;
  border: 1.5px solid ${COLOR.BLUE_3};
  background-color: ${COLOR.BLUE_3};
  margin: 0 auto;
  margin-top: 39px;
`;

const LectureTableContainer = styled.div`
  padding-top: 52px;
`;

const Container = styled.div`
  width: 100%;

  :nth-child(2) {
    text-align: center;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 22px;
  padding: 19px 26px 0px 41px;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  & > div {
    grid-column: span 2;
  }

  & > p:nth-child(1) {
    p {
      border-right: 1px solid ${COLOR.GRAY_9};
    }
  }
`;

const ColumnTitle = styled.p`
  ${FONT_STYLES.P_R}
  font-size: 18px;
  width: 100%;
  padding-bottom: 16px;
  :hover {
    cursor: pointer;
  }

  &.active {
    ${FONT_STYLES.P_SB}
  }

  & > p {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 53px;
  }
`;

const TableItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 29px 84px 29px 49px;
  border-top: 1px solid ${COLOR.GRAY_8};
  & > img {
    width: 139px;
    height: 139px;
    cursor: pointer;
    border-radius: 18px;
  }

  & > span {
    :nth-child(2) {
      opacity: 0.5;
      padding-right: 10px;
    }
  }
`;

const ProfessorInfoBox = styled.div`
  ${FONT_STYLES.P_R}
  font-size: 16px;
  padding: 0px 64px 0px 33px;
  cursor: pointer;

  p:nth-child(1) {
    ${FONT_STYLES.P_T}
    font-size: 15px;
    line-height: 18px;
    padding-bottom: 5px;
  }
`;

const LectureInfoBox = styled.div`
  text-align: left;
  cursor: pointer;
  flex: 1;
  p:nth-child(1) {
    ${FONT_STYLES.P_L}
    font-size: 13px;
  }

  p:nth-child(2) {
    ${FONT_STYLES.P_SB}
    font-size: 20px;
    padding: 8px 0;
  }

  p:nth-child(3) {
    ${FONT_STYLES.P_R}
    font-size: 16px;

    span:nth-child(1) {
      padding-right: 12px;
    }
  }
`;

const VideoInfoBox = styled.div`
  ${FONT_STYLES.P_SB}
  font-size: 18px;
  padding-right: 107px;
  p {
    :hover {
      cursor: pointer;
    }
  }
  p:nth-child(1) {
    padding-bottom: 17px;
  }
`;

const SelectBox = styled.div`
  text-align: left;

  p {
    display: flex;
  }

  p span:nth-child(2) {
    padding: 0px 26px 0px 10px;
  }
  p:nth-child(1) {
    padding-bottom: 17px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-left: 30px;

  button {
    &:nth-child(1) {
      margin-right: 17px;
    }
  }
`;

const LoadMoreBtn = styled.button`
  width: 120px;
  height: 50px;
  margin: 50px 0 70px 0;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 25px;
`;

export {
  LecturesContainer,
  TabTitle,
  Line,
  LectureTableContainer,
  Container,
  TableContainer,
  Table,
  TableItem,
  ProfessorInfoBox,
  LectureInfoBox,
  VideoInfoBox,
  SelectBox,
  ColumnTitle,
  ButtonContainer,
  LoadMoreBtn,
};
