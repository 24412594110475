import React, { useState } from 'react';
import { Button } from '../../components/atoms/Button';
import { TabTitle } from '../../components/organisms/LecturePageElements/Lectures/style';
import COLOR from '../../styles/common/color';
import { ButtonContainer } from '../QuestionDetail/style';
import {
  LibraryDetailContainer,
  LibraryDetailBox,
  DetailTitle,
  Info,
  DetailContent,
  Attachments,
  HorizonLine,
} from './style';
import ToastViewer from '../../components/atoms/ToastTextViewer';

import { useNavigate, useParams } from 'react-router-dom';

import { SpinLoader, typeList } from '../../components/atoms/SpinLoader';
import { useChangeMonthNumToEn } from '../../hooks/useChangeMonthNumToEn';
import { useQuery } from 'react-query';
import { getReference, downloadReference } from '../../axiosManage/Professor';
import useHandleError from '../../hooks/useHandleError';

const referenceDetailPage = () => {
  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const { id } = useParams();

  const [referenceDetail, setReferenceDetail] = useState([]);
  const { status: referenceDetailStatus } = useQuery(['getReference', id], () => getReference(id), {
    retry: 0,
    enabled: !!id,
    onSuccess: (data) => {
      if (data.data.reference !== undefined) {
        setReferenceDetail(data.data.reference);
      }
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const clickDownloadReference = (filename) => {
    downloadReference(id, filename);
  };

  return (
    <LibraryDetailContainer>
      <TabTitle>자료실</TabTitle>
      <HorizonLine />
      <LibraryDetailBox>
        {referenceDetail !== undefined ? (
          <>
            <DetailTitle>{referenceDetail.title}</DetailTitle>
            <Info>
              <div>
                <p>{referenceDetail.createdAt?.slice(8, 10)}</p>
                <p>{useChangeMonthNumToEn(referenceDetail.createdAt?.slice(5, 7))}</p>
                <p>{referenceDetail.createdAt?.slice(0, 4)}</p>
              </div>
              <div>
                <p>조회</p>
                <p>{referenceDetail.views}</p>
              </div>
            </Info>
            <ToastViewer content={referenceDetail.content} />
            {referenceDetail.existFile ? (
              <Attachments>
                <span>첨부 파일</span>
                <a onClick={() => clickDownloadReference(referenceDetail.fileName)}>
                  <span>{referenceDetail.fileName}</span>
                </a>
              </Attachments>
            ) : null}
          </>
        ) : (
          <SpinLoader type={typeList[2].prop} color={'#000'} width={1131} height={459} />
        )}
      </LibraryDetailBox>
      <ButtonContainer>
        <Button width={124} height={48} bgColor={COLOR.BLUE_4} onClickFunc={() => navigate(-1)}>
          목록으로
        </Button>
      </ButtonContainer>
    </LibraryDetailContainer>
  );
};

export default referenceDetailPage;
