import styled from 'styled-components';
import COLOR from '../../styles/common/color';
import { FONT_STYLES } from '../../styles/common/font-styles';

const MainPageContainer = styled.div`
  ${({ theme }) => theme.container.mainContainer}
`;

const BannerContainer = styled.div`
  width: 100%;

  .slick-dot li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
  }

  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
  }

  .slick-dots li button:before {
    font-size: 15px;
    line-height: 20px;
    position: absolute;
    top: 10px;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 1;
    color: ${COLOR.GRAY_3};
  }
  .slick-dots li.slick-active button:before {
    font-size: 18px;
    opacity: 1;
    color: ${COLOR.BLUE_3};
  }

  .slick-arrow::before {
    display: none;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
`;

const NewLectureSection = styled.div`
  width: 100%;
  padding: 77px 303px 88px 304px;

  @media screen and (min-width: 1700px) {
    padding: 77px 0px 88px 0px;
  }

  @media screen and (max-width: 1700px) {
    padding: 77px 0px 88px 0px;
  }
`;

const NewLecturesContainer = styled.div`
  width: 1312px;
  margin: 0 auto;
`;

const SectionTitle = styled.p`
  ${FONT_STYLES.P_EB}
  font-size: 36px;
  color: ${COLOR.BLUE_3};
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
`;

const Line = styled.p`
  height: 0;
  flex: 1;
  border: 0.5px solid ${COLOR.BLUE_3};
  margin-left: 128px;
`;

const SectionInfo = styled.p`
  ${FONT_STYLES.P_SB}
  font-size: 20px;
  margin-top: 32px;
  margin-bottom: 36px;
  color: ${COLOR.BLUE_3};
`;

const NewLectureContents = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const HotPickSection = styled.div`
  width: 100%;
  padding: 55px 303px 44px 304px;
  background-color: ${COLOR.BLUE_5};

  @media screen and (min-width: 1700px) {
    padding: 77px 0px 88px 0px;
  }

  @media screen and (max-width: 1700px) {
    padding: 77px 0px 88px 0px;
  }
`;

const HotPickContainer = styled.div`
  width: 1312px;
  margin: 0 auto;
`;

const HotPickTabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HotPickTabBox = styled.div`
  ${FONT_STYLES.P_B}
  font-size: 19px;
  width: 80px;
  display: flex;
  justify-content: space-between;
  color: ${COLOR.GRAY_3};
  p:nth-child(1) {
    color: ${({ activeDot1 }) => (activeDot1 ? `${COLOR.BLUE_3}` : '')};
    border-bottom: ${({ activeDot1 }) => (activeDot1 ? `4px solid ${COLOR.BLUE_3}` : '')};
  }
  p:nth-child(2) {
    color: ${({ activeDot2 }) => (activeDot2 ? `${COLOR.BLUE_3}` : '')};
    border-bottom: ${({ activeDot2 }) => (activeDot2 ? `4px solid ${COLOR.BLUE_3}` : '')};
  }
`;

const HotPickTab = styled.p`
  padding-bottom: 4px;
  :hover {
    cursor: pointer;
  }
`;

const HotPickBannerContainer = styled.div`
  height: 360px;
`;

const Best5Section = styled.div`
  padding: 60px 304px;

  @media screen and (min-width: 1700px) {
    padding: 77px 0px 88px 0px;
  }

  @media screen and (max-width: 1700px) {
    padding: 77px 0px 88px 0px;
  }
`;

const Best5Container = styled.div`
  width: 1312px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`;

const BestLectureContainer = styled.div`
  width: 535px;
  height: 649px;
  padding: 44px 45px 32px 45px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 20px;
  border-radius: 19px;
`;

const BestLectureTabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BestLectureTabBox = styled.div`
  ${FONT_STYLES.P_M}
  font-size: 18px;
  width: 217px;
  display: flex;
  justify-content: space-between;
  color: ${COLOR.GRAY_5};

  p {
    padding-bottom: 5px;
  }

  p:hover {
    cursor: pointer;
  }

  .active {
    ${FONT_STYLES.P_B}
    font-size: 18px;
    color: ${COLOR.BLUE_4};
    border-bottom: 3px solid ${COLOR.BLUE_4};
  }
`;

const BestBookTabBox = styled(BestLectureTabBox)``;

const BestBookTabContainer = styled(BestLectureTabContainer)``;

const BestBookContainer = styled(BestLectureContainer)`
  width: 758px;
  padding: 44px 45px 28px 46px;
`;

const Best5SectionTitle = styled.p`
  ${FONT_STYLES.P_B}
  font-size: 36px;
  color: ${COLOR.BLUE_3};
`;

const ReviewSection = styled.div`
  width: 100%;
  padding: 51px 304px 48px 304px;
  background-color: ${COLOR.BLUE_5};

  @media screen and (min-width: 1700px) {
    padding: 77px 0px 88px 0px;
  }

  @media screen and (max-width: 1700px) {
    padding: 77px 0px 88px 0px;
  }
`;

const ReviewContainer = styled.div`
  width: 1312px;
  margin: 0 auto;
`;

const RevewTabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ReviewSectionInfo = styled.p`
  ${FONT_STYLES.P_SB}
  font-size: 20px;
  margin-top: 35px;
  color: ${COLOR.BLUE_3};
`;

const ReviewLinkTabBox = styled.div`
  ${FONT_STYLES.P_EB}
  font-size: 18px;
  color: ${COLOR.BLUE_3};
  display: flex;
  align-items: center;
  p {
    padding-right: 9px;
  }
  :hover {
    cursor: pointer;
  }
`;

const AdSection = styled.div`
  padding: 60px 303px 63px 304px;

  @media screen and (min-width: 1700px) {
    padding: 77px 0px 88px 0px;
  }

  @media screen and (max-width: 1700px) {
    padding: 77px 0px 88px 0px;
  }
`;

const AdContainer = styled.div`
  width: 1312px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`;

const LeftAdContainer = styled.div`
  height: 332px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${COLOR.GRAY_0};
`;

const LeftAdContent1 = styled.div`
  background-image: url(${({ backgroundImg }) => backgroundImg});
  width: 650px;
  height: 161px;
  padding: 32px 0 17px 32px;
  cursor: pointer;

  p:nth-child(1) {
    ${FONT_STYLES.N_B}
    font-size: 22px;
    line-height: 25px;
  }

  p:nth-child(1) span {
    ${FONT_STYLES.N_EB}
    font-size: 22px;
  }

  p:nth-child(2) {
    ${FONT_STYLES.N_B}
    font-size: 15px;
    padding-top: 26px;
  }
`;

const LeftAdContent2 = styled(LeftAdContent1)`
  padding: 21px 0 17px 32px;
  cursor: pointer;
  p:nth-child(1) {
    ${FONT_STYLES.N_B}
    font-size: 15px;
  }

  p:nth-child(2) {
    ${FONT_STYLES.N_EB}
    font-size: 22px;
    line-height: 29px;
    padding-top: 0;
    span {
      color: ${COLOR.YELLOW_0};
    }
  }

  p:nth-child(3) {
    ${FONT_STYLES.N_B}
    font-size: 16px;
    padding-top: 20px;
  }
`;

const RightAdContainer = styled.div`
  color: ${COLOR.GRAY_0};
  cursor: pointer;
`;

const AdTextBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div:nth-child(1) p:nth-child(1) {
    ${FONT_STYLES.N_EB}
    font-size: 32px;
    line-height: 43px;
  }

  & > div:nth-child(1) p:nth-child(2) {
    ${FONT_STYLES.N_EB}
    font-size: 36px;
    line-height: 48px;
  }

  & > div:nth-child(2) p:nth-child(1) {
    ${FONT_STYLES.N_B}
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 9px;
  }

  & > div:nth-child(2) p:nth-child(2) {
    ${FONT_STYLES.N_B}
    font-size: 18px;
  }
`;

const RightContent = styled.div`
  background-image: url(${({ backgroundImg }) => backgroundImg});
  width: 651px;
  height: 100%;
  padding: 45px 0 48px 45px;
`;

export {
  MainPageContainer,
  BannerContainer,
  NewLectureSection,
  NewLecturesContainer,
  SectionTitle,
  Line,
  TitleBox,
  SectionInfo,
  NewLectureContents,
  HotPickSection,
  HotPickContainer,
  HotPickTabContainer,
  HotPickTabBox,
  HotPickTab,
  HotPickBannerContainer,
  Best5Section,
  BestLectureContainer,
  BestLectureTabContainer,
  BestLectureTabBox,
  BestBookContainer,
  BestBookTabContainer,
  BestBookTabBox,
  Best5SectionTitle,
  ReviewSection,
  RevewTabContainer,
  ReviewLinkTabBox,
  AdSection,
  LeftAdContainer,
  RightAdContainer,
  LeftAdContent1,
  LeftAdContent2,
  RightContent,
  AdTextBox,
  ReviewSectionInfo,
  Best5Container,
  AdContainer,
  ReviewContainer,
};
