import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  PassNoteContainer,
  PassNoteTitle,
  HorizonLine,
  ButtonBox,
  ButtonContainer,
  PassNoteSubTitle,
  PassNoteTable,
  PassNoteData,
  PassNoteDataInfo,
  PaginationBox,
  DataNotFound,
  BestTag,
} from './style';
import Pagination from '../../../molecules/Pagination';
import { Button } from '../../../atoms/Button/index';
import { ReactComponent as PrevBtn } from '../../../../assets/icons/icon-PrevBtnFull.svg';
import { ReactComponent as NextBtn } from '../../../../assets/icons/icon-NextBtnFull.svg';
import Filter from '../../../molecules/Filter';
import Search from '../../../molecules/Search';

import PassNoteDetailPage from '../PassNoteDetail/PassNoteDetailPage';

import { SpinLoader, typeList } from '../../../atoms/SpinLoader';
import { useChangeMonthNumToEn } from '../../../../hooks/useChangeMonthNumToEn';

import { isLoginAtom } from '../../../../recoil/AuthStore/AuthRecoil';

import { useRecoilValue, useRecoilState } from 'recoil';
import {
  examInfoPassNotePageInfoAtom,
  examInfoPassNoteListAtom,
} from '../../../../recoil/ExamInfoStore/ExamInfoRecoil';
import { selectedPassNoteFilterMenuAtom } from '../../../../recoil/SelectedFilterStore/SelectedFilterRecoil';

import { useQuery } from 'react-query';
import useHandleError from '../../../../hooks/useHandleError';
import {
  getPassReviewPageInfo,
  getPassReviewList,
  getBestPassReviewList,
} from '../../../../axiosManage/ExamInfo';

/** 카테고리 필터링 */
const FILTER_MENU = [
  {
    id: 0,
    category: '5급 공채',
  },
  {
    id: 1,
    category: '국립 외교원',
  },
  {
    id: 2,
    category: '지역인재',
  },
  {
    id: 3,
    category: '7급 공채',
  },
];

function PassNote() {
  const isLogin = useRecoilValue(isLoginAtom);
  const { handleError } = useHandleError();
  const { id } = useParams();
  const navigate = useNavigate();

  /* 페이지네이션 */
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(6);

  const clickPrevBtn = (e) => {
    setCurrentPage(currentPage - 1);
  };
  const clickNextBtn = (e) => {
    setCurrentPage(currentPage + 1);
  };

  /** 베스트 합격후기 */
  const [bestPassReviewList, setProfessorBestReviewList] = useState([]);
  const { refetch: bestPassReviewListRefetch } = useQuery(
    'getBestPassReviewList',
    getBestPassReviewList,
    {
      retry: 0,
      onSuccess: (data) => {
        setProfessorBestReviewList(data.data.passReviewList);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  /** 필터링 & 검색 기능 */
  const [keyword, setKeyword] = useState('');
  const [isSearchBtnClicked, setIsSearchBtnClicked] = useState(false);
  const keywordCheck = (e) => {
    setKeyword(e.currentTarget.value);
  };

  // 1. 유저가 선택한 필터링 카테고리 목록
  const selectedPassNoteFilterCategory = useRecoilValue(selectedPassNoteFilterMenuAtom);

  // 2. 서버에서 받아온 페이징 정보를 전역 상태에 저장
  const [examInfoPassNotePageInfo, setExamInfoPassNotePageInfo] = useRecoilState(
    examInfoPassNotePageInfoAtom,
  );
  const { refetch: examInfoPassNotePageInfoRefetch } = useQuery(
    ['getPassReviewPageInfo', selectedPassNoteFilterCategory],
    () =>
      getPassReviewPageInfo(
        String([...selectedPassNoteFilterCategory]).replace(/,/g, ' '),
        keyword,
      ),
    {
      retry: 0,
      onSuccess: (data) => {
        setExamInfoPassNotePageInfo(data.data.total);
        if (isSearchBtnClicked) {
          setIsSearchBtnClicked(false);
        }
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  useEffect(() => {
    examInfoPassNotePageInfoRefetch();
    setCurrentPage(1);
  }, [isSearchBtnClicked]);

  // 3. 서버에서 받아온 실제 데이터를 전역 상태에 저장
  const [examInfoPassNoteList, setExamInfoPassNoteList] = useRecoilState(examInfoPassNoteListAtom);
  const { refetch: examInfoPassNoteListRefetch } = useQuery(
    ['getPassReviewList', selectedPassNoteFilterCategory, currentPage],
    () =>
      getPassReviewList(
        currentPage,
        String([...selectedPassNoteFilterCategory]).replace(/,/g, ' '),
        keyword,
      ),
    {
      retry: 0,
      enabled: false,
      onSuccess: (data) => {
        setExamInfoPassNoteList(data.data.passReviewList);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  useEffect(() => {
    examInfoPassNoteListRefetch();
    bestPassReviewListRefetch();
  }, [selectedPassNoteFilterCategory, isSearchBtnClicked, currentPage, navigate]);

  useEffect(() => {
    if (navigate) {
      setCurrentPage(currentPage);
    }
  }, [navigate]);

  return (
    <>
      {id ? (
        <PassNoteDetailPage />
      ) : (
        <PassNoteContainer>
          <PassNoteTitle>합격후기</PassNoteTitle>
          <ButtonContainer>
            {isLogin === true ? (
              <Button
                width={124}
                height={48}
                onClickFunc={() => (navigate(`/examinfo/passReviewCreate`))}
              >
                글쓰기
              </Button>
            ) : (
              ''
            )}
          </ButtonContainer>
          <ButtonBox>
            <Filter
              filterMenu={FILTER_MENU}
              filterName={'카테고리'}
              filterLocation={'수험정보&팁 합격후기'}
              setCurrentPage={setCurrentPage}
            />
            <Search keywordCheck={keywordCheck} setIsSearchBtnClicked={setIsSearchBtnClicked} />
          </ButtonBox>
          <HorizonLine />
          <PassNoteTable>
            {bestPassReviewList !== undefined ? (
              <>
                {bestPassReviewList.map((data) => (
                  <PassNoteData
                    key={data.id}
                    onClick={(e) => (navigate(`/examinfo/passnote/${data.id}`))}
                  >
                    <BestTag>BEST</BestTag>
                    <div>
                      <p>{useChangeMonthNumToEn(data.createdAt?.slice(5, 7))}</p>
                      <p>{data.createdAt?.slice(8, 10)}</p>
                      <p>{data.createdAt?.slice(0, 4)}</p>
                    </div>
                    <div>
                      <p>{data.category}</p>
                      <p>{data.title}</p>
                    </div>
                    <PassNoteDataInfo>
                      <div>
                        <p>작성자</p>
                        <p>{data.name}</p>
                      </div>
                      <div>
                        <p>조회</p>
                        {data.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </div>
                    </PassNoteDataInfo>
                  </PassNoteData>
                ))}
              </>
            ) : (
              ''
            )}
            {examInfoPassNoteList !== undefined ? (
              examInfoPassNoteList.length < 1 ? (
                <DataNotFound>
                  <p>게시글이 존재하지 않습니다!</p>
                </DataNotFound>
              ) : (
                <>
                  {examInfoPassNoteList.map((data, i) => (
                    <PassNoteData
                      key={data.id}
                      onClick={(e) => (navigate(`/examinfo/passnote/${data.id}`))}
                    >
                      <div>{i + 1}</div>
                      <div>
                        <p>{useChangeMonthNumToEn(data.createdAt?.slice(5, 7))}</p>
                        <p>{data.createdAt?.slice(8, 10)}</p>
                        <p>{data.createdAt?.slice(0, 4)}</p>
                      </div>
                      <div>
                        <p>{data.category}</p>
                        <p>{data.title}</p>
                      </div>
                      <PassNoteDataInfo>
                        <div>
                          <p>작성자</p>
                          <p>{data.name}</p>
                        </div>
                        <div>
                          <p>조회</p>
                          {data.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                      </PassNoteDataInfo>
                    </PassNoteData>
                  ))}
                  <PaginationBox>
                    <PrevBtn
                      className="prev-btn"
                      onClick={(e) => {
                        if (currentPage === 1) {
                          e.preventDefault();
                        } else {
                          clickPrevBtn();
                        }
                      }}
                    />
                    <Pagination
                      currentPage={currentPage}
                      postsPerPage={postsPerPage}
                      totalPosts={examInfoPassNotePageInfo}
                      paginate={setCurrentPage}
                    ></Pagination>
                    <NextBtn
                      className="next-btn"
                      onClick={(e) => {
                        if (currentPage === Math.ceil(examInfoPassNotePageInfo / postsPerPage)) {
                          e.preventDefault();
                        } else {
                          clickNextBtn();
                        }
                      }}
                    />
                  </PaginationBox>
                </>
              )
            ) : (
              <SpinLoader type={typeList[2].prop} color={'#000'} width={1311} height={715} />
            )}
          </PassNoteTable>
        </PassNoteContainer>
      )}
    </>
  );
}

export default PassNote;
