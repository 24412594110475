import React, { useState, useEffect } from 'react';
import { RegularPassPageContainer, ContentContainer, InfoBox, BuyBox } from './style';

import { useRecoilState, useRecoilValue } from 'recoil';
import { isLoginAtom } from '../../recoil/AuthStore/AuthRecoil';
import { myOrderAtom } from '../../recoil/OrderBasketStore/OrderBasketRecoil';
import { useNavigate, useParams } from 'react-router-dom';

import { getRefundText } from '../../axiosManage/Assignment';
import { getPassDetail } from '../../axiosManage/Professor';
import { getMyPass } from '../../axiosManage/MyPage';
import useHandleError from '../../hooks/useHandleError';
import { myInfoAtom } from '../../recoil/MyPageStore/MyPageRecoil';
import { BasicCheckBox } from '../../components/atoms/CheckBox';

function PassDetailPage() {
  const isLogin = useRecoilValue(isLoginAtom);
  const navigate = useNavigate();
  const { id } = useParams();
  const { handleError } = useHandleError();

  const [passDetail, setPassDetail] = useState([]);
  useEffect(() => {
    getPassDetail(id)
      .then((res) => {
        setPassDetail(res.data.passInfo);
      })
      .catch((error) => {
        handleError(error);
      });
  }, [id]);

  const [myPassList, setMyPassList] = useState([]);
  useEffect(() => {
    if (isLogin === true) {
      getMyPass().then((res) => {
        setMyPassList(res.data.myPassList);
      });
    }
  }, [isLogin]);

  const [refundText, setRefundText] = useState('');
  useEffect(() => {
    getRefundText()
      .then((res) => {
        setRefundText(JSON.parse(res.data.data));
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  const [isAgree, setIsAgree] = useState(false);
  const clickAgree = () => {
    setIsAgree((isAgree) => !isAgree);
  };

  // 구매하기 버튼 클릭 했을 때
  const [selectedProduct, setSelectedProduct] = useRecoilState(myOrderAtom);
  const clickOrderButton = (pass) => {
    const isPassExist = myPassList.find((pass) => pass.passId === pass.id);

    if (isLogin === false) {
      alert('로그인을 해주세요.');
    } else if (isAgree === false) {
      alert('상품 이용관련 상품안내 및 환불규정에 동의해주세요.');
    } else {
      if (isPassExist) {
        alert('이미 구매하신 패스 상품입니다.');
      } else {
        setSelectedProduct(pass);
        navigate(`/order`);
      }
    }
  };

  function createMarkup(data) {
    return { __html: data?.replace(/\n/gi, '<br/>') };
  }

  return (
    <RegularPassPageContainer>
      <ContentContainer>
        <img src={passDetail.introductionImg} style={{ marginTop: '50px' }} />
        <InfoBox>
          <div>이용권 구매하기</div>
          <BuyBox>
            <div>
              <BasicCheckBox
                onClickFunc={() => {
                  clickAgree();
                }}
              />
              <span>상품 이용관련 상품안내 및 환불규정을 모두 확인하였고, 이에 동의합니다.</span>
            </div>
            <button onClick={() => clickOrderButton(passDetail)}>결제하기</button>
          </BuyBox>
          <div dangerouslySetInnerHTML={createMarkup(refundText.pass)} />
          {/* <div>
            {refundText &&
              refundText.pass.split('\n').map((content, j) => {
                return <p key={j}>{content}</p>;
              })}
          </div> */}
        </InfoBox>
      </ContentContainer>
    </RegularPassPageContainer>
  );
}

export default PassDetailPage;
