import styled from 'styled-components';
import COLOR from '../../../../styles/common/color';
import { FONT_STYLES } from '../../../../styles/common/font-styles';

const SubBannerContainer = styled.div`
  // ${({ theme }) => theme.container.sidePadding}
  position: relative;
  width: 100%;
  height: 450px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${({ backgroundImg }) => backgroundImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 20px;
`;

const SubBannerTextContainer = styled.div`
  p:nth-child(1) {
    ${FONT_STYLES.P_EB}
    font-size: 52px;
    line-height: 63px;
  }
  p:nth-child(2) {
    ${FONT_STYLES.P_M}
    font-size: 20px;
    line-height: 24px;
  }
  color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const HorizonLine = styled.div`
  width: 45px;
  border-bottom: 3px solid ${COLOR.GRAY_0};
  margin: 30px auto;
`;

export { SubBannerContainer, SubBannerTextContainer, HorizonLine };
