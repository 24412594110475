import React, { forwardRef } from 'react';
import {
  StyledInput,
  StyledSquareInput,
  StyledSearchIconInput,
  StyledCustomInput,
  StyledCustomBirthInput,
} from './style';
import CalendarBtnIcon from '../../../assets/icons/icon-CalendarBtn.png';

const Input = ({ inputName, width, height, placeholder, onChangeFunc, defaultValue }) => {
  return (
    <StyledInput
      name={inputName}
      width={width}
      height={height}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={onChangeFunc}
    />
  );
};

export const SquareInput = ({
  inputName,
  width,
  height,
  placeholder,
  onChangeFunc,
  value,
  defaultValue,
  textAlign,
  type,
}) => {
  return (
    <StyledSquareInput
      name={inputName}
      width={width}
      height={height}
      placeholder={placeholder}
      onChange={onChangeFunc}
      defaultValue={defaultValue}
      value={value}
      textAlign={textAlign}
      type={type}
    />
  );
};

export const SearchIconInput = ({
  inputName,
  width,
  height,
  placeholder,
  onChangeFunc,
  onKeyPressFunc,
}) => {
  return (
    <StyledSearchIconInput
      name={inputName}
      width={width}
      height={height}
      placeholder={placeholder}
      onChange={onChangeFunc}
      onKeyPress={onKeyPressFunc}
    />
  );
};

export const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => {
  return (
    <StyledCustomInput onClick={onClick} ref={ref}>
      <div>{value}</div>
      <div>
        <img src={CalendarBtnIcon} />
      </div>
    </StyledCustomInput>
  );
});

export const DatePickerBirthInput = forwardRef(({ value, onClick }, ref) => {
  return (
    <StyledCustomBirthInput onClick={onClick} ref={ref}>
      <div>{value}</div>
    </StyledCustomBirthInput>
  );
});

export default Input;
