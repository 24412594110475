import { useAxios } from '../../hooks/useAxios';

/** 이용 약관 API */
export const getAgreeText = async () => {
  return await useAxios.get(`/agree-text`);
};

/** 환불 정책 API */
export const getRefundText = async () => {
  return await useAxios.get(`/refund-text`);
};
