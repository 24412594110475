import styled, { css } from 'styled-components';
import COLOR from '../../../styles/common/color';
import { FONT_STYLES } from '../../../styles/common/font-styles';
import Check from '../../../assets/icons/icon-Check.svg';

export const StyledBasicCheckBox = styled.input`
  width: 15px;
  height: 15px;
  appearance: none;
  border: 1px solid ${COLOR.GRAY_3};
  border-radius: 2px;
  cursor: pointer;

  &:checked {
    border: transparent;
    border-radius: 2px;
    background-color: ${COLOR.BLUE_4};
    background-image: url(${Check});
    background-repeat: no-repeat;
    background-position: center;
  }
`;

// 동그란 체크박스
export const StyledCircleCheckboxWrapper = styled.div`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : '30px')};
  height: ${({ height }) => (height ? `${height}px` : '30px')};
`;

export const StyledCircleCheckbox = styled.input`
  visibility: hidden;
  ${({ isChecked }) =>
    isChecked
      ? css`
          border: 2px solid ${COLOR.BLUE_4};
          &:after: {
            opacity: 1;
          }
        `
      : null}
`;

export const StyledCircleLabel = styled.label`
  width: ${({ width }) => (width ? `${width}px` : '30px')};
  height: ${({ height }) => (height ? `${height}px` : '30px')};
  border: 2px solid ${COLOR.GRAY_3};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;

  &:after {
    border: 2px solid ${COLOR.GRAY_3};
    border-top: none;
    border-right: none;
    content: '';
    height: 6px;
    left: 7px;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }

  ${({ isChecked }) =>
    isChecked
      ? css`
          border: 2px solid ${COLOR.BLUE_4};
          &:after {
            border: 2px solid ${COLOR.BLUE_4};
            border-top: none;
            border-right: none;
            content: '';
            height: 6px;
            left: 7px;
            position: absolute;
            top: 8px;
            transform: rotate(-45deg);
            width: 12px;
          }
        `
      : null}
`;
