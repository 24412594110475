import React, { useState, useEffect } from 'react';
import {
  ArrowContainer,
  BestReview,
  EventBanner,
  ExLecture,
  HomeContainer,
  ReviewBanner,
  ReviewBannerContainer,
  ReviewContainer,
  Section1,
  Section1Container,
  Section2,
  Section3,
  Subtitle,
  VideoContainer,
} from './style';
import Iframe from 'react-iframe';
import LecturesTable from '../Lectures/LecturesTable';
import CurriculumImg from '../Curriculum/CurriculumImg';
import LectureReviewSlides from '../LectureReviewSlides';

import { useQuery } from 'react-query';
import useHandleError from '../../../../hooks/useHandleError';
import { getProfessorSideBanner } from '../../../../axiosManage/Professor';
import { getProfessorPreviewLecture } from '../../../../axiosManage/Video';

const Home = ({ professorId }) => {
  const { handleError } = useHandleError();

  /** url을 통한 교수 ID 설정 */
  const [ProfessorId, setProfessorId] = useState(1);
  useEffect(() => {
    setProfessorId(professorId);
  }, [professorId]);

  /** 교수별 자료실 상단 고정 게시글 */
  const [professorSideBannerImg, setProfessorSideBannerImg] = useState([]);
  const [professorSideBannerUrl, setProfessorSideBannerUrl] = useState([]);
  const { refetch: professorSideBannerStatus } = useQuery(
    ['getProfessorSideBanner', ProfessorId],
    () => getProfessorSideBanner(ProfessorId),
    {
      retry: 0,
      onSuccess: (data) => {
        setProfessorSideBannerImg(data.data.professorSideBannerImg);
        setProfessorSideBannerUrl(data.data.professorSideBannerUrl);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  const goToEventBannerUrl = () => {
    if (professorSideBannerUrl !== null) {
      window.open('about:blank').location.href = professorSideBannerUrl;
    }
  };

  return (
    <HomeContainer>
      <Section1>
        <ExLecture>
          <Subtitle>BEST 강좌</Subtitle>
          <VideoContainer>
            {ProfessorId && (
              <iframe
                src={`/preview.html?url=https://free-labstandard.service.concdn.com/${ProfessorId}.mp4`}
                width="646px"
                height="369px"
                display="block"
                position="relative"
              />
            )}
          </VideoContainer>
        </ExLecture>
        <BestReview>
          <Subtitle>BEST 수강후기</Subtitle>
          <ReviewContainer>
            <ReviewBannerContainer>
              <ReviewBanner>
                <LectureReviewSlides professorId={professorId} />
              </ReviewBanner>
            </ReviewBannerContainer>
            {professorSideBannerImg !== null ? (
              <EventBanner backgroundImg={professorSideBannerImg} onClick={goToEventBannerUrl} />
            ) : (
              ''
            )}
          </ReviewContainer>
        </BestReview>
      </Section1>
      <Section2>
        <Subtitle>커리큘럼</Subtitle>
        <CurriculumImg professorId={professorId} />
      </Section2>
      <Section3>
        <Subtitle>개설 강좌</Subtitle>
        <LecturesTable professorId={professorId} />
      </Section3>
    </HomeContainer>
  );
};

export default Home;
