import { useAxios } from '../../hooks/useAxios';

/** 메인페이지 - 메인 배너 */
export const getMainBanner = async () => {
  return await useAxios.get('/main-banner');
};

/** 메인페이지 - 하단 배너 */
export const getBottomBanner = async () => {
  return await useAxios.get('/bottom-banner');
};

/** 메인페이지 - 신규 강의 데이터 */
export const getNewLecture = async () => {
  return await useAxios.get('/lecture/new');
};

/** 메인페이지 - HotPick 강의 데이터 */
export const getHotLecture = async () => {
  return await useAxios.get('/lecture/hot');
};

/** 메인페이지 - HotPick 교재 데이터 */
export const getHotBook = async () => {
  return await useAxios.get('/book/hot');
};

/** 메인페이지 - Best 강의 데이터 */
export const getBestLecture = async () => {
  return await useAxios.get('/lecture/best');
};

/** 메인페이지 - Best 교재 데이터 */
export const getBestBook = async () => {
  return await useAxios.get('/book/best');
};
