import React, { useState } from 'react';
import { Button } from '../../components/atoms/Button';
import { TabTitle } from '../../components/organisms/LecturePageElements/Lectures/style';
import COLOR from '../../styles/common/color';
import { ButtonContainer, ContentContainer } from '../QuestionDetail/style';
import {
  ReviewDetailContainer,
  ReviewDetailBox,
  DetailTitle,
  Info,
  Attachments,
  HorizonLine,
} from './style';
import { useNavigate, useParams } from 'react-router-dom';
import ToastViewer from '../../components/atoms/ToastTextViewer';

import { SpinLoader, typeList } from '../../components/atoms/SpinLoader';
import { useChangeMonthNumToEn } from '../../hooks/useChangeMonthNumToEn';
import { useQuery } from 'react-query';

import { getReview, deleteReview } from '../../axiosManage/Professor';
import useHandleError from '../../hooks/useHandleError';

import { useRecoilState } from 'recoil';
import { myContactAtom, myReviewId } from '../../recoil/MyPageStore/MyPageRecoil';

const ReviewDetailPage = () => {
  const { handleError } = useHandleError();
  const navigate = useNavigate();
  const { id } = useParams();

  const [reviewId, setReviewContentId] = useRecoilState(myReviewId);
  const [reviewDetail, setReviewDetail] = useState([]);
  const [isWriter, setIsWriter] = useState(false);
  const { status: reviewDetailStatus } = useQuery(['getReview', id], () => getReview(id), {
    retry: 0,
    enabled: !!id,
    onSuccess: (data) => {
      setReviewContentId(id);
      if (data.data.isWriter === true) {
        setIsWriter(true);
      }
      if (data.data.review !== undefined) {
        setReviewDetail(data.data.review);
      }
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [reviewContent, setReviewContent] = useRecoilState(myContactAtom);
  const clickModify = (content) => {
    setReviewContent(content);
    navigate('/review/modify');
  };

  const clickDelete = (reviewId) => {
    let isYes = confirm('해당 수강후기를 삭제하시겠습니까?');

    if (isYes) {
      deleteReview(reviewId)
        .then((res) => {
          alert('삭제되었습니다.');
          navigate(-1);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  return (
    <ReviewDetailContainer>
      <TabTitle>수강후기</TabTitle>
      <HorizonLine />
      <ReviewDetailBox>
        {reviewDetail !== undefined ? (
          <>
            <DetailTitle>{reviewDetail.title}</DetailTitle>
            <Info>
              <div>
                <p>{reviewDetail.createdAt?.slice(8, 10)}</p>
                <p>{useChangeMonthNumToEn(reviewDetail.createdAt?.slice(5, 7))}</p>
                <p>{reviewDetail.createdAt?.slice(0, 4)}</p>
              </div>
              <div>
                <p>작성자</p>
                <p>{reviewDetail.name}</p>
              </div>
              <div>
                <p>조회</p>
                <p>{reviewDetail.views}</p>
              </div>
            </Info>
            <ToastViewer content={reviewDetail.content} />
            {reviewDetail.existFile ? (
              <Attachments>
                <span>첨부 파일</span>
                <a href="#">
                  <span>{reviewDetail.fileName}</span>
                </a>
              </Attachments>
            ) : null}
          </>
        ) : (
          <SpinLoader type={typeList[2].prop} color={'#000'} width={1131} height={459} />
        )}
      </ReviewDetailBox>
      <ButtonContainer>
        {isWriter ? (
          <>
            <Button width={120} height={48} onClickFunc={() => clickModify(reviewDetail)}>
              수정
            </Button>
            <Button width={120} height={48} onClickFunc={() => clickDelete(Number(id))}>
              삭제
            </Button>
          </>
        ) : (
          ''
        )}
        <Button width={124} height={48} bgColor={COLOR.BLUE_4} onClickFunc={() => navigate(-1)}>
          목록으로
        </Button>
      </ButtonContainer>
    </ReviewDetailContainer>
  );
};

export default ReviewDetailPage;
