import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Pagination from '../../../molecules/Pagination';
import {
  ListContainer,
  ListTitle,
  ButtonBox,
  HorizonLine,
  ListTable,
  DataNotFound,
  ListData,
  ListDataInfo,
  PaginationBox,
} from '../common/list-style';
import ErrataDetailPage from '../ErrataDetail';

import { ReactComponent as PrevBtn } from '../../../../assets/icons/icon-PrevBtnFull.svg';
import { ReactComponent as NextBtn } from '../../../../assets/icons/icon-NextBtnFull.svg';
import { ReactComponent as Bell } from '../../../../assets/icons/icon-bell.svg';
import Filter from '../../../molecules/Filter';
import Search from '../../../molecules/Search';
import { SpinLoader, typeList } from '../../../atoms/SpinLoader';

import { useChangeMonthNumToEn } from '../../../../hooks/useChangeMonthNumToEn';

import { useRecoilValue, useRecoilState } from 'recoil';
import {
  supportCenterErrataPageInfoAtom,
  supportCenterErrataListAtom,
} from '../../../../recoil/SupportCenterStore/SupportCenterRecoil';
import { selectedErrataFilterMenuAtom } from '../../../../recoil/SelectedFilterStore/SelectedFilterRecoil';

import { useQuery } from 'react-query';
import useHandleError from '../../../../hooks/useHandleError';
import {
  getErrataPageInfo,
  getErrata,
  getTopFixErrataList,
} from '../../../../axiosManage/SupportCenter';

const FILTER_MENU = [
  {
    id: 0,
    category: '언어논리',
  },
  {
    id: 1,
    category: '자료해석',
  },
  {
    id: 2,
    category: '상황판단',
  },
  {
    id: 3,
    category: '공통',
  },
];

function Errata() {
  const { handleError } = useHandleError();
  const { id } = useParams();
  const navigate = useNavigate();

  /** 정오표 상단 고정 게시글 */
  const [supportCenterTopErrataList, setSupportCenterTopErrataList] = useState([]);
  const { refetch: supportCenterTopErrataListRefetch } = useQuery(
    'getTopFixErrataList',
    getTopFixErrataList,
    {
      retry: 0,
      onSuccess: (data) => {
        setSupportCenterTopErrataList(data.data.erratas);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  /* 페이지네이션 */
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(6);

  const clickPrevBtn = (e) => {
    setCurrentPage(currentPage - 1);
  };
  const clickNextBtn = (e) => {
    setCurrentPage(currentPage + 1);
  };

  /** 필터링 & 검색 기능 */
  const [keyword, setKeyword] = useState('');
  const [isSearchBtnClicked, setIsSearchBtnClicked] = useState(false);
  const keywordCheck = (e) => {
    setKeyword(e.currentTarget.value);
  };

  // 1. 유저가 선택한 필터링 카테고리 목록
  const selectedErrataFilterCategory = useRecoilValue(selectedErrataFilterMenuAtom);

  // 2. 서버에서 받아온 페이징 정보를 전역 상태에 저장
  const [supportCenterErrataPageInfo, setSupportCenterErrataPageInfo] = useRecoilState(
    supportCenterErrataPageInfoAtom,
  );
  const { refetch: supportCenterErrataPageInfoRefetch } = useQuery(
    ['getErrataPageInfo', selectedErrataFilterCategory],
    () => getErrataPageInfo(keyword, String([...selectedErrataFilterCategory]).replace(/,/g, ' ')),
    {
      retry: 0,
      onSuccess: (data) => {
        setSupportCenterErrataPageInfo(data.data.total);
        if (isSearchBtnClicked) {
          setIsSearchBtnClicked(false);
        }
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  useEffect(() => {
    supportCenterErrataPageInfoRefetch();
    setCurrentPage(1);
  }, [isSearchBtnClicked]);

  // 3. 서버에서 받아온 실제 데이터를 전역 상태에 저장
  const [supportCenterErrataList, setSupportCenterErrataList] = useRecoilState(
    supportCenterErrataListAtom,
  );
  const { refetch: supportCenterErrataListRefetch } = useQuery(
    ['getErrata', selectedErrataFilterCategory, currentPage],
    () =>
      getErrata(keyword, String([...selectedErrataFilterCategory]).replace(/,/g, ' '), currentPage),
    {
      retry: 0,
      enabled: false,
      onSuccess: (data) => {
        setSupportCenterErrataList(data.data.erratas);
      },
      onError: (error) => {
        handleError(error);
      },
    },
  );

  useEffect(() => {
    supportCenterErrataListRefetch();
  }, [selectedErrataFilterCategory, isSearchBtnClicked, currentPage, navigate]);

  useEffect(() => {
    if (navigate) {
      setCurrentPage(currentPage);
    }
  }, [navigate]);

  return (
    <>
      {id ? (
        <ErrataDetailPage />
      ) : (
        <ListContainer>
          <ListTitle>자료실 & 정오표</ListTitle>
          <ButtonBox>
            <Filter
              filterMenu={FILTER_MENU}
              filterName={'과목'}
              filterLocation={'학습지원센터 정오표'}
              setCurrentPage={setCurrentPage}
            />
            <Search keywordCheck={keywordCheck} setIsSearchBtnClicked={setIsSearchBtnClicked} />
          </ButtonBox>
          <HorizonLine />
          <ListTable>
            {supportCenterTopErrataList !== undefined ? (
              <>
                {supportCenterTopErrataList.map((data, i) => (
                  <ListData
                    key={data.id}
                    onClick={(e) => navigate(`/supportcenter/errata/${data.id}`)}
                  >
                    <div>
                      <Bell />
                    </div>
                    <div>
                      <p>{useChangeMonthNumToEn(data.createdAt?.slice(5, 7))}</p>
                      <p>{data.createdAt?.slice(8, 10)}</p>
                      <p>{data.createdAt?.slice(0, 4)}</p>
                    </div>
                    <div>
                      <p>{data.category}</p>
                      <p>{data.title}</p>
                    </div>
                    <ListDataInfo>
                      <div>
                        <p>LAB</p>
                        <p>STANDARD</p>
                      </div>
                      <div>
                        <p>조회</p>
                        {data.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </div>
                    </ListDataInfo>
                  </ListData>
                ))}
              </>
            ) : (
              ''
            )}
            {supportCenterErrataList !== undefined ? (
              supportCenterErrataList.length < 1 ? (
                <DataNotFound>
                  <p>게시글이 존재하지 않습니다!</p>
                </DataNotFound>
              ) : (
                <>
                  {supportCenterErrataList.map((data, i) => (
                    <ListData
                      key={data.id}
                      onClick={(e) => navigate(`/supportcenter/errata/${data.id}`)}
                    >
                      <div>{i + 1}</div>
                      <div>
                        <p>{useChangeMonthNumToEn(data.createdAt?.slice(5, 7))}</p>
                        <p>{data.createdAt?.slice(8, 10)}</p>
                        <p>{data.createdAt?.slice(0, 4)}</p>
                      </div>
                      <div>
                        <p>{data.category}</p>
                        <p>{data.title}</p>
                      </div>
                      <ListDataInfo>
                        <div>
                          <p>LAB</p>
                          <p>STANDARD</p>
                        </div>
                        <div>
                          <p>조회</p>
                          {data.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                      </ListDataInfo>
                    </ListData>
                  ))}
                  <PaginationBox>
                    <PrevBtn
                      className="prev-btn"
                      onClick={(e) => {
                        if (currentPage === 1) {
                          e.preventDefault();
                        } else {
                          clickPrevBtn();
                        }
                      }}
                    />
                    <Pagination
                      currentPage={currentPage}
                      postsPerPage={postsPerPage}
                      totalPosts={supportCenterErrataPageInfo}
                      paginate={setCurrentPage}
                    ></Pagination>
                    <NextBtn
                      className="next-btn"
                      onClick={(e) => {
                        if (currentPage === Math.ceil(supportCenterErrataPageInfo / postsPerPage)) {
                          e.preventDefault();
                        } else {
                          clickNextBtn();
                        }
                      }}
                    />
                  </PaginationBox>
                </>
              )
            ) : (
              <SpinLoader type={typeList[2].prop} color={'#000'} width={1311} height={715} />
            )}
          </ListTable>
        </ListContainer>
      )}
    </>
  );
}

export default Errata;
